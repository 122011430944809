import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
  /* eslint-disable-next-line @angular-eslint/directive-selector */
  selector: "[lib-DisableEditor]"
})
export class DisableAngularEditorDirective implements OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("lib-DisableEditor") disableEditor = false;
  constructor(private el: ElementRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableEditor.currentValue) {
      let editors = this.el.nativeElement.getElementsByClassName("angular-editor-textarea");
      for (let i = 0; i < editors.length; i++) {
        editors.item(i)?.setAttribute("contenteditable", "false");
      }
    } else {
      let editors = this.el.nativeElement.getElementsByClassName("angular-editor-textarea");
      for (let i = 0; i < editors.length; i++) {
        if (!editors.item(i)?.classList.contains("disabled")) {
          editors.item(i)?.setAttribute("contenteditable", "true");
        }
      }
    }
  }
}
