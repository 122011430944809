import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { FlexModule } from "@vp/shared/directives/flex";
import { TopNavListModule } from "@vp/shared/top-nav/ui/top-nav-list";
import { TopNavLogoModule } from "@vp/shared/top-nav/ui/top-nav-logo";
import { TopNavSearchModule } from "@vp/shared/top-nav/ui/top-nav-search";
import { TopNavUserModule } from "@vp/shared/top-nav/ui/top-nav-user";
import { NgxPermissionsModule } from "ngx-permissions";
import { TopNavShellComponent } from "./top-nav-shell.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxsModule,
    FlexModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    TopNavUserModule,
    TopNavSearchModule,
    TopNavLogoModule,
    TopNavListModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: [TopNavShellComponent],
  exports: [TopNavShellComponent]
})
export class TopNavShellModule {}
