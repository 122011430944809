<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    currentRoute: (currentRoute$ | async),
    isLoggedIn: (authenticationService.isLoggedIn$() | async)
  }"
>
</ng-container>

<ng-template
  #template
  let-hubConnection="hubConnection"
  let-currentRoute="currentRoute"
  let-isLoggedIn="isLoggedIn"
>
  <div flexLayout="row" flexLayoutAlign="end center" flexLayoutGap="1rem">
    <div
      *ngIf="isLoggedIn?.isAuthenticated"
      [matMenuTriggerFor]="userMenu"
      class="user"
      flexLayout="row"
      flexLayoutAlign="end center"
      aria-label="User Menu"
    >
      <mat-icon color="primary" inline="true">account_circle</mat-icon>
      <div flexLayout="column" flexLayoutAlign="center stretch" flexShow.lt-lg="false">
        <span class="full-name">{{ userDisplayName$ | async }}</span>
        <span class="selected-role">{{ selectedRoleDisplayName$ | async }}</span>
      </div>
    </div>

    <mat-menu #userMenu="matMenu">
      <div
        mat-menu-item
        *ngxPermissionsOnly="[permConst.Global.ProfileDetailsPage.View]"
        [routerLink]="'/profile'"
        [queryParams]="{ returnUrl: currentRoute }"
      >
        {{ uiDisplayTagService.displayTags.profileNav }}
      </div>
      <div mat-menu-item [matMenuTriggerFor]="switchRoles">Switch Roles</div>
      <div mat-menu-item (click)="onLogout()">Logout</div>
    </mat-menu>

    <mat-menu #switchRoles="matMenu">
      <div
        *ngFor="let role of appStoreService.userRoles$ | async; trackBy: trackByFn"
        mat-menu-item
        (click)="switchUserRole(role.roleId)"
      >
        {{ role.displayName }}
      </div>
    </mat-menu>
  </div>
</ng-template>
