<div *ngIf="!props.showFormFieldOverlay" [class]="props.class">
  <h4 *ngIf="!props.hideLabel">{{ props.label }}</h4>
  <p>{{ formControl.value }}</p>
</div>

<div
  *ngIf="props.showFormFieldOverlay === true"
  flex="100"
  class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label mat-form-field-disabled"
>
  <div class="mat-form-field-wrapper">
    <div class="mat-form-field-flex">
      <div class="mat-form-field-infix">
        <span class="mat-form-field-label-wrapper" *ngIf="!props.hideLabel"
        ><label class="mat-form-field-label"
          ><mat-label>{{ props.label }}</mat-label></label
        ></span
      >
        <span
          class="mat-input-element mat-form-field-autofill-controlcdk-text-field-autofill-monitored"
        >
          {{ formControl.value }}
        </span>
      </div>
    </div>
  </div>
</div>
