import { EventBase } from "@vp/models";

export interface AppInsightsEventArgs {
  error: any;
  type: AppInsightsEventType;
  source?: string;
  average?: number | null | undefined;
  message?: string | null | undefined;
  properties?: { [key: string]: any } | null | undefined;
}

export class AppInsightsEvent extends EventBase<AppInsightsEventArgs> {
  constructor(private data: AppInsightsEventArgs) {
    super();
    this.args = this.data;

    if (this.args.type === "trace" && !this.args.message) {
      throw Error("message property is required for trace types");
    }

    if (!this.args.source && (this.args.type === "metric" || this.args.type === "event")) {
      throw Error("source property is required for metric or event types");
    }
  }
}

export declare type AppInsightsEventType = "event" | "exception" | "metric" | "pageView" | "trace";
