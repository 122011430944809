import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { OrganizationFeatures, RealTimeNotification } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { LoggerService } from "@vp/shared/logger-service";
import { filterNullMap } from "@vp/shared/operators";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { concatMap, filter, pairwise, take, tap } from "rxjs/operators";
import { SignalRApiService } from "./signal-r-api.service";
import * as SignalRStateActions from "./state/signal-r-state.actions";
import { SignalRState } from "./state/signal-r.state";

@Injectable({
  providedIn: "root"
})
export class SignalREventService {
  @Select(SignalRState.notifications) notifications$!: Observable<RealTimeNotification[]>;

  private listening$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store,
    private readonly signalRApiService: SignalRApiService,
    private readonly featureService: FeatureService,
    private readonly logger: LoggerService
  ) {}

  listen = () => {
    combineLatest([this.listening$, this.notifications$.pipe(pairwise())])
      .pipe(
        tap(([listening, [previous, current]]) => {
          const previousMap = new Map<string, RealTimeNotification>();
          previous.forEach((n: RealTimeNotification) =>
            previousMap.set(`${n.userId}-${n.groupName}`, n)
          );
          const toAdd: RealTimeNotification[] = [];
          current.forEach((n: RealTimeNotification) => {
            const key = `${n.userId}-${n.groupName}`;
            if (!previousMap.has(key)) {
              toAdd.push(n);
            } else {
              previousMap.delete(key);
            }
          });
          const toRemove = Array.from(previousMap.values());
          if (toRemove.length > 0) {
            this.signalRApiService.removeManyFromGroup(toRemove).subscribe();
          }
          if (listening == true && toAdd.length > 0) {
            this.signalRApiService.addManyToGroup(toAdd).subscribe();
          }
        })
      )
      .subscribe();

    this.featureService
      .feature$(OrganizationFeatures.signalR)
      .pipe(
        filterNullMap(),
        filter(f => f.enabled),
        take(1),
        concatMap(() => this.signalRApiService.initalize())
      )
      .subscribe(() => {
        this.signalRApiService.start();

        this.listening$.next(true);
        this.logger.systemEvent(
          `${this.constructor.name}`,
          "Signal-R is enabled. Hub started listening"
        );
      });
  };

  public stopListening() {
    this.listening$.next(false);
    return this.clearSubscriptions().pipe(
      take(1),
      tap(() => {
        this.signalRApiService.stop();
        this.logger.systemEvent(`${this.constructor.name}`, "Signal-R hub stopped listening");
      })
    );
  }

  public clearSubscriptions() {
    return this.store.dispatch(
      new SignalRStateActions.SetState({
        notifications: []
      })
    );
  }
}
