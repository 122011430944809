<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    form: (form$ | async) || null,
    legalNoticeRequested: (legalNoticeRequested$ | async) || false,
    toS: toS$ | async,
    acknowledgmentMessage: acknowledgmentMessage$ | async,
    buttonLabel: buttonLabel$ | async
  }"
>
</ng-container>

<ng-template
  #template
  let-form="form"
  let-legalNoticeRequested="legalNoticeRequested"
  let-toS="toS"
  let-acknowledgmentMessage="acknowledgmentMessage"
  let-buttonLabel="buttonLabel"
>
  <div flexLayout="row" flexLayoutAlign="center">
    <mat-card
      *ngIf="form && legalNoticeRequested"
      flexLayout="column"
      flexLayoutAlign="start"
      flex="50"
    >
      <form [formGroup]="form">
        <div [innerHTML]="toS"></div>
        <div
          class="action-row"
          flexLayout="row"
          flexLayoutAlign="space-between center"
          flexLayoutGap="16px"
        >
          <div flexLayout="row" flexLayoutAlign="start center"></div>
          <div groupGroupName="userData" flexLayout="row wrap" flexLayoutAlign="end center">
            <div></div>
            <mat-checkbox formControlName="legalNoticedAccepted" labelPosition="before">
              <span class="checkboxLabel" [innerHTML]="acknowledgmentMessage"> </span>
            </mat-checkbox>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="!form.valid"
              (click)="onAccept()"
              [innerHTML]="buttonLabel"
            ></button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</ng-template>
