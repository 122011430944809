<ng-container>
  <mat-tab-group #tabGroup>
    <mat-tab
      *ngFor="
        let tab of field.fieldGroup;
        trackBy: trackById;
        let tabIndex = index;
        let last = last
      "
    >
      <formly-field [field]="tab"></formly-field>
      <ng-template mat-tab-label>
        <div [class.tab-invalid]="!isValid(tabIndex)">{{ label | memoize: to:tab:tabIndex }}</div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
