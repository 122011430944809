import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import {
  DynamicLookupPipe,
  EllipsisPipe,
  GetNameForPipe,
  HasPermissionPipe,
  HideMaxDatePipe,
  MapFieldPipe,
  MemoizePipe,
  PathPartsPipe,
  SafePipe,
  ShortTimePipe,
  TableDatePipe,
  TitleTransformPipe
} from "./pipes";
import { RouterUtilities, RouterUtilityOptions } from "./router-utilities.service";
import { UTILITY_OPTIONS } from "./tokens";

const defaultConfig = (): RouterUtilityOptions => {
  return {
    routeDefault: "/"
  } as RouterUtilityOptions;
};

@NgModule({
  imports: [CommonModule],
  exports: [
    MapFieldPipe,
    DynamicLookupPipe,
    SafePipe,
    HasPermissionPipe,
    EllipsisPipe,
    GetNameForPipe,
    HideMaxDatePipe,
    MemoizePipe,
    ShortTimePipe,
    TableDatePipe,
    TitleTransformPipe,
    PathPartsPipe
  ],
  declarations: [
    MapFieldPipe,
    DynamicLookupPipe,
    SafePipe,
    HasPermissionPipe,
    EllipsisPipe,
    GetNameForPipe,
    HideMaxDatePipe,
    MemoizePipe,
    ShortTimePipe,
    TableDatePipe,
    TitleTransformPipe,
    PathPartsPipe
  ]
})
export class UtilitiesModule {
  static forRoot(
    options: RouterUtilityOptions | Record<string, never> = {}
  ): ModuleWithProviders<UtilitiesModule> {
    return {
      ngModule: UtilitiesModule,
      providers: [
        {
          provide: UTILITY_OPTIONS,
          multi: false,
          useFactory: defaultConfig,
          deps: [RouterUtilities]
        },
        { provide: UTILITY_OPTIONS, multi: false, useValue: options },
        RouterUtilities
      ]
    };
  }

  static forChild(
    options: RouterUtilityOptions | Record<string, never> = {}
  ): ModuleWithProviders<UtilitiesModule> {
    return {
      ngModule: UtilitiesModule,
      providers: [
        {
          provide: UTILITY_OPTIONS,
          multi: false,
          useFactory: defaultConfig,
          deps: [RouterUtilities]
        },
        { provide: UTILITY_OPTIONS, multi: false, useValue: options }
      ]
    };
  }
}
