import { newGuid } from "@vp/shared/utilities";
import { guid } from "guid-factory";

export interface IEvent {
  eventId: guid;
  eventTime: Date;
  handled: boolean;
  sender: string;
  args: any;
}

export class EventBase<T extends any> implements IEvent {
  readonly eventId: guid;
  readonly eventTime: Date;
  handled!: boolean;

  sender!: string;
  args!: T;

  constructor() {
    this.eventTime = new Date();
    this.eventId = newGuid();
    this.handled = false;
  }
}
