import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  SimpleChanges
} from "@angular/core";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { BaseDirective } from "../../base/base.directive";
import { FLEX_BREAKPOINTS } from "../model/flex-breakpoints";

@Directive({
  selector: `
  [flexShow], [flexShow.xs], [flexShow.sm], [flexShow.md], [flexShow.lg], [flexShow.xl], 
  [flexShow.lt-sm], [flexShow.lt-md], [flexShow.lt-lg],  [flexShow.lt-xl], 
  [flexShow.gt-xs], [flexShow.gt-sm], [flexShow.gt-md], [flexShow.gt-lg]
`
})
export class FlexShowDirective
  extends BaseDirective<string | boolean>
  implements OnInit, OnChanges
{
  private _originalDisplay: string = "";

  @Input() flexShow?: string | boolean;
  @Input("flexShow.xs") flexShow_xs?: string | boolean;
  @Input("flexShow.sm") flexShow_sm?: string | boolean;
  @Input("flexShow.md") flexShow_md?: string | boolean;
  @Input("flexShow.lg") flexShow_lg?: string | boolean;
  @Input("flexShow.xl") flexShow_xl?: string | boolean;
  @Input("flexShow.lt-sm") flexShow_lt_sm?: string | boolean;
  @Input("flexShow.lt-md") flexShow_lt_md?: string | boolean;
  @Input("flexShow.lt-lg") flexShow_lt_lg?: string | boolean;
  @Input("flexShow.lt-xl") flexShow_lt_xl?: string | boolean;
  @Input("flexShow.gt-xs") flexShow_gt_xs?: string | boolean;
  @Input("flexShow.gt-sm") flexShow_gt_sm?: string | boolean;
  @Input("flexShow.gt-md") flexShow_gt_md?: string | boolean;
  @Input("flexShow.gt-lg") flexShow_gt_lg?: string | boolean;

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._breakPointValueMap = {
      xs: this.flexShow_xs,
      sm: this.flexShow_sm,
      md: this.flexShow_md,
      lg: this.flexShow_lg,
      xl: this.flexShow_xl,
      lt_sm: this.flexShow_lt_sm,
      lt_md: this.flexShow_lt_md,
      lt_lg: this.flexShow_lt_lg,
      lt_xl: this.flexShow_lt_xl,
      gt_xs: this.flexShow_gt_xs,
      gt_sm: this.flexShow_gt_sm,
      gt_md: this.flexShow_gt_md,
      gt_lg: this.flexShow_gt_lg
    };

    const flexBreakpointInputs = Object.entries(changes)
      .filter(([key]) => key.includes("_"))
      .map(([inputKey]) => {
        return inputKey.replace(/flexShow_/, "");
      });
    const breakpointsMediaQuery = FLEX_BREAKPOINTS.filter(bp =>
      flexBreakpointInputs.includes(bp.alias)
    ).map(bp => bp.mediaQuery);
    this._breakpoints.next(breakpointsMediaQuery);
  }

  ngOnInit(): void {
    this.getOriginalDisplay();
    this.setupResponsiveObservers();
    this._currentValue
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(flexShowValue => this.applyFlexShow(flexShowValue));
  }

  private getOriginalDisplay() {
    const nativeElement = this.elementRef.nativeElement;
    if (!nativeElement) {
      return;
    }
    const computedStyle = window.getComputedStyle(nativeElement);
    this._originalDisplay = computedStyle.getPropertyValue("display");
  }

  private applyFlexShow(flexShowValue?: string | boolean) {
    const nativeElement = this.elementRef.nativeElement;
    if (!nativeElement) {
      return;
    }
    let show = true;
    switch (typeof flexShowValue) {
      case "string":
        show = flexShowValue === "true" || flexShowValue === "";
        break;
      case "boolean":
        show = flexShowValue;
        break;
      default:
        break;
    }
    const displayStyle = show ? this._originalDisplay || "block" : "none";
    this.renderer.setStyle(nativeElement, "display", displayStyle, RendererStyleFlags2.Important);
  }
}
