import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { of } from "rxjs";

@Injectable()
export class CasetypesFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  provide(state: Record<string, unknown>) {
    if (state?.filterCaseTypes) {
      const caseTypes = this.store.selectSnapshot(CaseTypesState.allCaseTypes);
      return of({ caseTypes: caseTypes });
    }
    return of({});
  }
}
