import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-multi-schema-type",
  templateUrl: "./formly-multi-schema-type.component.html",
  styleUrls: ["./formly-multi-schema-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMultiSchemaTypeComponent extends FieldType {
  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
