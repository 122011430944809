import { Inject, Injectable, Injector, OnDestroy } from "@angular/core";
import { Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { EventAggregator } from "@vp/data-access/application";
import { OrganizationFeatures } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Subject, combineLatest, of } from "rxjs";
import { mergeMap, take, takeUntil } from "rxjs/operators";
import { NotificationEvent } from "./notification-event";

//these classes are stored within toastr.css within the ngx-toastr module
const validPositionClasses = [
  "toast-top-right",
  "toast-bottom-right",
  "toast-bottom-left",
  "toast-top-left",
  "toast-top-full-width",
  "toast-bottom-full-width",
  "toast-top-center",
  "toast-bottom-center",
  "toast-center-center"
];

@Injectable({
  providedIn: "root"
})
export class NotificationService implements OnDestroy {
  private positionClass: string[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(Injector) private injector: Injector,
    private eventAggregator: EventAggregator,
    private featureService: FeatureService,
    private store: Store
  ) {
    this.eventAggregator
      .on<NotificationEvent>(NotificationEvent)
      .pipe(
        mergeMap(e => {
          const toastrService: ToastrService = this.injector.get<ToastrService>(ToastrService);
          return combineLatest([
            of(e),
            toastrService[e.args.type](e.args.message, e.args.title).onShown
          ]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(([ne, _]: [NotificationEvent, void]) => {
        this.store.dispatch(new ApplicationStateActions.EventHandled(ne));
      });

    this.featureService
      .configurationLists$(OrganizationFeatures.toastrPositionClass)
      .pipe(filterNullMap(), take(1))
      .subscribe((featureData: Record<string, string[]>) => {
        this.positionClass = featureData["positionClass"];
      });
  }

  listen() {
    console.log("notificatin service started");
  }

  // This will never get called because this service is a singleton, this should be removed
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkPositionClass(options: Partial<IndividualConfig>): Partial<IndividualConfig> {
    if (this.positionClass.length > 0) {
      if (validPositionClasses.includes(this.positionClass[0])) {
        return { ...options, positionClass: this.positionClass[0] };
      }
    }
    return options;
  }

  successMessage(
    message: string,
    title: string | null = "Success",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector
      .get(ToastrService)
      .success(message, title ?? undefined, this.checkPositionClass(options));
  }

  errorMessage(
    message: string,
    title: string | null = "Error",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector
      .get(ToastrService)
      .error(message, title ?? undefined, this.checkPositionClass(options));
  }

  infoMessage(
    message: string,
    title: string | null = "Info",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector
      .get(ToastrService)
      .info(message, title ?? undefined, this.checkPositionClass(options));
  }

  warningMessage(
    message: string,
    title: string | null = "Warning",
    options: Partial<IndividualConfig> = {}
  ): void {
    this.injector
      .get(ToastrService)
      .warning(message, title ?? undefined, this.checkPositionClass(options));
  }
}
