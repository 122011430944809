<ng-container
  *ngIf="{
    loading: loading | async
  } as vm"
>
  <div
    *ngIf="vm.loading"
    flexLayout="row"
    flexLayoutAlign="center center"
    [class.overlay]="overlay === true"
  >
    <mat-spinner [diameter]="diameter"></mat-spinner>
  </div>
</ng-container>
