<mat-form-field flexLayout="row">
  <mtx-datetimepicker
    #datetimePicker
    [type]="type"
    [mode]="mode"
    [multiYearSelector]="multiYearSelector"
    [startView]="startView"
    [twelvehour]="twelvehour"
    [timeInterval]="timeInterval"
    [touchUi]="touchUi"
    [timeInput]="timeInput"
  >
  </mtx-datetimepicker>
  <mat-label>{{ props.label }}</mat-label>
  <input
    [mtxDatetimepicker]="datetimePicker"
    [max]="maxDate"
    [min]="minDate"
    [formControl]="formControl"
    [placeholder]="'Date & Time'"
    matInput
  />
  <mtx-datetimepicker-toggle [for]="datetimePicker" matSuffix></mtx-datetimepicker-toggle>
</mat-form-field>
