import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { DialogComponent } from "./generic-dialog.component";
import { DialogData, DialogOptions } from "./generic-dialog.module";
import { GenericDialog } from "./generic-dialog.service";

@Injectable({
  providedIn: "root"
})
export class DialogFactoryService<T = undefined> {
  constructor(
    private dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver
  ) {}

  isSmall: Observable<BreakpointState> = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.TabletPortrait,
    Breakpoints.WebPortrait
  ]);

  defaultOptions: DialogOptions = {
    width: "40vw",
    disableClose: true,
    maxWidth: "100vw",
    minHeight: "90vh"
  };

  open(dialogData: DialogData<T>, options: Partial<DialogOptions>): GenericDialog<T> {
    const mergedOptions = { ...this.defaultOptions, ...options };
    const dialogRef = this.dialog.open<DialogComponent<T>, DialogData<T>>(DialogComponent, {
      ...mergedOptions,
      data: dialogData,
      panelClass: "mat-mdc-dialog"
    });

    const breakpointSubsription = this.isSmall.subscribe(size => {
      if (size.matches) {
        dialogRef.updateSize("100vw", "100vh");
      } else {
        dialogRef.updateSize(mergedOptions.width, mergedOptions.minHeight);
      }
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        breakpointSubsription.unsubscribe();
      });

    return new GenericDialog(dialogRef);
  }
}
