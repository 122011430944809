import { DevicePowerStatus, EventBase } from "@vp/models";

export interface DevicePowerStatusNotification {
  deviceUserId: string;
  devicePowerStatus: DevicePowerStatus;
}

export class DevicePowerStatusUpdatedEvent extends EventBase<DevicePowerStatusNotification> {
  constructor(notification: DevicePowerStatusNotification) {
    super();
    this.args = notification;
  }
}
