import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  selector: "[vpDynamicTemplate]"
})
export class DynamicTemplateDirective {
  @Input("vpDynamicTemplate") id: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public template: TemplateRef<any>) {}
}
