import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { OrganizationFeatures } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { Observable, of } from "rxjs";
import { filter, take, tap, withLatestFrom } from "rxjs/operators";
import { DialogComponent } from "./dialog/dialog.component";

@Injectable({
  providedIn: "root"
})
export class SharedConfirmationService {
  dialogRef!: MatDialogRef<DialogComponent>;
  shouldAutoConfirm = false;

  constructor(
    private readonly dialog: MatDialog,
    private featureService: FeatureService
  ) {}

  /**
   * Configure a new instance of the confirmation dialog.
   * @param message Question to ask
   * @param title Large header text
   * @param action Confirmaion label for button
   * @param dialogueName Optional name for dialogue to compare to autoConfirmFeature
   * @param maxWidth Optional string for max width in pixels (ie: "400px")
   * @example
   * ```
   * this.sharedConfirmationService.open("Want to delete?")
   *   .afterConfirmed().subscribe({
         next: () => alert("Confirmed")
       });
    ```
   */
  setShouldAutoConfirm(dialogueName: string) {
    this.featureService
      .configurationLists$(OrganizationFeatures.autoConfirmDialogues)
      .pipe(
        filterNullMap(),
        withLatestFrom(
          this.featureService.featureEnabled$(OrganizationFeatures.autoConfirmDialogues)
        ),
        tap(([featureData, enabled]: [Record<string, string[]>, boolean]) => {
          const dialoguesToSkip = featureData["dialoguesToAutoConfirm"];
          if (enabled && Array.isArray(dialoguesToSkip) && dialoguesToSkip.includes(dialogueName)) {
            this.shouldAutoConfirm = true;
          }
        })
      )
      .subscribe();
  }

  open(
    message: string,
    action = "Ok",
    title = "Confirm?",
    dialogueName?: string,
    maxWidth?: string,
    showCancel = true,
    disableClose = false,
    confirmColor = "primary"
  ) {
    if (dialogueName) {
      this.setShouldAutoConfirm(dialogueName);
    }

    this.dialogRef = this.dialog.open(DialogComponent, {
      maxWidth: maxWidth ? maxWidth : "400px",
      disableClose: disableClose,
      data: {
        message,
        action,
        title,
        showCancel,
        confirmColor
      },
      panelClass: "mat-mdc-dialog-confirm"
    });

    return {
      afterClosed: this.afterClosed.bind(this),
      afterConfirmed: this.afterConfirmed.bind(this),
      afterConfirmedOrSkipped: this.afterConfirmedOrSkipped.bind(this),
      afterConfirmedOrSkippedResult: this.afterConfirmedOrSkippedResult.bind(this),
      afterCanceled: this.afterCanceled.bind(this)
    };
  }

  /**
   * Get result when the dialog is closed.
   * Use switchMap to invoke another observable
   * @returns Observable<boolean>
   */
  private afterClosed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(take(1));
  }

  /**
   * Convenience method when the dialog is confirmed.
   * Use switchMap to invoke another observable
   * @returns Observable<true>
   */
  private afterConfirmed(): Observable<true> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      filter(result => result === true)
    );
  }

  /**
   * Convenience method when the dialog is confirmed or should be skipped via the SkipDialogueFeature.
   * Use switchMap to invoke another observable
   * @returns Observable<true>
   */
  private afterConfirmedOrSkipped(): Observable<boolean> {
    if (this.shouldAutoConfirm) {
      this.dialogRef.close();
      return of(true);
    } else {
      return this.dialogRef.afterClosed().pipe(
        filter(result => result === true),
        take(1)
      );
    }
  }

  private afterConfirmedOrSkippedResult(): Observable<boolean> {
    if (this.shouldAutoConfirm) {
      this.dialogRef.close();
      return of(true);
    } else {
      return this.dialogRef.afterClosed().pipe(take(1));
    }
  }

  /**
   * Convenience method when the dialog is canceled.
   * Use switchMap to invoke another observable
   * @returns Observable<false>
   */
  private afterCanceled(): Observable<false> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      filter(result => result === false)
    );
  }
}
