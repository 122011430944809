import { Pipe, PipeTransform } from "@angular/core";
import { CaseData } from "@vp/models";
import { getValueAtPath } from "@vp/shared/utilities";

@Pipe({
  name: "caseRecordData"
})
export class CaseRecordDataPipe implements PipeTransform {
  /**
   *
   * @param caseData
   * @param path
   * @returns
   */
  transform(caseData: CaseData, path: string): string | string[] {
    const pathParts = path.split(".");
    const value = getValueAtPath(caseData.recordData, pathParts);
    return value ?? "";
  }
}
