import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatBadgeModule } from "@angular/material/badge";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavigationCancel, Router, RouterModule } from "@angular/router";
import { FullCalendarModule } from "@fullcalendar/angular";
import { MtxDatetimepickerModule } from "@ng-matero/extensions/datetimepicker";
import { MtxPopoverModule } from "@ng-matero/extensions/popover";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { ApplicationState, EventAggregator } from "@vp/data-access/application";
import {
  CASE_SUMMARY_API_BASE_URL,
  CaseApiService,
  CaseState,
  CaseSummaryService
} from "@vp/data-access/case";
import { CASE_TYPES_API_BASE_URL, CaseTypesState } from "@vp/data-access/case-types";
import {
  CONTENT_MANAGEMENT_API_BASE_URL,
  ContentApiService,
  ContentFilterState
} from "@vp/data-access/content-management";
import { GROUPS_API_BASE_URL, GroupsState } from "@vp/data-access/groups";
import { MessagesState } from "@vp/data-access/message";
import { OrganizationState } from "@vp/data-access/organization";
import { TAG_API_BASE_URL, TagsApiService, TagsState } from "@vp/data-access/tags";
import { USER_API_BASE_URL, UserApiService, UserState } from "@vp/data-access/users";
import { FormlyJsonSchemaModule, JsonSchemaValueProviderService } from "@vp/formly/json-schema";
import { FORM_STATE_PROVIDERS } from "@vp/formly/ui-schema-config";
import { AuthenticationHeaderInterceptor, ServerErrorInterceptor } from "@vp/interceptors";
import { AJV_CLASS, AJV_INSTANCE, JsonSchemaValidationService } from "@vp/json-schema-validation";
import { AccessControlService } from "@vp/shared/access-control";
import { AppInsightsProxyService } from "@vp/shared/app-insights-proxy-service";
import { AuthenticationService } from "@vp/shared/authentication";
import { BreadcrumbModule } from "@vp/shared/components/breadcrumb";
import { PageMenuModule } from "@vp/shared/components/page-menu";
import { SecurityPopupModule } from "@vp/shared/components/security-popup";
import { CybersourceModule } from "@vp/shared/cybersouce";
import { FlexModule } from "@vp/shared/directives/flex";
import { FeatureInterceptor, FeatureService, FeaturesModule } from "@vp/shared/features";
import {
  CasetypesFormStateProviderService,
  ConditionalReadOnlyFieldsFormStateProviderService,
  ContentTagFormStateProviderService,
  CountryDropdownFormStateProviderService,
  CountryTagFormStateProviderService,
  RolesFormStateProviderService,
  TagsFormStateProviderService
} from "@vp/shared/form-state-providers";
import {
  AuthenticationCanActivateGuard,
  AuthenticationGuardService,
  IS_IVY_API
} from "@vp/shared/guards";
import {
  LOCAL_STORAGE_OPTIONS,
  LocalStorageModule,
  LocalStorageService
} from "@vp/shared/local-storage";
import { LocaleService } from "@vp/shared/locale-service";
import { ConsoleLoggingService, LoggerService } from "@vp/shared/logger-service";
import { MaterialModule } from "@vp/shared/material";
import { NotificationService } from "@vp/shared/notification-service";
import {
  PERMISSIONS_CONST_ENVIRONMENT_IS_PROD,
  PermissionsConstService
} from "@vp/shared/permissions-const";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { SignalRApiService, SignalRState } from "@vp/shared/signal-r-service";
import { HttpStateService, SpinnerInterceptorService, SpinnerModule } from "@vp/shared/spinner";
import { APP_STORE_API_BASE_URL, AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { SystemBannerModule } from "@vp/shared/system-banner";
import {
  ALLOW_REGISTRATION,
  AMBRA_BASE_URL,
  ANGULAR_EDITOR_CONFIG,
  APIM_SUB_KEY,
  APIM_TRACE,
  API_BASE_URL,
  APP_INSIGHTS_KEY,
  FUNC_ACCT_STOR_NAME,
  IVY_APP_SECRET,
  IVY_SUB_KEY,
  IVY_SUFFIX,
  IVY_URLS,
  IVY_USER,
  OKTA_CONSUMER_ENV,
  OKTA_LOGIN_BRAND,
  OKTA_ROUTES,
  TINYMCE_API_KEY,
  TINYMCE_DEFAULT_CONFIG
} from "@vp/shared/tokens";
import { TopNavFeatureModule } from "@vp/shared/top-nav/feature";
import { TopNavShellModule } from "@vp/shared/top-nav/ui/top-nav-shell";
import { AppSettingsResolverService, UTILITY_OPTIONS } from "@vp/shared/utilities";
import { TagManagerState } from "@vp/tag-manager/data-access/tag-manager-state";
import { ThemeManagerModule } from "@vp/theme-manager";
import { UserProfileGuard, UserProfileGuardService } from "@vp/user-profile/feature";
import {
  ADVANCED_COMMANDS_API_BASE_URL,
  AdvancedCommandsService
} from "@vp/virtual-care/feature-advanced-commands";
import { default as Ajv, default as ajv } from "ajv";
import {
  AuthModule,
  ConfigurationService,
  EventTypes,
  LogLevel,
  PublicEventsService
} from "angular-auth-oidc-client";
import {
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module
} from "ng-recaptcha";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { MomentModule } from "ngx-moment";
import { NgxPermissionsModule, NgxPermissionsService } from "ngx-permissions";
import { ToastrModule } from "ngx-toastr";
import { filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { config } from "../environments/environment.config";
import { accessControlFactory } from "./access-control.factory";
import { angularEditorConfigDefault, configureAuth, tinyMCEDefaultConfig } from "./app-config";
import { CONFIG_DEPENDENCIES, appInitializerFactory } from "./app-initializer.factory";
import { AppRoutingModule } from "./app-routing.module";
import { appThemeManagerConfig } from "./app-theme-manager.config";
import { AppComponent } from "./app.component";
import { GlobalErrorHandler } from "./global-error-handler";
import { LogoutComponent } from "./logout/logout.component";
import { ErrorComponent } from "./pages/error/error.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null; // for use with ngx-mask

@NgModule({
  declarations: [AppComponent, LogoutComponent, ErrorComponent],
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        ApplicationState,
        SignalRState,
        CaseState,
        ContentFilterState,
        CaseTypesState,
        GroupsState,
        OrganizationState,
        TagsState,
        UserState,
        MessagesState,
        TagManagerState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          injectContainerState: false
        }
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    AuthModule.forRoot({
      config: {
        authority: environment.authServer,
        redirectUrl: `${window.location.origin}/login/callback`,
        clientId: environment.oktaClientId,
        scope: "openid profile email",
        responseType: "code",
        postLogoutRedirectUri: `${window.location.origin}/loggedout`,
        startCheckSession: false,
        silentRenew: true,
        silentRenewUrl: `${window.location.origin}/silent-renew.html`,
        postLoginRoute: "/loggedin",
        forbiddenRoute: "/forbidden",
        unauthorizedRoute: "/login",
        logLevel: LogLevel.Warn,
        historyCleanupOff: true,
        disableIatOffsetValidation: true,
        renewTimeBeforeTokenExpiresInSeconds: 120
      }
    }),
    BrowserAnimationsModule,
    BrowserModule,
    BreadcrumbModule,
    FeaturesModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatBadgeModule,
    MatInputModule,
    MaterialModule,
    MtxPopoverModule,
    MtxDatetimepickerModule,
    PageMenuModule,
    RouterModule,
    MaterialModule,
    SpinnerModule,
    ContextDisplayModule,
    FlexModule,
    FullCalendarModule,
    HttpClientModule,
    LocalStorageModule,
    MatDatepickerModule,
    MaterialModule,
    MomentModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    RxReactiveFormsModule,
    SpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    }),
    NgxPermissionsModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    ThemeManagerModule.forRoot(appThemeManagerConfig),
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    FeaturesModule,
    CybersourceModule,
    TopNavFeatureModule,
    TopNavShellModule,
    SecurityPopupModule,
    SystemBannerModule,
    FormlyJsonSchemaModule.forRoot({
      providers: [],
      uischema: {
        layouts: [
          {
            types: ["payment"],
            elements: [
              {
                scope: "#/properties/payment",
                definition: "payment-page",
                formlyConfig: {}
              }
            ]
          },
          {
            types: ["images"],
            elements: [
              {
                scope: "#/properties/images",
                definition: "dicoms"
              }
            ]
          }
        ],
        definitions: [
          {
            types: ["email-field"],
            formlyConfig: {
              templateOptions: {
                type: "email",
                maxLength: 255
              },
              validators: {
                validation: ["email"]
              }
            }
          },
          {
            types: ["facesheet-selector"],
            formlyConfig: {
              type: "flex-layout",
              key: "facesheet",
              templateOptions: {
                label: "Facesheet Upload",
                fxLayout: "column",
                fxLayoutAlign: "start stretch"
              },
              fieldGroup: [
                {
                  key: "selUpload",
                  type: "checkbox",
                  templateOptions: {
                    label: "Check for facesheet upload.",
                    fxLayoutAlign: "end"
                  }
                },
                {
                  key: "facesheet-actions",
                  type: "facesheet-selector",
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  hideExpression: (model: any) => {
                    console.log(JSON.stringify(model));
                    return !model?.selUpload;
                  }
                }
              ]
            }
          },
          {
            types: ["attachments"],
            formlyConfig: {
              name: "attachments",
              templateOptions: {
                label: "Attachments",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "document-data",
                  type: "document-data",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-actions",
                  type: "document-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-grid",
                  type: "document-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-actions",
                  type: "image-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-grid",
                  type: "image-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["documents"],
            formlyConfig: {
              name: "documents",
              templateOptions: {
                label: "Documents",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "document-data",
                  type: "document-data",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-actions",
                  type: "document-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-grid",
                  type: "document-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["images"],
            formlyConfig: {
              name: "images",
              templateOptions: {
                label: "Images",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "image-data",
                  type: "image-data",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-actions",
                  type: "image-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-grid",
                  type: "image-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["dicoms"],
            formlyConfig: {
              name: "dicoms",
              templateOptions: {
                label: "Imaging",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "dicoms-data",
                  type: "image-data", // Reusing image-data component
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  template: `
                <div style='margin: 16px !important'>
                  <div>
                    <span><strong>1.</strong></span>
                    Please place the CD in the CD drive of your computer, or insert the USB Flash Drive you were given after receiving radiology imaging back from the facility you had radiologic imaging done.
                  </div>
                  <div>
                    <span><strong>2.</strong></span>
                    Close any other browser windows.
                  </div>
                  <div>
                    <span><strong>3.</strong></span>
                    Click on "Upload DICOM Folder" button.
                  </div>
                  <div>
                    <span ><strong>4.</strong></span>
                    Click Select Folder from your CD or USB Flash Drive.
                  </div>
                  <div>
                    <span><strong>5.</strong></span>
                    If the facility you had imaging done provided you with a download link please ensure the files are downloaded and unzipped. Export the files to a folder on your desktop and select the folder.
                  </div>
                  <div>
                    <span><strong>6.</strong></span>
                    Click the Upload button.
                  </div>
                </div>
                `
                },
                {
                  key: "dicoms-actions",
                  type: "dicoms-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "dicoms-grid",
                  type: "dicoms-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["assignments"],
            formlyConfig: {
              name: "assignments",
              templateOptions: {
                label: "Assignments",
                order: 3,
                fxLayout: "row wrap"
              },
              fieldGroup: [
                {
                  key: "assign-groups",
                  type: "assign-groups",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["state-selector"],
            formlyConfig: {
              type: "select",
              templateOptions: {
                options: [
                  {
                    label: "Alabama",
                    value: "AL"
                  },
                  {
                    label: "Alaska",
                    value: "AK"
                  },
                  {
                    label: "Arizona",
                    value: "AZ"
                  },
                  {
                    label: "Arkansas",
                    value: "AR"
                  },
                  {
                    label: "California",
                    value: "CA"
                  },
                  {
                    label: "Colorado",
                    value: "CO"
                  },
                  {
                    label: "Connecticut",
                    value: "CT"
                  },
                  {
                    label: "Delaware",
                    value: "DE"
                  },
                  {
                    label: "District Of Columbia",
                    value: "DC"
                  },
                  {
                    label: "Florida",
                    value: "FL"
                  },
                  {
                    label: "Georgia",
                    value: "GA"
                  },
                  {
                    label: "Guam",
                    value: "GU"
                  },
                  {
                    label: "Hawaii",
                    value: "HI"
                  },
                  {
                    label: "Idaho",
                    value: "ID"
                  },
                  {
                    label: "Illinois",
                    value: "IL"
                  },
                  {
                    label: "Indiana",
                    value: "IN"
                  },
                  {
                    label: "Iowa",
                    value: "IA"
                  },
                  {
                    label: "Kansas",
                    value: "KS"
                  },
                  {
                    label: "Kentucky",
                    value: "KY"
                  },
                  {
                    label: "Louisiana",
                    value: "LA"
                  },
                  {
                    label: "Maine",
                    value: "ME"
                  },
                  {
                    label: "Marshall Islands",
                    value: "MH"
                  },
                  {
                    label: "Maryland",
                    value: "MD"
                  },
                  {
                    label: "Massachusetts",
                    value: "MA"
                  },
                  {
                    label: "Michigan",
                    value: "MI"
                  },
                  {
                    label: "Minnesota",
                    value: "MN"
                  },
                  {
                    label: "Mississippi",
                    value: "MS"
                  },
                  {
                    label: "Missouri",
                    value: "MO"
                  },
                  {
                    label: "Montana",
                    value: "MT"
                  },
                  {
                    label: "Nebraska",
                    value: "NE"
                  },
                  {
                    label: "Nevada",
                    value: "NV"
                  },
                  {
                    label: "New Hampshire",
                    value: "NH"
                  },
                  {
                    label: "New Jersey",
                    value: "NJ"
                  },
                  {
                    label: "New Mexico",
                    value: "NM"
                  },
                  {
                    label: "New York",
                    value: "NY"
                  },
                  {
                    label: "North Carolina",
                    value: "NC"
                  },
                  {
                    label: "North Dakota",
                    value: "ND"
                  },
                  {
                    label: "Northern Mariana Islands",
                    value: "MP"
                  },
                  {
                    label: "Ohio",
                    value: "OH"
                  },
                  {
                    label: "Oklahoma",
                    value: "OK"
                  },
                  {
                    label: "Oregon",
                    value: "OR"
                  },
                  {
                    label: "Palau",
                    value: "PW"
                  },
                  {
                    label: "Pennsylvania",
                    value: "PA"
                  },
                  {
                    label: "Puerto Rico",
                    value: "PR"
                  },
                  {
                    label: "Rhode Island",
                    value: "RI"
                  },
                  {
                    label: "South Carolina",
                    value: "SC"
                  },
                  {
                    label: "South Dakota",
                    value: "SD"
                  },
                  {
                    label: "Tennessee",
                    value: "TN"
                  },
                  {
                    label: "Texas",
                    value: "TX"
                  },
                  {
                    label: "Utah",
                    value: "UT"
                  },
                  {
                    label: "Vermont",
                    value: "VT"
                  },
                  {
                    label: "Virgin Islands",
                    value: "VI"
                  },
                  {
                    label: "Virginia",
                    value: "VA"
                  },
                  {
                    label: "Washington",
                    value: "WA"
                  },
                  {
                    label: "West Virginia",
                    value: "WV"
                  },
                  {
                    label: "Wisconsin",
                    value: "WI"
                  },
                  {
                    label: "Wyoming",
                    value: "WY"
                  }
                ]
              }
            }
          },
          {
            types: ["payment-page"],
            formlyConfig: {
              type: "flex-layout",
              key: "payment",
              name: "payment",
              templateOptions: {
                label: "Payment",
                actions: {
                  payment: {
                    submit: true
                  }
                }
              },
              fieldGroup: [
                {
                  key: "cybersource",
                  type: "cybersource-payment"
                }
              ]
            }
          }
        ]
      }
    }),
    FormlyModule.forRoot({
      extras: {
        checkExpressionOn: "modelChange"
      },
      validators: [
        { name: "requiredTrue", validation: Validators.requiredTrue },
        { name: "email", validation: Validators.email }
      ],
      validationMessages: [{ name: "email", message: "A Valid email address is required" }],
      types: []
    })
  ],
  providers: [
    { provide: ALLOW_REGISTRATION, useValue: config.allowRegistration },
    { provide: API_BASE_URL, useValue: config.baseApi },
    { provide: OKTA_ROUTES, useValue: config.oktaApiRoutes },
    { provide: IVY_URLS, useValue: config.ivyUrls },
    { provide: IVY_SUFFIX, useValue: config.ivySuffix },
    { provide: IVY_SUB_KEY, useValue: config.ivyApimSubscriptionKey },
    { provide: APIM_TRACE, useValue: config.apimTrace },
    { provide: APIM_SUB_KEY, useValue: config.apimSubscriptionKey },
    { provide: APP_INSIGHTS_KEY, useValue: config.instrumentationKey },
    { provide: FUNC_ACCT_STOR_NAME, useValue: config.functionStorageAccountName },
    { provide: OKTA_LOGIN_BRAND, useValue: config.oktaLoginBrand },
    { provide: OKTA_CONSUMER_ENV, useValue: config.consumerOktaEnv },
    { provide: IVY_USER, useValue: config.ivyUser },
    { provide: IVY_APP_SECRET, useValue: config.ivyAppSecret },
    { provide: AMBRA_BASE_URL, useValue: config.ambraViewerBaseUrl },
    { provide: APP_STORE_API_BASE_URL, useValue: config.baseApi },
    { provide: CASE_SUMMARY_API_BASE_URL, useValue: config.baseApi },
    { provide: CASE_TYPES_API_BASE_URL, useValue: `${config.baseApi}/casetype` },
    { provide: GROUPS_API_BASE_URL, useValue: `${config.baseApi}/group` },
    { provide: PERMISSIONS_CONST_ENVIRONMENT_IS_PROD, useValue: environment.production },
    { provide: TAG_API_BASE_URL, useValue: `${config.baseApi}/tags` },
    { provide: USER_API_BASE_URL, useValue: config.baseApi },
    { provide: CONTENT_MANAGEMENT_API_BASE_URL, useValue: config.baseApi },
    { provide: ADVANCED_COMMANDS_API_BASE_URL, useValue: config.baseApi },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FeatureInterceptor, multi: true, deps: [Store] },
    { provide: TINYMCE_API_KEY, useValue: config.tinyMceApiKey },
    { provide: TINYMCE_DEFAULT_CONFIG, useValue: tinyMCEDefaultConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptorService,
      multi: true,
      deps: [HttpStateService]
    },
    ConfigurationService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.reCaptchaSiteKey,
        badge: "inline"
      } as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaSecretKey
    },
    { provide: AJV_CLASS, useValue: ajv },
    {
      provide: AJV_INSTANCE,
      useFactory: (ajvClass: typeof Ajv) => {
        // TODO - remove schema property in database before turning on strict mode
        return new ajvClass({ strict: false });
      },
      deps: [AJV_CLASS]
    },
    {
      provide: ANGULAR_EDITOR_CONFIG,
      useValue: angularEditorConfigDefault
    },
    {
      provide: EventAggregator,
      useClass: EventAggregator,
      deps: [Store]
    },
    {
      provide: LoggerService,
      useClass: LoggerService,
      deps: [Store]
    },

    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [LoggerService, EventAggregator]
    },

    {
      provide: ConsoleLoggingService,
      useClass: ConsoleLoggingService,
      deps: [EventAggregator, ApplicationState]
    },

    {
      provide: AppInsightsProxyService,
      useClass: AppInsightsProxyService,
      deps: [APP_INSIGHTS_KEY, EventAggregator, Store]
    },
    {
      provide: NotificationService,
      useClass: NotificationService,
      deps: [Injector, EventAggregator, FeatureService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      // WARNING! These deps need to match that of the appIntializerFactory DI
      deps: [
        AccessControlService,
        AppStoreService,
        AuthenticationService,
        CONFIG_DEPENDENCIES,
        FeatureService,
        IS_IVY_API,
        LocalStorageService,
        PermissionsConstService,
        Router,
        Store,
        UiDisplayTagService,
        ConsoleLoggingService,
        NotificationService,
        AppInsightsProxyService
      ]
    },
    {
      provide: AccessControlService,
      deps: [FeatureService, AppStoreService, LoggerService, NgxPermissionsService, Router]
    },

    { provide: UserProfileGuard, useClass: UserProfileGuardService },
    {
      provide: AuthenticationCanActivateGuard,
      useClass: AuthenticationGuardService
    },
    {
      provide: CONFIG_DEPENDENCIES,
      // Use a factory that return an array of dependant functions to be executed
      useFactory: (accessControlService: AccessControlService) => {
        // Easy to add or remove dependencies
        return [accessControlFactory(accessControlService)];
      },
      deps: [AccessControlService]
    },
    {
      provide: IS_IVY_API,
      useFactory: () => {
        const fromHost = window.location.href;
        return environment.ivySiteUrls.some(x => fromHost.includes(x));
      }
    },

    JsonSchemaValueProviderService,
    {
      provide: UTILITY_OPTIONS,
      useValue: {
        routeDefault: "/home"
      }
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (localeService: LocaleService) => {
        return localeService.getDateFormat();
      },
      deps: [LocaleService]
    },
    {
      provide: LOCAL_STORAGE_OPTIONS,
      useValue: {
        storageName: "app",
        maxAgeSeconds: 600
      }
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: TagsFormStateProviderService,
      multi: true,
      deps: [TagsApiService, Store, TagsState]
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: RolesFormStateProviderService,
      multi: true,
      deps: [Store, OrganizationState]
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: CountryTagFormStateProviderService,
      multi: true,
      deps: [Store, TagsState, ApplicationState]
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: CountryDropdownFormStateProviderService,
      multi: true
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: ContentTagFormStateProviderService,
      multi: true
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: CasetypesFormStateProviderService,
      multi: true
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: ConditionalReadOnlyFieldsFormStateProviderService,
      multi: true,
      deps: [Store, TagManagerState, OrganizationState, ApplicationState]
    },
    AppSettingsResolverService,
    JsonSchemaValidationService,
    AdvancedCommandsService,
    AppStoreService,
    SignalRApiService,
    TagsApiService,
    CaseApiService,
    ContentApiService,
    CaseSummaryService,
    PermissionsConstService,
    UserApiService,
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private eventService: PublicEventsService,
    private route: Router
  ) {
    this.route.events.pipe(filter(event => event instanceof NavigationCancel)).subscribe();

    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => {
          return notification.type === EventTypes.CheckSessionReceived;
        })
      )
      .subscribe();
  }
}
