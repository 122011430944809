import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { FieldArrayType, FieldArrayTypeConfig } from "@ngx-formly/core";

import { debounceTime, distinctUntilChanged, filter, startWith } from "rxjs/operators";

@Component({
  selector: "vp-custom-multi-select",
  templateUrl: "./custom-multi-select.component.html",
  styleUrls: ["./custom-multi-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Formly version 6 adds a validator to validate selected value
 * against the options in the multi select dropdown,
 * the issue is it uses the initial option values from the Json Schema,
 * if the options are dynamically generated, the field will always show
 * that it has error.
 * This custom formly component was created due to the formly bug above
 * as a temp workaround.
 * Github bug ref: https://github.com/ngx-formly/ngx-formly/issues/3873
 */
export class CustomMultiSelectComponent
  extends FieldArrayType<FieldArrayTypeConfig>
  implements OnInit
{
  selectedOptions = [];

  ngOnInit(): void {
    this.options.fieldChanges
      ?.pipe(filter(change => change.field === this.field))
      .subscribe(change => {
        // clear form selected options when options changed
        if (change["property"]?.includes("options")) {
          (this.formControl as FormArray).clear();
          if (this.field.parent) {
            this.field.parent.model[this.key as string] = [];
          }
        }
      });

    this.formControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), startWith(this.formControl.value))
      .subscribe(value => {
        this.selectedOptions = value;
      });
  }

  change($event: MatSelectChange) {
    const formArray = this.formControl as FormArray;

    const newValue = $event.value;
    if (this.field.parent) {
      this.field.parent.model[this.key as string] = newValue;
    }
    formArray.clear();
    newValue.forEach(() => formArray.push(new FormControl()));
    formArray.patchValue(newValue);
  }
}
