import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldTypeConfig } from "@ngx-formly/core";
import { FieldType } from "@ngx-formly/material/form-field";
import { ErrorStateMatcher } from "@angular/material/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "lib-formly-textarea-type",
  templateUrl: "./formly-textarea-type.component.html",
  styleUrls: ["./formly-textarea-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTextareaTypeComponent extends FieldType<FieldTypeConfig> {
  textAreaErrorMatcher = new TextAreaErrorMatcher();
  constructor() {
    super();
  }
}

export class TextAreaErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }
}
