import { Inject, Injectable, isDevMode } from "@angular/core";
import { IvyUser } from "@vp/models";
import { IVY_APP_SECRET, IVY_USER, OKTA_CONSUMER_ENV, OKTA_LOGIN_BRAND } from "@vp/shared/tokens";
import { AuthenticatedResult, OidcSecurityService, UserDataResult } from "angular-auth-oidc-client";
import { AuthOptions } from "angular-auth-oidc-client/lib/auth-options";
import { KJUR } from "jsrsasign";
import moment from "moment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private _isLoggingOut = false;

  constructor(
    @Inject(OKTA_LOGIN_BRAND) private oktaLoginBrand: string,
    @Inject(OKTA_CONSUMER_ENV) private consumerOktaEnv: string,
    @Inject(IVY_USER) private ivyUser: IvyUser,
    @Inject(IVY_APP_SECRET) private ivyAppSecret: string,
    private oidcSecurityService: OidcSecurityService
  ) {}

  get isLoggingOut(): boolean {
    return this._isLoggingOut;
  }

  checkAuth() {
    return this.oidcSecurityService.checkAuth();
  }

  isLoggedIn$(): Observable<AuthenticatedResult> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  checkSessionChanged(): Observable<boolean> {
    return this.oidcSecurityService.checkSessionChanged$;
  }

  getAccount(): Observable<UserDataResult> {
    return this.oidcSecurityService.userData$;
  }

  login(register = false): void {
    const authOptions: AuthOptions = {
      customParams: {
        brand: this.oktaLoginBrand as string,
        display_env: this.consumerOktaEnv as string
      }
    };

    if (authOptions.customParams) {
      if (register) authOptions.customParams.ui_hint = "register";
      if (isDevMode()) authOptions.customParams.display_env = "stg";
    }
    this.oidcSecurityService.authorize(undefined, authOptions);
  }

  logout(): void {
    this._isLoggingOut = true;
    this.oidcSecurityService.logoff().subscribe();
    this._isLoggingOut = false;
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  getIdToken(): Observable<string> {
    return this.oidcSecurityService.getIdToken();
  }

  //TODO should this be removed? Ivy not being used anymore
  //#region IVY API
  //The Ivy API is not protected via Okta, we validate the custom jwt claims for a
  //specific userid (i.e. the Ivy user) and verify the signature against our ivyAppSecret

  public generateIvyToken() {
    //token expires in 60 secs, enough time for API call to return.
    const tNow = moment.now() / 1000 - 5;
    const tEnd = tNow + 60;

    // Header
    const oHeader = { alg: "HS256", typ: "JWT" };
    const sHeader = JSON.stringify(oHeader);

    // Payload
    const ivyUser: IvyUser = this.ivyUser as IvyUser;
    const oPayload = {
      iss: window.location.hostname,
      nbf: tNow,
      iat: tNow,
      exp: tEnd,
      userid: ivyUser.userId,
      preferred_username: ivyUser.email,
      sub: ivyUser.email,
      given_name: ivyUser.firstName,
      family_name: ivyUser.lastName
    };
    const sPayload = JSON.stringify(oPayload);

    // Sign JWT
    return KJUR.jws.JWS.sign("HS256", sHeader, sPayload, this.ivyAppSecret);
  }

  //#endregion
}
