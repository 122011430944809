import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Message, MessageType } from "@vp/models";

import { API_BASE_URL } from "@vp/shared/tokens";
import { createUrlBuilder } from "@vp/shared/utilities";

export interface MessageFilter {
  messageType: MessageType;
  deviceIds?: string[];
  caseId?: string;
}

@Injectable({
  providedIn: "root"
})
export class MessageApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly _http: HttpClient
  ) {}

  getMessages(filter: MessageFilter) {
    const apiUrl = `${this.apiBaseUrl}/messages`;
    const urlBuilder = createUrlBuilder(apiUrl, Object.keys(filter));
    const urlWithQueryString = urlBuilder.build({}, filter);
    return this._http.get<Message[]>(urlWithQueryString);
  }

  sendMessages(messages: Message[], messageType: MessageType, recipient: string, caseId?: string) {
    const queryParams = { messageType, recipient, caseId };
    const apiUrl = `${this.apiBaseUrl}/messages`;
    const urlBuilder = createUrlBuilder(apiUrl, Object.keys(queryParams));
    const urlWithQueryString = urlBuilder.build({}, queryParams);
    return this._http.post(urlWithQueryString, messages);
  }

  deleteMessages(messageIds: string[], messageType: MessageType, deviceId?: string) {
    const queryParams = { messageIds, messageType, deviceId };
    const apiUrl = `${this.apiBaseUrl}/messages`;
    const urlBuilder = createUrlBuilder(apiUrl, Object.keys(queryParams));
    const urlWithQueryString = urlBuilder.build({}, queryParams);
    return this._http.delete(urlWithQueryString);
  }
}
