import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AlertModule } from "@vp/shared/components/alert";
import { SystemBannerComponent } from "./system-banner.component";

@NgModule({
  imports: [CommonModule, AlertModule],
  declarations: [SystemBannerComponent],
  exports: [SystemBannerComponent]
})
export class SystemBannerModule {}
