import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FocusStateModule } from "@vp/shared/directives/focus-state";
import { QuickSearchComponent } from "./quick-search.component";
@NgModule({
  imports: [
    CommonModule,
    FocusStateModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule
  ],
  declarations: [QuickSearchComponent],
  exports: [QuickSearchComponent]
})
export class QuickSearchModule {}
