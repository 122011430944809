/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { EventBase, IEvent } from "@vp/models";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import * as ApplicationStateActions from "../state+/application-state.actions";
import { ApplicationState } from "../state+/application.state";

@Injectable({
  providedIn: "root"
})
export class EventAggregator {
  constructor(private store: Store) {}

  emit(event: IEvent, sender: string): void {
    if (!event) {
      throw Error("Event is required.");
    }
    if (!sender) {
      throw Error("Sender is required.");
    }
    event.sender = sender;
    this.store.dispatch(new ApplicationStateActions.EmitEvent(event, event.sender));
  }

  on<T extends EventBase<any>>(constructor: new (...args: any[]) => T): Observable<T> {
    return this.store.select(ApplicationState.event).pipe(
      filter(event => event !== null && event instanceof constructor),
      map(event => event as unknown as T),
      filter(event => !event.handled)
    );
  }
}
