import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DeviceCommandListComponent } from "./device-command-list.component";
import { FlexModule } from "@vp/shared/directives/flex";

@NgModule({
  imports: [CommonModule, FlexModule, MatButtonModule, MatTooltipModule],
  declarations: [DeviceCommandListComponent],
  exports: [DeviceCommandListComponent]
})
export class DeviceCommandListModule {}
