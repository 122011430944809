import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-flex-layout-type",
  templateUrl: "./formly-flex-layout-type.component.html",
  styleUrls: ["./formly-flex-layout-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFlexLayoutTypeComponent extends FieldType {
  flexDefault = "100";
  flexSum = 0;

  getFlex(field: FormlyFieldConfig): string {
    const flexValue = field.className?.match(/\d+/g)?.[0] || "";
    if (flexValue) {
      return flexValue;
    }
    return this.flexDefault;
  }

  isLastInRow(field: FormlyFieldConfig): boolean {
    const fieldFlex = Number(this.getFlex(field));
    if (field.type != "flex-layout") {
      this.flexSum += fieldFlex;
      if (this.flexSum >= 100) {
        this.flexSum = 0; //reset flex
        return true;
      }
      return false;
    }
    return false;
  }

  getItemsPerRow(f: FormlyFieldConfig): number {
    let sum = 0;
    let count = 0;
    if (this.field.fieldGroup && f.type != "flex-layout") {
      for (const field of this.field.fieldGroup) {
        const flexValue = Number(this.getFlex(field));
        sum += flexValue;
        count++;

        if (sum >= 99) {
          if (f.key != field.key) {
            sum = 0;
            count = 0;
          } else {
            //this.lastFieldKey = field.key as string;
            break;
          }
        }
      }
    }
    return count;
  }

  getMaxWidth(field: FormlyFieldConfig): string {
    const flexValue = Number(this.getFlex(field));
    const itemsPerRow = this.getItemsPerRow(field);
    let maxWidth = "100";

    if (field.key) {
      maxWidth = `calc(${flexValue}% - ${itemsPerRow}rem)`;
      return maxWidth;
    }
    return maxWidth;
  }

  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
