import { Component, ChangeDetectionStrategy } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { ThemeManagerService } from "@vp/theme-manager";

@Component({
  selector: "vp-top-nav-logo",
  templateUrl: "./top-nav-logo.component.html",
  styleUrls: ["./top-nav-logo.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavLogoComponent {
  logo!: SafeUrl;
  logoRedirectLink: string | undefined;
  constructor(private readonly themeManagerService: ThemeManagerService) {
    this.logo = this.themeManagerService.logo;
    this.logoRedirectLink = this.themeManagerService.logoRedirectLink;
  }
}
