import { DeviceMicrophone, EventBase } from "@vp/models";

export interface DeviceMicrophonesNotificationEventArgs {
  deviceUserId: string;
  deviceMicrophones: DeviceMicrophone[];
}

export class DeviceMicrophonesUpdatedEvent extends EventBase<DeviceMicrophonesNotificationEventArgs> {
  constructor(args: DeviceMicrophonesNotificationEventArgs) {
    super();
    this.args = args;
  }
}
