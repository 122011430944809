import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "vp-formly-readonly-field",
  templateUrl: "./formly-readonly-field.component.html",
  styleUrls: ["./formly-readonly-field.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyReadonlyFieldComponent extends FieldType {}
