import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TopNavLogoComponent } from "./top-nav-logo.component";

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [TopNavLogoComponent],
  exports: [TopNavLogoComponent]
})
export class TopNavLogoModule {}
