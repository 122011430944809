import { IDocument } from "../common";

export interface Message extends IDocument {
  messageId: string;
  caseId: string | null | undefined;
  sender: string;
  recipient: string;
  body: string;
  ttl: number | null;
  active: boolean;
  status: "Delivered" | "Read";
  messageType: MessageType;
  additionalData: Record<string, unknown>;
}

export enum MessageType {
  PAC = "PAC",
  tigerConnect = "TigerConnect"
}
