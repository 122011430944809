import { Injectable } from "@angular/core";
import { getSchemaValueFromDotPath } from "@vp/shared/utilities";
import { JSONSchema7 } from "json-schema";
import moment from "moment";
import { JsonSchemaValueProvider } from "../formly-json-schema.module";

@Injectable()
export class JsonDateFormatProvider implements JsonSchemaValueProvider {
  constructor() {}

  provide(schema: JSONSchema7, record: Record<string, unknown>) {
    resolveEntries(schema, record);
  }
}

const resolveEntries = (
  schema: JSONSchema7,
  record: Record<string, unknown>,
  pathArray: string[] = []
) => {
  const result = Object.entries(record).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (collector: Record<string, unknown>, [key, value]: [string, any]) => {
      const path = [...pathArray, key];
      if (value === null || value === undefined) {
        return collector;
      }
      if (typeof value === "object" && (value instanceof moment || value instanceof Date)) {
        const subSchema: JSONSchema7 = getSchemaValueFromDotPath(schema, path.join("."));
        switch (subSchema.format) {
          case "date": {
            collector[key] = moment(value).format("YYYY-MM-DD");
            break;
          }
          case "date-time": {
            collector[key] = moment(value).format();
            break;
          }
          case "time": {
            collector[key] = moment(value).format().split("T")[1];
            break;
          }
        }
      } else if (typeof value === "object" && Object.keys(value).length > 0) {
        resolveEntries(schema, value, path);
      }
      return collector;
    },
    record
  );
  return result;
};
