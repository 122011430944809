<ng-container *ngIf="permissionsArray$ | async as permissionsArray">
  <button
    type="button"
    mat-mini-fab
    color="primary"
    [matMenuTriggerFor]="permissionsList"
    (menuOpened)="opened()"
    matTooltip="Toggle security preview"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="2000"
  >
    <mat-icon>lock</mat-icon>
  </button>
  <mat-menu
    #permissionsList="matMenu"
    [overlapTrigger]="false"
    [hasBackdrop]="true"
    xPosition="before"
    yPosition="above"
  >
    <ng-container *ngIf="caseData$ | async as caseContext; else noContext">
      <mat-list dense (click)="$event.stopPropagation()">
        <mat-list-item *ngIf="caseContext?.caseType">
          <mat-icon matListIcon>group_work</mat-icon>
          <span matList>{{ caseContext?.caseType?.friendlyId }}</span>
          <mat-list-item *ngIf="!caseContext?.caseType"
            ><mat-icon>group_work_off</mat-icon>No Opinion Type</mat-list-item
          >
        </mat-list-item>
      </mat-list>
    </ng-container>
    <mat-divider></mat-divider>
    <mat-list dense (click)="$event.stopPropagation()">
      <mat-list-item *ngFor="let perm of permissionsArray">
        <mat-icon matListIcon inline>{{
          perm.indexOf("hide") > 0 ? "visibility_off" : "visibility"
        }}</mat-icon>
        <span>{{ perm }}</span>
      </mat-list-item>
      <mat-list-item *ngIf="permissionsArray.length === 0">
        <mat-icon matListIcon>visibility_off</mat-icon>
        <span matLine>No permissions</span>
      </mat-list-item>
    </mat-list>
    <ng-template #noContext>
      <mat-list dense (click)="$event.stopPropagation()">
        <mat-list-item>
          <mat-icon matListIcon>group_work_off</mat-icon>
          <span matLine>No context</span>
        </mat-list-item>
      </mat-list>
    </ng-template>
  </mat-menu>
</ng-container>
