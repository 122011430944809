import { Directive, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { FLEX_BREAKPOINTS, FlexBreakPointAlias } from "../directives/model/flex-breakpoints";

import { BreakpointObserver } from "@angular/cdk/layout";
import { compareBy } from "@vp/shared/utilities";
import { filter, switchMap, takeUntil } from "rxjs/operators";

@Directive()
export abstract class BaseDirective<T = string> implements OnDestroy {
  protected _breakPointValueMap: { [key in FlexBreakPointAlias]?: T | undefined } = {};
  protected _destroyed$ = new Subject<void>();
  protected _breakpoints = new BehaviorSubject<string[]>([]);
  protected _currentValue = new BehaviorSubject<T | undefined>(undefined);

  constructor(protected breakpointObserver: BreakpointObserver) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  protected setupResponsiveObservers() {
    this._breakpoints
      .pipe(
        filter(bps => !!bps.length),
        switchMap(breakpoints => {
          return this.breakpointObserver.observe(breakpoints).pipe(takeUntil(this._destroyed$));
        })
      )
      .subscribe(result => {
        const matchingMediaQueries = Object.entries(result.breakpoints)
          .filter(([, breakPointMatch]) => breakPointMatch)
          .map(([mediaQuery]) => mediaQuery);

        const highestBreakPointPriority = FLEX_BREAKPOINTS.filter(bp =>
          matchingMediaQueries.includes(bp.mediaQuery)
        )
          .sort(compareBy(bp => bp.priority.toString()))
          .pop();

        if (highestBreakPointPriority) {
          this.handleBreakpointChange(highestBreakPointPriority.alias);
        } else {
          this._currentValue.next(undefined);
        }
      });
  }

  private handleBreakpointChange(breakpoint: FlexBreakPointAlias) {
    const value = this._breakPointValueMap[breakpoint];
    this._currentValue.next(value);
  }
}
