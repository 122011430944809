<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    selectedTags: (selectedTags$ | async) ?? [],
    tagTypes: (tagTypes$ | async) ?? [],
    tagGroups: (tagGroups$ | async) ?? []
  }"
></ng-container>

<ng-template
  #template
  let-selectedTags="selectedTags"
  let-tagTypes="tagTypes"
  let-tagGroups="tagGroups"
>
  <vp-multi-tag-selector
    [tagTypes]="tagTypes"
    [tagGroups]="tagGroups"
    [selectedTags]="selectedTags"
    (tagChanged)="onTagChanged($event)"
  ></vp-multi-tag-selector>
</ng-template>
