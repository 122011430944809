import { Pipe, PipeTransform } from "@angular/core";
import { compareDate, MAX_DATE } from "@vp/shared/utilities";

@Pipe({
  name: "hidemaxdate"
})
export class HideMaxDatePipe implements PipeTransform {
  transform(value: string): string | null {
    const max = MAX_DATE();
    const result = compareDate(new Date(value), max);
    if (result >= 0) {
      return null;
    }
    return value;
  }
}
