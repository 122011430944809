import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CaseType, PageResult } from "@vp/models";
import { CacheService } from "@vp/shared/services/cache";
import { createPatch, Operation } from "rfc6902";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CASE_TYPES_API_BASE_URL } from "../case-types-data-access.module";
import { CaseTypeFilter } from "../state+/case-types-state";

@Injectable({
  providedIn: "root"
})
export class CaseTypesApiService {
  constructor(
    @Inject(CASE_TYPES_API_BASE_URL) private _apiBaseUrl: string,
    private _http: HttpClient,
    private cacheService: CacheService<CaseType[]>
  ) {}

  createCaseType = (caseType: CaseType): Observable<CaseType> => {
    const apiURL = `${this._apiBaseUrl}`;
    return this._http.post<CaseType>(apiURL, caseType);
  };

  getAllCaseTypes = () => {
    if (!this.cacheService.has("CaseTypesApiService.getAllCaseTypes")) {
      return this.getAllCaseTypesInternal().pipe(
        tap(caseTypes => {
          this.cacheService.set("CaseTypesApiService.getAllCaseTypes", caseTypes);
        })
      );
    }
    return this.cacheService.get(
      "CaseTypesApiService.getAllCaseTypes",
      this.getAllCaseTypesInternal()
    );
  };

  private getAllCaseTypesInternal = (): Observable<CaseType[]> => {
    const apiURL = `${this._apiBaseUrl}`;
    return this._http.get<CaseType[]>(apiURL);
  };

  getCaseTypesPaged = (filter: Partial<CaseTypeFilter>): Observable<PageResult<CaseType>> => {
    if (filter.take === 0) filter.take = 10;
    let apiURL = `${this._apiBaseUrl}/?take=${filter.take}&skip=${filter.skip}`;
    if (filter.sort) {
      apiURL = apiURL + `&sort=${filter.sort}`;
    }
    if (filter.sort && filter.sortDirection !== null) {
      apiURL = apiURL + `&sort_direction=${filter.sortDirection}`;
    }
    if (filter.search) {
      apiURL = apiURL + `&search=${filter.search}`;
    }
    if (filter.filters) {
      apiURL = apiURL + filter.filters.map(filter => `&filter=${filter}`);
    }
    return this._http.get<PageResult<CaseType>>(apiURL);
  };

  getCaseTypeById = (caseTypeId: string): Observable<CaseType> => {
    const apiURL = `${this._apiBaseUrl}/${caseTypeId}`;
    return this._http.get<CaseType>(apiURL);
  };

  getCaseTypeFee = (caseTypeId: string): Observable<number> => {
    const apiURL = `${this._apiBaseUrl}/${caseTypeId}/fee`;
    return this._http.get<number>(apiURL);
  };

  updateCaseType = (caseType: CaseType): Observable<CaseType> => {
    const apiURL = `${this._apiBaseUrl}/${caseType.caseTypeId}`;
    return this._http.put<CaseType>(apiURL, caseType);
  };

  patchCaseType = (original: CaseType, changed: CaseType): Observable<CaseType> => {
    const patchJson = createPatch(original, changed);
    const apiURL = `${this._apiBaseUrl}/${original.caseTypeId}`;
    return this._http.patch<CaseType>(apiURL, patchJson);
  };

  patch = (caseTypeId: string, operations: Operation[], command: string | null = null) => {
    const apiURL = `${this._apiBaseUrl}/${caseTypeId}`;
    let params = new HttpParams();
    if (command) {
      params = params.append("command", String(command));
    }
    return this._http.patch<CaseType>(apiURL, operations, {
      params: params
    });
  };

  deleteCaseType = (caseTypeId: string): Observable<CaseType> => {
    const apiURL = `${this._apiBaseUrl}/${caseTypeId}`;
    return this._http.delete<CaseType>(apiURL);
  };
}
