/**
 * These are the Content Management permissions.
 */
// tslint:disable:variable-name
export const contentManagementPermissions = {
  Read: "adminUserContentCanRead",
  Write: "adminUserContentCanWrite",
  Delete: "adminUserContentCanDelete",
  Assign: {
    Read: "contentAssignTagCanRead",
    Write: "contentAssignTagCanWrite",
    Delete: "contentAssignTagCanDelete"
  }
};
