import { Breakpoints } from "@angular/cdk/layout";

export const MOBILE_BREAKPOINTS = [
  Breakpoints.XSmall,
  Breakpoints.Small,
  Breakpoints.TabletPortrait,
  Breakpoints.WebPortrait
];

export const DESKTOP_BREAKPOINTS = [
  Breakpoints.Medium,
  Breakpoints.Large,
  Breakpoints.XLarge,
  Breakpoints.WebLandscape,
  Breakpoints.TabletLandscape
];
