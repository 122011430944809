export interface FlexBreakPoint {
  mediaQuery: string;
  alias: FlexBreakPointAlias;
  priority: number;
}

export const FLEX_BREAKPOINTS: FlexBreakPoint[] = [
  {
    alias: "xs",
    mediaQuery: "screen and (min-width: 0px) and (max-width: 599.98px)",
    priority: 1000
  },
  {
    alias: "sm",
    mediaQuery: "screen and (min-width: 600px) and (max-width: 959.98px)",
    priority: 900
  },
  {
    alias: "md",
    mediaQuery: "screen and (min-width: 960px) and (max-width: 1279.98px)",
    priority: 800
  },
  {
    alias: "lg",
    mediaQuery: "screen and (min-width: 1280px) and (max-width: 1919.98px)",
    priority: 700
  },
  {
    alias: "xl",
    mediaQuery: "screen and (min-width: 1920px) and (max-width: 4999.98px)",
    priority: 600
  },
  {
    alias: "lt_sm",
    mediaQuery: "screen and (max-width: 599.98px)",
    priority: 950
  },
  {
    alias: "lt_md",
    mediaQuery: "screen and (max-width: 959.98px)",
    priority: 850
  },
  {
    alias: "lt_lg",
    mediaQuery: "screen and (max-width: 1279.98px)",
    priority: 750
  },
  {
    alias: "lt_xl",
    priority: 650,
    mediaQuery: "screen and (max-width: 1919.98px)"
  },
  {
    alias: "gt_xs",
    mediaQuery: "screen and (min-width: 600px)",
    priority: -950
  },
  {
    alias: "gt_sm",
    mediaQuery: "screen and (min-width: 960px)",
    priority: -850
  },
  {
    alias: "gt_md",
    mediaQuery: "screen and (min-width: 1280px)",
    priority: -750
  },
  {
    alias: "gt_lg",
    mediaQuery: "screen and (min-width: 1920px)",
    priority: -650
  }
];

export type FlexBreakPointAlias =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "lt_sm"
  | "lt_md"
  | "lt_lg"
  | "lt_xl"
  | "gt_xs"
  | "gt_sm"
  | "gt_md"
  | "gt_lg";
