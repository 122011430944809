import { ChangeDetectionStrategy, Component } from "@angular/core";
import {
  ActivatedRoute,
  Data,
  PRIMARY_OUTLET,
  Router,
  UrlSegment,
  UrlSegmentGroup
} from "@angular/router";
import { MenuItem } from "@vp/models";
import { AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { activatedRouteData } from "@vp/shared/utilities";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "vp-page-menu",
  templateUrl: "./page-menu.component.html",
  styleUrls: ["./page-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageMenuComponent {
  pageMenuItems$: Observable<MenuItem[]>;

  constructor(
    private router: Router,
    private appStoreService: AppStoreService,
    public activatedRoute: ActivatedRoute,
    public uiDisplayTagService: UiDisplayTagService
  ) {
    this.pageMenuItems$ = this.router.events.pipe(
      activatedRouteData(this.activatedRoute),
      map((data: Data) => data.pageMenuItems)
    );
  }

  navigate(routerLink: string | undefined, queryParams: Record<string, unknown> | undefined) {
    this.appStoreService.setRedirectRoute(this.router.url);
    this.router.navigate([routerLink], {
      queryParams: queryParams
    });
  }

  urlArray = (url: string | undefined): string[] => {
    if (url) {
      const parsedUrl = this.router.parseUrl(url);
      console.log(parsedUrl);
      const g: UrlSegmentGroup = parsedUrl.root.children[PRIMARY_OUTLET];
      const s: UrlSegment[] = g.segments;
      return s.map(p => p.path);
    }
    return [];
  };
}
