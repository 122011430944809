import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { HttpStateService } from "../service/http-state.service";

/**
 * Report the url, start and end state for requests to the httpStateService.
 */
@Injectable({
  providedIn: "root"
})
export class SpinnerInterceptorService implements HttpInterceptor {
  constructor(private httpStateService: HttpStateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpStateService.start(request.url);

    return next.handle(request).pipe(
      finalize(() => {
        this.httpStateService.end(request.url);
      })
    );
  }
}
