import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "lib-expansion-panel-wrapper",
  templateUrl: "./expansion-panel-wrapper.component.html",
  styleUrls: ["./expansion-panel-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelWrapperComponent extends FieldWrapper {}
