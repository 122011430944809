/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, Optional } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageRefService } from "./local-storage-ref.service";
import { LocalStorageOptions } from "./local-storage.module";
import { LOCAL_STORAGE_OPTIONS } from "./tokens";

export interface LocalStorageData {
  age: number | undefined;
  data: Record<string, any>;
}

@Injectable({
  providedIn: "root"
})
export class LocalStorageService {
  private _localStorage: Storage;
  private _localStorage$ = new BehaviorSubject<LocalStorageData | null>(null);

  public localStorage$ = this._localStorage$.asObservable();

  constructor(
    @Optional() @Inject(LOCAL_STORAGE_OPTIONS) private options: LocalStorageOptions,
    ref: LocalStorageRefService
  ) {
    this._localStorage = ref.localStorage;
  }

  // Unix date in seconds
  private now = () => Math.floor(Date.now() / 1000);

  set(data: Partial<LocalStorageData>) {
    const _data = {
      age: this.now(),
      ...data
    } as LocalStorageData;
    this._localStorage.setItem(this.options.storageName, JSON.stringify(_data));
    this._localStorage$.next(_data);
  }

  load() {
    const data = this._localStorage.getItem(this.options.storageName);
    if (data !== null) {
      const current: LocalStorageData = JSON.parse(data) as LocalStorageData;
      if (current.age && this.now() - current.age < this.options.maxAgeSeconds) {
        this._localStorage$.next(current);
      } else {
        this.clear(this.options.storageName);
      }
    }
  }

  clear(name: string) {
    this._localStorage.removeItem(name);
    this._localStorage$.next(null);
  }

  clearAll() {
    this._localStorage.clear();
    this._localStorage$.next(null);
  }
}
