import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { OrganizationState } from "@vp/data-access/organization";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { AssignableRoles } from "@vp/models";
import { TagManagerState } from "@vp/tag-manager/data-access/tag-manager-state";
import { of } from "rxjs";

@Injectable()
export class ConditionalReadOnlyFieldsFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  provide(state: Record<string, unknown>) {
    if (Object.keys(state).length === 0) return of({});

    const user = this.loggedInUserSnapShot();

    if (!user?.roles) {
      return of({
        allowedTagType: true,
        omitRuleOnFields: []
      });
    }

    const currentRole = user.roles.find(x => x.roleId === user.selectedRoleId);

    if (
      !currentRole ||
      !state.applicableRoles ||
      !(state.applicableRoles as string[]).includes(currentRole.friendlyId)
    ) {
      return of({
        allowedTagType: true,
        omitRuleOnFields: []
      });
    }

    const flag = state.omitRuleOnFields ? true : false;
    const organization = this.organizationSnapShot();

    const conditionalRole = (
      organization?.features?.find(x => x.friendlyId === state.feature)?.configurationDictionaries[
        currentRole.friendlyId
      ] as AssignableRoles[]
    ).find(x => x.roleFriendlyId === currentRole.friendlyId) as AssignableRoles;

    const tag = this.selectedTagSnapShot();
    if (flag && tag && conditionalRole) {
      return of({
        allowedTagType: conditionalRole.tagEditorReadOnlyFields.tagTypes.includes(
          tag.tagTypeFriendlyId
        ),
        omitRuleOnFields: conditionalRole.tagEditorReadOnlyFields.omitRuleOnFields
      });
    }
    return of({
      allowedTagType: true,
      omitRuleOnFields: []
    });
  }

  organizationSnapShot() {
    return this.store.selectSnapshot(OrganizationState.organization);
  }

  selectedTagSnapShot() {
    return this.store.selectSnapshot(TagManagerState.selectedTag);
  }

  loggedInUserSnapShot() {
    return this.store.selectSnapshot(ApplicationState.loggedInUser);
  }
}
