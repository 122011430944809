import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  isDevMode,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { ApplicationState } from "@vp/data-access/application";
import { User } from "@vp/models";
import { IS_IVY_API } from "@vp/shared/guards";
import { LoggerService } from "@vp/shared/logger-service";
import { SignalRApiService, SignalREventService } from "@vp/shared/signal-r-service";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;
  @Select(ApplicationState.isBrowserOnline) isBrowserOnline$!: Observable<boolean>;

  isProd = !isDevMode();
  isIframe = false;
  isLoggedIn = false;
  isAdmin = false;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly logger: LoggerService,
    private readonly router: Router,
    private readonly signalREventService: SignalREventService,
    private readonly signalRApiService: SignalRApiService,
    private readonly store: Store
  ) {}

  ngOnInit() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.signalRApiService.reconnect();
      }
    });

    window.addEventListener("focus", () => {
      this.signalRApiService.reconnect();
    });

    window.addEventListener("offline", () => {
      this.store.dispatch(
        new ApplicationStateActions.PatchState({
          isBrowserOnline: false
        })
      );
    });

    window.addEventListener("online", () => {
      this.store.dispatch(
        new ApplicationStateActions.PatchState({
          isBrowserOnline: true
        })
      );
      this.signalRApiService.reconnect();
    });

    //this.appInsightsService.logEvent("Breakout app initalized");
    this.isIframe = window !== window.parent && !window.opener;

    if (this.isIvyApi) {
      this.router.navigate(["/wizard"]);
    }

    this.loggedInUser$
      .pipe(
        tap((user: User | null) => {
          this.isLoggedIn = !!user;
          this.isAdmin = false;
          // TODO put into app store service
          if (user && user.roles) {
            for (const role of user.roles) {
              if (role.roleId === user.selectedRoleId && role.friendlyId === "admin") {
                this.isAdmin = true;
              }
            }
            this.signalREventService.listen();
          } else {
            this.signalREventService.stopListening();
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (user: User | null) => {
          this.logger.systemEvent(
            `${this.constructor.name}.${this.ngOnInit.name}.appStoreService.loggedInUser`,
            "Logged In User Context Changed",
            {
              user: user as Pick<User, "userId"> // TODO: Do we want to log any other information from user here?
            }
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
