import { Group, GroupType, Tag, TagType, User } from "@vp/models";
import { Operation } from "rfc6902";

export interface UserAdministrationStateModel {
  user: User | null;
  workingCopy: User | null;
  pendingOperations: Operation[];
  assignableGroupTypes: GroupType[];
  assignableGroups: Group[];
  assignableTagTypes: TagType[];
  assignableTags: Tag[];
}

export const defaultUserAdministrationState = (): UserAdministrationStateModel => {
  return {
    user: null,
    workingCopy: null,
    pendingOperations: [],
    assignableGroupTypes: [],
    assignableGroups: [],
    assignableTagTypes: [],
    assignableTags: []
  };
};
