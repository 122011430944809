import { CommonModule } from "@angular/common";
import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { TagsState } from "@vp/data-access/tags";
import { Observable } from "rxjs";
import { UiSchemaStateProvider } from "./ui-schema-state-provider.service";

export abstract class FormStateProvider {
  abstract provide(
    state: Record<string, unknown>,
    context?: Record<string, unknown>
  ): Observable<Record<string, unknown>>;
}

export const FORM_STATE_PROVIDERS = new InjectionToken<FormStateProvider[]>("FORM_STATE_PROVIDERS");

export interface UiSchemaConfigOptions {
  providers: FormStateProvider[];
}

@NgModule({
  imports: [CommonModule],
  providers: [UiSchemaStateProvider]
})
export class UiSchemaConfigModule {
  static forRoot(options: UiSchemaConfigOptions): ModuleWithProviders<UiSchemaConfigModule> {
    return this.registerProviders(options);
  }

  static forChild(options: UiSchemaConfigOptions): ModuleWithProviders<UiSchemaConfigModule> {
    return this.registerProviders(options);
  }

  private static registerProviders(options: UiSchemaConfigOptions) {
    return {
      ngModule: UiSchemaConfigModule,
      providers: [
        {
          provide: FORM_STATE_PROVIDERS,
          multi: true,
          useValue: [],
          deps: [TagsState]
        },
        {
          provide: FORM_STATE_PROVIDERS,
          multi: true,
          useValue: options.providers,
          deps: [TagsState]
        }
      ]
    };
  }
}
