import { Pipe, PipeTransform } from "@angular/core";

export type PathPart = "filename" | "path" | "fullpath";

@Pipe({
  name: "pathPart",
  pure: true
})
export class PathPartsPipe implements PipeTransform {
  static getPathPart(path: string, part: PathPart) {
    const pathParts = path.split("/");
    switch (part) {
      case "filename":
        return pathParts[pathParts.length - 1];
      case "path":
        return pathParts.slice(0, -1).join("/");
      default:
        return path;
    }
  }

  transform(value: string, part: PathPart): unknown {
    return PathPartsPipe.getPathPart(value, part);
  }
}
