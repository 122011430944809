import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { BaseDirective } from "../../base/base.directive";
import { FLEX_BREAKPOINTS } from "../model/flex-breakpoints";

@Directive({
  selector: `
  [flex], [flex.xs], [flex.sm], [flex.md],
  [flex.lg], [flex.xl], [flex.lt-sm], [flex.lt-md],
  [flex.lt-lg], [flex.lt-xl], [flex.gt-xs], [flex.gt-sm],
  [flex.gt-md], [flex.gt-lg]
`
})
export class FlexDirective extends BaseDirective implements OnInit, OnChanges {
  private _grow = "1";
  private _shrink = "1";
  private _basis = "0";

  @Input() flex?: string;
  @Input("flex.xs") flex_xs?: string;
  @Input("flex.sm") flex_sm?: string;
  @Input("flex.md") flex_md?: string;
  @Input("flex.lg") flex_lg?: string;
  @Input("flex.xl") flex_xl?: string;
  @Input("flex.lt-sm") flex_lt_sm?: string;
  @Input("flex.lt-md") flex_lt_md?: string;
  @Input("flex.lt-lg") flex_lt_lg?: string;
  @Input("flex.lt-xl") flex_lt_xl?: string;
  @Input("flex.gt-xs") flex_gt_xs?: string;
  @Input("flex.gt-sm") flex_gt_sm?: string;
  @Input("flex.gt-md") flex_gt_md?: string;
  @Input("flex.gt-lg") flex_gt_lg?: string;

  @HostBinding("style.box-sizing") boxSizing = "border-box";

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._breakPointValueMap = {
      xs: this.flex_xs,
      sm: this.flex_sm,
      md: this.flex_md,
      lg: this.flex_lg,
      xl: this.flex_xl,
      lt_sm: this.flex_lt_sm,
      lt_md: this.flex_lt_md,
      lt_lg: this.flex_lt_lg,
      lt_xl: this.flex_lt_xl,
      gt_xs: this.flex_gt_xs,
      gt_sm: this.flex_gt_sm,
      gt_md: this.flex_gt_md,
      gt_lg: this.flex_gt_lg
    };

    const flexBreakpointInputs = Object.entries(changes)
      .filter(([key, value]) => value.currentValue && key.includes("_"))
      .map(([inputKey]) => {
        return inputKey.replace(/flex_/, "");
      });
    const breakpointsMediaQuery = FLEX_BREAKPOINTS.filter(bp =>
      flexBreakpointInputs.includes(bp.alias)
    ).map(bp => bp.mediaQuery);
    this._breakpoints.next(breakpointsMediaQuery);
  }

  ngOnInit(): void {
    this.setupResponsiveObservers();
    this._currentValue
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(flexValue => this.setFlexValue(flexValue));
  }

  private setFlexValue(flexValue?: string) {
    if (flexValue === undefined) {
      flexValue = this.flex || "1 1 0";
    }

    switch (flexValue) {
      case "nogrow":
        this._grow = "0";
        this._basis = "auto";
        break;
      case "noshrink":
        this._shrink = "0";
        this._basis = "auto";
        break;
      default:
        this.parseFlexInput(flexValue);
        break;
    }

    this.applyFlex();
  }

  private parseFlexInput(input: string) {
    const flexRegex =
      /^((?<grow>\d+\.?\d*) (?<shrink>\d+\.?\d*) )?(?<basis>\d+\.?\d*(px|rem|em|%)?|calc\(.+\)|auto)$/;
    const match = flexRegex.exec(input);
    if (match) {
      this._grow = match.groups?.grow ?? "1";
      this._shrink = match.groups?.shrink ?? "1";
      this._basis = match.groups?.basis ?? "0";
    }
  }

  private applyFlex() {
    const nativeElement = this.elementRef.nativeElement;
    if (this._basis !== "0" && this._basis !== "auto") {
      this._basis = /^\d+$/.test(this._basis) ? `${this._basis}%` : this._basis;
      const parentElement = nativeElement.parentElement;
      if (parentElement) {
        const parentComputedStyle = window.getComputedStyle(parentElement);
        const parentFlexDirection = parentComputedStyle.getPropertyValue("flex-direction");
        const maxProperty = parentFlexDirection === "column" ? "max-height" : "max-width";
        this.renderer.setStyle(nativeElement, maxProperty, this._basis);
      }
    }
    const flexValue = `${this._grow} ${this._shrink} ${this._basis}`;
    this.renderer.setStyle(nativeElement, "flex", flexValue);
  }
}
