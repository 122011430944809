import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FlexModule } from "@vp/shared/directives/flex";
import { NgxPermissionsModule } from "ngx-permissions";
import { TopNavSearchComponent } from "./top-nav-search.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    NgxPermissionsModule.forChild(),
    FlexModule
  ],
  declarations: [TopNavSearchComponent],
  exports: [TopNavSearchComponent]
})
export class TopNavSearchModule {}
