import { AngularEditorConfig } from "@kolkov/angular-editor";
import { LogLevel, StsConfigStaticLoader } from "angular-auth-oidc-client";
import { environment } from "../environments/environment";

declare let tinymce: any; // Declare tinymce

// this checks if the app is running on IE
export const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function configureAuth() {
  return () => {
    return new StsConfigStaticLoader({
      authority: environment.authServer,
      redirectUrl: `${window.location.origin}/login/callback`,
      clientId: environment.oktaClientId,
      scope: "openid profile email",
      responseType: "code",
      postLogoutRedirectUri: `${window.location.origin}/loggedout`,
      startCheckSession: false,
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      postLoginRoute: "/loggedin",
      forbiddenRoute: "/forbidden",
      unauthorizedRoute: "/login",
      logLevel: LogLevel.Warn,
      historyCleanupOff: true,
      disableIatOffsetValidation: true,
      renewTimeBeforeTokenExpiresInSeconds: 120
    });
  };
}

export const angularEditorConfigDefault: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  width: "auto",
  minHeight: "490px",
  placeholder: "Enter text here...",
  translate: "no",
  sanitize: true,
  // toolbarPosition: 'top',
  outline: true,
  defaultFontSize: "3",
  // showToolbar: false,
  defaultParagraphSeparator: "p",
  toolbarHiddenButtons: [
    ["subscript", "superscript", "heading", "fontName"],
    [
      "insertImage",
      "insertVideo",
      "link",
      "unlink",
      "customClasses",
      "backgroundColor",
      "fontSize",
      "insertHorizontalRule",
      "removeFormat",
      "toggleEditorMode"
    ]
  ]
};

export const tinyMCEDefaultConfig: any = {
  plugins:
    "pagebreak anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount casechange formatpainter linkchecker tinymcespellchecker powerpaste advtable advcode editimage autocorrect inlinecss insertdatetime preview",
  toolbar:
    "pagebreak undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | align | numlist bullist indent outdent | image link table | insertdatetime preview | spellcheckdialog charmap removeformat | code",
  contextmenu: "pagebreak insertdatetime",
  height: "60vh",
  pagebreak_separator: "<div class='page-break'></div>",
  // statusbar: false,
  menubar: "edit view",
  file_picker_types: "image",
  images_file_types: "jpg,jpeg,png",
  file_picker_callback: (
    cb: (arg0: any, arg1: { title: string }) => void,
    _value: any,
    _meta: any
  ) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const fileList = target.files as FileList;
      const file = fileList[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const id = "blobid" + new Date().getTime();
        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        const base64 = (reader.result as string).split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        /* call the callback and populate the Title field with the file name */
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  }
};
