import { Injectable } from "@angular/core";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";
import { FormlyJsonschemaOptions } from "@ngx-formly/core/json-schema/formly-json-schema.service";
import { UiSchema, UiSchemaConfigService, UiSchemaDefinition } from "@vp/formly/ui-schema-config";
import {
  deeperCopy,
  deleteProperty,
  getValueAtPath,
  insert,
  objectsEqual
} from "@vp/shared/utilities";
import { JSONSchema7 } from "json-schema";
import { Observable, of } from "rxjs";

interface InsertParams {
  targetObject: Record<string, unknown>;
  props: string[];
  objToInsert: any;
}

export type PermissionsMode = "anyOf" | "allOf";

@Injectable()
export class UiSchemaLayoutProvider {
  constructor(
    private configService: UiSchemaConfigService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  getFieldConfig(schema: any, options: FormlyJsonschemaOptions | undefined = undefined) {
    return [this.formlyJsonschema.toFieldConfig(schema, options)];
  }

  applyScopes(
    type: string,
    schema: any | null | undefined = null,
    scope: string | null = null,
    permissionsClaim: string[] = [],
    permissionsMode: PermissionsMode = "anyOf"
  ): Observable<JSONSchema7> {
    let clone = {};
    if (Object.keys(schema ?? {}).length === 0) {
      clone = {
        schema: "http://json-schema.org/draft-06/schema#",
        type: "object",
        title: "data",
        additionalProperties: false,
        properties: {}
      };
    } else {
      clone = deeperCopy(schema);
    }

    const elements = this.configService.getLayoutElements(type, scope);

    if (elements && elements.length > 0) {
      elements.forEach((element: UiSchema) => {
        const permissions = element.requiredPermissions ?? [];
        const some = permissionsClaim.some(value => permissions.includes(value));
        const every = permissionsClaim.every(value => permissions.includes(value));
        if (
          permissions.length === 0 ||
          objectsEqual(permissionsClaim, permissions) ||
          (permissionsMode === "anyOf" && some) ||
          (permissionsMode === "allOf" && every)
        ) {
          clone = this.applyWidgets(clone, element);
          clone = this.applyDefinition(clone, element);
        } else if (
          permissions.length > 0 &&
          ((permissionsMode === "anyOf" && !some) || (permissionsMode === "allOf" && !every))
        ) {
          /** If permissions are specified and the user does not have the correct permissions
           * with respect to the permissionsMode, then we delete the property from the schema
           * using the schema element scope path, so it does not render at all.
           */
          deleteProperty(clone, element.scope);
        }
      });
    }
    return of(clone);
  }

  private applyDefinition(
    schema: Record<string, unknown>,
    element: UiSchema
  ): Record<string, unknown> {
    if (!element.definition) return schema;
    const definitions = this.configService.getDefinitions(element.definition);
    if (definitions.length > 0) {
      const scopeParts = element.scope.split("/").splice(1);
      definitions.forEach((definition: UiSchemaDefinition) => {
        const params = {
          targetObject: schema,
          props: scopeParts,
          objToInsert: {
            widget: {
              formlyConfig: definition.formlyConfig
            }
          }
        };
        insert(params, "merge");
        return params.targetObject;
      });
    }
    return schema;
  }

  private insertWidget(
    scope: Record<string, unknown>,
    scopeParts: string[],
    element: UiSchema
  ): Record<string, unknown> {
    if (scopeParts[scopeParts.length - 1] === "*") {
      const _parts = scopeParts.slice(1, -1);
      const params = {
        targetObject: scope,
        props: [],
        objToInsert: null
      } as InsertParams;
      const scoped = getValueAtPath(scope, _parts);
      Object.keys(scoped).forEach((key: string) => {
        params.props = [..._parts, key];
        params.objToInsert = {
          widget: {
            formlyConfig: deeperCopy(element.formlyConfig)
          }
        };
        insert(params);
      });
      return params.targetObject;
    } else {
      const params = {
        targetObject: scope,
        props: scopeParts,
        objToInsert: {
          widget: {}
        }
      } as InsertParams;
      if (element.formlyConfig) {
        params.objToInsert.widget["formlyConfig"] = element.formlyConfig;
      }
      insert(params);
      return params.targetObject;
    }
  }

  private applyWidgets(
    schema: Record<string, unknown>,
    element: UiSchema
  ): Record<string, unknown> {
    const scopeParts = element.scope.split("/");
    const scoped: Record<string, unknown> = this.insertWidget(schema, scopeParts, element);
    if (getValueAtPath(scoped, scopeParts)) {
      return scoped;
    } else {
      throw Error("failed to apply widget.");
    }
  }
}
