import { InjectionToken } from "@angular/core";
import { IvyUser } from "@vp/models";

export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");
export const AMBRA_BASE_URL = new InjectionToken<string>("AMBRA_BASE_URL");

export const OKTA_ROUTES = new InjectionToken<string[]>("OKTA_ROUTES");
export const IVY_URLS = new InjectionToken<Record<string, string>>("IVY_URLS");
export const IVY_SUFFIX = new InjectionToken<string>("IVY_SUFFIX");
export const IVY_SUB_KEY = new InjectionToken<string>("IVY_SUB_KEY");
export const IVY_USER = new InjectionToken<IvyUser>("IVY_USER");
export const IVY_APP_SECRET = new InjectionToken<string>("IVY_APP_SECRET");

export const APIM_TRACE = new InjectionToken<string>("APIM_TRACE");
export const APIM_SUB_KEY = new InjectionToken<string>("APIM_SUB_KEY");
export const APP_INSIGHTS_KEY = new InjectionToken<string>("APP_INSIGHTS_KEY");
export const FUNC_ACCT_STOR_NAME = new InjectionToken<string>("FUNC_ACCT_STOR_NAME");
export const OKTA_LOGIN_BRAND = new InjectionToken<string>("OKTA_LOGIN_BRAND");
export const OKTA_CONSUMER_ENV = new InjectionToken<string>("OKTA_CONSUMER_ENV");

export const ALLOW_REGISTRATION = new InjectionToken<string>("ALLOW_REGISTRATION");
