<div class="input-container">
  <input
    title="Masked Input"
    matInput
    [type]="type"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="placeHolder"
    [mask]="mask"
    [patterns]="pattern"
    [prefix]="prefix"
    [suffix]="suffix"
    [attr.dropSpecialCharacters]="dropSpecialCharacters ? 'true' : 'false'"
    [showMaskTyped]="showMaskTyped"
  />
</div>
