import { Injectable } from "@angular/core";
import { Action, Select, Selector, State, StateContext } from "@ngxs/store";
import { StateOperator, append, patch } from "@ngxs/store/operators";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import { CaseData, CaseType, CaseUser, TagType } from "@vp/models";
import { cleanData, filterNullMap } from "@vp/shared/operators";
import {
  deeperCopy,
  emptyGuid,
  getUtcNow,
  isValidGuid,
  mergeDeep,
  parseError
} from "@vp/shared/utilities";
import { Operation, createPatch } from "rfc6902";
import { EMPTY, Observable, combineLatest, of, throwError } from "rxjs";
import { catchError, concatMap, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { CaseApiService } from "../api/case-api.service";
import * as CaseActions from "./case.actions";

export type CaseDataState = {
  caseData: CaseData | null;
  errors: string[];
};

@State<CaseDataState>({
  name: "case",
  defaults: {
    caseData: null,
    errors: []
  }
})
@Injectable()
export class CaseState {
  @Select(OrganizationState.tagTypes) tagTypes$!: Observable<TagType[]>;
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;
  @Select(CaseState.current) caseData$!: Observable<CaseData>;

  constructor(private api: CaseApiService) {}

  @Selector()
  public static currentInternal(state: CaseDataState): CaseData | null {
    return state.caseData;
  }

  @Selector([CaseState.currentInternal])
  public static current(caseData: CaseData): CaseData | null {
    return caseData ? deeperCopy(caseData) : null;
  }

  @Selector([CaseState.currentInternal, CaseTypesState.allCaseTypes])
  public static currentCaseType(caseData: CaseData, caseTypes: CaseType[]) {
    return caseTypes.find(ct => ct.caseTypeId === caseData?.caseType.caseTypeId);
  }

  /**
   * Retrieves a case by its caseId from the server and sets the state with the response
   * @param ctx
   * @param param1
   * @returns
   */
  @Action(CaseActions.SetState)
  set(ctx: StateContext<CaseDataState>, { caseId }: CaseActions.SetState) {
    return this.api.getCase(caseId).pipe(
      tap((caseData: CaseData) => {
        ctx.patchState({
          caseData: caseData
        });
      }),
      catchError(error => {
        ctx.patchState({
          caseData: null,
          //assignableTagTypes: [],
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  @Action(CaseActions.CreateCurrent)
  createCase(ctx: StateContext<CaseDataState>) {
    const caseData = ctx.getState().caseData;
    if (caseData == null) return;
    return this.api.createCase(caseData).pipe(
      tap((caseData: CaseData) => {
        ctx.patchState({
          caseData: caseData
        });
      }),
      catchError(error => {
        ctx.patchState({
          caseData: null,
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  /**
   * Generates a new "empty" case from the server and sets the state with the response
   * @param ctx
   * @param param1
   * @returns
   */
  @Action(CaseActions.SetEmptyCase)
  setNew(ctx: StateContext<CaseDataState>, action: CaseActions.SetEmptyCase) {
    if (!action.caseTypeId) {
      return throwError("caseTypeId is not valid.");
    }

    if (!!action.subjectUserId && !isValidGuid(action.subjectUserId)) {
      return throwError("subjectUserId is not valid.");
    }

    return this.api.getCase(emptyGuid(), action.caseTypeId).pipe(
      tap((caseData: CaseData) => {
        if (action.recordData && Object.keys(action.recordData).length > 0) {
          caseData.recordData = cleanData(action.recordData);
        }
        if (action.subjectUserId) {
          caseData.subjectUserId = action.subjectUserId;
        }
        ctx.patchState({
          caseData: caseData
        });
      }),
      take(1)
    );
  }

  /**
   * TODO: needs to be refactored to use partial
   * Patch passed state to server, and update state with response.
   * @param ctx
   * @param { caseData }
   * @returns {Observable<CaseData>}
   */
  @Action(CaseActions.Patch)
  patch(ctx: StateContext<CaseDataState>, { caseData }: CaseActions.Patch): Observable<CaseData> {
    return of(caseData).pipe(
      filterNullMap(),
      switchMap((changed: CaseData) => combineLatest([of(ctx.getState().caseData), of(changed)])),
      map(([original, changed]: [CaseData | null, CaseData]) => {
        return {
          caseId: changed.caseId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) =>
        this.api
          .patch(caseOperations.caseId, caseOperations.operations)
          .pipe(map(() => caseOperations.caseId))
      ),
      mergeMap(caseId => this.api.getCase(caseId)),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      catchError(error => {
        ctx.patchState({ errors: parseError(error) });
        return throwError(error);
      })
    );
  }

  /**
   *
   * @param ctx
   * @param action
   * @returns void
   */
  @Action(CaseActions.PartialPatch)
  partialPatch(ctx: StateContext<CaseDataState>, action: CaseActions.PartialPatch) {
    const original = ctx.getState().caseData;
    if (!original) return;

    const changed = mergeDeep(original, action.caseData, "replace");
    const operations = createPatch(original, changed);

    this.api.patch(original.caseId, operations).subscribe({
      next: () => {
        ctx.patchState({ caseData: changed });
      },
      error: error => {
        ctx.patchState({ errors: parseError(error) });
      }
    });
  }

  /**
   * Updates state only, does not save
   * @param ctx
   * @param param1
   */
  @Action(CaseActions.UpdateState)
  updateState(ctx: StateContext<CaseDataState>, { caseData }: CaseActions.UpdateState) {
    ctx.patchState({
      caseData: caseData
    });
  }

  @Action(CaseActions.ResetState)
  resetState(ctx: StateContext<CaseDataState>) {
    ctx.patchState({
      caseData: null
    });
  }

  @Action(CaseActions.UpdateResponse)
  updateResponse(
    ctx: StateContext<CaseDataState>,
    { caseFile, caseResponse }: CaseActions.UpdateResponse
  ) {
    if (!caseFile) {
      throw Error("UpdateResponseAction.caseFile.required");
    }
    if (!caseResponse) {
      throw Error("UpdateResponseAction.caseResponse.required");
    }
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      map(caseData => {
        const index = caseData.responses.findIndex(r => r.responseId === caseResponse.responseId);
        let operations: Operation[] = [];
        if (index > -1) {
          operations = [
            { op: "replace", path: `/responses/${index}/document`, value: caseFile.url },
            { op: "add", path: "/documents/documentList/-", value: caseFile }
          ];
        }
        return {
          caseId: caseData.caseId,
          operations: operations
        };
      }),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) => {
        if (caseOperations.operations.length > 0) {
          return this.api
            .patch(caseOperations.caseId, caseOperations.operations)
            .pipe(map(() => caseOperations.caseId));
        }
        return EMPTY;
      }),
      mergeMap(caseId => {
        return this.api.getCase(caseId);
      }),
      tap(caseData => {
        ctx.patchState({ caseData });
      })
    );
  }

  @Action(CaseActions.AcceptOrRejectCase)
  acceptCase(ctx: StateContext<CaseDataState>, action: CaseActions.AcceptOrRejectCase) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      map(caseData => {
        const caseDataCopy = deeperCopy(caseData);

        const caseUser = caseDataCopy.users.find(
          (u: CaseUser) =>
            u.userId === action.caseUser.userId &&
            u.roleId === action.caseUser.roleId &&
            u.responsibilityFriendlyId === action.caseUser.responsibilityFriendlyId
        );
        caseUser.acceptanceStatus = action.accept ? "accepted" : "rejected";
        caseUser.acceptanceStatusLastUpdatedDateTime = getUtcNow();

        return {
          caseId: caseData.caseId,
          operations: createPatch(caseData, caseDataCopy)
        };
      }),
      filterNullMap(),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) => {
        if (caseOperations.operations.length > 0) {
          return this.api
            .patch(caseOperations.caseId, caseOperations.operations)
            .pipe(map(() => caseOperations.caseId));
        }
        return EMPTY;
      }),
      mergeMap(caseId => {
        return this.api.getCase(caseId);
      }),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated this will go away when the behvior pipeline/patch stuff is implemented
   * This should just change the status on the case data and let the the api handle the transition logic
   * before applying the change to the case data. Ideally, at which point the state should be updated via
   * signal-r so the second get call should not be needed either.
   * @param ctx
   * @param param1
   */
  @Action(CaseActions.UpdateStatus)
  updateStatus(ctx: StateContext<CaseDataState>, { statusId }: CaseActions.UpdateStatus) {
    const caseData = ctx.getState().caseData;
    if (!caseData) return EMPTY;

    return this.api.updateCaseStatus(caseData.caseId, statusId).pipe(
      mergeMap((success: boolean) => {
        if (success) {
          return this.api.getCase(caseData.caseId);
        }
        return EMPTY;
      }),
      tap(caseData => {
        ctx.patchState({ caseData });
      })
    );
  }

  @Action(CaseActions.SubmitCase)
  submitCase(ctx: StateContext<CaseDataState>) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.submitCase(caseData.caseId).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.AddCaseUser)
  addCaseUser(ctx: StateContext<CaseDataState>, action: CaseActions.AddCaseUser) {
    ctx.setState(
      patch<CaseDataState>({
        caseData: patch<CaseData>({
          users: append([action.caseUser])
        }) as StateOperator<CaseData | null>
      })
    );
  }

  @Action(CaseActions.AddTags)
  addTags(ctx: StateContext<CaseDataState>, action: CaseActions.AddTags) {
    ctx.setState(
      patch<CaseDataState>({
        caseData: patch<CaseData>({
          tags: append(action.tags)
        }) as StateOperator<CaseData | null>
      })
    );
  }

  @Action(CaseActions.AddCaseServiceFee)
  addCaseService(
    ctx: StateContext<CaseDataState>,
    { caseServiceFee }: CaseActions.AddCaseServiceFee
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.addCaseService(caseData.caseId, caseServiceFee).pipe(
          mergeMap((response: boolean) => {
            if (response) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param {caseServiceFee}
   */
  @Action(CaseActions.EditCaseServiceFee)
  editCaseService(
    ctx: StateContext<CaseDataState>,
    { caseServiceFee }: CaseActions.EditCaseServiceFee
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.editCaseService(caseData.caseId, caseServiceFee).pipe(
          mergeMap((response: boolean) => {
            if (response) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param {caseServiceFee}
   */
  @Action(CaseActions.DeleteCaseServiceFee)
  deleteCaseService(
    ctx: StateContext<CaseDataState>,
    { serviceFeeId }: CaseActions.DeleteCaseServiceFee
  ) {
    const caseData: CaseData | null = ctx.getState().caseData;
    if (caseData) {
      const caseId: string = caseData.caseId;
      return this.api.deleteCaseService(caseId, serviceFeeId).pipe(
        mergeMap((response: boolean) => {
          if (response) {
            return this.api.getCase(caseId);
          }
          return EMPTY;
        }),
        tap((caseData: CaseData) => {
          ctx.patchState({ caseData });
        })
      );
    }
    return EMPTY;
  }

  /**
   * @deprecated we shouldn't be explicitly refreshing state like this from anywhere
   * the things that mutate should instead patch a value, and those should be emitted
   * automatically.
   * @param ctx
   * @param {caseServiceFee}
   */
  @Action(CaseActions.RefreshCurrent)
  refreshCurrent(ctx: StateContext<CaseDataState>) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) => this.api.getCase(caseData.caseId)),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param param1
   */
  @Action(CaseActions.RemoveGroup)
  removeGroup(ctx: StateContext<CaseDataState>, { groupId }: CaseActions.RemoveGroup) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.removeGroupFromCase(caseData.caseId, groupId).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.DeleteResult)
  deleteResult(ctx: StateContext<CaseDataState>, { resultId }: CaseActions.DeleteResult) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.deleteResult(caseData.caseId, resultId).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.UpdateResult)
  updateResult(
    ctx: StateContext<CaseDataState>,
    { caseResultData, finishLater }: CaseActions.UpdateResult
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.updateResult(caseData.caseId, caseResultData, finishLater).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return throwError("Update Result Failed.");
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param param1
   * @returns
   */
  @Action(CaseActions.CreateResult)
  createResult(
    ctx: StateContext<CaseDataState>,
    { caseResultData, finishLater }: CaseActions.CreateResult
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.createResult(caseData.caseId, caseResultData, finishLater).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return throwError("Create Result Failed.");
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.EditDocumentProperties)
  editDocumentProperties(
    ctx: StateContext<CaseDataState>,
    actions: CaseActions.EditDocumentProperties
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        const caseDataCopy: CaseData = deeperCopy(caseData);
        const documentToUpdate = caseDataCopy.documents.documentList.find(
          doc =>
            doc.fileName === actions.caseFile.fileName &&
            doc.uploadedDateTime === actions.caseFile.uploadedDateTime
        );

        if (documentToUpdate) {
          if (actions.caseFile.displayName) {
            documentToUpdate.displayName = actions.caseFile.displayName;
          }
          if (actions.caseFile.fileDescription)
            documentToUpdate.fileDescription = actions.caseFile.fileDescription;
        }
        return createPatch(caseData, caseDataCopy);
      }),
      concatMap(operations => {
        return this.api.patch(actions.caseId, operations);
      }),
      concatMap(() => {
        return this.api.getCase(actions.caseId);
      }),
      take(1)
    );
  }
}
