import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  RequestMetaDataResolver,
  RequestMetaDataStateModule
} from "@vp/data-access/request-meta-data";
import { FilteredTagsResolver } from "@vp/data-access/tags";
import { LegalNoticeComponent } from "@vp/user-profile/ui/legal-notice";
import { UserProfileShellComponent } from "@vp/user-profile/ui/user-profile-shell";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: UserProfileShellComponent,
    resolve: { metaData: RequestMetaDataResolver, tags: FilteredTagsResolver },
    data: {
      metaData: {
        "feature-name": "profile"
      },
      tagFilter: {
        accessible: true
      }
    }
  },
  { path: "legalnotice", pathMatch: "full", component: LegalNoticeComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes), RequestMetaDataStateModule],
  exports: [RouterModule]
})
export class UserProfileRoutingModule {}
