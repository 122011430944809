<ng-container
  *ngIf="{
    disabled: disabled | async
  } as vm"
>
  <div class="mb-3">
    <div flexLayout="row" flexLayoutAlign="space-between end" flexLayoutGap="30px">
      <mat-hint class="mat-hint" *ngIf="props.label && props.hideLabel !== true">{{
        props.label
      }}</mat-hint>
      <vp-insert-snippet
        *ngIf="showSnippet() && !vm.disabled"
        (insertSnippetEvent)="insertSnippet($event)"
      ></vp-insert-snippet>
    </div>

    <angular-editor
      [lib-DisableEditor]="vm.disabled ?? false"
      [config]="config"
      [formlyAttributes]="field"
      [formControl]="formControl"
      #editor
      (focusout)="saveSelection()"
      (keyup)="saveSelection()"
    >
    </angular-editor>
    <mat-hint *ngIf="props.description && props.hideDescription !== true">{{
      props.description
    }}</mat-hint>
  </div>
</ng-container>
