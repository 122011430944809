import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ToastrModule, ToastContainerDirective } from "ngx-toastr";
import { AlertComponent } from "./alert.component";

@NgModule({
  declarations: [AlertComponent],
  imports: [CommonModule, ToastrModule, ToastContainerDirective],
  exports: [AlertComponent]
})
export class AlertModule {}

export type AlertItem = {
  id: string;
  type: "toast-success" | "toast-info" | "toast-warning" | "toast-error";
  message: string;
  title: string;
};
