import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { ActiveToast, IndividualConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AlertItem } from "./alert.module";

export const INLINE_TOASTR_CONFIG: Partial<IndividualConfig> = {
  positionClass: "inline",
  enableHtml: true,
  tapToDismiss: true,
  closeButton: true,
  disableTimeOut: true
};

@Component({
  selector: "vp-alert-container",
  template: "<span toastContainer></span>",
  styleUrls: ["./alert.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ToastrService]
})
export class AlertComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() alerts: AlertItem[] = [];
  @Input() config: Partial<IndividualConfig> = INLINE_TOASTR_CONFIG;
  @Output() alertDismissed = new EventEmitter<AlertItem>();

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer!: ToastContainerDirective;

  private readonly alertItems$$ = new BehaviorSubject<AlertItem[]>([]);
  private readonly destroyed$$ = new Subject<void>();

  constructor(private toastrService: ToastrService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private activeToasts: ActiveToast<any>[] = [];

  ngOnInit(): void {
    this.toastrService.toastrConfig.preventDuplicates = false;
  }

  ngAfterViewInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;

    this.alertItems$$.pipe(takeUntil(this.destroyed$$)).subscribe(alerts => {
      this.toastrService.clear();
      alerts.forEach((alert: AlertItem) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let activeToast: ActiveToast<any> | null = null;
        if (typeof alert === "string") {
          if (alert) {
            activeToast = this.toastrService.show(alert, undefined, this.config, "toast-info");
          }
        } else {
          const alertObject = alert as AlertItem;
          if (alertObject.message) {
            activeToast = this.toastrService.show(
              alertObject.message,
              alertObject.title,
              this.config,
              alertObject.type || "toast-info"
            );
          }
        }

        if (activeToast) {
          this.activeToasts.push(activeToast);
          activeToast.onHidden.subscribe(() => {
            if (alert) {
              this.alertDismissed.emit(alert);
            }
            this.activeToasts = this.activeToasts.filter(toast => toast !== activeToast);
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.alertItems$$.next(changes.alerts.currentValue ?? []);
  }

  ngOnDestroy(): void {
    this.toastrService.clear();
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }
}
