import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { Tag } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "tagsWithParent"
})
export class TagWithParentsPipe implements PipeTransform {
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  public transform(tagId: string): Observable<string> {
    return this.tags$.pipe(
      shareReplay(),
      map(tags => {
        const tagPath: Tag[] = generatePath(tagId, tags, []);
        return tagPath.map(tag => tag.displayName).join(" / ");
      })
    );
  }
}

const generatePath = (tagId: string, tags: Tag[], tagPath: Tag[]): Tag[] => {
  const parentTag: Tag | undefined = tags.find(t => t.tagId === tagId);
  if (parentTag) {
    tagPath.unshift(parentTag);
    if (parentTag.tagPath) {
      const pathArray: string[] = parentTag.tagPath.split(".");
      const grandparentType = tags.find(t => t.tagId === pathArray[pathArray.length - 1]);
      if (grandparentType) {
        return generatePath(grandparentType.tagId, tags, tagPath);
      }
    }
  }
  return tagPath;
};
