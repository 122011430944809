import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  QueryList,
  TemplateRef,
  TrackByFunction,
  ViewChildren
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { ContentFilterState, ContentManagementService } from "@vp/data-access/content-management";
import { ContentData } from "@vp/models";
import { DynamicTemplateDirective } from "@vp/shared/directives/dynamic-template";
import { BehaviorSubject, combineLatest, throwError } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";

@Component({
  selector: "vp-view-content-page",
  templateUrl: "./view-content-page.component.html",
  styleUrls: ["./view-content-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContentPageComponent implements OnInit, AfterViewInit {
  public formGroup: UntypedFormGroup;

  template!: TemplateRef<any>;
  @ViewChildren(DynamicTemplateDirective) templateRefs!: QueryList<DynamicTemplateDirective>;

  private readonly _selectedContent$ = new BehaviorSubject<ContentData | null>(null);
  private readonly _contents$ = new BehaviorSubject<ContentData[]>([]);

  // TODO: This fix is temporary until this feature can be refactored to be more state based
  private readonly caseId$ = new BehaviorSubject<string | null>(null);
  private readonly contentId$ = new BehaviorSubject<string | null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ViewContentPageComponent>,
    private readonly store: Store,
    public readonly contentManagementService: ContentManagementService
  ) {
    this.formGroup = fb.group({
      htmlContent: new UntypedFormControl("", [Validators.required])
    });
  }

  selectedContent$ = this._selectedContent$.asObservable();
  contents$ = this._contents$.asObservable();
  singleMode$ = this.contents$.pipe(
    map((contents: ContentData[]) => {
      return contents.filter(c => c.active === true).length === 1;
    })
  );

  ngOnInit(): void {
    this.contentId$.next(this.data.contentId ?? null);
    this.caseId$.next(this.data.caseId ?? null);

    combineLatest([this.caseId$, this.contentId$])
      .pipe(
        switchMap(([caseId, contentId]: [string | null, string | null]) => {
          if (caseId) {
            return this.contentManagementService.getContentsByCaseId(caseId);
          } else if (contentId) {
            return this.contentManagementService
              .getContent(contentId)
              .pipe(map(content => [content]));
          }
          return throwError(() => new Error("Request contained no content specifiers"));
        }),
        tap((contents: ContentData[]) => {
          const workingCopy = this.store.selectSnapshot(ContentFilterState.workingCopy);
          const firstContent = contents[0];
          if (!firstContent?.contentId && workingCopy) {
            this._selectedContent$.next(workingCopy);
            contents.push(workingCopy);
            contents = contents.filter(x => x.contentId != null);
          }
          this._contents$.next(contents ?? []);
          if (contents.length > 0 && this.data.tabDescription !== "Preview") {
            this._selectedContent$.next(firstContent);
          } else {
            this._selectedContent$.next(workingCopy);
          }
        }),
        take(1)
      )
      .subscribe({
        next: () => {
          this.template = this.getTemplate("results");
          this.cdRef.detectChanges();
        }
      });
  }

  ngAfterViewInit(): void {
    this.template = this.getTemplate("loading");
    this.cdRef.detectChanges();
  }

  isContentSelected(selectedContent: ContentData, currentContent: ContentData) {
    return selectedContent?.friendlyId === currentContent.friendlyId;
  }

  onContentClicked = (content: ContentData) => {
    this._selectedContent$.next(content);
  };

  onCancel = () => {
    this.dialogRef.close();
  };

  trackByContentId: TrackByFunction<ContentData> = (_, item) => item.contentId;

  private getTemplate(templateId: string): TemplateRef<any> {
    const template = this.templateRefs?.toArray().find(x => x.id === templateId)?.template;

    if (template) return template;
    throw Error("templateNotFound");
  }
}
