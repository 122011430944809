import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import {
  AssignableRoles,
  AssignedRolePerDepartment,
  Department,
  DepartmentRole,
  Organization,
  User
} from "@vp/models";
import { IAssignmentService } from "@vp/shared/assignments/models";
import { filterNullMap } from "@vp/shared/operators";
import { sortItems } from "@vp/shared/utilities";
import { Observable, combineLatest } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";
import { AssignableRolesService, mapToVm } from "./assignable-roles-service";
import { UserAdministrationService } from "./user-administration.service";

@Injectable()
export class RolesAssignmentService implements IAssignmentService {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  assignableEntities$: Observable<AssignedRolePerDepartment[]>;

  private _assignedRoles$: Observable<AssignedRolePerDepartment[]>;
  private _allRoles$: Observable<AssignedRolePerDepartment[]>;

  assignableRole$ = this.assignableRolesService.assignableRole$;

  constructor(
    private readonly userAdministrationService: UserAdministrationService,
    private assignableRolesService: AssignableRolesService
  ) {
    this._allRoles$ = this.assignableRolesService.allowedRolesPerDepartment$;

    this._assignedRoles$ = this.userAdministrationService.workingCopy$.pipe(
      filterNullMap(),
      map(user => mapToVm(user.roles))
    );

    this.assignableEntities$ = combineLatest([this._assignedRoles$, this._allRoles$]).pipe(
      map(([assigned, all]: [AssignedRolePerDepartment[], AssignedRolePerDepartment[]]) => {
        return all.filter(
          e =>
            // Exclude already assigned items from assignable list
            assigned.findIndex(r => r.departmentId === e.departmentId && r.roleId === e.roleId) < 0
        );
      }),
      withLatestFrom(this.organization$.pipe(shareReplay())),
      map(([assignables, org]: [AssignedRolePerDepartment[], Organization]) => {
        const models = assignables.map(item => {
          const roleMatch = org.roles.find(r => r.roleId === item.roleId);
          const deptMatch = org.departments.find(d => d.departmentId === item.departmentId);
          // Stuff the searchable content together in a string that is not displayed
          // but inspected by the filterTerm pipe for the purposes of searching.
          item.search = `
            ${deptMatch?.displayName ?? ""}
            ${deptMatch?.description ?? ""}
            ${roleMatch?.displayName ?? ""}`;
          return item;
        });
        return sortItems(models, ["search"], "asc");
      })
    );
  }
  selectedRoles$(matchingRole: AssignableRoles | null, workingCopy: User) {
    return this.organization$.pipe(
      filterNullMap(),
      map((org: Organization) => {
        const assignableRoles = matchingRole
          ? org.userTypeConfig.find(ut => ut.type == matchingRole?.userType)
              ?.assignableRoleFriendlyId
          : org.userTypeConfig.find(ut => ut.type == workingCopy.userType.friendlyId)
              ?.assignableRoleFriendlyId;
        const models: AssignedRolePerDepartment[] = [];
        org.departments.map((dept: Department) => {
          dept.roles.forEach((deptRole: DepartmentRole) => {
            if (assignableRoles && assignableRoles.includes(deptRole.friendlyId)) {
              const model: AssignedRolePerDepartment = {
                departmentId: dept.departmentId,
                roleId: deptRole.roleId
              };
              models.push(model);
            }
          });
        });
        return models;
      })
    );
  }
}
