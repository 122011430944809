<mat-card-header *ngIf="props.label">
  <mat-card-title>{{ props.label }}</mat-card-title>
  <mat-card-subtitle *ngIf="props.description">{{ props.description }}</mat-card-subtitle>
</mat-card-header>

<div class="mat-error" role="alert" *ngIf="showError && formControl.errors">
  <formly-validation-message [field]="field"></formly-validation-message>
</div>

<mat-card
  class="mat-card-flat"
  *ngFor="let field of field.fieldGroup; trackBy: trackById; let i = index"
>
  <formly-field [field]="field"></formly-field>
  <mat-card-actions>
    <button mat-icon-button type="button" (click)="remove(i)" *ngIf="!props.disabled">
      <mat-icon color="warn">remove_circle</mat-icon>
    </button>
  </mat-card-actions>
  <mat-divider></mat-divider>
</mat-card>

<mat-action-row>
  <button mat-icon-button type="button" (click)="add()" *ngIf="!props.disabled">
    <mat-icon color="warn">add_circle</mat-icon>
  </button>
</mat-action-row>
