import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { of } from "rxjs";

@Injectable()
export class RolesFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  provide(state: Record<string, unknown>) {
    const roles = state?.roles as string[];
    if (roles?.length) {
      const org = this.store.selectSnapshot(OrganizationState.organization);

      return of({ roles: org?.roles.filter(r => roles.includes(r.friendlyId)) });
    }
    return of({});
  }

  organizationSnapShot() {
    return this.store.selectSnapshot(OrganizationState.organization)?.roles;
  }
}
