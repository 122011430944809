import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldWrapper, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-flex-wrapper",
  templateUrl: "./formly-flex-layout-wrapper.component.html",
  styleUrls: ["./formly-flex-layout-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFlexLayoutWrapperComponent extends FieldWrapper {
  fxFlexDefault = 100;

  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
