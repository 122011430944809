/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from "@angular/common/http";
import { EventBase } from "../common/event-base";

export interface ApplicationErrorEventArgs {
  stack: string;
  source: string | null | undefined;
  message?: string | null | undefined;
  status?: number | null | undefined;
  url?: string | null | undefined;
  properties?: { [key: string]: any } | null | undefined;
}

export class ApplicationErrorEvent extends EventBase<any> {
  constructor(args: ApplicationErrorEventArgs) {
    super();
    this.args = args;
  }

  static fromHttpErrorResponse(
    errorResponse: HttpErrorResponse,
    source?: string,
    errorMessage?: string,
    properties?: { [key: string]: any } | null | undefined
  ): ApplicationErrorEvent {
    const message = errorMessage || (errorResponse.error && errorResponse.error.message) || null;
    const status = errorResponse.status || 500;
    const stack = errorResponse.error && errorResponse.error.stack ? errorResponse.error.stack : "";

    return new ApplicationErrorEvent({
      stack: stack,
      source: source,
      message: message,
      status: status,
      url: errorResponse?.url,
      properties: properties
    });
  }

  static fromAnyErrorResponse(
    error: any,
    errorMessage?: string,
    properties?: { [key: string]: any } | null | undefined
  ): ApplicationErrorEvent {
    const source = error && error.source ? error.source : getLocationFromStack(error.stack);
    const message = errorMessage ?? error.message ?? error.name ?? null;
    const stack = error && error.stack ? error.stack : "";

    return new ApplicationErrorEvent({
      stack: stack,
      source: source,
      message: message,
      status: null,
      url: null,
      properties: properties
    });
  }
}

const getLocationFromStack = (stack: string): string | null => {
  const stackTrace = stack;
  const regex = /at\s+(\S+)\s+\((.*):(\d+):(\d+)\)/i;
  const match = regex.exec(stackTrace);
  const functionName = match && match[1];
  if (!functionName) {
    return null;
  }
  const className = functionName.substring(0, functionName.indexOf("."));
  const formattedName = `${className}.${functionName}`;
  return formattedName;
};
