import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { UtilitiesModule } from "@vp/shared/utilities";
import { AddOrEditSnippetDialogModule } from "@vp/user-snippets/ui/add-or-edit-snippet-dialog";
import { UserSnippetsComponent } from "./user-snippets.component";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    UtilitiesModule,
    AddOrEditSnippetDialogModule
  ],
  exports: [UserSnippetsComponent],
  declarations: [UserSnippetsComponent]
})
export class UserSnippetsModule {}
