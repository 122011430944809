<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    searchString: searchString$ | async,
    selectedOptions: selectedOptions$ | async,
    filteredOptions: filteredOptions$ | async
  }"
></ng-container>

<ng-template
  #template
  let-searchString="searchString"
  let-selectedOptions="selectedOptions"
  let-filteredOptions="filteredOptions"
>
  <mat-form-field>
    <mat-label>{{ props.label ?? "" }}</mat-label>
    <mat-chip-grid #chipGrid>
      @for (selectedOption of selectedOptions; track selectedOption.value) {
        <mat-chip-row>
          {{ selectedOption.label }}
          <button matChipRemove (click)="toggleSelection(selectedOption)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      #inputTrigger
      (keyup)="search($event)"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [placeholder]="props.placeholder ?? ''"
      [disabled]="!!props.disabled"
      [formlyAttributes]="field"
      (keydown.backspace)="onBackspaceKeydown($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" hideSingleSelectionIndicator>
      @for (option of filteredOptions; track option.value) {
        <mat-option [value]="option" style="position: relative">
          <!-- using abosulte position to take entire container size and prevent event propagation to mat-option from dismissing dropdown -->
          <div
            flexLayout="row"
            flexLayoutAlign="start center"
            style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding-left: 0.5rem"
            (click)="toggleSelection(option, $event)"
          >
            <mat-checkbox [checked]="option.checked" color="primary">
              {{ option.label }}
            </mat-checkbox>
          </div>
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
