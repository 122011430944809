import { PipeTransform, Pipe } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Pipe({
  name: "tableData"
})
export class TableDatePipe implements PipeTransform {
  transform(value: any, sort: MatSort, paginator: MatPaginator): MatTableDataSource<any> {
    const source = new MatTableDataSource(value);
    source.paginator = paginator;
    source.sort = sort;
    return source;
  }
}
