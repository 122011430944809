import { AccessControlService } from "@vp/shared/access-control";

export function accessControlFactory(
  accessControlService: AccessControlService
): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise(resolve => {
      accessControlService.initNgxPermissions();
      resolve(void 0);
    });
  };
}
