/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorHandler, Injectable } from "@angular/core";
import { LoggerService } from "@vp/shared/logger-service";

@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private logger: LoggerService) {
    super();
  }

  handleError(error: any): void {
    this.handleOutdatedModuleFiles(error);
    if (this.isTemplateError(error)) {
      throw error;
    } else {
      console.error("An error occurred:", error);
    }
    this.logger.errorEvent(error);
  }

  private isTemplateError(error: any): boolean {
    const isTemplate = error.stack.includes("_Template");
    if (isTemplate)
      console.error("Template error occurred. Execution can not continue. Error:", error);

    return isTemplate;
  }

  private handleOutdatedModuleFiles(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }
  }
}
