import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, UrlTree } from "@angular/router";
import { OrganizationFeatures } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

/**
 * Directs case-dashboard to individual or single page.
 * Navigating to individual page will redirect to single when enabled.
 *
 * Notes:
 * Use 'force' query param flag to use individual pages when single is enabled.
 * The single page will scroll to the fragment.
 * Fragment uses "case-" prefix based on navigation friendlyName.
 * See CaseDashboardResolver
 */
@Injectable({
  providedIn: "root"
})
export class CaseDashboardAsSinglePageService {
  useSingle = false;

  constructor(private router: Router, private featureService: FeatureService) {}

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const force = route.queryParams["force"] !== undefined;
    const currentlyOnSingle = route.routeConfig?.path === "single";
    const id = route.paramMap.get("caseId") || route.parent?.paramMap.get("caseId");
    const fragment = route.routeConfig?.path === "" ? undefined : `case-${route.routeConfig?.path}`;

    return this.featureService.featureEnabled$(OrganizationFeatures.caseDashboardAsSinglePage).pipe(
      switchMap((featureEnabled: boolean) => {
        if (featureEnabled === true && !currentlyOnSingle && !force) {
          return this.router.navigate(["case", id, "single"], { fragment });
        }
        return of(true);
      })
    );
  }
}

export const CaseDashboardAsSinglePageGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(CaseDashboardAsSinglePageService).canActivateChild(route);
};
