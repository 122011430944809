export interface CaseTypeFilter {
  take?: number;
  skip?: number;
  search?: string | null | undefined;
  sort?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filters?: string[] | null | undefined;
}

export const defaultCaseTypeFilter = (): Partial<CaseTypeFilter> => {
  return {
    sort: "displayName",
    sortDirection: "asc"
  };
};
