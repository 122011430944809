import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "@vp/shared/notification-service";
import SignaturePad from "signature_pad";

interface CanvasDimension {
  width: number;
  height: number;
}

@Component({
  selector: "vp-e-signature-dialog",
  templateUrl: "./e-signature-dialog.component.html",
  styleUrls: ["./e-signature-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ESignatureDialogComponent implements OnInit {
  @ViewChild("signaturePad", { static: true }) canvas!: ElementRef<HTMLCanvasElement>;

  canvasDimension: CanvasDimension = {
    width: 450,
    height: 150
  };

  private signaturePad!: SignaturePad;
  constructor(
    private dialogRef: MatDialogRef<ESignatureDialogComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) private eSignature: string
  ) {}

  ngOnInit(): void {
    this.signaturePad = new SignaturePad(this.canvas?.nativeElement);
    if (this.eSignature) {
      this.signaturePad.fromDataURL(this.eSignature, this.canvasDimension);
    }
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  saveSignature() {
    const signaturePng = this.signaturePad.isEmpty() ? null : this.signaturePad.toDataURL();
    this.dialogRef.close({ save: true, eSignature: signaturePng });
  }

  onUploadSignature(event: any) {
    const file = event.target?.files[0];

    if (file) {
      const fileSize = file.size;
      const oneMB = 1024 * 1024;
      if (fileSize > oneMB) {
        this.notificationService.warningMessage(
          "Image file size exceeds the limit and should be reduced to be under 1 MB."
        );
      } else {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          if (event.target.result) {
            this.signaturePad.clear();
            this.signaturePad.fromDataURL(event.target.result, this.canvasDimension);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }
}
