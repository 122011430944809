import { Pipe, PipeTransform } from "@angular/core";
import { DropDownData } from "@vp/models";

@Pipe({
  name: "getNameFor"
})
export class GetNameForPipe implements PipeTransform {
  transform(value: DropDownData[], id: number) {
    const item = value ? value.find(x => x.value === id) : undefined;
    return item !== undefined ? item.viewValue : "";
  }
}
