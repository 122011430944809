import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-null-type",
  templateUrl: "./formly-null-type.component.html",
  styleUrls: ["./formly-null-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyNullTypeComponent extends FieldType {}
