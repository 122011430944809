<h1 mat-dialog-title>Signature</h1>
<div mat-dialog-content flexLayout="column" flexLayoutGap="16px">
  <canvas #signaturePad [width]="canvasDimension.width" [height]="canvasDimension.height"></canvas>
  <div flexLayout="row" flexLayoutAlign="start" flexLayoutGap="16px">
    <input
      title="File Input"
      type="file"
      #fileInput
      accept=".png, .jpeg, .jpg"
      class="d-none"
      (change)="onUploadSignature($event)"
    />
    <button type="button" mat-stroked-button color="primary" (click)="fileInput.click()">
      Upload Signature
    </button>
    <button mat-stroked-button color="primary" (click)="clearSignature()">Clear</button>
  </div>
</div>
<div mat-dialog-actions flexLayout="row" flexLayoutAlign="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="saveSignature()">Save</button>
</div>
