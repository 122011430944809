import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Route } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { TagsState } from "@vp/data-access/tags";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { EmailVerificationModule } from "@vp/user-profile/ui/email-verification";
import { LegalNoticeModule } from "@vp/user-profile/ui/legal-notice";
import { UserProfileShellModule } from "@vp/user-profile/ui/user-profile-shell";
import { UserProfileRoutingModule } from "./user-profile-routing.module";
export const userProfileFeatureRoutes: Route[] = [];

@NgModule({
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    NgxsModule.forFeature([UserAdministrationState, ApplicationState, TagsState]),
    UserProfileShellModule,
    EmailVerificationModule,
    LegalNoticeModule
  ]
})
export class UserProfileFeatureModule {}
