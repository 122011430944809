import { Inject, ModuleWithProviders, NgModule } from "@angular/core";
import { ThemeManagerConfig, THEME_MANAGER_CONFIG } from "./theme-manager-config.model";
import { ThemeManagerService } from "./theme-manager.service";

@NgModule({
  providers: [ThemeManagerService]
})
export class ThemeManagerModule {
  constructor(
    @Inject(THEME_MANAGER_CONFIG) private readonly themeManagerConfig: ThemeManagerConfig,
    private readonly sharedStyleManagerService: ThemeManagerService
  ) {
    if (this.themeManagerConfig?.hostnames) {
      this.sharedStyleManagerService.setTheme();
    }
  }

  static forRoot(config?: ThemeManagerConfig): ModuleWithProviders<ThemeManagerModule> {
    return {
      ngModule: ThemeManagerModule,
      providers: [
        {
          provide: THEME_MANAGER_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
