import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AdvancedCommand } from "@vp/models";

@Component({
  selector: "vp-device-command-list",
  templateUrl: "./device-command-list.component.html",
  styleUrls: ["./device-command-list.component.scss"]
})
export class DeviceCommandListComponent {
  @Input() commands: AdvancedCommand[] | undefined;
  @Output() sendCommand = new EventEmitter<AdvancedCommand>();

  sendAdvancedCommand(command: AdvancedCommand): void {
    this.sendCommand.emit(command);
  }
}
