import { CaseType, PageState } from "@vp/models";
import { ArrayAction } from "@vp/shared/utilities";
import { Operation } from "rfc6902";
import { CaseTypeFilter } from "./case-types-state";

export class CreateCaseType {
  public static readonly type = "[CaseTypes] Create CaseType";
  constructor(public caseType: CaseType) {}
}

export class LoadCaseTypes {
  public static readonly type = "[CaseTypes] Load CaseTypes";
}

export class SetFilter {
  public static readonly type = "[CaseTypes] Set Filter";
  constructor(
    public filter: Partial<CaseTypeFilter>,
    public arrayAction: ArrayAction = "replace"
  ) {}
}

export class SetPageState {
  public static readonly type = "[CaseTypes] Set Page State";
  constructor(public pageState: Partial<PageState>) {}
}

export class LoadPagedCaseTypes {
  public static readonly type = "[CaseTypes] Load Paged CaseTypes";
  constructor(public filter?: Partial<CaseTypeFilter>) {}
}

export class SetCurrentCaseType {
  public static readonly type = "[CaseTypes] Set Current CaseType";
  constructor(public caseTypeId: string) {}
}

export class UnsetCurrentCaseType {
  public static readonly type = "[CaseTypes] Unset Current CaseType";
}

export class GetCaseTypeFee {
  public static readonly type = "[CaseTypes] Get CaseType Fee";
  constructor(public caseTypeId: string) {}
}

export class UpdateCaseType {
  public static readonly type = "[CaseTypes] Update CaseType";
  constructor(public caseType: CaseType) {}
}

export class PatchCaseType {
  public static readonly type = "[CaseTypes] Patch CaseType";
  constructor(
    public original: CaseType,
    public changed: CaseType
  ) {}
}

export class PatchCaseTypeWithOperations {
  public static readonly type = "[CaseTypes] Patch CaseType With Operations";
  constructor(
    public caseTypeId: string,
    public operations: Operation[]
  ) {}
}

export class DeleteCaseType {
  public static readonly type = "[CaseTypes] Delete CaseType";
  constructor(public caseTypeId: string) {}
}
