export enum OrganizationFeatures {
  accessTags = "accessTags",
  allRolesAssignable = "allRolesAssignable",
  analytics = "analytics",
  autoConfirmDialogues = "autoConfirmDialogues",
  cannotUnassignSelf = "cannotUnassignSelf",
  caseCreate = "caseCreate",
  caseDashboard = "caseDashboard",
  caseDashboardAsSinglePage = "caseDashboardAsSinglePage",
  caseProgressCircle = "caseProgressCircle",
  caseRequest = "caseRequest",
  common = "common",
  directConnect = "directConnect",
  feeBasedServices = "feeBasedServices",
  multiOpinionResponse = "multiOpinionResponse",
  userAdministration = "userAdministration",
  notificationChimes = "notificationChimes",
  paymentsInSubmit = "paymentsInSubmit",
  signalR = "signalR",
  smsOptIn = "smsOptIn",
  taggedDocumentUploads = "taggedDocumentUploads",
  toastrPositionClass = "toastrPositionClass",
  virtualCare = "virtualCare",
  profile = "profile",
  contentManager = "contentManager",
  navigatorPortal = "navigatorPortal",
  communications = "communications",
  termsOfService = "termsOfService",
  vCompanion = "vCompanion",
  userPermissions = "userPermissions",
  homePage = "homePage",
  initialDepartment = "initial-department"
}
