import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

/**
 * Removes undefined and null values
 */
export const filterNull = <T>() => filter<T>(value => value !== undefined && value !== null);

/**
 * Removes undefined and null values but maps to a concrete type so T | null does not need
 * to be used in subsequent operator functions.
 */
export function filterNullMap<T>() {
  return (source$: Observable<T | null | undefined>) =>
    source$.pipe(
      filterNull(),
      map(event => event as T)
    );
}

/**
 * Removes undefined and null values, expanded, same as above
 */
export function filterNullExpanded() {
  return <T>(source: Observable<T>): Observable<T> => {
    return new Observable(subscriber => {
      return source.subscribe({
        next(value) {
          if (value !== undefined && value !== null) {
            subscriber.next(value);
          }
        },
        error(error: unknown) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        }
      });
    });
  };
}
