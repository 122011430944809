import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { isEmptyObject } from "@vp/shared/utilities";

/**
 * Forked from https://github.com/solodynamo/ng2-search-filter
 */
@Pipe({
  name: "filterTerm"
})
@Injectable()
export class FilterTermPipe implements PipeTransform {
  static filter(
    items: Array<{ [key: string]: any }>,
    term: string | undefined,
    excludes: any,
    filters: Record<string, unknown> | undefined
  ): Array<{ [key: string]: any }> {
    let filteredItem = false;
    let foundItem = false;

    return items.filter(item =>
      checkInside(item, term, excludes, filters, foundItem, filteredItem)
    );
  }

  /**
   *
   * @param items List of items to filter
   * @param term  String term to compare with every property of the list
   * @param excludes List of keys which will be ignored during search
   * @param filter List of key/value to filter on
   *
   */
  transform(
    items: any,
    term: string | undefined,
    excludes: any = [],
    filters: Record<string, unknown> | undefined = undefined
  ): any {
    if (!items) {
      return items;
    }

    if (!term && (!filters || isEmptyObject(filters))) {
      return items;
    }

    return FilterTermPipe.filter(items, term, excludes, filters);
  }
}

const checkInside = (
  item: any,
  term: string | undefined,
  excludes: any,
  filters: Record<string, unknown> | undefined,
  found: boolean,
  filtered: boolean
) => {
  const toCompare = term?.toLowerCase();
  if (toCompare && typeof item === "string" && item.toString().toLowerCase().includes(toCompare)) {
    found = true;
  }
  for (let property in item) {
    if (filters && filters[property] && item[property] !== filters[property]) {
      filtered = true;
    }
    if (item[property] === null || item[property] === undefined || excludes.includes(property)) {
      continue;
    }
    if (toCompare && typeof item[property] === "object") {
      if (checkInside(item[property], term, excludes, filters, found, filtered)) {
        found = true;
      }
    } else if (toCompare && item[property].toString().toLowerCase().includes(toCompare)) {
      found = true;
    }
  }
  return (term && found && !filtered) || (!term && !filtered);
};
