import { CommonModule } from "@angular/common";
import { InjectionToken, NgModule } from "@angular/core";
import { CaseTypesDataResolverService } from "../services/case-types-data-resolver.service";

export const CASE_TYPES_API_BASE_URL = new InjectionToken<string>("CASE_TYPES_API_BASE_URL");

@NgModule({
  imports: [CommonModule],
  providers: [CaseTypesDataResolverService]
})
export class CaseTypesDataAccessModule {}
