<div
  [attr.flexLayout]="props.flexLayout ?? 'row wrap'"
  flexLayout.lt-md="column"
  [attr.flexLayoutAlign]="props.flexLayoutAlign ?? 'flex-start stretch'"
  [flexLayoutGap]="props.flexLayoutGap ?? '1rem grid'"
>
  <ng-container *ngFor="let f of field.fieldGroup; trackBy: trackById">
    <formly-field
      [flex]="getFlex(f)"
      [field]="f"
      [style.--max-width]="getMaxWidth(f)"
    >
    </formly-field>
  </ng-container>
</div>
