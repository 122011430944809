import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { BaseDirective } from "../../base/base.directive";
import { FLEX_BREAKPOINTS } from "../model/flex-breakpoints";

@Directive({
  selector: `
  [flexLayoutAlign.xs], [flexLayoutAlign.sm], [flexLayoutAlign.md],
  [flexLayoutAlign.lg], [flexLayoutAlign.xl], [flexLayoutAlign.lt-sm], [flexLayoutAlign.lt-md],
  [flexLayoutAlign.lt-lg], [flexLayoutAlign.lt-xl], [flexLayoutAlign.gt-xs], [flexLayoutAlign.gt-sm],
  [flexLayoutAlign.gt-md], [flexLayoutAlign.gt-lg]
`
})
export class FlexLayoutAlignDirective extends BaseDirective implements OnChanges, OnInit {
  @Input("flexLayoutAlign.xs") flexLayoutAlign_xs?: string;
  @Input("flexLayoutAlign.sm") flexLayoutAlign_sm?: string;
  @Input("flexLayoutAlign.md") flexLayoutAlign_md?: string;
  @Input("flexLayoutAlign.lg") flexLayoutAlign_lg?: string;
  @Input("flexLayoutAlign.xl") flexLayoutAlign_xl?: string;
  @Input("flexLayoutAlign.lt-sm") flexLayoutAlign_lt_sm?: string;
  @Input("flexLayoutAlign.lt-md") flexLayoutAlign_lt_md?: string;
  @Input("flexLayoutAlign.lt-lg") flexLayoutAlign_lt_lg?: string;
  @Input("flexLayoutAlign.lt-xl") flexLayoutAlign_lt_xl?: string;
  @Input("flexLayoutAlign.gt-xs") flexLayoutAlign_gt_xs?: string;
  @Input("flexLayoutAlign.gt-sm") flexLayoutAlign_gt_sm?: string;
  @Input("flexLayoutAlign.gt-md") flexLayoutAlign_gt_md?: string;
  @Input("flexLayoutAlign.gt-lg") flexLayoutAlign_gt_lg?: string;

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._breakPointValueMap = {
      xs: this.flexLayoutAlign_xs,
      sm: this.flexLayoutAlign_sm,
      md: this.flexLayoutAlign_md,
      lg: this.flexLayoutAlign_lg,
      xl: this.flexLayoutAlign_xl,
      lt_sm: this.flexLayoutAlign_lt_sm,
      lt_md: this.flexLayoutAlign_lt_md,
      lt_lg: this.flexLayoutAlign_lt_lg,
      lt_xl: this.flexLayoutAlign_lt_xl,
      gt_xs: this.flexLayoutAlign_gt_xs,
      gt_sm: this.flexLayoutAlign_gt_sm,
      gt_md: this.flexLayoutAlign_gt_md,
      gt_lg: this.flexLayoutAlign_gt_lg
    };
    const breakpointInputs = Object.entries(changes)
      .filter(([, value]) => value.currentValue)
      .map(([inputKey]) => {
        return inputKey.replace(/flexLayoutAlign_/, "");
      });
    const breakpointsMediaQuery = FLEX_BREAKPOINTS.filter(bp =>
      breakpointInputs.includes(bp.alias)
    ).map(bp => bp.mediaQuery);
    this._breakpoints.next(breakpointsMediaQuery);
  }

  ngOnInit(): void {
    this.setupResponsiveObservers();
    this._currentValue
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(flexLayoutValue => this.applyFlexLayoutAlign(flexLayoutValue));
  }

  private applyFlexLayoutAlign(layoutAlign: string | undefined) {
    const nativeElement = this.elementRef.nativeElement;
    if (!nativeElement) {
      return;
    }
    if (layoutAlign) {
      let [mainAxis, crossAxis] = layoutAlign.split(" ");
      mainAxis = this.mapAxis(mainAxis);
      crossAxis = this.mapAxis(crossAxis, true);
      if (crossAxis === "stretch") {
        const computedStyle = window.getComputedStyle(nativeElement);
        const flexDirection = computedStyle.getPropertyValue("flex-direction");
        const maxProperty = flexDirection === "column" ? "max-width" : "max-height";
        this.renderer.setStyle(nativeElement, maxProperty, "100%");
      }
      this.renderer.setStyle(nativeElement, "justify-content", mainAxis);
      this.renderer.setStyle(nativeElement, "align-items", crossAxis);
      this.renderer.setStyle(nativeElement, "align-content", crossAxis);
    } else {
      ["justify-content", "align-items", "align-content"].forEach(property => {
        this.renderer.removeStyle(nativeElement, property);
      });
    }
  }

  private mapAxis(value: string | undefined, isCrossAxis?: boolean): string {
    switch (value) {
      case "start":
      case "flex-start":
        return "flex-start";
      case "end":
      case "flex-end":
        return "flex-end";
      case "center":
      case "space-around":
      case "space-between":
      case "space-evenly":
        return value;
      default:
        return isCrossAxis ? "stretch" : "flex-start";
    }
  }
}
