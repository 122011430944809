import { Inject, Injectable, isDevMode, OnDestroy } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { EventAggregator } from "@vp/data-access/application";
import { APP_INSIGHTS_KEY } from "@vp/shared/tokens";
import { Observable, of, Subject, zip } from "rxjs";
import { catchError, mergeMap, takeUntil, tap } from "rxjs/operators";
import { AppInsightsEvent } from "./app-insights-event";

// TODO What is the following comment block from? The `enableProductionMode` is not available?

/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */
export enum LogLevel {
  Off = 0,
  Debug,
  Info,
  Warning,
  Error
}

// TODO Is this an Application Insights concept? Is it fully implemented? If so, how?
// TODO Add parameter to optionally disable/enable logging to console for better dev exp.
// TODO Perhaps the static "outputs" can be used to do this, but that is not evident yet.
export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

@Injectable({
  providedIn: "root"
})
export class AppInsightsProxyService implements OnDestroy {
  static level = LogLevel.Debug;
  static outputs: LogOutput[] = [];
  appInsights!: ApplicationInsights;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(APP_INSIGHTS_KEY) instrumentationKey: string,
    eventAggregator: EventAggregator,
    private store: Store
  ) {
    if (isDevMode()) return;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();

    eventAggregator
      .on<AppInsightsEvent>(AppInsightsEvent)
      .pipe(
        mergeMap((e: AppInsightsEvent) => {
          let handled: Observable<boolean> = of(false);
          switch (e.args.type) {
            case "event":
              if (e.args.source) {
                handled = this.logEvent(e.args.source, e.args);
              }
              break;
            case "exception":
              handled = this.logException(e.args.error, e.sender, e.args);
              break;
            case "metric":
              if (e.args.source) {
                handled = this.logMetric(e.args.source, e.args.average, e.args);
              }
              break;
            case "pageView":
              handled = this.logPageView(e.args.source, "");
              break;
            case "trace":
              if (e.args.message) {
                handled = this.logTrace(e.args.message, e.args);
              }
              break;
            default:
              break;
          }
          return zip(handled, of(e));
        }),
        tap(([handled, event]) => {
          if (handled) {
            this.store.dispatch(new ApplicationStateActions.EventHandled(event));
          }
        }),

        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  listen() {
    console.log("app insights proxy service has started");
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private logEvent(name: string, properties?: { [key: string]: any }) {
    return new Observable<boolean>(observer => {
      this.appInsights.trackEvent({ name }, properties);
      observer.next(true);
      observer.complete();
    }).pipe(catchError(() => of(false)));
  }

  private logException(error: Error, areaOfApp?: string, properties?: { [key: string]: any }) {
    return new Observable<boolean>(observer => {
      this.appInsights.trackException(
        {
          id: areaOfApp,
          exception: error,
          severityLevel: LogLevel.Error
        },
        properties
      );
      observer.next(true);
      observer.complete();
    }).pipe(catchError(() => of(false)));
  }

  private logMetric(
    name: string,
    average?: number | null | undefined,
    properties?: { [key: string]: any } | null | undefined
  ) {
    return new Observable<boolean>(observer => {
      this.appInsights.trackMetric({ name: name, average: average ?? 0 }, properties ?? undefined);
      observer.next(true);
      observer.complete();
    }).pipe(catchError(() => of(false)));
  }

  private logPageView(name?: string, url?: string) {
    return new Observable<boolean>(observer => {
      this.appInsights.trackPageView({
        name,
        uri: url
      });
      observer.next(true);
      observer.complete();
    }).pipe(catchError(() => of(false)));
  }

  private logTrace(message: string, properties?: { [key: string]: any }) {
    return new Observable<boolean>(observer => {
      this.appInsights.trackTrace({ message }, properties);
      observer.next(true);
      observer.complete();
    }).pipe(catchError(() => of(false)));
  }
}
