import { Directive, HostBinding } from "@angular/core";

@Directive({
  selector: "[cssFill]"
})
export class CssFillDirective {
  @HostBinding("style.margin") margin = "0";
  @HostBinding("style.width") width = "100%";
  @HostBinding("style.height") height = "100%";
  @HostBinding("style.min-width") minWidth = "100%";
  @HostBinding("style.min-height") minHeight = "100%";
}
