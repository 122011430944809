import { HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { iif, insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { CaseApiService } from "@vp/data-access/case";
import { OrganizationState } from "@vp/data-access/organization";
import { RequestMetaDataState } from "@vp/data-access/request-meta-data";
import {
  CaseData,
  CaseUser,
  Organization,
  OrganizationStatus,
  PageResult,
  PageState
} from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import {
  deeperCopy,
  getUtcNow,
  mapToPageResult,
  mergeDeep,
  parseError,
  withHeaderData
} from "@vp/shared/utilities";
import { Operation, createPatch } from "rfc6902";
import { EMPTY, Observable, of, throwError, zip } from "rxjs";
import { catchError, concatMap, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { CaseFilterApiService } from "../api/case-filter-api.service";
import { CaseDataFilter } from "../models/case-data-filter";
import { CASE_FILTER_OPTIONS, CaseFilterOptions } from "../tokens";
import * as CaseFilterStateActions from "./case-filter-state.actions";

export type CaseFilterStateModel = {
  filter: Partial<CaseDataFilter>;
  pageState: Partial<PageState>;
  results: CaseData[];
  errors: string[];
};

export const defaultCaseFilterState = (): CaseFilterStateModel => {
  return {
    filter: {},
    results: [],
    pageState: {
      totalRecords: 0,
      pageIndex: 0,
      pageCount: 0,
      pageSize: 25,
      lastPage: 1
    },
    errors: []
  };
};

/**
 * TODO: This is starting to feel like its not really specific to queue, instead it feels like
 * this could be the "filtered" state for all case data collections for any searchy type pages.
 * Consider renaming this and moving it up with the case state perhaps?
 */
@State<CaseFilterStateModel>({
  name: "caseFilter",
  defaults: defaultCaseFilterState()
})
@Injectable()
export class CaseFilterState {
  constructor(
    private api: CaseApiService,
    private caseFilterApiService: CaseFilterApiService,
    private store: Store,
    @Inject(CASE_FILTER_OPTIONS)
    private caseFilterOptions: CaseFilterOptions
  ) {}

  @Selector()
  public static getCaseFn(state: CaseFilterStateModel) {
    return (caseId: string) => state.results.find(caseData => caseData.caseId == caseId);
  }

  @Selector([CaseFilterState.getResults])
  public static results(results: CaseData[]): CaseData[] {
    return deeperCopy(results);
  }

  @Selector()
  public static getResults(state: CaseFilterStateModel): CaseData[] {
    return state.results;
  }

  @Selector()
  static getCurrentFilter(state: CaseFilterStateModel) {
    return state.filter;
  }

  @Selector([CaseFilterState.getCurrentFilter])
  static currentFilter(filter: Partial<CaseDataFilter>): Partial<CaseDataFilter> {
    return filter;
  }

  @Selector()
  static pageState(state: CaseFilterStateModel): Partial<PageState> {
    return state.pageState;
  }

  @Action(CaseFilterStateActions.ResetState)
  reset(ctx: StateContext<CaseFilterStateModel>) {
    ctx.patchState(defaultCaseFilterState());
  }

  @Action(CaseFilterStateActions.SetPageState)
  setPageState(
    ctx: StateContext<CaseFilterStateModel>,
    action: CaseFilterStateActions.SetPageState
  ) {
    ctx.setState(
      patch({
        pageState: patch(action.pageState),
        filter: patch({
          take: action.pageState.pageSize,
          skip:
            action.pageState.pageSize && action.pageState.pageIndex
              ? action.pageState.pageSize * action.pageState.pageIndex
              : 0
        })
      })
    );
  }

  @Action(CaseFilterStateActions.SetFilterState)
  setFilterState(
    ctx: StateContext<CaseFilterStateModel>,
    action: CaseFilterStateActions.SetFilterState
  ) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        pageState: patch({
          pageIndex: 0
        }),
        filter: patch({
          ...action.filter,
          take: action.filter.take ? action.filter.take : state.pageState.pageSize,
          skip: 0
        })
      })
    );
  }

  @Action(CaseFilterStateActions.ResetFilterState)
  resetFilterState(ctx: StateContext<CaseFilterStateModel>) {
    const currentState = ctx.getState();
    ctx.setState(
      patch<CaseFilterStateModel>({
        pageState: patch({
          pageIndex: 0
        }),
        filter: {
          take: currentState.filter.take,
          skip: currentState.filter.skip,
          sort: currentState.filter.sort,
          sortDir: currentState.filter.sortDir
        }
      })
    );
  }

  @Action(CaseFilterStateActions.ResetPageState)
  resetPageState(ctx: StateContext<CaseFilterStateModel>) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        pageState: patch({
          pageIndex: 0
        }),
        filter: patch({
          take: state.pageState.pageSize,
          skip: 0
        })
      })
    );
  }

  @Action(CaseFilterStateActions.SetFilter)
  setFilter(ctx: StateContext<CaseFilterStateModel>, action: CaseFilterStateActions.SetFilter) {
    const currentFilter = ctx.getState().filter;
    const mergedFilter = mergeDeep(currentFilter, action.filter, "replace") as CaseDataFilter;
    const requestMetadata = this.store.selectSnapshot(RequestMetaDataState);
    this.caseFilterApiService
      .filteredCases(
        mergedFilter,
        this.caseFilterOptions.feature ?? requestMetadata.metaData["feature-name"]
      )
      .subscribe((response: HttpResponse<CaseData[]>) => {
        const total: number = Number(response.headers.get("X-Paging-TotalRecordCount")).valueOf();
        const results = response.body as CaseData[];
        ctx.setState(
          patch({
            pageState: patch({
              totalRecords: total
            }),
            filter: patch({ ...action.filter }),
            results: results
          })
        );
      });
  }

  @Action(CaseFilterStateActions.UpdateState)
  updateState(
    ctx: StateContext<CaseFilterStateModel>,
    { state: state }: CaseFilterStateActions.UpdateState
  ) {
    return this.caseFilterApiService
      .filteredCases(state.filter, this.caseFilterOptions.feature)
      .pipe(
        tap((response: HttpResponse<CaseData[]>) => {
          const total: number = Number(response.headers.get("X-Paging-TotalRecordCount")).valueOf();
          const results = response.body as CaseData[];
          ctx.setState(
            patch({
              pageState: patch({
                totalRecords: total
              }),
              results: results
            })
          );
        })
      );
  }

  @Action(CaseFilterStateActions.GetFiltered)
  getFiltered(ctx: StateContext<CaseFilterStateModel>) {
    const state: CaseFilterStateModel = ctx.getState();
    const requestMetadata = this.store.selectSnapshot(RequestMetaDataState);
    return this.caseFilterApiService
      .filteredCases(
        state.filter,
        this.caseFilterOptions.feature ?? requestMetadata.metaData["feature-name"]
      )
      .pipe(withHeaderData(), mapToPageResult<CaseData>())
      .subscribe((pageResults: PageResult<CaseData>) => {
        ctx.setState(
          patch({
            pageState: patch({
              totalRecords: pageResults.totalRecords
            }),
            results: pageResults.results ?? []
          })
        );
      });
  }

  @Action(CaseFilterStateActions.RefreshCase)
  getFilteredCase(
    ctx: StateContext<CaseFilterStateModel>,
    { caseId, addIfNotExists }: CaseFilterStateActions.RefreshCase
  ) {
    const currentState: CaseFilterStateModel = ctx.getState();
    const caseData = currentState.results.find(c => c.caseId === caseId);

    if (caseData) {
      return this.caseFilterApiService.getCase(caseData.caseId).pipe(
        tap(caseData => {
          ctx.dispatch(new CaseFilterStateActions.UpdateCaseState(caseData.caseId, caseData));
        })
      );
    } else if (addIfNotExists) {
      return ctx.dispatch(new CaseFilterStateActions.AddNewCase(caseId));
    }
    return EMPTY;
  }

  @Action(CaseFilterStateActions.AssignUserToCase)
  assignUserToCase(
    ctx: StateContext<CaseFilterStateModel>,
    { caseId: caseId, caseUser: caseUser }: CaseFilterStateActions.AssignUserToCase
  ) {
    const currentState = ctx.getState();
    const caseData = currentState.results.find(c => c.caseId === caseId);
    if (!caseData) {
      return;
    }

    return of(caseData).pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        return {
          ...caseData,
          users: caseData.users.concat(caseUser as CaseUser)
        };
      }),
      concatMap((caseData: CaseData) =>
        ctx.dispatch(new CaseFilterStateActions.PatchCase(caseData))
      )
    );
    //TODO: We should be handling this more like the Tag Manager
    // ctx.setState(
    //   patch({
    //     results: updateItem<CaseData>(
    //       item => item?.caseId === caseId,
    //       patch<CaseData>({
    //         assignedDateTime: caseData?.assignedDateTime ?? getUtcNow(),
    //         users: iif<CaseUser[]>(
    //           users => users?.length === 0 || !users?.some(u => u.userId === caseUser.userId),
    //           append<CaseUser>([caseUser as CaseUser])
    //         )
    //       })
    //     )
    //   })
    // );
  }

  @Action(CaseFilterStateActions.UnassignUserFromCase)
  unassignUserFromCase(
    ctx: StateContext<CaseFilterStateModel>,
    {
      caseId: caseId,
      userId: userId,
      unassignedByUserId: unassignedByUserId
    }: CaseFilterStateActions.UnassignUserFromCase
  ) {
    const currentState = ctx.getState();
    const unassignCase = currentState.results.find(c => c.caseId === caseId);

    return of(unassignCase).pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        return {
          ...caseData,
          assignedDateTime: caseData.assignedDateTime ?? getUtcNow(),
          users: unassignedByUserId === "all" ? [] : caseData.users.filter(u => u.userId !== userId)
        };
      }),
      concatMap((caseData: CaseData) =>
        ctx.dispatch(new CaseFilterStateActions.PatchCase(caseData))
      )
    );
  }

  /**
   * Patch passed state to server, and update state with response.
   * @param ctx
   * @param { caseData }
   * @returns {Observable<CaseData>}
   */
  @Action(CaseFilterStateActions.PatchCase)
  patch(
    ctx: StateContext<CaseFilterStateModel>,
    { caseData: caseData }: CaseFilterStateActions.PatchCase
  ): Observable<CaseData> {
    const currentState = ctx.getState();
    return zip(
      of(currentState.results.find(c => c.caseId === caseData.caseId)).pipe(filterNullMap()),
      of(caseData)
    ).pipe(
      map(([original, changed]) => {
        return {
          caseId: changed.caseId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) => {
        if (caseOperations.operations.length) {
          return this.caseFilterApiService
            .patch(caseOperations.caseId, caseOperations.operations)
            .pipe(map(() => caseOperations.caseId));
        }
        return EMPTY;
      }),
      concatMap(caseId => this.caseFilterApiService.getCase(caseId)),
      tap(caseData => {
        ctx.dispatch(new CaseFilterStateActions.UpdateCaseState(caseData.caseId, caseData));
      }),
      catchError(error => {
        ctx.patchState({ errors: parseError(error) });
        return throwError(error);
      })
    );
  }

  @Action(CaseFilterStateActions.UpdateCaseState)
  updateCaseState(
    ctx: StateContext<CaseFilterStateModel>,
    action: CaseFilterStateActions.UpdateCaseState
  ) {
    ctx.setState(
      patch({
        results: updateItem<CaseData>(
          caseData => caseData?.caseId === action.caseId,
          caseData => mergeDeep(caseData, action.caseData, "replace")
        )
      })
    );
  }

  @Action(CaseFilterStateActions.PatchCaseState)
  patchCaseState(
    ctx: StateContext<CaseFilterStateModel>,
    action: CaseFilterStateActions.UpdateCaseState
  ) {
    ctx.setState(
      patch({
        results: updateItem<CaseData>(
          caseData => caseData?.caseId === action.caseId,
          patch({ ...action.caseData })
        )
      })
    );
  }

  @Action(CaseFilterStateActions.AddNewCase)
  addNewCase(
    ctx: StateContext<CaseFilterStateModel>,
    { caseId }: CaseFilterStateActions.AddNewCase
  ) {
    this.api
      .getCase(caseId)
      .pipe(
        tap(caseData => {
          ctx.setState(
            patch<CaseFilterStateModel>({
              results: insertOrUpdate(caseId, caseData)
            })
          );
        })
      )
      .subscribe();
  }

  @Action(CaseFilterStateActions.RemoveCaseState)
  RemoveCaseState(
    ctx: StateContext<CaseFilterStateModel>,
    { caseId }: CaseFilterStateActions.RemoveCaseState
  ) {
    ctx.setState(
      patch({
        results: removeItem<CaseData>(item => item?.caseId === caseId)
      })
    );
  }

  @Action(CaseFilterStateActions.UpdateStatus)
  updateCaseStatus(
    ctx: StateContext<CaseFilterStateModel>,
    { caseId: caseId, newStatusId: newStatusId, tagId: tagId }: CaseFilterStateActions.UpdateStatus
  ) {
    const currentState = ctx.getState();
    const caseToUpdate = currentState.results.find(c => c.caseId === caseId);
    const organization: Organization | null = this.store.selectSnapshot(
      OrganizationState.organization
    );
    const newStatusFriendlyId: string | undefined = organization?.statuses.find(
      (status: OrganizationStatus) => status.statusId === newStatusId
    )?.friendlyId;

    if (!newStatusFriendlyId) {
      return throwError(`Status with id of "${newStatusId}" was not found.`);
    }

    return of(caseToUpdate).pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        let changes = {
          ...caseData,
          status: {
            statusId: newStatusId,
            friendlyId: newStatusFriendlyId
          }
        };

        if (tagId) {
          const tags =
            caseData.tags?.indexOf(tagId) === -1
              ? [...caseData.tags, tagId]
              : (caseData.tags ?? []);
          changes = {
            ...changes,
            tags: tags
          };
        }

        return changes;
      }),
      concatMap((caseData: CaseData) =>
        ctx.dispatch(new CaseFilterStateActions.PatchCase(caseData))
      )
    );
  }

  @Action(CaseFilterStateActions.AcceptOrRejectCase)
  acceptCase(
    ctx: StateContext<CaseFilterStateModel>,
    action: CaseFilterStateActions.AcceptOrRejectCase
  ) {
    const caseData = ctx.getState().results.find(cd => cd.caseId === action.caseId);
    if (!caseData) return;
    of(caseData)
      .pipe(
        filterNullMap(),
        map(caseData => {
          const caseDataCopy = deeperCopy(caseData);
          const caseUser = caseDataCopy.users.find(
            (u: CaseUser) =>
              u.userId === action.caseUser.userId &&
              u.roleId === action.caseUser.roleId &&
              u.responsibilityFriendlyId === action.caseUser.responsibilityFriendlyId
          );
          caseUser.acceptanceStatus = action.accept ? "accepted" : "rejected";
          caseUser.acceptanceStatusLastUpdatedDateTime = getUtcNow();

          return {
            caseId: caseData.caseId,
            operations: createPatch(caseData, caseDataCopy)
          };
        }),
        filterNullMap(),
        switchMap((caseOperations: { caseId: string; operations: Operation[] }) => {
          if (caseOperations.operations.length > 0) {
            return this.api
              .patch(caseOperations.caseId, caseOperations.operations)
              .pipe(map(() => caseOperations.caseId));
          }
          return EMPTY;
        }),
        mergeMap(caseId => {
          return this.api.getCase(caseId);
        }),
        tap(caseData => {
          ctx.setState(
            patch({
              results: updateItem<CaseData>(item => item?.caseId === caseData.caseId, caseData)
            })
          );
        }),
        take(1)
      )
      .subscribe();
  }
}

const insertOrUpdate = (caseId: string, caseData: CaseData) => {
  return iif<CaseData[]>(
    cases => cases?.some(c => c.caseId === caseId) ?? false,
    updateItem<CaseData>(item => item?.caseId === caseId, patch(caseData)),
    insertItem<CaseData>(caseData)
  );
};
