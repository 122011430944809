import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FilterTermPipe } from "./filter-term.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [FilterTermPipe],
  exports: [FilterTermPipe]
})
export class FilterTermPipeModule {}
