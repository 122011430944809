import { Pipe, PipeTransform } from "@angular/core";
import { millisecondsToHoursString, millisecondsToMinuteString } from "@vp/shared/utilities";

@Pipe({
  name: "shortTime"
})
export class ShortTimePipe implements PipeTransform {
  transform(value: number, format: "hours" | "minutes" = "minutes"): string {
    if (format === "hours") {
      return millisecondsToHoursString(value);
    }
    return millisecondsToMinuteString(value);
  }
}
