import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormlyModule } from "@ngx-formly/core";
import { FlexModule } from "@vp/shared/directives/flex";
import { MaterialModule } from "@vp/shared/material";
import { SpecialtyTagsComponent } from "./specialty-tags.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    FormlyModule.forChild({
      types: [{ name: "specialty-tags", component: SpecialtyTagsComponent }]
    }),
    FlexModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialModule,
    MatSelectModule
  ],
  declarations: [SpecialtyTagsComponent],
  exports: [SpecialtyTagsComponent]
})
export class SpecialtyTagsModule {}
