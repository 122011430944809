import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { BaseDirective } from "../../base/base.directive";
import { FLEX_BREAKPOINTS } from "../model/flex-breakpoints";

@Directive({
  selector: `
  [flexLayout.xs], [flexLayout.sm], [flexLayout.md],
  [flexLayout.lg], [flexLayout.xl], [flexLayout.lt-sm], [flexLayout.lt-md],
  [flexLayout.lt-lg], [flexLayout.lt-xl], [flexLayout.gt-xs], [flexLayout.gt-sm],
  [flexLayout.gt-md], [flexLayout.gt-lg]
`
})
export class FlexLayoutDirective extends BaseDirective implements OnChanges, OnInit {
  @Input("flexLayout.xs") flexLayout_xs?: string;
  @Input("flexLayout.sm") flexLayout_sm?: string;
  @Input("flexLayout.md") flexLayout_md?: string;
  @Input("flexLayout.lg") flexLayout_lg?: string;
  @Input("flexLayout.xl") flexLayout_xl?: string;
  @Input("flexLayout.lt-sm") flexLayout_lt_sm?: string;
  @Input("flexLayout.lt-md") flexLayout_lt_md?: string;
  @Input("flexLayout.lt-lg") flexLayout_lt_lg?: string;
  @Input("flexLayout.lt-xl") flexLayout_lt_xl?: string;
  @Input("flexLayout.gt-xs") flexLayout_gt_xs?: string;
  @Input("flexLayout.gt-sm") flexLayout_gt_sm?: string;
  @Input("flexLayout.gt-md") flexLayout_gt_md?: string;
  @Input("flexLayout.gt-lg") flexLayout_gt_lg?: string;

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._breakPointValueMap = {
      xs: this.flexLayout_xs,
      sm: this.flexLayout_sm,
      md: this.flexLayout_md,
      lg: this.flexLayout_lg,
      xl: this.flexLayout_xl,
      lt_sm: this.flexLayout_lt_sm,
      lt_md: this.flexLayout_lt_md,
      lt_lg: this.flexLayout_lt_lg,
      lt_xl: this.flexLayout_lt_xl,
      gt_xs: this.flexLayout_gt_xs,
      gt_sm: this.flexLayout_gt_sm,
      gt_md: this.flexLayout_gt_md,
      gt_lg: this.flexLayout_gt_lg
    };

    const breakpointInputs = Object.entries(changes)
      .filter(([, value]) => value.currentValue)
      .map(([inputKey]) => {
        return inputKey.replace(/flexLayout_/, "");
      });
    const breakpointsMediaQuery = FLEX_BREAKPOINTS.filter(bp =>
      breakpointInputs.includes(bp.alias)
    ).map(bp => bp.mediaQuery);
    this._breakpoints.next(breakpointsMediaQuery);
  }

  ngOnInit(): void {
    this.setupResponsiveObservers();
    this._currentValue
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(flexLayoutValue => this.applyFlexLayout(flexLayoutValue));
  }

  private applyFlexLayout(layout: string | undefined) {
    const nativeElement = this.elementRef.nativeElement;
    if (!nativeElement) {
      return;
    }
    if (layout) {
      const [layoutDirection, wrap] = layout.split(" ");
      this.renderer.setStyle(nativeElement, "display", "flex");
      this.renderer.setStyle(nativeElement, "flex-direction", layoutDirection);
      if (wrap) {
        this.renderer.setStyle(nativeElement, "flex-wrap", "wrap");
      }
    } else {
      this.renderer.removeStyle(nativeElement, "display");
      this.renderer.removeStyle(nativeElement, "flex-direction");
      this.renderer.removeStyle(nativeElement, "flex-wrap");
    }
  }
}
