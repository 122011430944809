import { Tag, TagType, User } from "@vp/models";
import { JSONSchema7 } from "json-schema";
import { CommunicationData } from "libs/models/src/lib/application/communications";
import { Observable, Timestamp, timer } from "rxjs";
import { debounceTime, scan, switchMap, timestamp } from "rxjs/operators";
import { Differences, mergeDeep } from "..";

/**
 * Merge `arr2` into `arr1` using the `key` property
 */
export const mergeByKey = <T>(arr1: T[], arr2: T[], key: string) => {
  return arr1.map((obj: any) => arr2.find((o: any) => o[key] === obj[key]) || obj);
};

export type arrayAction = "merge" | "concat" | "replace";

/**
 * @description Uses fisher yates algorithm to shuffle an array
 * @param array array to shuffle
 */
export const shuffle = (array: Array<any>): Array<any> => {
  const modified = [...array];
  for (let i = modified.length - 1; i > 0; --i) {
    const j = Math.floor(Math.random() * modified.length);
    const temp = modified[i];
    modified[i] = modified[j];
    modified[j] = temp;
  }

  return modified;
};

/**
 * @description Check to determine if IE11 or Edge browser
 */
export const isIE11orEdge = () => {
  return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./) || navigator.userAgent.match(/Edge/);
};

export const isIE = () =>
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Converts milliseconds to a human readable string using mm:ss
 * @param milliseconds number of milliseconds
 * @returns formatted string
 */
export const millisecondsToMinuteString = (milliseconds: number) => {
  milliseconds = 1000 * Math.round(milliseconds / 1000);
  const date = new Date(milliseconds);
  const seconds =
    date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : `${date.getUTCSeconds()}`;
  return `${date.getUTCMinutes()}:${seconds}`;
};

/**
 * Converts milliseconds to a human readable string using hh:mm:ss
 * @param milliseconds number of milliseconds
 * @returns formatted string
 */
export const millisecondsToHoursString = (milliseconds: number) => {
  milliseconds = 1000 * Math.round(milliseconds / 1000);
  const date = new Date(milliseconds);
  const minutes =
    date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : `${date.getUTCMinutes()}`;
  const seconds =
    date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : `${date.getUTCSeconds()}`;

  return `${date.getUTCHours()}:${minutes}:${seconds}`;
};

export type keySelector<T, U> = (x: T) => U;
/**
 * Sort array comparer using selector function
 * @param selector function to determine the key to use in the sort
 * @returns sorted array
 */
export const compareBy = <T>(selector: keySelector<T, string>) => {
  return (a: T, b: T) => selector(a).localeCompare(selector(b));
};

/**
 * Finds a needle in a haystack
 * @param selector function to determine the key to use in the comparison
 * @param needle string to find
 * @param haystack array to look in
 * @returns index found
 */
export const findIndexInArrayBy = <T>(
  selector: keySelector<T, string>,
  needle: string,
  haystack: T[]
) => {
  const count = haystack.length;
  for (let i = 0; i < count; i++) {
    if (selector(haystack[i]) === needle) {
      return i;
    }
  }
  return -1;
};

/**
 * Validates `mm/yy` pattern
 */
export const MONTH_YEAR_PATTERN = /\d\d\/\d\d/;

/**
 * Returns `mm` of a `mm/yy` string
 * @param value A `mm/yy` string
 */
export const getMonthYearFromString = (value: string) => {
  const _split = splitAtIndex(value, 2);
  if (TryParseNumber(_split[0]) > 12) {
    throw Error("Not a valid month.");
  }
  if (_split[1].length > 2) {
    throw Error("Year is not the in correct format.");
  }
  return {
    month: _split[0],
    year: _split[1]
  };
};

export const convert12to24 = (time12: string) => {
  const [time, modifier] = time12.split(" ");

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
};

export const splitAtIndex = (slicable: string, ...indices: number[]) => {
  return [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));
};

export const capitalizeFirstCharOnString = (input: string) => {
  if (input == null) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const camelize = (str: string) => {
  return str
    .replace(/[-_]+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const TryParseNumber = (input: string): number => {
  if (!input) return NaN;
  if (input.trim().length === 0) {
    return NaN;
  }
  return Number(input);
};

/**
 * Convert a string to a number; undefined otherwise.
 * @param s A nullable string
 */
export const stringToNumber = (s: string | null | undefined): number | undefined => {
  if (s === null || s === undefined) {
    return undefined;
  }
  const n = parseFloat(s);
  if (isNaN(n)) {
    return undefined;
  }
  return n;
};

/**
 * Scoped property creation on target object. Inserts the objcToInsert object at the location of props specificed by
 * an array of properties representing the "path" of the object to insert:
 *
 * For Example:
 *  ["parent","child", "grandchild", "greatgrandchild"] = parent.child.grandchild.greatgrandchild
 *
 * This also creates any missing nested objects along the way. So if grandchild is missing in the above example, then it
 * is created, and the greatgrandchild is created with the objToInsert object.
 *
 * @param targetObject Object - The target object to insert said properties into. By reference.
 * @param props Array - Array of properties representing the path at which to insert. See above for example.
 * @param objecToInsert Object - the object to be inserted at the above path as the last property in the props array
 */
export const insertObjectAt = (targetObject: any, props: string[], objToInsert: any): void => {
  props.reduce((result, prop) => {
    if (!result[prop]) {
      result[prop] = {};
      return insertObjectAt(result, [prop], objToInsert);
    } else if (result[prop].constructor === Object) {
      result[prop] = mergeDeep(result[prop], objToInsert);
    }
    return result[prop];
  }, targetObject);
};
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

/**
 * Test for any items in an array.
 * Use: `myArray.some(hasAny)`
 */
export const hasAny = () => true;

interface Item<T = any> {
  [key: string]: T;
}

/**
 * A function to use with array filter based on object propery
 * Use: `array.filter(uniqueByKeyFilterFn("myProperyKey"))`
 *
 * @param key propery used to test uniqueness
 */
export const uniqueByKeyFilterFn = <T extends Item>(key: keyof T) => {
  return (v: T, i: number, a: T[]) => a.findIndex((t: T) => t[key] === v[key]) === i;
};

export const toObject = (pairs: [[string, unknown]]) => {
  return Array.from(pairs).reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {});
};

export const isRecord = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && value !== null;
};

/**
 * NonNullable return type
 * @param value Value to check
 */
export const isNonNull = <T>(value: T): value is NonNullable<T> => {
  return value !== null;
};

export interface IPredicate {
  (...args: any[]): void;
}

export interface IFilterPredicate {
  key: string;
  predicate: IPredicate;
}

export const propertyAtPath = (obj: any | undefined, path: string) => {
  if (!obj) return null;
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  const a = path.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }
  return obj;
};

/**
 * @deprecated use mergeDeep
 */
export const deepMerge = (target: any, source: any) => {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = deepMerge(target[key] || {}, source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

/**
 * Compares two Date objects and returns e number value that represents
 * the result:
 * 0 if the two dates are equal.
 * 1 if the first date is greater than second.
 * -1 if the first date is less than second.
 * @param date1 First date object to compare.
 * @param date2 Second date object to compare.
 */
export const compareDate = (date1: Date, date2: Date): number => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const same = d1.getTime() === d2.getTime();
  if (same) return 0;
  if (d1 > d2) return 1;
  if (d1 < d2) return -1;
  throw Error("Invalid Date Range");
};

//Updated to be compatible with .NET framework largest date value
export const MAX_DATE = (): Date => {
  const maxYear = 9999;
  const maxMonth = 11; // 0-based index: 0 represents January, 11 represents December
  const maxDay = 30; //day before
  const maxHour = 23;
  const maxMinute = 59;
  const maxSecond = 59;
  const maxMillisecond = 999;

  const maxDate = new Date(
    maxYear,
    maxMonth,
    maxDay,
    maxHour,
    maxMinute,
    maxSecond,
    maxMillisecond
  );
  return maxDate;
};

export const indexOfOf = (value: any, outer: number[], inner: number[]) => {
  const result = outer.indexOf(inner.indexOf(value)) > -1;
  return result;
};

export const parseError = (err: any) => {
  if (typeof err.error?.errors === "object" && err?.error?.errors !== null) {
    return Object.keys(err.error.errors).map(key => `${key} ${err.error.errors[key]}`);
  }
  return [err.error];
};

export const parseToBool = (boolString: string) => {
  if (/true/i.test(boolString)) {
    return true;
  }
  return false;
};

/**
 * Applies "default" properties to a JSON Schema object based on values provided.
 * @param recordSchema JSONSchema7
 * @param defaults {Record{string, unknown}}
 */
export const applyDefaultProperties = (
  recordSchema: JSONSchema7,
  defaults: Record<string, unknown>
) => {
  if (!recordSchema) {
    throw Error("recordSchema is required.");
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(defaults).reduce((accumulator: any, pair: [string, any]) => {
    const key = pair[0];
    const value = pair[1];
    if (Object.prototype.hasOwnProperty.call(value, "properties")) {
      applyDefaultProperties(value["properties"], propertyAtPath(value["properties"], key));
    } else {
      Object.entries(value).forEach(([_key, _value]) => {
        if (accumulator[key]["properties"][_key]) {
          accumulator[key]["properties"][_key]["default"] = _value;
        }
      });
      return accumulator;
    }
  }, recordSchema["properties"]);
};

/**
 * Calculates human age in years given a birth day. Optionally ageAtDate
 * can be provided to calculate age at a specific date
 *
 * @param string|Date Object birthDate
 * @param string|Date Object ageAtDate optional
 * @returns integer Age between birthday and a given date or today
 */
export const gregorianAge = (birthDate: Date | string, ageAtDate: Date | string): number => {
  const _birthDate = new Date(birthDate);
  const _ageAtDate = ageAtDate ? new Date() : new Date(ageAtDate);

  // if conversion to date object fails return null
  if (_ageAtDate == null || _birthDate == null) return 0;

  if (
    Object.prototype.toString.call(_birthDate) !== "[object Date]" ||
    Object.prototype.toString.call(_ageAtDate) !== "[object Date]"
  )
    return 0;

  const _m = _ageAtDate.getMonth() - _birthDate.getMonth();

  // answer: ageAt year minus birth year less one (1) if month and day of
  // ageAt year is before month and day of birth year
  return (
    _ageAtDate.getFullYear() -
    _birthDate.getFullYear() -
    (_m < 0 || (_m === 0 && _ageAtDate.getDate() < _birthDate.getDate()) ? 1 : 0)
  );
};

export const getTagsToRemove = (
  selected: Tag[],
  tagTypes: TagType[],
  tags: Tag[],
  user: User
): string[] => {
  const tagsToRemove: string[] = [];
  if (selected.length === 0) return tagsToRemove;
  const assignedTags: Tag[] = [];
  user.assignedTags?.forEach(tagId => {
    const tag: Tag | undefined = tags.find(tag => tag.tagId === tagId);
    if (tag) assignedTags.push(tag);
  });
  if (assignedTags.length === 0) return tagsToRemove;
  selected.forEach((tag: Tag) => {
    const tagType: TagType | undefined = tagTypes.find(t => t.tagTypeId === tag.tagTypeId);
    if (tagType?.singleAssignment) {
      const tag: Tag | undefined = assignedTags.find(t => t.tagTypeId == tagType.tagTypeId);
      if (tag) tagsToRemove.push(tag.tagId);
    }
  });
  return tagsToRemove;
};

export const sortTagsByTagPath = (tags: Tag[], sortOrder: "asc" | "desc"): Tag[] => {
  // Create a map of tagId to Tag object
  const tagMap: Record<string, Tag> = {};
  for (const tag of tags) {
    tagMap[tag.tagId] = tag;
  }
  function compareTagsByTagPath(a: Tag, b: Tag): number {
    const aPath = a.tagPath ? a.tagPath.split(".") : [];
    const bPath = b.tagPath ? b.tagPath.split(".") : [];

    for (let i = 0; i < Math.max(aPath.length, bPath.length); i++) {
      const aParent = aPath[i];
      const bParent = bPath[i];

      if (aParent && bParent) {
        if (aParent === bParent) {
          continue;
        }
        const aParentTag = tagMap[aParent];
        const bParentTag = tagMap[bParent];
        if (aParentTag && bParentTag) {
          const result = compareTagsByTagPath(aParentTag, bParentTag);
          if (result !== 0) {
            return result;
          }
        }
      } else if (aParent) {
        return -1;
      } else {
        return 1;
      }
    }

    // If tag paths are identical, sort by displayName
    return a.displayName.localeCompare(b.displayName);
  }
  const sortedTags = tags.sort(compareTagsByTagPath);
  return sortOrder === "desc" ? sortedTags.reverse() : sortedTags;
};

/**
 * Calculate time elapsed since time
 * @param startTime
 * @param format (Optional) can be a custom format function
 *  or a string format where ddd represents days, hh represents hours, mm represents minutes and ss represents seconds.
 *  e.g. "ddd:hh:mm:ss"
 * @returns
 */
export const timeSince = (
  startTime: string,
  format?: string | ((timeElapsedInfo: TimeElapsedInfo) => string)
) => {
  const endDate = new Date();
  const startData = new Date(startTime);
  const seconds = (endDate.getTime() - startData.getTime()) / 1000;
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const formatter = _timeElapsedFormatter(format);
  const timeElapsedInfo = {
    ddd: d < 0 ? 0 : d,
    hh: h < 0 ? 0 : h,
    mm: m < 0 ? 0 : m,
    ss: s < 0 ? 0 : s
  } as TimeElapsedInfo;
  const result = formatter(timeElapsedInfo);
  return result;
};

export interface TimeElapsedInfo {
  ddd: number;
  hh: number;
  mm: number;
  ss: number;
}

function _timeElapsedFormatter(
  formatter: string | ((timeElapsedInfo: TimeElapsedInfo) => string) | undefined
): (timeElapsedInfo: TimeElapsedInfo) => string {
  const defaultFormatter = (timeElapsedInfo: TimeElapsedInfo) => {
    const { ddd: d, hh: h, mm: m } = timeElapsedInfo;
    const dDisplay = d > 0 ? d + (d == 1 ? " day" : " days") : null;
    const hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : null;
    const mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes") : null;
    //const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : null;
    return [dDisplay, hDisplay, mDisplay].filter(s => s).join(" ");
  };

  if (formatter == undefined) {
    return defaultFormatter;
  }

  if (typeof formatter === "function") {
    return formatter;
  }

  if (typeof formatter !== "string") {
    throw new TypeError("formatter must be a string");
  }

  const formatterFunc = (timeElapsedInfo: TimeElapsedInfo) => {
    const { ddd, hh, mm, ss } = timeElapsedInfo;
    return formatter
      .replace("ddd", ddd.toString().padStart(3, "0"))
      .replace("hh", hh.toString().padStart(2, "0"))
      .replace("mm", mm.toString().padStart(2, "0"))
      .replace("ss", ss.toString().padStart(2, "0"));
  };
  return formatterFunc;
}

/**
 * @description maps child communications to respective parent communication
 * @param communications communications to map
 */
export const mapCommunications = (communications: CommunicationData[]) => {
  const map: { [key: string]: number } = {},
    roots = [];
  let node;
  for (let i = 0; i < communications.length; i += 1) {
    node = communications[i];
    node.responses = [];
    map[node.communicationId] = i;
    if (node.parentId) {
      communications[map[node.parentId]].responses.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots; // Return the roots array instead of communications
};

export const versionIsGreaterOrEqualTo = (
  currentVersion: string,
  minimumVersionNumber: string
): boolean => {
  const currentVersionArray = currentVersion.split(".").map(Number);
  const minimumVersionArray = minimumVersionNumber.split(".").map(Number);
  while (currentVersionArray.length < minimumVersionArray.length) {
    currentVersionArray.push(0);
  }
  while (minimumVersionArray.length < currentVersionArray.length) {
    minimumVersionArray.push(0);
  }
  for (let i = 0; i < currentVersionArray.length; i++) {
    if (currentVersionArray[i] > minimumVersionArray[i]) {
      return true;
    } else if (currentVersionArray[i] < minimumVersionArray[i]) {
      return false;
    }
  }
  return true;
};

export const getTagDifferences = (
  previous: Tag[],
  current: Tag[],
  tagTypeFriendlyId: string
): Differences => {
  const previousSet = new Set(
    previous.filter(tag => tag.tagTypeFriendlyId === tagTypeFriendlyId).map(tag => tag.tagId)
  );
  const currentSet = new Set(
    current.filter(tag => tag.tagTypeFriendlyId === tagTypeFriendlyId).map(tag => tag.tagId)
  );

  const added = [...currentSet].filter(item => !previousSet.has(item));
  const removed = [...previousSet].filter(item => !currentSet.has(item));
  return {
    added,
    removed
  };
};

export const groupByParentTagId = (tags: Tag[]): Record<string, Tag[]> => {
  return tags.reduce((acc: Record<string, Tag[]>, tag: Tag) => {
    const parentTagId = tag.tagPath?.split(".").slice(-1)[0] || "root";
    if (!acc[parentTagId]) {
      acc[parentTagId] = [];
    }
    acc[parentTagId].push(tag);
    return acc;
  }, {});
};

export interface TagGroup {
  key: string;
  tag: Tag;
  tagTypeFriendlyId: string;
  children: TagGroup[];
}

export const createNestedTagGroups = (
  groupedTags: Record<string, Tag[]>,
  tagTypes: TagType[],
  parentTagId?: string | null
): TagGroup[] => {
  const parentTagIdStr = parentTagId ?? "root";
  const what = (groupedTags[parentTagIdStr] || []).map(tag => {
    const children = createNestedTagGroups(groupedTags, tagTypes, tag.tagId);
    const tagType = tagTypes.find(tt => tt.tagTypeId === tag.tagTypeId);
    return {
      key: tag.tagId,
      tag,
      tagTypeFriendlyId: tagType?.friendlyId,
      children: children.length ? children : []
    } as TagGroup;
  });
  return what;
};
interface AdaptiveDelayItem {
  delay: number;
  timestamp: number;
  value: null;
}

export function adaptiveDelay(
  baseTimeFrame: number,
  stepIncrease: number,
  maxDelayMultiplier: number
): (source: Observable<any>) => Observable<any> {
  return (source: Observable<any>) => {
    return source.pipe(
      timestamp(),
      scan(
        (acc: AdaptiveDelayItem, curr: Timestamp<any>) => {
          const timeElapsed = curr.timestamp - acc.timestamp;

          if (timeElapsed > baseTimeFrame) {
            // If emission happens after the base time frame, reset delay
            return { delay: baseTimeFrame, timestamp: curr.timestamp, value: curr.value };
          } else {
            // If emission is within the base time frame, increase delay
            const maxDelay = baseTimeFrame * maxDelayMultiplier;
            const newDelay = Math.min(acc.delay + stepIncrease, maxDelay);
            return { delay: newDelay, timestamp: curr.timestamp, value: curr.value };
          }
        },
        { delay: baseTimeFrame, timestamp: 0, value: null }
      ),
      // Delay the emission by the dynamically calculated delay
      switchMap((item: AdaptiveDelayItem) => {
        return timer(item.delay).pipe(
          debounceTime(baseTimeFrame),
          switchMap(() => {
            return [item.value];
          })
        );
      })
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function areEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true;
  if (obj1 == null || obj2 == null) return false;
  if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!areEqual(obj1[i], obj2[i])) return false;
    }
    return true;
  }
  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (!keys2.includes(key)) return false;
    if (!areEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}
