import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "lib-description-wrapper",
  templateUrl: "./description-wrapper.component.html",
  styleUrls: ["./description-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionWrapperComponent extends FieldWrapper {
  constructor() {
    super();
  }
}
