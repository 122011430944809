import { Injectable } from "@angular/core";
import { Tag } from "@vp/models";
import { createPatch } from "rfc6902";
import { TagModel, TagsApiService } from "../api/tags-api.service";

@Injectable({
  providedIn: "root"
})
export class TagsService {
  constructor(private readonly tagApiService: TagsApiService) {}

  createTag(tag: TagModel) {
    return this.tagApiService.addTag(tag);
  }

  patchTag(originalTag: Tag, updatedTag: Partial<Tag>) {
    const patchOperations = createPatch(originalTag, updatedTag);
    return this.tagApiService.patch(originalTag.tagId, patchOperations);
  }

  deleteTag(tagId: string) {
    return this.tagApiService.deleteTag(tagId);
  }
}
