import { Injectable } from "@angular/core";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { ICreateDicomImagesUploadResponse } from "@vp/models";
import { from } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BlobStorageService {
  private containerClient(uploadConfig: ICreateDicomImagesUploadResponse): ContainerClient {
    //THIS DOES NOT WORK WHEN RUNNING API LOCALLY AND RETURNING LOCAL STORAGE ACCOUNT
    return new BlobServiceClient(
      `https://${uploadConfig.accountName}.blob.core.windows.net?${uploadConfig.sasToken}`
    ).getContainerClient(uploadConfig.containerName);
  }

  public uploadAmbraFile(config: ICreateDicomImagesUploadResponse, file: File) {
    let fullpath = (file as any).webkitRelativePath;
    fullpath = fullpath ?? file.name;
    const folderName = fullpath.substr(0, fullpath.lastIndexOf("/"));
    const blobName = `${config.uploadId}/${folderName}/${file.name}`;
    const blockBlobClient = this.containerClient(config).getBlockBlobClient(blobName);
    return from(
      blockBlobClient.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type }
      })
    );
  }
}
