import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { UserAdministrationState } from "./state+/user-administration.state";

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([UserAdministrationState, TagsState, OrganizationState])
  ]
})
export class UserAdministrationStateModule {}
