import { Injectable, InjectionToken } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { StateOperator, append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { OrganizationState } from "@vp/data-access/organization";
import { Tag, TagFilter, TagType } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import {
  createNestedTagGroups,
  deeperCopy,
  groupByParentTagId,
  mergeDeep
} from "@vp/shared/utilities";
import { take, tap } from "rxjs/operators";
import { TagsApiService } from "../api/tags-api.service";
import { TagsService } from "../services/tags.service";
import * as TagsActions from "./tags-actions";

export const TAG_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export interface TagsStateModel {
  tags: Tag[];
  selectedId: string | null;
  filter: Partial<TagFilter>;
  selectedTags: Tag[];
  tagState: Tag | null;
  filtered: Tag[] | null;
}

@State<TagsStateModel>({
  name: "tags",
  defaults: {
    tags: [],
    selectedId: null,
    filter: {},
    selectedTags: [],
    tagState: null,
    filtered: []
  }
})
@Injectable()
export class TagsState implements NgxsAfterBootstrap {
  constructor(
    private readonly tagsService: TagsService,
    private readonly tagsApiService: TagsApiService,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  public static getTags(state: TagsStateModel) {
    return state.tags;
  }

  @Selector([TagsState.getTags])
  public static tags(tags: Tag[]) {
    return [...tags];
  }

  @Selector([TagsState.tags])
  public static getTagsOfTagTypeFn(tags: Tag[]): (tagTypeFriendlyId: string) => Tag[] {
    return (tagTypeFriendlyId: string) =>
      tags.filter(t => t.tagTypeFriendlyId === tagTypeFriendlyId);
  }

  @Selector()
  public static selectedTagId(state: TagsStateModel) {
    return state.selectedId;
  }

  @Selector()
  public static filter(state: TagsStateModel): Partial<TagFilter> {
    return { ...state.filter };
  }

  @Selector()
  public static selectedTags(state: TagsStateModel) {
    return [...state.selectedTags];
  }

  @Selector([TagsState.selectedTags])
  public static selectedTagsDisplay(selectedTags: Tag[] | null) {
    return (assignableTagType: TagType[]) => {
      return selectedTags?.reduce((groupByTagType: Record<string, string[]>, tag: Tag) => {
        const tagTypeDisplayName = assignableTagType.find(
          t => t.tagTypeId === tag.tagTypeId
        )?.displayName;
        if (tagTypeDisplayName) {
          groupByTagType[tagTypeDisplayName] = groupByTagType[tagTypeDisplayName] ?? [];
          groupByTagType[tagTypeDisplayName].push(tag.displayName);
        }
        return groupByTagType;
      }, {});
    };
  }

  @Selector()
  public static tagState(state: TagsStateModel) {
    return state.tagState;
  }

  @Selector()
  public static filtered(state: TagsStateModel) {
    return state.filtered ?? [];
  }

  @Selector([TagsState.filtered, OrganizationState.tagTypes])
  public static filteredGroups(filtered: Tag[], tagTypes: TagType[]) {
    if (!filtered) return [];
    const groupedTags = groupByParentTagId(filtered);
    const tagGroups = createNestedTagGroups(groupedTags, tagTypes);
    return tagGroups;
  }

  ngxsAfterBootstrap(ctx: StateContext<TagsStateModel>) {
    this.authenticationService
      .isLoggedIn$()
      .pipe(take(1))
      .subscribe(authenticatedResult => {
        if (authenticatedResult.isAuthenticated) {
          ctx.dispatch(new TagsActions.LoadTags(false));
        }
      });
  }

  @Action(TagsActions.CreateTag)
  create(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.CreateTag) {
    return this.tagsService.createTag(tag).pipe(
      tap((tag: Tag) => {
        ctx.setState(
          patch<TagsStateModel>({
            tags: append([tag]),
            filtered: append([tag]) as StateOperator<Tag[] | null>
          })
        );
      })
    );
  }

  @Action(TagsActions.LoadTags)
  load(ctx: StateContext<TagsStateModel>, action: TagsActions.LoadTags) {
    return this.tagsApiService.getTags({}, action.useCache).pipe(
      tap((tags: Tag[]) => {
        ctx.patchState({ tags: tags });
      })
    );
  }

  @Action(TagsActions.SetSelectedTagId)
  setSelectedId(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.SetSelectedTagId) {
    ctx.patchState({ selectedId: tagId });
  }

  @Action(TagsActions.UpdateTag)
  update(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.UpdateTag) {
    const original = ctx.getState().tags.find(t => t.tagId === tag.tagId);
    if (original && tag.tagId) {
      this.tagsService
        .patchTag(original, tag)
        .pipe(
          tap(() =>
            ctx.setState(
              patch<TagsStateModel>({
                tags: updateItem<Tag>(t => t?.tagId === tag.tagId, patch<Tag>(tag)),
                filtered: updateItem<Tag>(
                  t => t?.tagId === tag.tagId,
                  patch<Tag>(tag)
                ) as StateOperator<Tag[] | null>
              })
            )
          )
        )
        .subscribe();
    }
  }

  @Action(TagsActions.DeleteTag)
  delete(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.DeleteTag) {
    return this.tagsService.deleteTag(tagId).pipe(
      tap(() => {
        ctx.setState(
          patch<TagsStateModel>({
            tags: removeItem(t => t.tagId === tagId),
            filtered: removeItem(t => t.tagId === tagId) as StateOperator<Tag[] | null>
          })
        );
      })
    );
  }

  @Action(TagsActions.SetDefaultFilter)
  setDefaultFilters(ctx: StateContext<TagsStateModel>, action: TagsActions.SetDefaultFilter) {
    ctx.patchState({ filter: action.tagFilter });
  }

  @Action(TagsActions.SetSelectedTags)
  setSelectedTags(
    ctx: StateContext<TagsStateModel>,
    { selectedTags }: TagsActions.SetSelectedTags
  ) {
    ctx.patchState({ selectedTags: selectedTags });
  }

  @Action(TagsActions.SetTagState)
  setTagState(ctx: StateContext<TagsStateModel>, { tagState }: TagsActions.SetTagState) {
    const currentState = ctx.getState();
    const updatedState = mergeDeep(currentState, tagState);
    return ctx.patchState(updatedState);
  }

  @Action(TagsActions.LoadTagState)
  loadContent(ctx: StateContext<TagsStateModel>, action: TagsActions.LoadTagState) {
    return this.updateState$(action.tagData, ctx);
  }

  @Action(TagsActions.SetFiltered)
  getAssignableTags(ctx: StateContext<TagsStateModel>, action: TagsActions.SetFiltered) {
    return this.tagsApiService.getTags(action.tagFilter, false).pipe(
      tap((tags: Tag[]) => {
        ctx.patchState({ filtered: tags });
      })
    );
  }

  private updateState$ = (partialTag: Partial<Tag>, ctx: StateContext<TagsStateModel>) => {
    const tagData: Tag = deeperCopy(partialTag);
    return ctx.patchState({
      tagState: tagData
    });
  };
}
