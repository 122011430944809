import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild
} from "@angular/core";
import { AngularEditorComponent, AngularEditorConfig } from "@kolkov/angular-editor";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

import { Snippet } from "@vp/models";
import { AppStoreService } from "@vp/shared/store/app";
import { ANGULAR_EDITOR_CONFIG } from "@vp/shared/tokens";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "lib-formly-rich-text-type",
  templateUrl: "./formly-rich-text-type.component.html",
  styleUrls: ["./formly-rich-text-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRichTextTypeComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  config: AngularEditorConfig;
  selectedSnippet: Snippet | undefined = undefined;
  snippets: Snippet[] = [];
  cursorPosition: Range | null = null;

  @ViewChild(AngularEditorComponent)
  angularEditor!: AngularEditorComponent;

  private readonly _disabled = new BehaviorSubject<boolean>(false);

  public disabled = this._disabled.asObservable();

  constructor(
    @Inject(ANGULAR_EDITOR_CONFIG) public defaultEditorConfig: AngularEditorConfig,
    private appStore: AppStoreService
  ) {
    super();
    this.config = { ...defaultEditorConfig };
    this.config.rawPaste = true;
    this.config.minHeight = "100px";
    this.config.maxHeight = "200px";
    this.appStore.user$.subscribe(user => {
      this.snippets = (user.userData?.snippets as Snippet[]) || [];
    });
  }

  ngAfterViewInit(): void {
    if (this.props.minHeight) this.config.minHeight = this.props.minHeight;
    if (this.props.maxHeight) this.config.maxHeight = this.props.maxHeight;
    this._disabled.next(!!this.formState.disabled);
    this.form.statusChanges.subscribe(() => {
      this._disabled.next(this.formState.disabled);
    });
  }

  saveSelection() {
    const sel = window.getSelection();
    if (sel?.getRangeAt && sel.rangeCount) {
      this.cursorPosition = sel.getRangeAt(0);
    }
  }

  showSnippet() {
    const show = this.props?.showSnippet ?? false;
    return show;
  }

  initializePosition() {
    this.angularEditor.focus();
    this.saveSelection();
  }

  insertSnippet(snippet: string | undefined) {
    if (!this.cursorPosition) {
      this.initializePosition();
    }
    if (snippet && this.cursorPosition) {
      const node = document.createRange().createContextualFragment(snippet);
      this.cursorPosition.insertNode(node as Node);
      this.angularEditor.onContentChange(this.angularEditor.textArea.nativeElement);
    }
  }
}
