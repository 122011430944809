<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    specialtyTags: (specialtyTags$ | async),
    selectedOptions: (selectedOptions$ | async)
  }"
>
</ng-container>

<ng-template #template let-specialtyTags="specialtyTags" let-selectedOptions="selectedOptions">
  <mat-form-field>
    <mat-label>Specialties</mat-label>
    <mat-select [value]="selectedOptions" [multiple]="true">
      @for (tag of specialtyTags; track tag.tagId) {
        <mat-option [value]="tag.tagId" (click)="toggleSelection(tag.tagId)">{{
          tag.displayName
        }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</ng-template>
