import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Organization, Tag } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";
import { getTagDisplay } from "./tag-display.pipe";

@Pipe({
  name: "tagSort"
})
export class TagSortPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  public transform(tagIds: string[]): Observable<string[]> {
    return this.organization$.pipe(
      shareReplay(),
      withLatestFrom(this.tags$.pipe(shareReplay())),
      map(([org, tags]: [Organization, Tag[]]) => {
        return [...tagIds].sort(compareTagDisplayNames(org, tags));
      })
    );
  }
}

export const compareTagDisplayNames =
  (org: Organization, tags: Tag[]) => (firstTagId: string, secondTagId: string) => {
    const tag1 = getTagDisplay(firstTagId, tags, org);
    const tag2 = getTagDisplay(secondTagId, tags, org);
    if (!tag1 || !tag2) {
      return 0;
    }
    return tag1 > tag2 ? 0 : -1;
  };
