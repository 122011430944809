import { Group, PageRequestOptions } from "@vp/models";

export class CreateGroup {
  public static readonly type = "[Groups] Create Group";
  constructor(public group: Group) {}
}

export class LoadGroups {
  static readonly type = "[Groups] Load Groups";
}

export class LoadPagedGroups {
  public static readonly type = "[Groups] Load Paged Groups";
  constructor(public options: PageRequestOptions) {}
}

export class SetCurrentGroup {
  public static readonly type = "[Groups] Set Current Group";
  constructor(public groupId: string) {}
}

export class UnsetCurrentGroup {
  public static readonly type = "[Groups] Unset Current Group";
}

export class UpdateGroup {
  public static readonly type = "[Groups] Update Group";
  constructor(public group: Group) {}
}

export class DeleteGroup {
  public static readonly type = "[Groups] Delete Group";
  constructor(public groupId: string) {}
}
