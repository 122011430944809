/**
 * These are the Dashboard permissions.
 */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export const dashboardPermissions = {
  Overview: {
    Read: "dashboardOverviewCanRead",
    Write: "dashboardOverviewCanWrite",
    Delete: "dashboardOverviewCanDelete"
  }
};
