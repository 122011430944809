import { GroupType, TagType, User } from "@vp/models";
import { Operation } from "rfc6902";

export class LoadUser {
  public static readonly type = "[User Admininistration] Load User";
  constructor(public user: User) {}
}

export class SetUser {
  public static readonly type = "[User Admininistration] Set User";
  constructor(public id: string) {}
}

export class ResetState {
  public static readonly type = "[User Admininistration] Reset State";
}

export class UpdateWorkingCopy {
  public static readonly type = "[User Admininistration] Set Working Copy";
  constructor(public user: Partial<User>) {}
}

export class SetPendingOperations {
  public static readonly type = "[User Admininistration] Set Pending Operations";
  constructor(public pendingOperations: Operation[]) {}
}

export class SetAssignableEntities {
  public static readonly type = "[User Administration] Set Assignable Entities";
  constructor(
    public assignableGroupTypes: GroupType[],
    public assignableTagTypes: TagType[]
  ) {}
}
