import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { RequestMetaDataResolverService } from "./services/request-meta-data-resolver.service";
import { RequestMetaDataState } from "./state+/request-meta-data.state";

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([RequestMetaDataState])],
  providers: [RequestMetaDataResolverService]
})
export class RequestMetaDataStateModule {}
