import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { FormlyModule } from "@ngx-formly/core";
import { CybersourcePaymentComponent } from "./cybersource-payment/cybersource-payment.component";
import { CybersourceRefundDialogComponent } from "./cybersource-refund-dialog/cybersource-refund-dialog.component";
import { FlexModule } from "@vp/shared/directives/flex";

@NgModule({
  declarations: [CybersourcePaymentComponent, CybersourceRefundDialogComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    FlexModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormlyModule.forChild({
      types: [
        {
          name: "cybersource-payment",
          component: CybersourcePaymentComponent
        }
      ]
    })
  ],
  exports: [CybersourcePaymentComponent, CybersourceRefundDialogComponent]
})
export class CybersourceModule {}
