import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { MatTabGroup } from "@angular/material/tabs";
import { FieldType, FormlyFieldConfig } from "@ngx-formly/core";
import { FormlyFieldProps } from "@ngx-formly/material/form-field";
import { UiSchemaConfigService } from "@vp/formly/ui-schema-config";

/**
 * A pure function which will use tab labels based on the template options of the tab;
 * otherwise use index number.
 */
export const getLabel = (fieldProps: FormlyFieldProps, tab: FormlyFieldConfig, index: number) => {
  return fieldProps.hideLabel !== true ? tab.props?.label : index + 1;
};

@Component({
  selector: "lib-formly-tabs-layout-type",
  templateUrl: "./formly-tabs-layout-type.component.html",
  styleUrls: ["./formly-tabs-layout-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTabsLayoutTypeComponent extends FieldType implements AfterViewInit {
  label = getLabel;
  trackById = this.getTrackByFn.bind(this);

  constructor(private service: UiSchemaConfigService) {
    super();
  }

  @Input() selectedIndex = 0;

  @ViewChild(MatTabGroup) tabGroup?: MatTabGroup;

  ngAfterViewInit(): void {
    this.tabGroup?.selectedIndexChange.subscribe((index: number) => {
      this.service.tabChanged(index);
    });
  }

  getTrackByFn(index: number, item: any) {
    return item.id ?? index;
  }

  // This is NOT performant, but I couldnt find another way to to do this.
  isValid(index: number): boolean {
    const key = Object.keys(this.form.controls)[index];
    const control = (this.form.controls as { [key: string]: AbstractControl<any, any> })[key];
    if (control) {
      return !(this.form.enabled === true && control.status === "INVALID");
    }
    return true;
  }
}
