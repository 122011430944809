/* eslint-disable @typescript-eslint/no-explicit-any */

export const config = {
  httpRetry: 1,
  sessionInactivityExpirationMinutes: 60 * 3, // 3 hours
  sessionKey: "",
  apimTrace: "true",

  // BASE API
  baseApi: (window as any)?.baseApi || "http://localhost:7071/api",
  apimSubscriptionKey: (window as any)?.apimSubscriptionKey || "7858cb38da754c63a04516b200a6f73e",

  // baseApi: (window as any)?.baseApi || "https://apim.dev.dignityhealthppe.org/vcapi-dev",
  // apimSubscriptionKey: (window as any)?.apimSubscriptionKey || "1e8905fb0c8b4f39bb571790e080dd0d",

  //baseApi: (window as any)?.baseApi || "https://apim.dev.dignityhealthppe.org/2opint-stg",
  //apimSubscriptionKey: (window as any)?.apimSubscriptionKey || "e6e6eb02ff444505a88c3ccb42c7a8d9",

  // IVY
  ivySuffix: (window as any)?.ivySuffix || "ivyapi",
  ivyApimSubscriptionKey:
    (window as any)?.ivyApimSubscriptionKey || "5ba14f445e66496f846ed8645feb85be",
  ivyAppSecret: "GARh1LvPeob4AJ8nDy7FuKwBTw4xKwaq",

  // List of site URLs that aren't authenticted by Okta and we will force
  // to a specific user.
  ivySiteUrls: [
    // Uncomment this to test ivy configuration locally
    //"https://localhost:44322/",
    "https://breakout-demo1.azureedge.net",
    "https://secondopinion2-dev-ivy.barrowneuro.org",
    "https://secondopinion2-qa-ivy.barrowneuro.org",
    "https://secondopinion2-stg-ivy.barrowneuro.org",
    "https://secondopinion2-prd-ivy.barrowneuro.org",
    "https://ivy.secondopinion.barrowneuro.org"
  ],
  ivyUser: {
    userId: "ivyUserId",
    email: "No-reply.IvySubmitter@gmail.com",
    firstName: "Ivy",
    lastName: "Account"
  },
  ivyUrls: {
    homePage: "https://www.ivybraintumorcenter.org/"
  },

  // APP INSIGHTS
  //instrumentationKey: (window as any)?.instrumentationKey || "05cd4c7f-fdd1-4ebe-8002-1a5796430160",
  instrumentationKey: (window as any)?.instrumentationKey || "13d21202-ae5d-4dc8-bf0f-0e6051067686",

  // AMBRA VIEWER
  ambraViewerBaseUrl:
    (window as any)?.ambraViewerBaseUrl ||
    "https://uat.dicomgrid.com/api/v3/link/external?u=e12b2ef8-fbe5-4a3f-addc-69b1b2d18f9d&v=",

  // DEVICE LOGIN SUFFIX
  deviceLoginSuffix: (window as any)?.deviceLoginSuffix || "patient-console-api",

  functionStorageAccountName:
    (window as any)?.functionStorageAccountName || "staw3vcdpfunctionsdev",

  //RECAPTCHA
  reCaptchaSiteKey: (window as any)?.reCaptchaSiteKey || "6LcwDuQZAAAAAENay0bGl-dAljkaO96nl5DLsqna",
  reCaptchaSecretKey:
    (window as any)?.reCaptchaSecretKey || "6LcwDuQZAAAAAMQS4JWBV2KvviMX6bWodwUaPtoS",

  //OKTA
  authServer:
    (window as any)?.authServer ||
    "https://loginpreview.commonspirit.org/oauth2/ausb2b0jbri7MsQGl0h7",
  oktaClientId: (window as any)?.oktaClientId || "0oazsndj073HBeByn0h7",
  oktaLoginBrand: (window as any)?.oktaLoginBrand || "CSH",
  consumerOktaEnv: (window as any)?.consumerOktaEnv || "dev",

  // This is a list of the APIM gateway URLs protected by Okta that the SPA can call.
  // It's a best practice to double check those in code within the SPA.
  oktaApiRoutes: [
    "http://localhost:7071",
    "https://apim-tst-west-us2.azure-api.net",
    "https://apim-dev-west-us2.azure-api.net",
    "https://api-dev-uw2-bni-sec2.azure-api.net",
    "https://apim.dev.dignityhealthppe.org",
    "https://apim.dignityhealth.org",
    "https://apim.dignityhealthstg.org"
  ],

  allowRegistration: (window as any)?.allowRegistration || false,
  tinyMceApiKey: "y5b4wkfkgwn8zknzzbydjjsj3dc0j4lj2rqt0el9gy5do0v1"
};
