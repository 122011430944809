import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Organization, Tag } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";

@Pipe({
  name: "tagsArray"
})
export class TagsArrayPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  public transform(tagIds: string[]): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      withLatestFrom(this.tags$.pipe(shareReplay())),
      map(([org, tags]: [Organization, Tag[]]) => {
        return getTagArraysDisplay(tagIds, org, tags);
      })
    );
  }
}

export const getTagArraysDisplay = (tagIds: string[], org: Organization, tags: Tag[]) => {
  const tagDisplayNames: string[] = [];
  tagIds?.forEach((tagId: string) => {
    let tagDisplayName = "[tag not found]";
    const tag = tags.find(t => t.tagId === tagId);
    if (tag !== undefined) {
      tagDisplayName = tag.displayName;
      const tagType = org.tagTypes.find(r => r.tagTypeId === tag.tagTypeId);
      if (tagType !== undefined) {
        tagDisplayName = `${tagType.displayName}: ${tag.displayName}`;
      }
    }
    tagDisplayNames.push(tagDisplayName);
  });
  return tagDisplayNames.sort().join(", ");
};
