import { Directive } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

// Im sure we could find a better place for this, but doesnt warrant its own lib entirely.
@Directive()
export abstract class SchemaFieldType extends FieldType {
  get props() {
    return this.field?.templateOptions || {};
  }
}
