import { IDocument } from "@vp/models";
import { JSONSchema7 } from "json-schema";
import { DisplayTag } from "../ui";

export interface CaseType extends IDocument {
  caseTypeId: string;
  friendlyId: string;
  active: boolean;
  displayName: string;
  description: string;
  fee: number;
  workflowConfigFriendlyId: string;
  caseDisplayName: CaseTypeCaseDisplayName;
  creation: CaseCreation;
  documents: CaseTypeDocuments;
  images: CaseTypeImages;
  organization: CaseTypeOrganization;
  assignableRoles: CaseTypeAssignableRole[];
  assignableGroupTypes: string[];
  assignableTagTypes: string[];
  departments: CaseTypeDepartment[];
  statuses: CaseTypeStatus[];
  communications: CaseTypeCommunication[];
  rolePermissions: CaseTypeRolePermission[];
  response: JSONSchema7;
  recordLayout: LayoutConfigOption;
  recordSchema: JSONSchema7;
  managementSchema: JSONSchema7;
  resultSchema: JSONSchema7;
  caseTypeDisplayTags: DisplayTag[];
}

export interface CaseCreation {
  subjectRoleId: string;
  createSubject: boolean;
  createSubjectRoles: string[];
  createLoginRoles: string[];
  userMap: Record<string, string>;
  groupTypesToCopyFromCreator: string[];
  tagTypesToCopyFromCreator: string[];
}

export interface CaseTypeResponse {
  templates: string[];
  addendumTemplates: string[];
  sendAsEmail: boolean;
  generatePdf: boolean;
}

export interface CaseTypeCommunication {
  communicationTypeId: string;
  displayName: string;
  resolvable: boolean;
  uploadImage: boolean;
  uploadDocument: boolean;
  audience: CaseTypeAudience;
  communicationWorkflowTag: string;
  isDefault: boolean;
}

export interface CaseTypeAudience {
  roles: CaseTypeAudienceRole[];
}

export interface CaseTypeAudienceRole {
  roleId: string;
  prettyId: string;
  displayName: string;
}

export interface CaseTypeDepartment {
  departmentId: string;
  friendlyId: string;
}

export interface CaseTypeDocuments {
  documentSchema: JSONSchema7;
  documentLayout: Record<string, unknown>;
  documentDescriptors: FileDescriptor[];
}

export interface CaseTypeImages {
  imageSchema: JSONSchema7;
  imageLayout: Record<string, unknown>;
  imageDescriptors: FileDescriptor[];
}

export class FileDescriptor {
  constructor(
    public fileTypes: string[] = [],
    public required: string[] = [],
    public recommended: string[] = []
  ) {}
  /** Convert fileTypes to comma-separated list with dot */
  fileTypesAsList = () => this.fileTypes.map(type => "." + type).join(",");
  requiredAsList = () => this.required.join(", ");
  recommendedAsList = () => this.recommended.join(", ");
}

export interface CaseTypeOrganization {
  organizationId: string;
  displayName: string;
}

export interface CaseTypeAssignableRole {
  roleFriendlyId: string;
  requireAcceptance: boolean;
  requireResponsibility: boolean;
}

export interface CaseTypeCaseDisplayName {
  displayNameTemplate: string;
  default: string;
}

export interface MultipleConfig {
  enabled: boolean;
  displayList: string[];
}

export interface CaseTypeRolePermission {
  roles: CaseTypeRole[];
  permissions: string[];
}

export interface CaseTypeRole {
  friendlyId: string;
}

export interface CaseTypeStatus {
  statusId: string;
  friendlyId: string;
  displayName: string;
  initial: boolean | null;
}

export interface LayoutConfigOption {
  layouts: LayoutScopeCollection[];
  definitions: LayoutDefinitionCollection[];
}

export interface LayoutScopeCollection {
  types: string[];
  elements: LayoutScope[];
}

export interface LayoutDefinitionCollection {
  types: string[];
  formlyConfig: Record<string, unknown>;
}

export interface LayoutScope {
  scope: string;
  schema: string;
  definition: string;
  order: number;
  formlyConfig: Record<string, unknown>;
}

export interface ClassTypeRecordLayoutOptions {
  listItems: number;
  addable: boolean;
  orderable: boolean;
  removalble: boolean;
  enableErrorState: boolean;
  enableSuccessState: boolean;
  feedback: boolean;
  feedbackOnRender: boolean;
  noTitle: boolean;
  disabled: boolean;
  readOnly: boolean;
  returnEmptyFields: boolean;
  validationMessages: ClassTypeRecordLayoutOptionsValidationMessages[];
  required: boolean;
  title: string;
}

export interface ClassTypeRecordLayoutOptionsValidationMessages {
  required: string;
  minLength: string;
  maxLength: string;
  pattern: string;
  minimum: string;
  exclusiveMinimum: string;
  maximum: string;
  exclusiveMaximum: string;
  minProperties: string;
  maxProperties: string;
  minItems: string;
  maxItems: string;
  uniqueItems: string;
}

export interface CaseTypeConfig {
  type: string;
  caseTypeSchema: JSONSchema7;
  caseTypeLayout: LayoutConfigOption;
}
