import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  CommunicationData,
  CommunicationSummary,
  CommunicationSummaryParams,
  CommunicationUpdate
} from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { createUrlBuilder } from "@vp/shared/utilities";
import { Operation } from "rfc6902";
import { EMPTY, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CommunicationsApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly http: HttpClient
  ) {}

  public createCommunication(
    caseId: string,
    com: CommunicationData
  ): Observable<CommunicationData> {
    const apiURL = `${this.apiBaseUrl}/communication?caseId=${caseId}&resolvable=${false}`;
    return this.http.post<CommunicationData>(apiURL, com);
  }

  public updateCommunication(caseId: string, com: CommunicationUpdate) {
    const apiURL = `${this.apiBaseUrl}/communication/${com.communicationId}?caseId=${caseId}`;
    return this.http.put<CommunicationData>(apiURL, com);
  }

  public markResolved(caseId: string, communicationId: string, isResolved: boolean) {
    const apiURL = `${this.apiBaseUrl}/communication/${communicationId}/resolved/${isResolved}?caseId=${caseId}`;
    return this.http.put<CommunicationData>(apiURL, null);
  }

  getCommunicationSummary(
    queryParams: CommunicationSummaryParams
  ): Observable<CommunicationSummary> {
    const apiURL = `${this.apiBaseUrl}/communications/summary`;
    const urlBuilder = createUrlBuilder(apiURL, Object.keys(queryParams));
    const urlWithQueryString = urlBuilder.build({}, queryParams);
    return this.http.get<CommunicationSummary>(urlWithQueryString);
  }

  public getCommunications(caseIds: string[]): Observable<CommunicationData[]> {
    let params = new HttpParams();

    if (caseIds) {
      params = params.append("casesId", caseIds.join(","));
      const apiURL = `${this.apiBaseUrl}/communications?caseIds=${caseIds}`;
      return this.http.get<CommunicationData[]>(apiURL, {
        params: params
      });
    }
    return EMPTY;
  }

  public getCommunicationById(
    caseId: string,
    communicationId: string
  ): Observable<CommunicationData> {
    const apiURL = `${this.apiBaseUrl}/communication/${communicationId}?caseId=${caseId}`;
    return this.http.get<CommunicationData>(apiURL);
  }

  public patch = (
    caseId: string,
    communicationId: string,
    operations: Operation[],
    command: string | null = null
  ) => {
    const apiURL = `${this.apiBaseUrl}/communication/${communicationId}?caseId=${caseId}`;
    let params = new HttpParams();
    if (command) {
      params = params.append("command", String(command));
    }
    return this.http.patch(apiURL, operations, {
      params: params
    });
  };
}
