import { trigger, state, style, transition, animate, AUTO_STYLE } from "@angular/animations";

const DEFAULT_DURATION = 600;

/**
 * Slide with fade animation
 *
 * Triggers are based on elements entering/leaving (think ngIf) the DOM instead
 * of explicit triggers for easier maintenance.
 */
export const SlideInOutAnimation = [
  trigger("slideInOut", [
    // The "in" style determines the "resting" state of the element when it is visible.
    state(
      "in",
      style({
        height: AUTO_STYLE,
        opacity: 1
      })
    ),

    // Slide/fade in when created. this could also be written as transition('void => *')
    transition(":enter", [
      style({
        height: 0,
        opacity: 0
      }),
      animate(DEFAULT_DURATION)
    ]),

    // Slide/fade out when destroyed. this could also be written as transition('* => void')
    transition(
      ":leave",
      animate(
        DEFAULT_DURATION,
        style({
          height: 0,
          opacity: 0
        })
      )
    )
  ])
];
