/**
 * These are the Case Queue permissions.
 */
export const caseQueuePermissions = {
  ConsultActions: {
    Case: {
      Write: "caseQueueConsultCanWrite",
      Cancel: "caseQueueConsultCanCancel"
    }
  }
};
