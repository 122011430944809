import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Tag, TagFilter } from "@vp/models";
import { HttpResponseCache } from "@vp/shared/services/cache";
import { createUrlBuilder } from "@vp/shared/utilities";
import { Operation } from "rfc6902";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TAG_API_BASE_URL } from "../state+/tags-state";

export type TagModel = Omit<
  Partial<Tag>,
  "lastUpdatedBy" | "lastUpdatedDateTime" | "createdBy" | "createdDateTime"
>;

@Injectable({
  providedIn: "root"
})
export class TagsApiService {
  constructor(
    @Inject(TAG_API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient,
    private httpResponseCache: HttpResponseCache<Tag[]>
  ) {}

  getTags = (
    filter: Partial<TagFilter> = {},
    useCache: boolean = true,
    cacheExpireTime: number = 5000
  ): Observable<Tag[]> => {
    const urlBuilder = createUrlBuilder(this.apiBaseUrl, Object.keys(filter));
    const urlWithQueryString = urlBuilder.build({}, filter);

    if (useCache) {
      return this.httpResponseCache
        .getCachedData(urlWithQueryString, "tags", cacheExpireTime, urlWithQueryString)
        .pipe(
          map((httpResponse: HttpResponse<Tag[]>) => {
            if (httpResponse.status === 200 && Array.isArray(httpResponse.body)) {
              return httpResponse.body;
            }
            return [];
          })
        );
    }
    return this.http.get<Tag[]>(urlWithQueryString);
  };

  addTag = (tag: TagModel): Observable<Tag> => {
    return this.http.post<Tag>(`${this.apiBaseUrl}/${tag.tagId}`, tag);
  };

  updateTag = (tag: TagModel): Observable<Tag> => {
    return this.http.put<Tag>(`${this.apiBaseUrl}/${tag.tagId}`, tag);
  };

  deleteTag = (tagId: string): Observable<Tag> => {
    return this.http.delete<Tag>(`${this.apiBaseUrl}/${tagId}`);
  };

  patch = (tagId: string, operations: Operation[]) => {
    const apiURL = `${this.apiBaseUrl}/${tagId}`;
    return this.http.patch(apiURL, operations);
  };
}
