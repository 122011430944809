<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    alerts: (banners$ | async) ?? []
  }"
>
</ng-container>

<ng-template #template let-alerts="alerts">
  <vp-alert-container
    [alerts]="alerts"
    [config]="alertConfig"
    (alertDismissed)="alertDismissed($event)"
  ></vp-alert-container>
</ng-template>
