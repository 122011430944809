<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    items: (menuItems$ | async) ?? []
  }"
>
</ng-container>

<ng-template #template let-items="items">
  <ng-container *ngIf="!menuOption">
    <div flex flexLayout="row" flexLayoutAlign="start stretch">
      <div
        *ngFor="let item of items"
        class="menu-item"
        flexLayout="column"
        flexLayoutAlign="center center"
        routerLinkActive="active"
        [routerLink]="item.routerLink"
      >
        {{ item?.displayName }}
        <mat-divider *ngIf="menuOption"></mat-divider>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="menuOption">
    <div flex flexLayout="row" flexLayoutAlign="start stretch">
      <div
        *ngFor="let item of items"
        class="menu-item"
        flexLayout="row"
        flexLayoutAlign="start center"
        [routerLink]="item.routerLink"
      >
        <div flexAlign="center">{{ item?.displayName }}</div>
      </div>
    </div>
  </ng-container>
</ng-template>
