<mat-form-field>
  <mat-label>{{ props.label ?? "" }}</mat-label>
  <mat-select
    [id]="id"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
    [required]="!!props.required"
    [disabled]="!!props.disabled"
    [multiple]="props.multiple"
    [(ngModel)]="selectedOptions"
    (selectionChange)="change($event)"
  >
    <ng-container *ngIf="props.options | formlySelectOptions: field | async as selectOptions">
      <ng-container *ngFor="let item of selectOptions">
        <mat-optgroup *ngIf="item.group" [label]="item.label">
          <mat-option
            *ngFor="let child of item.group"
            [value]="child.value"
            [disabled]="child.disabled"
          >
            {{ child.label }}
          </mat-option>
        </mat-optgroup>
        <mat-option *ngIf="!item.group" [value]="item.value" [disabled]="item.disabled">{{
          item.label
        }}</mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
</mat-form-field>
