import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Select } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { SchemaFieldType } from "@vp/formly/json-schema";
import { OrganizationFeatures, Tag, User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import {
  updateUserAssignedTags,
  updateUserRoleAccessTags,
  UserAdministrationService,
  UserAdministrationState
} from "@vp/user-administration/data-access/user-administration-state";
import { map, Observable, Subject, take, takeUntil, withLatestFrom } from "rxjs";

@Component({
  selector: "vp-specialty-tags",
  templateUrl: "./specialty-tags.component.html",
  styleUrls: ["./specialty-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialtyTagsComponent extends SchemaFieldType implements OnDestroy {
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  @Select(UserAdministrationState.workingCopy) workingCopy$!: Observable<User | null>;

  private readonly _destroyed$ = new Subject<void>();
  private readonly _user$ = this.workingCopy$.pipe(filterNullMap());

  private _excludedRoles$ = this.featureService.configurationListValue$(
    OrganizationFeatures.allRolesAssignable,
    "roles"
  );

  specialtyTags$ = this.tags$.pipe(
    takeUntil(this._destroyed$),
    map((tags: Tag[]) => {
      return tags.filter(x => x.tagTypeFriendlyId === "specialty");
    })
  );

  selectedOptions$ = this._user$.pipe(
    takeUntil(this._destroyed$),
    withLatestFrom(this.specialtyTags$),
    map(([user, tags]) => (user?.assignedTags || []).filter(a => tags.some(t => t.tagId === a)))
  );

  constructor(
    private readonly featureService: FeatureService,
    private readonly userAdministrationService: UserAdministrationService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggleSelection(tagId: string) {
    this._user$
      .pipe(
        take(1),
        withLatestFrom(this._excludedRoles$),
        map(([user, excludedRoles]) => {
          const add = user.assignedTags.every(a => a !== tagId);
          const addTagList = add ? [tagId] : [];
          const removeTagList = add ? [] : [tagId];
          const updatedAssignedTags = updateUserAssignedTags(user, addTagList, removeTagList);
          const updatedRoleAccessTags = updateUserRoleAccessTags(
            user,
            excludedRoles,
            addTagList,
            removeTagList
          );
          this.userAdministrationService.updateWorkingCopy({
            assignedTags: updatedAssignedTags,
            roles: updatedRoleAccessTags
          });
        })
      )
      .subscribe();
  }
}
