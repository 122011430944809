import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import * as TagsActions from "@vp/data-access/tags";
import {
  TagTypesFilter,
  TagsState,
  getChildTags,
  getMissingHierarchyTags,
  getTagPaths,
  handleSelectedTagEvent
} from "@vp/data-access/tags";
import { AssignmentModalOptions, Column, Tag, TagType, User } from "@vp/models";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { TagTypePathPipe, TagWithParentsPipe } from "@vp/shared/pipes/context-display";
import { compareBy } from "@vp/shared/utilities";
import {
  UserAdministrationService,
  UserAdministrationState
} from "@vp/user-administration/data-access/user-administration-state";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, Observable, Subject, combineLatest, of, zip } from "rxjs";
import { map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-user-assign-tags",
  templateUrl: "./user-assign-tags.component.html",
  styleUrls: ["./user-assign-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "tagTypeFriendlyId",
            header: "Tag Type",
            pipe: TagTypePathPipe
          },
          {
            field: "tagId",
            header: "Display Name",
            pipe: TagWithParentsPipe
          },
          {
            field: "description",
            header: "Description"
          }
        ],
        title: "Assign Tags",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignTagsComponent implements OnInit, OnDestroy {
  @Select(UserAdministrationState.assignableTagTypes)
  assignableTagTypes$!: Observable<TagType[]>;
  @Select(UserAdministrationState.assignableTags)
  assignableTags$!: Observable<Tag[]>;
  @Select(TagsState.selectedTags) selectedTags$!: Observable<Tag[]>;

  displayedColumns$: Observable<Column[]>;
  excludeProperties: string[] = [];
  selectedTagsDisplay$!: Observable<Record<string, string[]> | undefined>;
  searchTerm$: Observable<string | null>;
  selectedCount$: Observable<number>;
  tags$: Observable<Tag[]>;
  infoMessage$ = new BehaviorSubject<string | null>(null);
  title = "Assign Tags";
  selectedTagTypes: TagType[] = [];

  private readonly _displayedColumns$ = new BehaviorSubject<Column[]>([]);
  private readonly _searchTerm$ = new BehaviorSubject<string | null>(null);
  private readonly _tagTypesFilter = new BehaviorSubject<TagTypesFilter | null>(null);

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    @Inject(ASSIGNMENT_MODAL_OPTIONS) public options: AssignmentModalOptions,
    private ngxPermissionsService: NgxPermissionsService,
    private userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService,
    private store: Store
  ) {
    this.searchTerm$ = this._searchTerm$.asObservable();
    this.selectedCount$ = this.selectedTags$.pipe(map(tags => tags.length));

    // set already assigned tags
    zip(this.assignableTags$, this.userAdministrationService.workingCopy$.pipe(filterNullMap()))
      .pipe(
        tap(([assignable, workingCopy]) => {
          const alreadyAssignedTags = assignable.filter(tag =>
            workingCopy.assignedTags.includes(tag.tagId)
          );
          this.store.dispatch(new TagsActions.SetSelectedTags(alreadyAssignedTags));
        }),
        take(1)
      )
      .subscribe();

    this.assignableTagTypes$
      .pipe(
        filterNullMap(),
        tap(tagTypes => {
          this.selectedTagsDisplay$ = this.store
            .select(TagsState.selectedTagsDisplay)
            .pipe(map(fn => fn(tagTypes)));
        })
      )
      .subscribe();

    this.tags$ = combineLatest([
      this.assignableTags$,
      this._tagTypesFilter.pipe(filterNullMap()),
      this.selectedTags$
    ]).pipe(
      map(
        ([assignable, { tagTypeFriendlyIds, tagPaths }, selectedTags]: [
          Tag[],
          TagTypesFilter,
          Tag[]
        ]) => {
          if (tagTypeFriendlyIds.length > 0) {
            return assignable
              .filter(
                tag => tagTypeFriendlyIds.includes(tag.tagTypeFriendlyId) && tag.active == true
              )
              .filter(tag => (tagPaths?.length ? tagPaths.includes(tag.tagPath) : true));
          } else {
            return selectedTags;
          }
        }
      ),
      map(tags => tags.sort(compareBy((t: Tag) => t.displayName))),
      takeUntil(this._destroyed$)
    );

    this.displayedColumns$ = this._displayedColumns$.pipe(
      switchMap(columns => {
        return combineLatest([
          of(columns),
          this.ngxPermissionsService.hasPermission([
            this.permConst.Admin.User.TagsAssignment.Delete
          ])
        ]);
      }),
      map(([columns, hasWritePermissions]: [Column[], boolean]) => {
        if (hasWritePermissions) {
          const cols = [...columns];
          cols.push({
            field: "actions",
            header: "Actions"
          } as Column);
          return cols;
        }
        return columns;
      })
    );
  }

  ngOnInit(): void {
    this._displayedColumns$.next(this.options.columns);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.store.dispatch(new TagsActions.SetSelectedTags());
  }

  searched(event: string) {
    this._searchTerm$.next(event);
  }

  tagTypeFilterChanged(tagTypes: TagType[]) {
    this.selectedTagTypes = tagTypes;
    this.selectedTags$
      .pipe(
        take(1),
        tap((selectedTags: Tag[]) => {
          if (this.hasMissingHierachicalTags(selectedTags, tagTypes) == false) {
            this._tagTypesFilter.next({
              tagTypeFriendlyIds: tagTypes.map((tagType: TagType) => tagType.friendlyId),
              tagPaths: getTagPaths(tagTypes, selectedTags)
            });
          }
        })
      )
      .subscribe();
  }

  assignSelected() {
    this.selectedTags$.pipe(take(1)).subscribe((selected: Tag[]) => {
      this.userAdministrationService.updateWorkingCopy({
        assignedTags: selected.map(tag => tag.tagId)
      });
    });
  }

  selectionChanged(tag: Tag) {
    this.selectedTags$
      .pipe(
        take(1),
        map(selected => {
          return [...selected];
        }),
        withLatestFrom(
          this.assignableTagTypes$,
          this.userAdministrationService.workingCopy$.pipe(filterNullMap())
        ),
        map(([selected, tagTypes, user]: [Tag[], TagType[], User]) => {
          const tagType = tagTypes.filter(t => t.tagTypeId == tag.tagTypeId);
          if (!this.hasMissingHierachicalTags(selected, tagType)) {
            return handleUserSelectedTagEvent(user, tag, tagType, tagTypes, selected);
          }
          return selected;
        })
      )
      .subscribe(selected => {
        this.store.dispatch(new TagsActions.SetSelectedTags(selected));
      });
  }

  isSelected(tag: Tag): Observable<boolean> {
    return this.selectedTags$.pipe(
      map((selected: Tag[]) => selected?.findIndex(i => i.tagId === tag.tagId) > -1 ?? false)
    );
  }

  goToTagList(tagTypeFormControl: UntypedFormControl) {
    zip(this.selectedTags$, this.assignableTagTypes$)
      .pipe(
        take(1),
        tap(([selected, tagTypes]) => {
          const missingParentTags = getMissingHierarchyTags(selected, this.selectedTagTypes);
          const rootTag = missingParentTags.pop();
          if (rootTag) {
            const tagType = tagTypes.find(t => t.friendlyId === rootTag);
            if (tagType) {
              tagTypeFormControl.patchValue(tagType);
              this.infoMessage$.next(null);
              this._tagTypesFilter.next({
                tagTypeFriendlyIds: [tagType.friendlyId],
                tagPaths: getTagPaths([tagType], selected)
              });
            }
          }
        })
      )
      .subscribe();
  }

  hasMissingHierachicalTags(selected: Tag[], tagTypes: TagType[]) {
    this.infoMessage$.next(null);
    const missingParentTags = getMissingHierarchyTags(selected, tagTypes);
    if (missingParentTags.length > 0) {
      this.infoMessage$.next(
        `Please assign ${missingParentTags.join(", ")} tag(s) before assigning ${tagTypes
          .map(tp => tp.friendlyId)
          .join(", ")}`
      );
      return true;
    }
    return false;
  }

  trackById: TrackByFunction<Tag> = (_: number, tag: Tag) => tag.tagId;

  originalOrder = () => 0;
}

const handleUserSelectedTagEvent = (
  user: User,
  tag: Tag,
  tagType: TagType[],
  tagTypes: TagType[],
  selected: Tag[]
): Tag[] => {
  if (
    user.userType.friendlyId === "login-user" &&
    tagType[0].assignmentOverrides?.find(
      t => t.objectType === "user" && t.singleAssignment === false
    )
  ) {
    const alreadySelected: boolean = selected.includes(tag);
    if (!alreadySelected) {
      selected.push(tag);
    } else {
      const childTagIds = getChildTags(tag, selected).map(t => t.tagId);
      selected = selected.filter(t => t.tagId != tag.tagId && !childTagIds.includes(t.tagId));
    }
  } else {
    return handleSelectedTagEvent(tag, tagTypes, selected);
  }
  return selected;
};
