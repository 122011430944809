import { EventBase } from "@vp/models";

export class MovementInRoomDetectedEvent extends EventBase<MovementAlert> {
  constructor(result: MovementAlert) {
    super();
    this.args = result;
  }
}

export interface MovementAlert {
  message: string;
  status: MovementStatus;
}

export interface MovementStatus {
  displayMovement: string;
  movement: string;
  notificationColor: number;
}
