import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldType } from "@ngx-formly/core";
import { Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { OrganizationFeatures, User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, combineLatest } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ESignatureDialogComponent } from "./e-signature-dialog/e-signature-dialog.component";

@Component({
  selector: "vp-user-e-signature",
  templateUrl: "./user-e-signature.component.html",
  styleUrls: ["./user-e-signature.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserESignatureComponent extends FieldType {
  @Select(UserAdministrationState.user) user$!: Observable<User | null>;

  showButton$ = combineLatest([
    this.ngxPermissionsService.hasPermission([this.permConst.Admin.User.Signature.Write]),
    this.featureService.featureFlagEnabled$(OrganizationFeatures.profile, "esignatureEnabled"),
    this.user$
  ]).pipe(
    map(([hasPermission, featureEnabled, user]: [boolean, boolean, User | null]) => {
      const logguedUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
      if (user && user.userId === logguedUser?.userId) {
        return true;
      }
      return hasPermission && featureEnabled;
    })
  );

  constructor(
    private dialog: MatDialog,
    public permConst: PermissionsConstService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly featureService: FeatureService,
    private readonly store: Store
  ) {
    super();
  }

  openSignature() {
    this.dialog
      .open(ESignatureDialogComponent, {
        data: this.formControl.value,
        panelClass: "mat-mdc-dialog-basic"
      })
      .afterClosed()
      .pipe(
        tap(({ save, eSignature }: { save: boolean; eSignature: string }) => {
          if (save) {
            this.formControl.patchValue(eSignature);
          }
        })
      )
      .subscribe();
  }
}
