import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AdvancedCommand, AdvancedCommandRequest } from "@vp/models";
import { NotificationService } from "@vp/shared/notification-service";
import { isNullOrUndefined } from "@vp/shared/utilities";
import { take } from "rxjs/operators";
import { AdvancedCommandsService } from "../services/advanced-commands.service";

interface AdvancedCommandDialogData {
  caseId: string | undefined;
  deviceId: string | undefined;
  zoomCallActive: boolean | undefined;
}

@Component({
  selector: "vp-advanced-commands",
  templateUrl: "./advanced-commands.component.html",
  styleUrls: ["./advanced-commands.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedCommandsComponent implements OnInit {
  @Input() incomingCaseId: string | undefined;
  @Input() incomingDeviceId: string | undefined;

  caseId: string | undefined;
  deviceId: string | undefined;
  zoomCallActive: boolean | undefined;
  listOfCommands: AdvancedCommand[] | undefined;

  constructor(
    private readonly dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    private _advancedCommandsService: AdvancedCommandsService,
    private notificationService: NotificationService,
    @Optional() public dialogRef: MatDialogRef<AdvancedCommandsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: AdvancedCommandDialogData | undefined
  ) {}

  ngOnInit(): void {
    if (!isNullOrUndefined(this.dialogRef) && !isNullOrUndefined(this.data)) {
      this.caseId = this.data.caseId;
      this.deviceId = this.data.deviceId;
      this.zoomCallActive = this.data.zoomCallActive;
    } else {
      this.caseId = this.incomingCaseId;
      this.deviceId = this.incomingDeviceId;
    }

    this._advancedCommandsService
      .getDeviceCommands()
      .pipe(take(1))
      .subscribe(commands => {
        if (this.zoomCallActive) {
          commands = commands.filter(c => c.showWhenZoomActive);
        } else {
          commands = commands.filter(c => c.showWhenZoomInactive);
        }
        this.listOfCommands = commands.sort((a, b) => (a.methodName < b.methodName ? -1 : 1));
        this._changeDetectorRef.detectChanges();
      });
  }

  sendAdvancedCommand(command: AdvancedCommand): void {
    const sendCommand: AdvancedCommandRequest = {
      commandName: command.methodName,
      caseId: this.caseId !== "(No Assigned Value)" ? this.caseId : undefined,
      deviceUserId: this.deviceId
    };

    this._advancedCommandsService
      .sendDeviceCommand(sendCommand)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.successMessage(
          `Advanced Command '${command.displayName}' successfully sent`
        );
      });
  }

  public showAdvancedCommands(caseId: string, zoomCallActive: boolean): void {
    this.dialog.open(AdvancedCommandsComponent, {
      data: {
        caseId: caseId,
        zoomCallActive: zoomCallActive
      }
    });
  }
}
