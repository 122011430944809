import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DomSanitizer, Meta, MetaDefinition, SafeUrl, Title } from "@angular/platform-browser";
import {
  HostnameSettings,
  ThemeManagerConfig,
  THEME_MANAGER_CONFIG
} from "./theme-manager-config.model";

const getClassNameForKey = (key: string) => `theme-manager-${key}`;

/**
 * Theme Manager for dynamic assets based on browser hostname.
 * Includes title, meta, body class, styles, logo, favicon
 */
@Injectable({
  providedIn: "root"
})
export class ThemeManagerService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(THEME_MANAGER_CONFIG) private readonly sharedStyleManagerConfig: ThemeManagerConfig,
    private readonly rendererFactory: RendererFactory2,
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly domSanitizer: DomSanitizer
  ) {
    if (this.document.defaultView) {
      this.window = this.document.defaultView ?? null;
      this.location = this.window.location;
      this.locationHostname = this.location?.hostname;
      this.renderer2 = this.rendererFactory.createRenderer(null, null);
    }
  }
  private window!: Window;
  private location!: Location;
  private locationHostname!: string;
  private renderer2!: Renderer2;
  private currentHostname!: string;
  private title!: string;
  private metas!: MetaDefinition[];
  private bodyClass!: string;
  private styles!: string[];
  private logoUrl!: SafeUrl;
  private logoRedirectUrl = "";
  private faviconUrl!: string;

  /**
   * Set all theme assets based the current hostname.
   */
  setTheme() {
    this.remmoveThemeSettings();
    this.currentHostname = this.getHostnameFromUrlOrConfig();
    this.setThemeSettings();
  }

  /**
   * Set all theme assets for a specified hostname (usually for debugging).
   */
  setThemeFor(hostname: string) {
    this.remmoveThemeSettings();
    this.currentHostname = hostname;
    this.setThemeSettings();
  }

  /**
   * Get the logo URL for the current theme.
   */
  get logo() {
    return this.logoUrl;
  }

  get logoRedirectLink() {
    return this.logoRedirectUrl;
  }

  /**
   * Set all theme settings.
   */
  private setThemeSettings() {
    this.title = this.getSetting<string>(this.currentHostname, "title", "Breakout");
    if (this.title) {
      this.titleService.setTitle(this.title);
    }
    this.metas = this.getSetting<MetaDefinition[]>(this.currentHostname, "metas", []);
    if (this.metas) {
      this.metaService.addTags(this.metas);
    }
    this.styles = this.getSetting<string[]>(this.currentHostname, "styles", []);
    if (this.styles?.length) {
      this.styles.forEach((style, index) => this.setStyleLink(`theme-${index}`, style));
    }
    this.bodyClass = this.getSetting<string>(this.currentHostname, "bodyClass", "");
    if (this.bodyClass) {
      this.addClass(this.bodyClass);
    }
    const logoUrl = this.getSetting<string>(this.currentHostname, "logoUrl", "");
    if (logoUrl) {
      this.logoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(logoUrl);
    }
    const logoRedirectUrl = this.getSetting<string>(this.currentHostname, "logoRedirectUrl", "");
    if (logoRedirectUrl) {
      this.logoRedirectUrl = logoRedirectUrl;
    }
    this.faviconUrl = this.getSetting<string>(this.currentHostname, "faviconUrl", "");
    if (this.faviconUrl) {
      this.setIconLink(`favicon`, this.faviconUrl);
    }
  }

  /**
   * Removes all theme settings.
   */
  private remmoveThemeSettings() {
    if (this.title) {
      this.titleService.setTitle("Breakout");
      this.title = "";
    }
    if (this.metas) {
      this.metas.forEach(meta => this.metaService.removeTag(`${meta.name}=${meta.content}`));
      this.metas = [];
    }
    if (this.styles) {
      this.styles?.forEach((_, index) => this.removeStyleLink(`theme-${index}`));
      this.styles = [];
    }
    if (this.bodyClass) {
      this.removeClass(this.bodyClass);
      this.bodyClass = "";
    }
    if (this.logoUrl) {
      this.logoUrl = "";
    }
    if (this.faviconUrl) {
      this.removeIconLink(`favicon`);
      this.faviconUrl = "";
    }
  }

  /**
   * Determine the theme based on the current hostname or debug hostname override.
   */
  private getHostnameFromUrlOrConfig() {
    if (this.sharedStyleManagerConfig.debug && this.sharedStyleManagerConfig.debug.hostname) {
      return this.sharedStyleManagerConfig.debug.hostname;
    } else {
      return this.locationHostname;
    }
  }

  /**
   * Set the stylesheet with the specified key.
   * @param key Key to map.
   * @param href Url to insert.
   */
  private setStyleLink(key: string, href: string) {
    this.findOrCreateStyleLink(key).setAttribute("href", href);
  }

  /**
   * Sets the icon link to the specified HREF.
   * @param key Class name.
   * @param href Location URL of the icon.
   */
  private setIconLink(key: string, href: string) {
    this.findOrCreateIconLink(key).setAttribute("href", href);
  }

  /**
   * Get a setting for hostname and key.
   * @param hostname Current or debug key.
   * @param settingKey One of hostname setting key.
   * @param defaultValue Return value, if not found.
   */
  private getSetting<T>(
    hostname: string | undefined,
    settingKey: keyof HostnameSettings,
    defaultValue: T
  ): T {
    if (!hostname) {
      return defaultValue;
    }
    const keyValues = Object.entries(this.sharedStyleManagerConfig?.hostnames ?? {});
    const [, value] = keyValues.find(([key]) => key === hostname) ??
      keyValues.find(([key]) => key === "default-theme") ?? [undefined, undefined];
    if (!value) {
      return defaultValue;
    }
    const settingValue = value[settingKey] as unknown;
    if (!settingValue) {
      return defaultValue;
    }
    return settingValue as T;
  }

  /**
   * Add a class name on the body using Angular.
   * @param key Class name suffix.
   */
  private addClass(key: string) {
    this.renderer2.addClass(this.document.body, key);
  }

  /**
   *  Remove a class name on the body using Angular.
   * @param key Class name suffix.
   */
  private removeClass(key: string) {
    this.renderer2.removeClass(this.document.body, key);
  }

  /**
   * Get or create a new element for style links.
   * @param key Class name suffix.
   */
  private findOrCreateStyleLink(key: string) {
    return this.getExistingStyleLink(key) || this.createStyleLink(key);
  }

  /**
   * Create a new style link.
   * @param key Class name suffix to apply to link.
   */
  private createStyleLink(key: string) {
    const linkEl = this.createLinkElementWithKey(key);
    linkEl.setAttribute("rel", "stylesheet");
    return linkEl;
  }

  /**
   * Finds a stylesheet link.
   * @param key Class name suffix to locate stylesheet.
   */
  private getExistingStyleLink(key: string) {
    return this.document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
  }

  /**
   * Remove the stylesheet with the specified key.
   * @param key Key to lookup.
   */
  private removeStyleLink(key: string) {
    const existingLinkElement = this.getExistingStyleLink(key);
    if (existingLinkElement) {
      this.renderer2.removeChild(document.head, existingLinkElement);
    }
  }

  /**
   * Find or create a new icon link.
   * @param key Class name suffix to apply to link.
   */
  private findOrCreateIconLink(key: string) {
    return this.getExistingIconLink(key) || this.createIconLink(key);
  }

  /**
   * Create a new icon link.
   * @param key Class name suffix to apply to link.
   */
  private createIconLink(key: string) {
    const linkEl = this.createLinkElementWithKey(key);
    linkEl.setAttribute("rel", "icon");
    return linkEl;
  }

  /**
   * Get icon link in the DOM.
   * @param key Class name suffix of icon link.
   */
  private getExistingIconLink(key: string) {
    return this.document.head.querySelector(`link[rel="icon"].${getClassNameForKey(key)}`);
  }

  /**
   * Remove the stylesheet with the specified key.
   * @param key Class name suffix to lookup.
   */
  private removeIconLink(key: string) {
    const existingLinkElement = this.getExistingIconLink(key);
    if (existingLinkElement) {
      this.renderer2.removeChild(document.head, existingLinkElement);
    }
  }

  /**
   * Creates a new stylesheet link using Angular.
   * @param key Class name suffix of new element.
   */
  private createLinkElementWithKey(key: string) {
    const linkEl = this.renderer2.createElement("link");
    linkEl.classList.add(getClassNameForKey(key));
    this.renderer2.appendChild(this.document.head, linkEl);
    return linkEl;
  }
}
