/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";
import { filterNullMap } from "@vp/shared/operators";
import { AsyncSubject, Subject } from "rxjs";
import { concatMap, takeUntil } from "rxjs/operators";
import { FeatureService } from "./feature.service";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[featureFlag]"
})
export class FeatureFlagDirective implements OnDestroy, OnInit {
  private errorRef!: TemplateRef<ErrorContext>;
  private beforeRef!: TemplateRef<null>;
  private unsubscribe = new Subject<boolean>();
  private init = new AsyncSubject<void>();
  private _feature = "common";

  @Input() set feature(feature: string) {
    if (feature) {
      this._feature = feature;
    }
  }

  @Input()
  set featureFlag(featureFlag: string) {
    if (!featureFlag) {
      return;
    }
    this.showBefore();
    this.unsubscribe.next(true);

    this.init
      .pipe(
        concatMap(() => this.featureService.featureFlagEnabled$(this._feature, featureFlag)),
        filterNullMap(),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        featureFlagEnabled => {
          if (featureFlagEnabled === true) {
            this.view.clear();
            this.view.createEmbeddedView(this.nextRef, {
              $implicit: featureFlag,
              featureFlag: featureFlag
            });
            this.changes.markForCheck();
          }
        },
        error => {
          if (this.errorRef) {
            this.view.clear();
            this.view.createEmbeddedView(this.errorRef, { $implicit: error });
            this.changes.markForCheck();
          }
        }
      );
  }

  constructor(
    private view: ViewContainerRef,
    private nextRef: TemplateRef<FeatureFlagContext>,
    private changes: ChangeDetectorRef,
    private featureService: FeatureService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
  }

  ngOnInit() {
    this.showBefore();
    this.init.next();
    this.init.complete();
  }

  private showBefore(): void {
    if (this.beforeRef) {
      this.view.clear();
      this.view.createEmbeddedView(this.beforeRef);
    }
  }
}

export interface FeatureFlagContext {
  $implicit: string;
  featureFlag: string;
}

export interface ErrorContext {
  $implicit: Error;
}
