import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { LOCAL_STORAGE_OPTIONS } from "./tokens";

const defaultConfig = (): LocalStorageOptions => {
  return {
    storageName: "app",
    maxAgeSeconds: 600
  } as LocalStorageOptions;
};

export interface LocalStorageOptions {
  storageName: string;
  maxAgeSeconds: number;
}

@NgModule({
  imports: [CommonModule]
})
export class LocalStorageModule {
  static forRoot(options: LocalStorageOptions | {} = {}): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [
        {
          provide: LOCAL_STORAGE_OPTIONS,
          multi: false,
          useFactory: defaultConfig,
          deps: [LocalStorageService]
        },
        { provide: LOCAL_STORAGE_OPTIONS, multi: false, useValue: options },
        LocalStorageService
      ]
    };
  }

  static forChild(options: LocalStorageOptions | {} = {}): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [
        {
          provide: LOCAL_STORAGE_OPTIONS,
          multi: false,
          useFactory: defaultConfig,
          deps: [LocalStorageService]
        },
        { provide: LOCAL_STORAGE_OPTIONS, multi: false, useValue: options }
      ]
    };
  }
}
