import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { OrganizationDataAccessModule } from "@vp/data-access/organization";
import { TagTypeSelectorComponent } from "./tag-type-selector.component";

@NgModule({
  imports: [
    CommonModule,
    OrganizationDataAccessModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  declarations: [TagTypeSelectorComponent],
  exports: [TagTypeSelectorComponent]
})
export class TagTypeSelectorModule {}
