import { CommunicationData, CommunicationSummaryParams } from "@vp/models";

export class SetCommunicationSummaries {
  public static readonly type = "[Communication] Set Communications Summary";
  constructor(public queryParams: CommunicationSummaryParams) {}
}

export class SetCommunications {
  public static readonly type = "[Communication] Set Communications";
  constructor(
    public comms: CommunicationData[],
    public caseIds: string[]
  ) {}
}

export class SetCurrentCommunication {
  public static readonly type = "[Communication] Set Original Communication";
  constructor(public communicationId: string) {}
}

export class DeleteCommunication {
  public static readonly type = "[Communication] Delete Communication";
  constructor(
    public communicationData: CommunicationData,
    public communicationId: string
  ) {}
}

export class CreateNewCommunication {
  public static readonly type = "[Communication] Create New Communication";
  constructor(
    public communication: Partial<CommunicationData>,
    public caseId: string
  ) {}
}

export class ResolveCommunication {
  public static readonly type = "[Communication] Resolve Communication";
  constructor(
    public communicationId: string,
    public isResolved: boolean,
    public caseId: string
  ) {}
}

export class Patch {
  public static readonly type = "[Communication] Patch Operations";
  constructor(
    public communicationId: string,
    public caseId: string
  ) {}
}

export class UpdateState {
  public static readonly type = "[Communication] Update Current";
  constructor(public communicationData: CommunicationData) {}
}

export class UpdateWorkingCopy {
  public static readonly type = "[Communication] Update Working Copy";
  constructor(public communicationData: Partial<CommunicationData>) {}
}
