import { Inject, Injectable } from "@angular/core";
import { JSONSchema7 } from "json-schema";
import { JsonSchemaValueProvider, JSON_SCHEMA_VALUE_PROVIDER } from "./formly-json-schema.module";

@Injectable()
export class JsonSchemaValueProviderService {
  constructor(@Inject(JSON_SCHEMA_VALUE_PROVIDER) private providers: JsonSchemaValueProvider[]) {}

  applyValues = (schema: JSONSchema7, recordData: Record<string, unknown>): void => {
    this.providers.forEach(provider => {
      provider.provide(schema, recordData);
    });
  };
}
