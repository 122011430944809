/**
 * These are the System Information permissions.
 */
// tslint:disable:variable-name
export const systemInformationPermissions = {
  Read: "adminUserSystemInformationCanRead",
  Write: "adminUserSystemInformationCanWrite",
  Delete: "adminUserSystemInformationCanDelete",
  Assign: {
    Read: "systemInformationAssignTagCanRead",
    Write: "systemInformationAssignTagCanWrite",
    Delete: "systemInformationAssignTagCanDelete"
  }
};
