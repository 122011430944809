import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Snippet } from "@vp/models";
import { ANGULAR_EDITOR_CONFIG } from "@vp/shared/tokens";

@Component({
  selector: "vp-add-or-edit-snippet-dialog",
  templateUrl: "./add-or-edit-snippet-dialog.component.html",
  styleUrls: ["./add-or-edit-snippet-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ANGULAR_EDITOR_CONFIG,
      useValue: {
        editable: true,
        spellcheck: true,
        width: "auto",
        minHeight: "10em",
        translate: "no",
        outline: true,
        defaultFontSize: "3",
        defaultParagraphSeparator: "p",
        toolbarHiddenButtons: [
          ["subscript", "superscript", "heading", "fontName"],
          [
            "insertImage",
            "insertVideo",
            "link",
            "unlink",
            "customClasses",
            "backgroundColor",
            "fontSize",
            "insertHorizontalRule",
            "removeFormat",
            "toggleEditorMode"
          ]
        ]
      }
    }
  ]
})
export class AddOrEditSnippetDialogComponent {
  action: "Add" | "Edit";
  snippet: Snippet;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ANGULAR_EDITOR_CONFIG) public config: AngularEditorConfig
  ) {
    this.snippet = data.snippet ?? ({} as Snippet);
    this.action = data.action;
  }
}
