import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, removeItem } from "@ngxs/store/operators";
import { RealTimeNotification } from "@vp/models";
import { EMPTY } from "rxjs";
import * as SignalRStateActions from "./signal-r-state.actions";

export interface SignalRStateModel {
  hubConnection: Partial<SignalRConnectionState>;
  notifications: RealTimeNotification[];
}

export interface SignalREvent {
  method: string;
  data: any;
  eventTime: Date;
}

export interface SignalRConnectionState {
  state: string | null;
  lastUpdated: Date | null;
  connectionId: string | null;
  receivedEvents: SignalREvent[];
}

@State<SignalRStateModel>({
  name: "signalR",
  defaults: {
    hubConnection: {
      state: null,
      lastUpdated: null,
      connectionId: null,
      receivedEvents: []
    },
    notifications: []
  }
})
@Injectable()
export class SignalRState {
  @Selector()
  public static getState(state: SignalRStateModel) {
    return state;
  }

  @Selector()
  public static hubConnection(state: SignalRStateModel) {
    return state.hubConnection;
  }

  @Selector()
  public static getNotifications(state: SignalRStateModel) {
    return state.notifications;
  }

  @Selector([SignalRState.getNotifications])
  public static notifications(notifications: RealTimeNotification[]) {
    return notifications;
  }

  @Action(SignalRStateActions.SetState)
  setState(ctx: StateContext<SignalRStateModel>, action: SignalRStateActions.SetState) {
    ctx.setState(
      patch<SignalRStateModel>({
        ...action.state
      })
    );
  }

  @Action(SignalRStateActions.AddUserToGroup)
  addUserToGroup(ctx: StateContext<SignalRStateModel>, action: SignalRStateActions.AddUserToGroup) {
    const notifications = ctx.getState().notifications;
    if (!notifications.find(n => n.userId == action.userId && n.groupName == action.groupName)) {
      ctx.setState(
        patch({
          notifications: append([
            {
              userId: action.userId,
              groupName: action.groupName
            } as RealTimeNotification
          ])
        })
      );
    }
  }

  @Action(SignalRStateActions.RemoveFromGroup)
  removeFromGroup(
    ctx: StateContext<SignalRStateModel>,
    action: SignalRStateActions.RemoveFromGroup
  ) {
    ctx.setState(
      patch({
        notifications: removeItem<RealTimeNotification>(
          n => n?.userId == action.userId && n?.groupName == action.groupName
        )
      })
    );
  }

  @Action(SignalRStateActions.AddManyToGroup)
  addManyToGroup(ctx: StateContext<SignalRStateModel>, action: SignalRStateActions.AddManyToGroup) {
    return ctx.setState(
      patch({
        notifications: append(
          action.notifications.reduce(
            (acc, notification) => {
              if (
                !acc.find(
                  n => n.userId == notification.userId && n.groupName == notification.groupName
                )
              ) {
                acc.push(notification);
              }
              return acc;
            },
            [...ctx.getState().notifications]
          )
        )
      })
    );
  }

  @Action(SignalRStateActions.RemoveManyFromGroup)
  removeManyFromGroup(
    ctx: StateContext<SignalRStateModel>,
    action: SignalRStateActions.RemoveManyFromGroup
  ) {
    return ctx.setState(
      patch({
        notifications: removeItem<RealTimeNotification>(notification =>
          notification
            ? action.notifications.findIndex(
                n => n.groupName === notification.groupName && n.userId === notification.userId
              ) > -1
            : false
        )
      })
    );
  }

  @Action(SignalRStateActions.ReconcileGroups)
  reconcileGroups(
    ctx: StateContext<SignalRStateModel>,
    action: SignalRStateActions.ReconcileGroups
  ) {
    const toRemove = action.toRemove.filter(
      n => action.toAdd.findIndex(a => a.userId == n.userId && a.groupName == n.groupName) === -1
    );

    if (action.toAdd.length > 0 || toRemove.length > 0) {
      const newNotificationList = action.toAdd.filter(
        notification =>
          toRemove.findIndex(
            n => n.groupName === notification?.groupName && n.userId === notification?.userId
          ) === -1
      );
      return ctx.setState(
        patch<SignalRStateModel>({
          notifications: newNotificationList
        })
      );
    }
    return EMPTY;
  }
}
