import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { ContentFilterState } from "@vp/data-access/content-management";
import { TagsState } from "@vp/data-access/tags";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { ContentData } from "@vp/models";
import { of } from "rxjs";

@Injectable()
export class ContentTagFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  /**
   * Gets content with the tagType that matches the tag of logged in User
   * @param state
   * @returns content
   */
  provide(state: Record<string, unknown>) {
    if (Object.keys(state).length === 0 || !state.content) return of({});

    const { contentType, tagTypeFriendlyId } = state.content as {
      contentType: string;
      tagTypeFriendlyId: string;
    };

    let content: ContentData | undefined;
    if (contentType && tagTypeFriendlyId) {
      const loggedInUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
      const allTags = this.store.selectSnapshot(TagsState.tags);

      const loggedInUserTag = allTags
        .filter(t => t.tagTypeFriendlyId === tagTypeFriendlyId)
        .find(c => loggedInUser?.assignedTags.includes(c.tagId));

      if (loggedInUserTag) {
        const allContents = this.store.selectSnapshot(ContentFilterState.allContents);
        content = allContents.find(
          c => c.tags.includes(loggedInUserTag.tagId) && c.contentTypeId === contentType
        );
      }
    }
    return of(content ? { content: content } : {});
  }
}
