import { RealTimeNotification } from "@vp/models";
import { SignalRStateModel } from "./signal-r.state";

export class AddUserToGroup {
  public static readonly type = "[Signal-R] Add User To Group";
  constructor(
    public userId: string,
    public groupName: string
  ) {}
}

export class RemoveFromGroup {
  public static readonly type = "[Signal-R] Remove From Group";
  constructor(
    public userId: string,
    public groupName: string
  ) {}
}

export class AddManyToGroup {
  public static readonly type = "[Signal-R] Add Many To Group";
  constructor(public notifications: RealTimeNotification[]) {}
}

export class RemoveManyFromGroup {
  public static readonly type = "[Signal-R] Remove Many From Group";
  constructor(public notifications: RealTimeNotification[]) {}
}

export class ReconcileGroups {
  public static readonly type = "[Signal-R] Reconcile Groups";
  constructor(
    public toRemove: RealTimeNotification[],
    public toAdd: RealTimeNotification[]
  ) {}
}

export class SetState {
  public static readonly type = "[Signal-R] Set State";
  constructor(public state: Partial<SignalRStateModel>) {}
}
