import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { Organization } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "caseStatus"
})
export class CaseStatusPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  transform(statusId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      map((org: Organization) => {
        const status = org.statuses.find(s => s.statusId === statusId);
        return `${status?.displayName ?? "[status  not found]"}`;
      })
    );
  }
}
