import { Inject, Injectable, InjectionToken, inject } from "@angular/core";
import { CanActivateFn, CanMatchFn, Router, UrlTree } from "@angular/router";
import { Select } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import { CaseData, CaseType, CaseUser, Organization, OrganizationFeatures, User } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { CaseContextService } from "@vp/shared/case-context";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { AuthenticatedResult } from "angular-auth-oidc-client";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, combineLatest, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

export const IS_IVY_API = new InjectionToken<boolean>("IS_IVY_API");

@Injectable({ providedIn: "root" })
export class AuthenticationGuardService {
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  constructor(
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly appStoreService: AppStoreService,
    private readonly authenticationService: AuthenticationService,
    private readonly caseContextService: CaseContextService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly featureService: FeatureService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const currentNavigation = this.router.getCurrentNavigation();
    const theRoute = currentNavigation?.finalUrl?.toString();
    return this.authenticationService.isLoggedIn$().pipe(
      switchMap((authenticatedResult: AuthenticatedResult) => {
        if (authenticatedResult.isAuthenticated) {
          return combineLatest([
            this.appStoreService.loggedInUser$,
            this.organization$.pipe(filterNullMap()),
            this.caseContextService.caseData$,
            this.caseTypes$.pipe(filterNullMap()),
            this.featureService.featureEnabled$(OrganizationFeatures.userPermissions)
          ]).pipe(
            tap(([user, organization, caseData, caseTypes, userPermissionsEnabled]) => {
              this.ngxPermissionsService.flushPermissions();
              let currentPermissions = [...(user?.userData?.permissions ?? [])];

              if (user && organization) {
                if (caseData) {
                  const found = caseTypes.find(
                    caseType => caseType.caseTypeId === caseData.caseType.caseTypeId
                  );
                  if (found) {
                    getCaseTypePermissions(found, user, caseData, currentPermissions);
                  }
                }
                if (userPermissionsEnabled) {
                  const selectedRole = user?.roles.find(x => x.roleId === user.selectedRoleId);
                  const applicablePerm = user?.userData?.userPermissions
                    ?.filter(x => x.roleId === selectedRole?.friendlyId)
                    .map(x => x.permissionSets.filter(perm => perm.includes("global")))
                    .flat();
                  if (applicablePerm) {
                    currentPermissions = Array.from(
                      new Set([...currentPermissions, ...applicablePerm])
                    );
                    this.ngxPermissionsService.loadPermissions(currentPermissions);
                  }
                  const userPermissions = user?.userData?.userPermissions?.find(
                    x => theRoute?.includes(x.moduleId) && x.roleId === selectedRole?.friendlyId
                  );
                  if (userPermissions) {
                    currentPermissions = Array.from(
                      new Set([...currentPermissions, ...userPermissions.permissionSets])
                    );
                  }
                }
              }
              // Load permissions into NgxPermissionsService (duplicates removed)
              this.ngxPermissionsService.loadPermissions(currentPermissions);
            }),
            map(() => {
              return true;
            })
          );
        }
        return of(authenticatedResult.isAuthenticated);
      }),
      map((isAuthenticated: boolean) => {
        if (this.isIvyApi) {
          if (theRoute === "/wizard" || theRoute === "/") {
            return true;
          } else {
            return this.router.createUrlTree(["/wizard"]);
          }
        } else if (!isAuthenticated) {
          return this.router.createUrlTree(["/login"]);
        }
        return true;
      })
    );
  }

  canMatch(): Observable<boolean | UrlTree> {
    const theRoute = this.router.url;
    return this.authenticationService.isLoggedIn$().pipe(
      map((authenticatedResult: AuthenticatedResult) => {
        if (this.isIvyApi) {
          if (theRoute === "/wizard" || theRoute === "/") {
            return true;
          } else {
            return this.router.createUrlTree(["/wizard"]);
          }
        } else if (!authenticatedResult.isAuthenticated) {
          return this.router.createUrlTree(["/login"]);
        }

        return true;
      })
    );
  }
}

/**
 * Get user's non-case type permissions
 * @param caseType The current case type object
 * @param user The current user object
 * @param currentPermissions Updated permissions by ref
 */
const getCaseTypePermissions = (
  caseType: CaseType,
  user: User,
  caseData: CaseData,
  currentPermissions: string[]
) => {
  // From User Object get the selectedRoleFriendlyId and the UserId for the current loggued user
  const loggedInUserCurrentRole = user.roles.find(
    u => u.roleId === user.selectedRoleId
  )?.friendlyId;
  const userId = user.userId;
  // From Case Check if currentUser is added on CaseUsers
  const currentUserPermisionsOnCase = caseData.users.filter(
    x => x.userId == userId && x.roleId == user.selectedRoleId
  );

  // Get the roleResponsibilities assigned to the current user in the current case

  let roleResponsibilities: (string | undefined)[] = [];

  roleResponsibilities =
    currentUserPermisionsOnCase.length === 0
      ? [loggedInUserCurrentRole]
      : (roleResponsibilities = currentUserPermisionsOnCase.reduce(
          (roleResponsibilities: (string | undefined)[], caseUser: CaseUser) => {
            if (caseUser.requireAcceptance === true && caseUser.acceptanceStatus !== "accepted") {
              roleResponsibilities.push(`${loggedInUserCurrentRole}.pending-acceptance`);
            } else {
              if (
                caseUser.responsibilityFriendlyId === null ||
                caseUser.responsibilityFriendlyId === undefined
              ) {
                roleResponsibilities.push(loggedInUserCurrentRole);
              } else {
                roleResponsibilities.push(
                  `${loggedInUserCurrentRole}.${caseUser.responsibilityFriendlyId}`
                );
              }
            }
            // doing a distinct before returning it.
            return [...new Set(roleResponsibilities.map(item => item))];
          },
          []
        ));

  // from CaseType let's evaluate if any roleResponsibility matches with any of the current user role.responsability
  caseType.rolePermissions.forEach(rolePermissionGroup => {
    const hasRoleResponsibility = rolePermissionGroup.roles.find(role =>
      roleResponsibilities.find(x => x === role.friendlyId)
    );
    if (hasRoleResponsibility) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentPermissions.push(...new Set(rolePermissionGroup.permissions.map(item => item!)));
    }
  });
};

export const AuthenticationCanActivateGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  return inject(AuthenticationGuardService).canActivate();
};

export const AuthenticationCanMatchGuard: CanMatchFn = (): Observable<boolean | UrlTree> => {
  return inject(AuthenticationGuardService).canMatch();
};
