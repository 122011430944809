import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Select } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { CaseData } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { getValueAtPath } from "@vp/shared/utilities";
import { map, Observable, tap } from "rxjs";

@Component({
  selector: "lib-formly-hidden-type",
  templateUrl: "./formly-hidden-type.component.html",
  styleUrl: "./formly-hidden-type.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyHiddenTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @Select(CaseState.current) public caseData$!: Observable<CaseData>;

  selOptions: string[] = [];

  ngOnInit(): void {
    this.caseData$
      .pipe(
        filterNullMap(),
        map((casedata: CaseData) => casedata.recordData),
        tap(recordData => {
          const fieldParent = this.field.parent?.key?.toString();
          const field = this.field.key?.toString();
          if (fieldParent && field) {
            const recordDataField = getValueAtPath(recordData, [fieldParent, field]);

            if (recordDataField) {
              this.formControl.patchValue(recordDataField);
            }
            if (this.props.type === "select") {
              this.selOptions = this.props.options as string[];
            }
          }
        })
      )
      .subscribe();
  }

  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }
}
