/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { ApplicationErrorEvent, SystemEvent } from "@vp/models";
import { AppInsightsEvent } from "@vp/shared/app-insights-proxy-service";

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  constructor(private readonly store: Store) {}

  systemEvent(
    source: string,
    message: string,
    properties?: { [key: string]: any } | null | undefined
  ) {
    const systemEvent = new SystemEvent({
      source: source,
      message: message,
      properties: properties
    });

    this.store.dispatch(new ApplicationStateActions.EmitEvent(systemEvent, source));
  }

  errorEvent(error: any, source?: string, message?: string, properties?: { [key: string]: any }) {
    let appError: ApplicationErrorEvent;
    if (error instanceof HttpErrorResponse) {
      appError = ApplicationErrorEvent.fromHttpErrorResponse(error, source, message, properties);
    } else {
      appError = ApplicationErrorEvent.fromAnyErrorResponse(error, message, properties);
    }

    const _source = source ?? error.source ?? error.name ?? "unkonwn";

    this.store.dispatch(new ApplicationStateActions.EmitEvent(appError, _source));
    this.store.dispatch(
      new ApplicationStateActions.EmitEvent(
        new AppInsightsEvent({
          error: error,
          type: "exception",
          source: source,
          message: message
        }),
        _source
      )
    );
  }
}
