import { sentenceCase } from "change-case";
import { titleCase } from "title-case";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "titleTransform"
})
export class TitleTransformPipe implements PipeTransform {
  transform(value: string) {
    return titleCase(sentenceCase(value));
  }
}
