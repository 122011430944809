import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexModule } from "@vp/shared/directives/flex";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxsModule } from "@ngxs/store";
import { UserState } from "@vp/data-access/users";
import { SpinnerModule } from "@vp/shared/spinner";
import { DeviceCommandListModule } from "@vp/virtual-care/ui/device-command-list";
import { AdvancedCommandsComponent } from "./components/advanced-commands.component";

@NgModule({
  declarations: [AdvancedCommandsComponent],
  imports: [
    CommonModule,
    DeviceCommandListModule,
    FlexModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SpinnerModule,
    NgxsModule.forFeature([UserState])
  ],
  exports: [AdvancedCommandsComponent]
})
export class AdvancedCommandsModule {}
