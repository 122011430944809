import { Tag, TagType } from "@vp/models";

export const handleSelectedTagEvent = (tag: Tag, tagTypes: TagType[], selected: Tag[]) => {
  const alreadySelected: boolean = selected.includes(tag);
  if (!alreadySelected) {
    const tagType: TagType | undefined = tagTypes.find(t => t.tagTypeId === tag.tagTypeId);
    if (tagType?.singleAssignment && selected.length >= 1) {
      for (let index = selected.length - 1; index >= 0; index--) {
        const tag: Tag = selected[index];
        if (tag.tagTypeId === tagType.tagTypeId) selected.splice(index, 1);
      }
    }
    selected.push(tag);
  } else {
    const childTagIds = getChildTags(tag, selected).map(t => t.tagId);
    selected = selected.filter(t => t.tagId != tag.tagId && !childTagIds.includes(t.tagId));
  }
  return selected;
};

export const getChildTags = (tag: Tag, selectedTags: Tag[]): Tag[] => {
  const childTags = selectedTags.filter(t => {
    const path = t.tagPath?.split(".").pop();
    return path === tag.tagId;
  });
  if (childTags.length > 0) {
    const result = childTags.map(t => getChildTags(t, selectedTags));
    const flatResult = result.reduce((acc, current) => acc.concat(current), []);
    return childTags.concat(flatResult);
  }
  return childTags;
};

export const getMissingHierarchyTags = (selectedTags: Tag[], tagTypes: TagType[] = []) => {
  const missingHierarchyTags: string[] = [];
  tagTypes.forEach(tagType => {
    const tagHierarchy = getTagHierarchy(tagType);
    const missingTags = tagHierarchy.filter(
      t => selectedTags.some(s => s.tagTypeFriendlyId == t) == false
    );
    missingHierarchyTags.push(...missingTags);
  });
  return missingHierarchyTags;
};

export const getTagHierarchy = (tagType: TagType): string[] => {
  return tagType.tagTypeFriendlyPathId ? tagType.tagTypeFriendlyPathId.split(".") : [];
};

// get tag path for each tag type
export const getTagPaths = (tagTypes: TagType[] = [], selectedTags: Tag[]): string[] => {
  return tagTypes.reduce((tagPaths: string[], tagType: TagType) => {
    const parentTagTypeFriendlyId = getTagHierarchy(tagType)?.pop();
    if (parentTagTypeFriendlyId) {
      const parentTags = selectedTags.filter(t => t.tagTypeFriendlyId == parentTagTypeFriendlyId);
      parentTags.forEach(t => {
        const path = generatePath(t, selectedTags);
        tagPaths.push(path);
      });
    }
    return tagPaths;
  }, []);
};

// generate the path to the Tag
export const generatePath = (tag: Tag, selectedTags: Tag[]): string => {
  if (tag.tagPath) {
    const parentTag = selectedTags.find(t => t.tagId == tag.tagPath);
    if (parentTag) {
      const path = generatePath(parentTag, selectedTags);
      return `${path}.${tag.tagId}`;
    }
  }
  return tag.tagId;
};

export const intersectTags = (tagIds: string[], tags: Tag[]) => {
  return tagIds.reduce((tagsEntities: Tag[], tagId: string) => {
    const index = tags.findIndex(te => te.tagId === tagId);
    if (index > -1) {
      tagsEntities.push(tags[index]);
    }
    return tagsEntities;
  }, []);
};

export const intersectingTags = (tagsA: Tag[], tagsB: Tag[]) => {
  return tagsA.reduce((tags: Tag[], availableTag: Tag) => {
    const found = tagsB.find(w => w.tagId === availableTag.tagId);
    if (found) {
      tags.push(found);
    }
    return tags;
  }, []);
};
