import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";
import { mergeDeep } from "@vp/formly/json-schema";

@Component({
  selector: "lib-formly-flex-input-wrapper",
  templateUrl: "./formly-flex-input-wrapper.component.html",
  styleUrls: ["./formly-flex-input-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFlexInputWrapperComponent extends FieldWrapper implements OnInit {
  flexProps: any = {};
  flexDefault = {
    flex: "100",
    flexOffset: "0"
  };

  ngOnInit(): void {
    this.flexProps = mergeDeep(this.flexDefault, this.props, "replace");
  }
}
