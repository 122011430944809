import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { GroupsState } from "@vp/data-access/groups";
import { Group, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "userGroups"
})
export class UserGroupsPipe implements PipeTransform {
  @Select(GroupsState.allGroups) groups$!: Observable<Group[]>;

  public transform(user: User): Observable<string> {
    return this.groups$.pipe(
      filterNullMap(),
      shareReplay(),
      map((groups: Group[]) => {
        return getUserGroups(user, groups);
      })
    );
  }
}

export const getUserGroups = (user: User, groups: Group[]) => {
  const groupDisplayNames: string[] = [];
  user.groups.forEach(userGroup => {
    const group = groups.find(t => t.groupId === userGroup.groupId);
    if (group !== undefined) {
      groupDisplayNames.push(group.displayName);
    }
  });
  return groupDisplayNames.join(" ");
};
