import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-mixed-schema-type",
  templateUrl: "./formly-mixed-schema-type.component.html",
  styleUrls: ["./formly-mixed-schema-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMixedSchemaTypeComponent extends FieldType {}
