import { Injectable } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { CaseData, OrganizationFeatures } from "@vp/models";
import { ProgressOption } from "@vp/shared/component/progress-circle";
import { FeatureService } from "@vp/shared/features";
import { map } from "rxjs/operators";

export interface CaseProgress {
  current: number;
  total: number;
  color: ThemePalette;
  options: ProgressOption[];
  paid: boolean;
}

@Injectable({
  providedIn: "root"
})
export class CaseProgressService {
  constructor(private featureService: FeatureService) {}

  calculateCaseProgress(caseData: CaseData) {
    let current = 25;
    if (caseData.result?.results?.length > 0) {
      current = 100;
    } else if (caseData.assignedDateTime) {
      current = 75;
    } else if (caseData.lastSubmittedBy) {
      current = 50;
    }
    const total = 100;
    const color: ThemePalette = "primary";
    const paid = caseData.paid;
    const options: ProgressOption[] = [
      { min: 0, max: 25, label: "Just Started" },
      { min: 25, max: 50, label: "In Progress" },
      { min: 50, max: 75, label: "Amost Done" },
      { min: 100, max: 100, label: "Completed" }
    ];

    this.featureService
      .configurationListValue$(
        OrganizationFeatures.caseProgressCircle,
        caseData.department.friendlyId
      )
      .pipe(
        map((descriptionList: string[]) => {
          if (descriptionList && descriptionList.length > 0) {
            descriptionList.forEach((value: string, index: number) => {
              options[index].label = value;
            });
          }
        })
      )
      .subscribe();

    const progress: CaseProgress = {
      current,
      total,
      color,
      options,
      paid
    };

    return progress;
  }
}
