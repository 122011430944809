import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { SignalRState } from "@vp/shared/signal-r-service";

import { CaseFilterState } from "./state+/case-filter.state";
import { CASE_FILTER_OPTIONS, CaseFilterOptions } from "./tokens";

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([SignalRState, CaseFilterState])]
})
export class CaseFilterStateModule {
  static forRoot(config?: CaseFilterOptions): ModuleWithProviders<CaseFilterStateModule> {
    return {
      ngModule: CaseFilterStateModule,
      providers: providers(config)
    };
  }

  static forChild(
    config?: CaseFilterOptions | undefined
  ): ModuleWithProviders<CaseFilterStateModule> {
    return {
      ngModule: CaseFilterStateModule,
      providers: providers(config)
    };
  }
}

const providers = (config: CaseFilterOptions | undefined): Provider[] => {
  const providers: Provider[] = [];
  if (config) {
    providers.push({ provide: CASE_FILTER_OPTIONS, multi: false, useValue: config });
  } else {
    providers.push({ provide: CASE_FILTER_OPTIONS, multi: false, useValue: defaultConfig() });
  }
  return providers;
};

const defaultConfig = (): CaseFilterOptions => {
  return {
    filters: []
  };
};
