import { Injectable } from "@angular/core";
import { UserApiService } from "@vp/data-access/users";
import { User } from "@vp/models";
import { AppStoreService } from "@vp/shared/store/app";
import { deeperCopy } from "@vp/shared/utilities";
import { Observable, of, throwError } from "rxjs";
import { first, map, mergeMap, switchMap, take, withLatestFrom } from "rxjs/operators";

@Injectable()
export class EmailVerificationService {
  constructor(
    private readonly appStoreService: AppStoreService,
    private userApiService: UserApiService
  ) {}

  verify(data: any): Observable<User> {
    return of({ ...data }).pipe(
      withLatestFrom(this.appStoreService.user$.pipe(map((user: User) => deeperCopy(user)))),
      switchMap(([data, user]: [any, User]) => {
        const index = user.tags.indexOf(`verify.${data.verificationCode}`);
        if (index > -1) {
          user.tags.slice(index, 1);
          user.tags.push("email.verified");
          return this.appStoreService.patchUser(user, "user_verifyEmail");
        }
        return throwError("invalid");
      }),
      switchMap(user => this.appStoreService.loadUser(user.userId)),
      first()
    );
  }

  sendVerification(): Observable<User> {
    return this.appStoreService.user$.pipe(
      first(),
      map((user: User) => deeperCopy(user)),
      /**
       * Not a fan of calling updateUser instead of patch here, but this code is going
       * away soon so not going to refactor the verify code right now
       */
      switchMap((user: User) => this.userApiService.updateUser(user, true).pipe()),
      mergeMap(user => this.appStoreService.loadUser(user.userId)),
      take(1)
    );
  }
}
