import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { TopNavListModule } from "@vp/shared/top-nav/ui/top-nav-list";
import { TopNavLogoModule } from "@vp/shared/top-nav/ui/top-nav-logo";
import { TopNavSearchModule } from "@vp/shared/top-nav/ui/top-nav-search";
import { TopNavShellModule } from "@vp/shared/top-nav/ui/top-nav-shell";
import { TopNavUserModule } from "@vp/shared/top-nav/ui/top-nav-user";

@NgModule({
  imports: [
    CommonModule,
    NgxsModule,
    TopNavShellModule,
    TopNavUserModule,
    TopNavSearchModule,
    TopNavLogoModule,
    TopNavListModule
  ]
})
export class TopNavFeatureModule {}
