import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormlyModule } from "@ngx-formly/core";
import { AlertModule } from "@vp/shared/components/alert";
import { UserSearchAlertWrapperComponent } from "./user-search-alert-wrapper.component";

@NgModule({
  imports: [
    CommonModule,
    AlertModule,
    FormlyModule.forChild({
      wrappers: [{ name: "user-search-alert-wrapper", component: UserSearchAlertWrapperComponent }]
    })
  ],
  declarations: [UserSearchAlertWrapperComponent],
  exports: [UserSearchAlertWrapperComponent]
})
export class UserSearchAlertWrapperModule {}
