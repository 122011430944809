import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormSanitizerDirective } from "./form-sanitizer.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [FormSanitizerDirective],
  exports: [FormSanitizerDirective]
})
export class FormSanitizerModule {}
