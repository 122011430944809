import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { Organization, TagType } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "tagTypePath"
})
export class TagTypePathPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  public transform(tagTypeFriendlyId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      map(org => {
        const tagPath: TagType[] = generatePath(tagTypeFriendlyId, org.tagTypes, []);
        return tagPath.map(type => type.displayName).join(" / ");
      })
    );
  }
}

const generatePath = (
  tagTypeFriendlyId: string,
  tagTypes: TagType[],
  tagPath: TagType[]
): TagType[] => {
  const parentType = tagTypes.find(tt => tt.friendlyId === tagTypeFriendlyId);
  if (parentType) {
    tagPath.unshift(parentType);
    if (parentType.tagTypeFriendlyPathId) {
      const pathArray: string[] = parentType.tagTypeFriendlyPathId.split(".");
      const grandparentType = tagTypes.find(
        tt => tt.friendlyId === pathArray[pathArray.length - 1]
      );
      if (grandparentType) {
        return generatePath(grandparentType.friendlyId, tagTypes, tagPath);
      }
    }
  }
  return tagPath;
};
