import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";
import { Select } from "@ngxs/store";
import { UserApiService, UserFilter, UserState } from "@vp/data-access/users";
import { PageResult, Tag, User, UserTypeFriendlyId } from "@vp/models";
import { AlertItem } from "@vp/shared/components/alert";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "vp-user-search-alert-wrapper",
  templateUrl: "./user-search-alert-wrapper.component.html",
  styleUrls: ["./user-search-alert-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSearchAlertWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  @Select(UserState.currentFilter) filter$!: Observable<UserFilter>;
  @Select(UserState.filtered) filteredUsers$!: Observable<User[]>;

  private readonly destroyed$ = new Subject<void>();
  private readonly alertItems$$ = new BehaviorSubject<AlertItem[]>([]);

  constructor(private readonly userApi: UserApiService) {
    super();
  }

  public alertItems$ = this.alertItems$$.asObservable();

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(1000),
        switchMap((tags: Tag[]) => {
          return this.userApi.getUsersPageResult(
            {
              tags: tags.map(t => t.tagId),
              userType: [UserTypeFriendlyId.device, UserTypeFriendlyId.acesoTv]
            },
            true
          );
        }),
        tap((pageResult: PageResult<User>) => {
          if (pageResult.partialResult) {
            this.alertItems$$.next([
              {
                id: "search-limit-exceeded",
                type: "toast-warning",
                title: "Search Limit Exceeded",
                message:
                  "Your search has exceeded the maximum 200 allowable results. Please refine your search."
              }
            ]);
          } else {
            this.alertItems$$.next([]);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
