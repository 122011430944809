<h1 mat-dialog-title>
  <span>Refund Payment</span>
</h1>
<mat-dialog-content flexLayout="column" flexLayoutGap="10px">
  <div>
    Available refunds: <b>{{ getAvailableRefunds() | currency }}</b>
  </div>
  <mat-checkbox [(ngModel)]="allowPartialRefund" (change)="onPartialRefundToggle()" color="primary"
    >Partial refund?</mat-checkbox
  >
  <form [formGroup]="form" flexLayout="column" flexLayoutAlign="space-around" flexLayoutGap="10px">
    <mat-form-field>
      <mat-label>Refund Amount:</mat-label>
      <input
        type="number"
        matInput
        formControlName="amount"
        [readonly]="allowPartialRefund === false"
      />
      <mat-error *ngIf="form.controls.amount.errors?.max"
        >Refund amount cannot be greater than available refund amount</mat-error
      >
      <mat-error *ngIf="form.controls.amount.errors?.pattern">Invalid amount</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea
        cdkTextareaAutosize
        cdkAutosizeMinRows="4"
        matInput
        formControlName="notes"
      ></textarea>
      <mat-error>This field is required</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="refund()" [disabled]="form.invalid">
    Confirm
  </button>
</mat-dialog-actions>
