import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldTypeConfig } from "@ngx-formly/core";
import { FieldType } from "@ngx-formly/material/form-field";

@Component({
  selector: "lib-formly-masked-type",
  templateUrl: "./formly-masked-type.component.html",
  styleUrls: ["./formly-masked-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMaskedTypeComponent extends FieldType<FieldTypeConfig> {
  maskTypes: MaskTypes = {};

  constructor() {
    super();
  }

  get type() {
    return this.props.type || "text";
  }

  get dropSpecialCharacters(): boolean {
    return this.props.dropSpecialCharacters ? this.props.dropSpecialCharacters : false;
  }

  get prefix() {
    return this.props.prefix || "";
  }

  get suffix() {
    return this.props.suffix || "";
  }

  get mask() {
    return this.props.mask || "";
  }

  get pattern(): any {
    return this.props.pattern || "";
  }

  get placeHolder() {
    return this.props.placeholder || "";
  }

  get showMaskTyped(): boolean {
    return this.props.showMaskTyped || false;
  }

  get specialCharacters() {
    return this.props.specialCharacters || "";
  }
}

export interface MaskTypes {
  [k: string]: {
    mask: string;
    pattern: any;
    symbol: string;
    prefix: string;
    suffix: string;
    dropSpecialCharacters: boolean;
    showMaskTyped: boolean;
    clearIfNotMatch: boolean;
    specialCharacters: any;
  };
}
