import { UserTypeFriendlyId } from "@vp/models";

export interface UserFilter {
  take?: number;
  skip?: number;
  search?: string | null | undefined;
  tags?: string[] | null | undefined;
  sort?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filters?: string[] | null | undefined;
  userId?: string | null | undefined;
  roleId?: string | null | undefined;
  userType?: UserTypeFriendlyId[] | null | undefined;
  deptId?: string | null | undefined;
}

export const defaultUserFilter = (): Partial<UserFilter> => {
  return {
    tags: [],
    sort: "email",
    sortDirection: "asc"
  };
};
