import { TemplateRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { first } from "rxjs/operators";
import { DialogComponent } from "./generic-dialog.component";

type DialogRef<T> = MatDialogRef<DialogComponent<T>>;

export class GenericDialog<T = undefined> {
  opened$ = this.dialogRef.afterOpened().pipe(first());
  closed$ = this.dialogRef.afterClosed();

  constructor(private dialogRef: DialogRef<T>) {}

  get context() {
    return this.dialogRef.componentInstance.outletContext;
  }

  close() {
    this.dialogRef.close();
  }

  setHeaderText(headerText: string): void {
    this.dialogRef.componentInstance.headerText = headerText;
  }

  setTemplate(template: TemplateRef<any>): void {
    this.dialogRef.componentInstance.templateRef = template;
  }
}
