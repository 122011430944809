import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldWrapper, FormlyFieldConfig } from "@ngx-formly/core";

export interface ModalWrapperDialogData {
  title: string;
  fields: FormlyFieldConfig | FormlyFieldConfig[];
  model: any;
}

@Component({
  selector: "lib-modal-wrapper",
  templateUrl: "./modal-wrapper.component.html",
  styleUrls: ["./modal-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalWrapperComponent extends FieldWrapper implements OnInit {
  snapshot: any = {};

  @ViewChild("modalWrapper", { static: true }) modalWrapper!: TemplateRef<any>;

  constructor(public dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.snapshot = this.formControl.value;
  }

  onClose(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.formControl.reset(this.snapshot);
    this.dialog.closeAll();
  }

  onCommit = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();

    if (this.formControl.value) {
      this.dialog.closeAll();
    }
  };

  onClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.dialog
      .open(this.modalWrapper, {
        width: `${this.props.width}`
      })
      .afterClosed()
      .subscribe();
  }
}
