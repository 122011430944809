import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { BaseDirective } from "../../base/base.directive";
import { FLEX_BREAKPOINTS } from "../model/flex-breakpoints";

@Directive({
  selector: `
  [flexOrder], [flexOrder.xs], [flexOrder.sm], [flexOrder.md],
  [flexOrder.lg], [flexOrder.xl], [flexOrder.lt-sm], [flexOrder.lt-md],
  [flexOrder.lt-lg], [flexOrder.lt-xl], [flexOrder.gt-xs], [flexOrder.gt-sm],
  [flexOrder.gt-md], [flexOrder.gt-lg]
`
})
export class FlexOrderDirective extends BaseDirective implements OnInit, OnChanges {
  @Input() flexOrder?: string;
  @Input("flexOrder.xs") flexOrder_xs?: string;
  @Input("flexOrder.sm") flexOrder_sm?: string;
  @Input("flexOrder.md") flexOrder_md?: string;
  @Input("flexOrder.lg") flexOrder_lg?: string;
  @Input("flexOrder.xl") flexOrder_xl?: string;
  @Input("flexOrder.lt-sm") flexOrder_lt_sm?: string;
  @Input("flexOrder.lt-md") flexOrder_lt_md?: string;
  @Input("flexOrder.lt-lg") flexOrder_lt_lg?: string;
  @Input("flexOrder.lt-xl") flexOrder_lt_xl?: string;
  @Input("flexOrder.gt-xs") flexOrder_gt_xs?: string;
  @Input("flexOrder.gt-sm") flexOrder_gt_sm?: string;
  @Input("flexOrder.gt-md") flexOrder_gt_md?: string;
  @Input("flexOrder.gt-lg") flexOrder_gt_lg?: string;

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._breakPointValueMap = {
      xs: this.flexOrder_xs,
      sm: this.flexOrder_sm,
      md: this.flexOrder_md,
      lg: this.flexOrder_lg,
      xl: this.flexOrder_xl,
      lt_sm: this.flexOrder_lt_sm,
      lt_md: this.flexOrder_lt_md,
      lt_lg: this.flexOrder_lt_lg,
      lt_xl: this.flexOrder_lt_xl,
      gt_xs: this.flexOrder_gt_xs,
      gt_sm: this.flexOrder_gt_sm,
      gt_md: this.flexOrder_gt_md,
      gt_lg: this.flexOrder_gt_lg
    };

    const flexBreakpointInputs = Object.entries(changes)
      .filter(([key, value]) => value.currentValue && key.includes("_"))
      .map(([inputKey]) => {
        return inputKey.replace(/flexOrder_/, "");
      });
    const breakpointsMediaQuery = FLEX_BREAKPOINTS.filter(bp =>
      flexBreakpointInputs.includes(bp.alias)
    ).map(bp => bp.mediaQuery);
    this._breakpoints.next(breakpointsMediaQuery);
  }

  ngOnInit(): void {
    this.setupResponsiveObservers();
    this._currentValue
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(flexOrder => this.setFlexOrder(flexOrder));
  }

  private setFlexOrder(flexOrder: string | undefined) {
    const nativeElement = this.elementRef.nativeElement;
    if (!nativeElement) {
      return;
    }
    flexOrder = flexOrder || this.flexOrder;
    if (flexOrder) {
      this.renderer.setStyle(nativeElement, "order", flexOrder);
    } else {
      this.renderer.removeStyle(nativeElement, "order");
    }
  }
}
