import { Inject, Injectable, inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import {
  ALLOW_REGISTRATION,
  APIM_SUB_KEY,
  API_BASE_URL,
  IVY_SUB_KEY,
  IVY_SUFFIX
} from "@vp/shared/tokens";
import { Observable, of } from "rxjs";

@Injectable()
export class AppSettingsResolverService {
  constructor(
    @Inject(ALLOW_REGISTRATION) private allowRegistration: boolean,
    @Inject(API_BASE_URL) private baseApi: string,
    @Inject(APIM_SUB_KEY) private apimSubscriptionKey: string,
    @Inject(IVY_SUFFIX) private ivySuffix: string,
    @Inject(IVY_SUB_KEY) private ivyApimSubscriptionKey: string
  ) {}
  resolve(): Observable<appSettings> {
    return of({
      allowRegistration: this.allowRegistration,
      baseApi: this.baseApi,
      apimSubscriptionKey: this.apimSubscriptionKey,
      ivyApimSubscriptionKey: this.ivyApimSubscriptionKey,
      ivySuffix: this.ivySuffix
    } as appSettings);
  }
}

export interface appSettings {
  allowRegistration: boolean;
  baseApi: string;
  apimSubscriptionKey: string;
  ivySuffix: string;
  ivyApimSubscriptionKey: string;
  instrumentationKey: string;
  ambraViewerBaseUrl: string;
  deviceLoginSuffix: string;
  reCaptchaSiteKey: string;
  reCaptchaSecretKey: string;
  authServer: string;
  oktaClientId: string;
  consumerOktaEnv: string;
  oktaLoginBrand: string;
  functionStorageAccountName: string;
}

export const AppSettingsResolver: ResolveFn<appSettings> = (): Observable<appSettings> => {
  return inject(AppSettingsResolverService).resolve();
};
