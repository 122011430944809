import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FocusStateDirective } from "./focus-state.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [FocusStateDirective],
  exports: [FocusStateDirective]
})
export class FocusStateModule {}
