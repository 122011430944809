/**
 * These are the Admin permissions.
 */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export const adminPermissions = {
  CaseType: {
    Read: "adminCaseTypeCanRead",
    Write: "adminCaseTypeCanWrite",
    Delete: "adminCaseTypeCanDelete"
  },
  Group: {
    Read: "adminGroupCanRead",
    Write: "adminGroupCanWrite",
    Delete: "adminGroupCanDelete"
  },
  InviteUser: {
    Read: "adminInviteUserCanRead",
    Write: "adminInviteUserCanWrite",
    Delete: "adminInviteUserCanDelete"
  },
  Schedule: {
    Read: "adminScheduleCanRead",
    Write: "adminScheduleCanWrite",
    Delete: "adminScheduleCanDelete"
  },
  User: {
    Read: "adminUserCanRead",
    Write: "adminUserCanWrite",
    Delete: "adminUserCanDelete",
    Profile: {
      Read: "adminUserProfileCanRead",
      Write: "adminUserProfileCanWrite",
      Delete: "adminUserProfileCanDelete"
    },
    Bio: {
      Write: "canModifyOtherBios"
    },
    Signature: {
      Write: "canModifyOtherSignatures"
    },
    CloneUser: {
      Write: "adminCreateUserCanClone"
    },
    RoleAssignment: {
      Read: "adminUserRoleAssignmentCanRead",
      Write: "adminUserRoleAssignmentCanWrite",
      Delete: "adminUserRoleAssignmentCanDelete"
    },
    GroupAssignment: {
      Read: "adminUserGroupAssignmentCanRead",
      Write: "adminUserGroupAssignmentCanWrite",
      Delete: "adminUserGroupAssignmentCanDelete"
    },
    TagsAssignment: {
      Read: "adminUserTagsAssignmentCanRead",
      Write: "adminUserTagsAssignmentCanWrite",
      Delete: "adminUserTagsAssignmentCanDelete"
    },
    AccessTagsAssignment: {
      Read: "adminUserAccessTagsAssignmentCanRead",
      Write: "adminUserAccessTagsAssignmentCanWrite",
      Delete: "adminUserAccessTagsAssignmentCanDelete"
    },
    Snippet: {
      Read: "adminUserSnippetCanRead",
      Write: "adminUserSnippetCanWrite"
    }
  },
  Device: {
    Read: "adminDeviceCanRead",
    Write: "adminDeviceCanWrite",
    Delete: "adminDeviceCanDelete"
  },
  QaConsole: {
    Read: "adminQaConsoleCanRead",
    Write: "adminQaConsoleCanWrite",
    Delete: "adminQaConsoleCanDelete"
  },
  Organization: {
    Read: "adminOrganizationCanRead",
    Write: "adminOrganizationCanWrite"
  },
  Tags: {
    Read: "adminTagsCanRead",
    Write: "adminTagsCanWrite",
    Delete: "adminTagsCanDelete"
  },
  Backup: {
    Read: "adminBackupCanRead",
    Write: "adminBackupCanWrite"
  },
  Accounting: {
    Read: "adminAccountingCanRead"
  },
  Layout: {
    Create: "adminLayoutCanCreate",
    Write: "adminLayoutCanWrite",
    Delete: "adminLayoutCanDelete"
  }
};
