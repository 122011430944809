import { Injectable } from "@angular/core";
import { Store } from "rxjs-observable-store";

export class UploadState {
  files: FileState[] = [];
  fileCount = 0;
  isDisabled = true;
  dimensions!: IImageDimensions;
}

export interface FileState extends Partial<File> {
  icon: string;
  path: string;
}

export interface IImageDimensions {
  height: number;
  width: number;
  fileName: string;
}

@Injectable()
export class UploadStoreService extends Store<UploadState> {
  constructor() {
    super(new UploadState());
  }
}
