import { EventBase } from "@vp/models";

export interface NotificationEventArgs {
  title: string;
  message: string;
  error: any;
  type: NotificationEventType;
}

export class NotificationEvent extends EventBase<NotificationEventArgs> {
  constructor(private data: NotificationEventArgs) {
    super();
    this.args = this.data;
  }
}

export declare type NotificationEventType = "success" | "error" | "info" | "warning";
