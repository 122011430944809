import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable({
  providedIn: "root"
})
export class LocaleService {
  getLocale() {
    const lang = navigator.language || window.navigator.language;
    if (lang) {
      return lang;
    }
    return "en-US";
  }

  getLocaleDateFormat() {
    const locale = this.getLocale();
    const dateFormat = moment.localeData(locale).longDateFormat("L");
    return dateFormat;
  }

  getDateFormat() {
    const localeDateFormat = this.getLocaleDateFormat();
    if (localeDateFormat) {
      return {
        parse: {
          dateInput: localeDateFormat
        },
        display: {
          dateInput: localeDateFormat,
          monthYearLabel: "MMMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY"
        }
      };
    }
    return {
      parse: {
        dateInput: "MM/DD/YYYY"
      },
      display: {
        dateInput: "MM/DD/YYYY",
        monthYearLabel: "MMMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY"
      }
    };
  }
}
