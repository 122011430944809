import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as RequestMetaDataActions from "./request-meta-data-state.actions";

export type RequestMetaDataStateModel = {
  metaData: Record<string, string>;
};

export const defaultRequestMetaData = (): RequestMetaDataStateModel => {
  return {
    metaData: {}
  };
};
@State<RequestMetaDataStateModel>({
  name: "requestMetaData",
  defaults: defaultRequestMetaData()
})
@Injectable()
export class RequestMetaDataState {
  @Selector()
  public static metaData(state: RequestMetaDataStateModel) {
    return state.metaData;
  }

  @Selector()
  public static featureName(state: RequestMetaDataStateModel) {
    if ("feature-name" in state.metaData === true) {
      return state.metaData["feature-name"];
    } else {
      return null;
    }
  }

  @Action(RequestMetaDataActions.SetMetaData)
  setMetaData(
    ctx: StateContext<RequestMetaDataStateModel>,
    { metaData }: RequestMetaDataActions.SetMetaData
  ) {
    if (!metaData) {
      metaData = {};
    } else {
      ctx.setState({ metaData: metaData });
    }
  }

  @Action(RequestMetaDataActions.ResetMetaData)
  clearMetaData(ctx: StateContext<RequestMetaDataStateModel>) {
    ctx.setState(defaultRequestMetaData());
  }
}
