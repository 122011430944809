import { Pipe, PipeTransform } from "@angular/core";
/**
 * Use a pure function in a template with explicit inputs to reduce change detection churn.
 * Please note that use of class properties inside the function is disallowed and will
 * instead need to be passed into the function to make it pure.
 * Use to fix the following lint issue:
 *   template-no-call-expression     Avoid calling expressions in templates
 * See https://medium.com/angular-in-depth/tiny-angular-pipe-to-make-any-function-memoizable-f6c8fa917f2f
 */
@Pipe({
  name: "memoize",
  pure: true //can be omitted as default value
})
export class MemoizePipe implements PipeTransform {
  transform(fn: (...args: any[]) => any, ...args: any[]): any {
    if (typeof fn !== "function") {
      throw new Error(
        "MemoizePipe: Use pipe on a pure function, e.g. myFunction | memoize:myInput"
      );
    }
    return fn(...args);
  }
}
