import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CssFillDirective } from "./directives/css-fill/css-fill.directive";
import { CssFlexBasisDirective } from "./directives/css-flex-basis/css-flex-basis.directive";
import { FlexLayoutAlignDirective } from "./directives/flex-layout-align/flex-layout-align.directive";
import { FlexLayoutGapDirective } from "./directives/flex-layout-gap/flex-layout-gap.directive";
import { FlexLayoutDirective } from "./directives/flex-layout/flex-layout.directive";
import { FlexOrderDirective } from "./directives/flex-order/flex-order.directive";
import { FlexShowDirective } from "./directives/flex-show/flex-show.directive";
import { FlexDirective } from "./directives/flex/flex.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    CssFillDirective,
    FlexDirective,
    CssFlexBasisDirective,
    FlexLayoutDirective,
    FlexLayoutAlignDirective,
    FlexLayoutGapDirective,
    FlexShowDirective,
    FlexOrderDirective
  ],
  exports: [
    CssFillDirective,
    FlexDirective,
    CssFlexBasisDirective,
    FlexLayoutDirective,
    FlexLayoutAlignDirective,
    FlexLayoutGapDirective,
    FlexShowDirective,
    FlexOrderDirective
  ]
})
export class FlexModule {}
