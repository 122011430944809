import moment from "moment";

/**
 * Removes any nulls, undefineds, empty strings, or empty objects from the recordData
 * object to avoid JSONSchema validation errors on save.
 * @param data A Record<string, unknown> object containing the collection of recordData
 * to save
 * @returns returns a copy of the input object with all invalid values removed
 */
export function cleanData(data: Record<string, unknown>): Record<string, unknown> {
  const clone = deeperCopy(data);
  Object.keys(clone).forEach((key: string) => {
    const value = clone[key];

    if (value === null || value === undefined) {
      // delete null or undefined values to avoid schema validation issues
      delete clone[key];
      return;
    }
    if (typeof value === "string") {
      // delete empty string values to avoid schema validation issues
      if (value === "") {
        delete clone[key];
        return;
      }
      //Trim trailing and leading white space from strings with value
      clone[key] = value.trim();
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      // Delete any objects that have no properties
      delete clone[key];
    } else if (typeof value === "object") {
      // If object has properties, recusivly clean the properties
      const cleaned = cleanData(value);
      if (Object.keys(cleaned).length > 0) {
        clone[key] = cleaned;
      } else {
        delete clone[key];
      }
    }
  });
  return clone;
}

//This should be placed in a utility library.  It is copied here for now.
function deeperCopy(objectToCopy: any): any {
  let copy: any;
  if (null === objectToCopy || "object" !== typeof objectToCopy) return objectToCopy;
  if (objectToCopy instanceof moment) {
    return moment(objectToCopy);
  }
  if (objectToCopy instanceof Date) {
    copy = new Date();
    copy.setTime(objectToCopy.getTime());
    return copy;
  }

  if (objectToCopy instanceof Array) {
    copy = [];
    for (let i = 0, len = objectToCopy.length; i < len; i++) {
      copy[i] = deeperCopy(objectToCopy[i]);
    }
    return copy;
  }
  if (objectToCopy instanceof Object) {
    const object: { [key: string]: any } = {};
    for (const attr in objectToCopy) {
      if (Object.prototype.hasOwnProperty.call(objectToCopy, attr)) {
        object[attr] = deeperCopy(objectToCopy[attr]);
      }
    }
    return object;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}
