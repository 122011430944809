<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    alerts: (alertItems$ | async) ?? []
  }"
>
</ng-container>
<ng-template #template let-alerts="alerts">
  <vp-alert-container [alerts]="alerts"></vp-alert-container>
  <ng-container #fieldComponent></ng-container>
</ng-template>
