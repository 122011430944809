/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, isDevMode, OnDestroy } from "@angular/core";
import { EventAggregator } from "@vp/data-access/application";
import { ApplicationErrorEvent, EventBase, IEvent, SystemEvent } from "@vp/models";
import { AppInsightsEvent } from "@vp/shared/app-insights-proxy-service";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, mergeMap, takeUntil } from "rxjs/operators";

const infoStyle = "color: #87CEEB; padding: 3px; font-size: 9px;";
const warnStyle = "color: #FFFF00; padding: 3px; font-size: 9px;";
const errorStyle = "color: #FF0000; padding: 3px; font-size: 9px;";
const logStyle = "color: #fff; padding: 3px; font-size: 9px;";

@Injectable({
  providedIn: "root"
})
export class ConsoleLoggingService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private eventAggregator: EventAggregator) {
    if (!isDevMode()) return;

    this.eventAggregator
      .on<any>(EventBase)
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((event: IEvent) => {
          if (event instanceof AppInsightsEvent && !isDevMode()) {
            return EMPTY;
          }

          if (event instanceof ApplicationErrorEvent) {
            return this.error(event);
          } else if (event instanceof SystemEvent) {
            return this.info(event);
          } else if (event instanceof AppInsightsEvent) {
            return this.metric(event);
          } else return this.log(event);
        })
      )
      .subscribe();
  }

  listen() {
    console.log("Console Logging Service Started");
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  metric(event: AppInsightsEvent): Observable<unknown> {
    return new Observable(observer => {
      console.log(
        `%c[metric] @%s \n[at] %s %c\n[data] %s`,
        logStyle,
        event.eventTime,
        event.args.source ?? event.sender,
        "color: unset",
        event.args.properties ? JSON.stringify(event.args.properties, null, 4) : "empty"
      );
      observer.next();
      observer.complete();
    }).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  }

  log(event: IEvent): Observable<unknown> {
    return new Observable(observer => {
      console.log(
        `%c[log] %s \n[at] %s %c\n[data] %s`,
        logStyle,
        event.args.message ?? event.args.error?.message,
        event.args.source ?? event.sender ?? event.args.error.name,
        "color: unset",
        event.args.properties ? JSON.stringify(event.args.properties, null, 4) : ""
      );
      observer.next();
      observer.complete();
    }).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  }

  error(event: IEvent) {
    const message =
      event.args.message != null
        ? event.args.message
        : event.sender === "HttpErrorResponse"
          ? (event.args.url ?? event.args.error?.message)
          : "unknown error";

    return new Observable(observer => {
      const at =
        event.args?.source !== null
          ? event.args?.source
          : event?.sender !== null
            ? event?.sender
            : event.args?.error?.name !== null
              ? event.args?.error?.name
              : "unknown source";

      console.log(
        `%c[error] %s \n[at] %s %c\n[data] %s`,
        errorStyle,
        message,
        at,
        "color: unset",
        event.args.properties ? JSON.stringify(event.args.properties, null, 4) : ""
      );
      observer.next();
      observer.complete();
    }).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  }

  info(event: IEvent) {
    return new Observable(observer => {
      console.info(
        `%c[info] %s \n[at] %s %c\n[data] %s`,
        infoStyle,
        event.args.message,
        event.args.source,
        "color: unset",
        event.args.properties ? JSON.stringify(event.args.properties, null, 4) : ""
      );
      observer.next();
      observer.complete();
    }).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  }

  warn(event: IEvent) {
    return new Observable(observer => {
      console.warn(
        `%c[warn] %s \n[at] %s %c\n[data] %s`,
        warnStyle,
        event.args.message,
        event.args.source,
        "color: unset",
        event.args.properties ? JSON.stringify(event.args.properties, null, 4) : ""
      );
      observer.next();
      observer.complete();
    }).pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  }
}
