import { Injectable, inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { OrganizationFeatures, Role } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { LocalStorageData, LocalStorageService } from "@vp/shared/local-storage";
import { AppStoreService } from "@vp/shared/store/app";
import { RouterUtilities } from "@vp/shared/utilities";
import { Observable } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

export const ROUTE_DEFAULT = "/login";

@Injectable({
  providedIn: "root"
})
export class RedirectGuardService {
  constructor(
    private readonly router: Router,
    private readonly appStoreService: AppStoreService,
    private localStorageService: LocalStorageService,
    private routerUtilities: RouterUtilities,
    private featureService: FeatureService
  ) {
    localStorageService.load();
  }

  featureEnabled$ = this.featureService.featureEnabled$(OrganizationFeatures.homePage);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appStoreService.selectedRole.pipe(
      withLatestFrom(this.localStorageService.localStorage$, this.featureEnabled$),
      map(([role, localStorage, featureEnabled]: [Role, LocalStorageData | null, boolean]) => {
        if (localStorage?.data?.redirect === true) {
          const pathname = localStorage.data?.pathname;
          const fragment = localStorage.data?.fragment?.replace("#", "");
          const search = RouterUtilities.parseQueryString(localStorage.data?.search);
          const url = new URL(pathname, window.location.origin);
          const pathParts = url.pathname.split("/");
          this.localStorageService.clearAll();

          return this.router.createUrlTree(pathParts, {
            queryParams: search,
            fragment: fragment
          });
        }
        const { path, queryParams } = this.routerUtilities.getRouteDefaultFromRole(role as Role);
        if (featureEnabled) {
          return this.router.createUrlTree(["/home"]);
        }
        return this.router.createUrlTree([path], {
          queryParams: queryParams
        });
      })
    );
  }
}

export const RedirectGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  return inject(RedirectGuardService).canActivate();
};
