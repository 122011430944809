import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { FormlySelectOption, FormlySelectOptionsPipe } from "@ngx-formly/core/select";
import { BehaviorSubject, combineLatest, map, take } from "rxjs";

type Option = FormlySelectOption & { checked: boolean };
@Component({
  selector: "lib-multi-select-auto-complete",
  templateUrl: "./multi-select-auto-complete.component.html",
  styleUrl: "./multi-select-auto-complete.component.scss",
  providers: [FormlySelectOptionsPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectAutoCompleteComponent extends FieldType implements OnInit {
  private readonly _allOptions$ = new BehaviorSubject<Option[]>([]);

  constructor(private formlySelectOptions: FormlySelectOptionsPipe) {
    super();
  }

  searchString$ = new BehaviorSubject("");

  filteredOptions$ = combineLatest([this._allOptions$, this.searchString$]).pipe(
    map(([allOptions, searchString]) => {
      return allOptions.filter(option =>
        option.label.toLowerCase().includes(searchString.toLowerCase())
      );
    })
  );

  selectedOptions$ = this._allOptions$.pipe(
    map(options => {
      return options.filter(option => option.checked);
    })
  );

  ngOnInit(): void {
    this.formlySelectOptions
      .transform(this.props.options, this.field)
      .subscribe((options: FormlySelectOption[]) => {
        const allOptions = options.map(option => {
          const vm = { ...option } as Option;
          vm.checked = this.formControl.value?.includes(option.value);
          return vm;
        });
        this._allOptions$.next(allOptions);
      });

    this.selectedOptions$.subscribe(selectedOptions => {
      this.formControl.setValue(selectedOptions.map(option => option.value));
    });
  }

  search(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    this.searchString$.next(inputElement.value);
  }

  toggleSelection(option: Option, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this._allOptions$.pipe(take(1)).subscribe(allOptions => {
      const index = allOptions.findIndex(o => o.value === option.value);
      if (index > -1) {
        allOptions[index] = { ...option, checked: !option.checked };
      }
      this._allOptions$.next([...allOptions]);
    });
  }

  onBackspaceKeydown($event: any) {
    $event.stopImmediatePropagation();
  }
}
