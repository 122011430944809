import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { CaseType } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Pipe({
  name: "caseTypeDisplay"
})
export class CaseTypeDisplayPipe implements PipeTransform {
  @Select(CaseTypesState.allCaseTypes) allCaseTypes$!: Observable<CaseType[]>;
  transform(caseTypeId: string): Observable<string> {
    return this.allCaseTypes$.pipe(
      shareReplay(),
      map((caseTypes: CaseType[]) => {
        const caseType = caseTypes.find(c => c.caseTypeId === caseTypeId);
        return caseType?.displayName ?? "[caseType not found]";
      })
    );
  }
}
