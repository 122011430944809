import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { PageState, User, UserRole } from "@vp/models";
import { LoggerService } from "@vp/shared/logger-service";
import { filterNullMap } from "@vp/shared/operators";
import { cleanUnused, equals, getValueForPath, mergeDeep, parseError } from "@vp/shared/utilities";
import { of, throwError } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";
import { UserApiService } from "../api/user-api.service";
import { UserFilter, defaultUserFilter } from "../models/user-filter";
import { UserStateModel } from "../models/user-state.model";
import * as UserStateActions from "./user-state.actions";

const defaultState = {
  filter: defaultUserFilter(),
  users: [],
  visibleUsers: [],
  currentUser: null,
  errors: [],
  pageState: {
    totalRecords: 0,
    pageIndex: 0,
    pageCount: 0,
    pageSize: 25,
    lastPage: 1,
    partialResult: false
  } as PageState
} as UserStateModel;

@State<UserStateModel>({
  name: "user",
  defaults: defaultState
})
@Injectable()
export class UserState {
  constructor(
    private readonly logger: LoggerService,
    private readonly api: UserApiService,
    private readonly store: Store
  ) {}

  @Selector()
  public static currentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  public static currentUserRole(state: UserStateModel) {
    return state.currentUser?.roles.find(
      (userRole: UserRole) => userRole.roleId === state.currentUser?.selectedRoleId
    );
  }

  @Selector()
  public static getCurrentFilter(state: UserStateModel) {
    return state.filter;
  }

  @Selector([UserState.getCurrentFilter])
  public static currentFilter(filter: Partial<UserFilter>) {
    return filter;
  }

  @Selector()
  public static getUsers(state: UserStateModel) {
    return state.users;
  }

  @Selector([UserState.getUsers])
  public static users(users: User[]) {
    return users;
  }

  @Selector([UserState.getUsers, UserState.currentFilter])
  public static filtered(users: User[], filter: UserFilter) {
    const filters = filter.filters ?? [];

    if (users.length === 0 || !filters || filters.length === 0) {
      return users;
    }

    const parsedFilters = filters.map(filter => {
      const [path, value] = filter.split("=");
      return {
        path,
        value
      };
    });

    return users.filter(user =>
      parsedFilters.every(filter => {
        const actualValue = getValueForPath(user, filter.path);
        return equals(actualValue, filter.value);
      })
    );
  }

  @Selector()
  public static getVisibleUsers(state: UserStateModel) {
    return state.visibleUsers;
  }

  @Selector([UserState.getVisibleUsers])
  public static visibleUsers(visibleUsers: User[]) {
    return visibleUsers;
  }

  @Selector()
  public static getUserFn(state: UserStateModel) {
    return (userId: string) => state.users.find(user => user.userId === userId);
  }

  @Selector()
  static getPageState(state: UserStateModel): Partial<PageState> {
    return state.pageState;
  }

  @Selector([UserState.getPageState])
  static pageState(pageState: PageState): Partial<PageState> {
    return pageState;
  }

  @Selector()
  public static getErrors(state: UserStateModel) {
    return state.errors;
  }

  @Selector([UserState.getErrors])
  public static errors(errors: never[]) {
    return errors;
  }

  @Action(UserStateActions.PatchState)
  patchState(ctx: StateContext<UserStateModel>, { userStateModel }: UserStateActions.PatchState) {
    ctx.patchState(userStateModel);
  }

  @Action(UserStateActions.ResetState)
  resetState(ctx: StateContext<UserStateModel>) {
    ctx.setState(defaultState);
  }

  /**
   * Retrieves a user by its userId from the server and sets the state with the response
   */
  @Action(UserStateActions.SetCurrentUser)
  setCurrentUser(ctx: StateContext<UserStateModel>, { userId }: UserStateActions.SetCurrentUser) {
    return this.api.getUser(userId, false).pipe(
      tap(user => {
        ctx.patchState({ currentUser: user });
      }),
      catchError(error => {
        ctx.patchState({
          currentUser: null,
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  @Action(UserStateActions.SetCurrentUserRole)
  setCurrentUserRole(
    ctx: StateContext<UserStateModel>,
    { roleId }: UserStateActions.SetCurrentUserRole
  ) {
    let user: User | null = ctx.getState().currentUser;
    if (user === null) {
      this.logger.errorEvent(
        new Error("Current user not set in user state"),
        `${this.constructor.name}.${this.setCurrentUserRole.name}`
      );
    } else {
      user = { ...user, selectedRoleId: roleId };
      ctx.patchState({ currentUser: user });
    }
  }

  @Action(UserStateActions.SetFilter)
  setFilter(ctx: StateContext<UserStateModel>, actions: UserStateActions.SetFilter) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        filter: mergeDeep(state.filter, actions.filter, actions.arrayAction)
      })
    );
  }

  @Action(UserStateActions.SetPageState)
  setPageState(ctx: StateContext<UserStateModel>, actions: UserStateModel) {
    const take = actions.pageState.pageSize ?? 25;
    const skip =
      actions.pageState.pageSize && actions.pageState.pageIndex
        ? actions.pageState?.pageSize * actions.pageState.pageIndex
        : 0;

    const currentFilter = ctx.getState().filter;
    if (currentFilter) {
      const updatedFilter: UserFilter = {
        ...currentFilter,
        take: take,
        skip: skip
      };

      ctx.setState({
        ...ctx.getState(),
        filter: updatedFilter
      });
    }
  }

  @Action(UserStateActions.GetFiltered)
  getFiltered(ctx: StateContext<UserStateModel>, { filter }: UserStateActions.GetFiltered) {
    const state = ctx.getState();
    const userFilter = cleanUnused(filter ?? state.filter);
    this.api
      .getUsersPageResult(userFilter)
      .pipe(
        catchError(error => {
          ctx.patchState({
            users: [],
            errors: parseError(error)
          });
          return throwError(error);
        })
      )
      .subscribe(pageResult => {
        ctx.setState(
          patch({
            users: pageResult.results,
            pageState: patch({
              totalRecords: pageResult.totalRecords,
              partialResult: pageResult.partialResult,
              totalFilteredRecords: pageResult.pagingTotalRecordCount
            }),
            visibleUsers: []
          })
        );
      });
  }

  @Action(UserStateActions.AddUser)
  addUser(ctx: StateContext<UserStateModel>, action: UserStateActions.AddUser) {
    return this.api.getUser(action.userId, false).pipe(
      filterNullMap(),
      tap((user: User) => {
        const currentUsers = ctx.getState().users;
        const exists = currentUsers.some(u => u.userId === user.userId);
        if (!exists) {
          ctx.setState(
            patch({
              users: append([user])
            })
          );
        } else {
          ctx.dispatch(new UserStateActions.UpdateUser(user.userId, user));
        }
      }),
      catchError(error => {
        ctx.patchState({
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  @Action(UserStateActions.GetUser)
  getUser(ctx: StateContext<UserStateModel>, action: UserStateActions.GetUser) {
    const state = ctx.getState();
    const user = state.users.find(user => user.userId === action.userId);
    if (!user) {
      return this.store.dispatch(new UserStateActions.AddUser(action.userId));
    }
    return of(user);
  }

  @Action(UserStateActions.UpdateUser)
  updateUser(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateUser) {
    ctx.setState(
      patch({
        users: updateItem<User>(
          u => u?.userId === action.userId,
          user => mergeDeep(user, action.user, "merge")
        )
      })
    );
  }

  @Action(UserStateActions.UpdateCurrentUser)
  updateCurrentUser(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateCurrentUser) {
    const currentUser = ctx.getState().currentUser;
    if (currentUser != null) {
      const updatedUser: User = { ...currentUser, ...action.user };
      ctx.patchState({
        currentUser: updatedUser
      });
    }
  }

  @Action(UserStateActions.UpdateUserData)
  updateUserData(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateUserData) {
    ctx.setState(
      patch({
        users: updateItem<User>(
          u => u?.userId === action.userId,
          patch({
            userData: patch({
              ...action.userData
            })
          })
        )
      })
    );
  }

  @Action(UserStateActions.AssignTags)
  assignTags(ctx: StateContext<UserStateModel>, action: UserStateActions.AssignTags) {
    const user = ctx.getState().users.find(u => u?.userId === action.userId);
    ctx.setState(
      patch({
        users: updateItem<User>(
          u => u?.userId === action.userId,
          patch({
            assignedTags: append([
              ...action.tagIds.filter(tagId => !user?.assignedTags?.includes(tagId))
            ])
          })
        )
      })
    );
  }

  @Action(UserStateActions.AddOrUpdateUser)
  addOrUpdateUser(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateUser) {
    const state = ctx.getState();
    const existingUser = state.users.find(u => u?.userId === action.userId);

    if (existingUser) {
      ctx.setState(
        patch({
          users: updateItem<User>(
            u => u?.userId === action.userId,
            user => mergeDeep(user, action.user, "merge")
          )
        })
      );
    } else {
      ctx.dispatch(new UserStateActions.AddUser(action.userId));
    }
  }

  @Action(UserStateActions.DeleteUser)
  deleteUser(ctx: StateContext<UserStateModel>, action: UserStateActions.DeleteUser) {
    ctx.setState(
      patch({
        users: removeItem<User>(user => user?.userId === action.userId)
      })
    );
  }

  @Action(UserStateActions.AddVisibleUser)
  addVisibleUser(ctx: StateContext<UserStateModel>, { user }: UserStateActions.AddVisibleUser) {
    const exists = ctx.getState().visibleUsers.some(u => u.userId === user.userId);
    if (!exists) {
      ctx.setState(
        patch({
          visibleUsers: append([user])
        })
      );
    }
  }
}
