import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { EventAggregator } from "@vp/data-access/application";
import {
  ICreateDicomImagesUploadResponse,
  IFile,
  IFileUpload,
  UploadCompletedEvent,
  UploadFileCompleteEvent,
  UploadFileFailEvent,
  UploadProgressEvent
} from "@vp/models";
import { BlobStorageService } from "@vp/shared/azure-blob-storage";
import { NotificationService } from "@vp/shared/notification-service";
import { removeFileExtension } from "@vp/shared/utilities";
import * as dicomParser from "dicom-parser";
import { minimatch } from "minimatch";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { EMPTY, Subject, from, of } from "rxjs";
import {
  catchError,
  concatMap,
  finalize,
  mergeMap,
  reduce,
  switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import { FileState } from "./upload-store.service";

export interface Study {
  studyID: string;
  studyDescription: string;
  seriesCount: number;
  patientName: string;
  patientDOB?: Date;
  modality: string;
  studyDate: Date;
  images: File[];
  selected: boolean;
}

export const DICOMDIR_NAME = "DICOMDIR";
export const DICOM_FILE_EXTENTIONS_GLOB = "*.dcm";
export const MAX_CONCURRENT_REQUESTS = 3;
export const getFileNameFromPartialPath = (value: string) => value.split("/").pop() ?? "";

@Injectable({
  providedIn: "root"
})
export class FileUploadService implements OnDestroy {
  private destroyed$ = new Subject<void>();
  uploadCompleted = false;

  constructor(
    private eventAggregator: EventAggregator,
    private httpClient: HttpClient,
    private blobStorage: BlobStorageService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private upload = (file: IFileUpload, uploadURL: string) => {
    return this.httpClient.post<any>(uploadURL, file.file, {
      reportProgress: true,
      observe: "events"
    });
  };

  readDicomFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const byteArray = new Uint8Array(arrayBuffer);
        const dataSet = dicomParser.parseDicom(byteArray);
        resolve(dataSet);
      };
      fileReader.onerror = error => {
        reject(error);
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  getDicomFiles(dicoms: NgxFileDropEntry[]) {
    const files = dicoms.filter(d => {
      const fileName = d.fileEntry.name.toLowerCase();
      return d.fileEntry.isFile && fileName.endsWith(".dcm");
    });
    return Promise.all(files.map(file => this.getFileFromSystem(file)));
  }

  organizeDicomFilesByStudy = async (files: File[]) => {
    const studies: { [studyID: string]: Study } = {};
    for (const file of files) {
      const dataSet = await this.readDicomFile(file);
      const studyID = dataSet.string("x0020000d");
      if (!studies[studyID]) {
        const patientDOBStringDate = dataSet.string("x00100030");
        const patientDOBDate = patientDOBStringDate
          ? new Date(
              patientDOBStringDate.substring(0, 4),
              patientDOBStringDate.substring(4, 6) - 1,
              patientDOBStringDate.substring(6, 8)
            )
          : undefined;
        const studyStringDate = dataSet.string("x00080020");
        const formattedStudyDate = new Date(
          studyStringDate.substring(0, 4),
          studyStringDate.substring(4, 6) - 1,
          studyStringDate.substring(6, 8)
        );
        studies[studyID] = {
          studyID,
          studyDescription: dataSet.string("x00081030"),
          seriesCount: 0,
          patientName: dataSet.string("x00100010"),
          patientDOB: patientDOBDate,
          modality: dataSet.string("x00080060"),
          studyDate: formattedStudyDate,
          images: [],
          selected: true
        };
      }
      studies[studyID].images.push(file);
      studies[studyID].seriesCount++;
    }
    return Object.values(studies);
  };

  uploadWithProgress = (file: IFileUpload, uploadURL = "") => {
    this.upload(file, uploadURL)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (event: HttpEvent<HttpProgressEvent>) => {
          //send events for progress uploader
          switch (event.type) {
            case HttpEventType.UploadProgress:
              {
                const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
                this.eventAggregator.emit(
                  new UploadProgressEvent({ progress, status: "Uploading..." }),
                  "FileUploadService"
                );
              }
              break;
            case HttpEventType.Response:
              this.eventAggregator.emit(
                new UploadProgressEvent({ progress: 100, status: "Upload complete!" }),
                "FileUploadService"
              );
              break;
            default:
              break;
          }
        },
        error: () => {
          this.notificationService.warningMessage("File Type Not Supported", "Warning");
          this.eventAggregator.emit(new UploadFileFailEvent(file), "FileUploadService");
        },
        complete: () =>
          this.eventAggregator.emit(new UploadCompletedEvent(file), "FileUploadService")
      });
  };

  prepareFolderWithValidation = async (files: NgxFileDropEntry[]) => {
    this.eventAggregator.emit(
      new UploadProgressEvent({ progress: 0, status: "Validating..." }),
      "FileUploadService"
    );
    return await new Promise<FileState[]>(resolveValidation => {
      const fileEntries: FileState[] = [];
      const onlyDcmFiles = files.every(
        (file: NgxFileDropEntry) =>
          getFileNameFromPartialPath(file.fileEntry.name) !== DICOMDIR_NAME
      );
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          // `file` method is an async callback when using drag-n-drop only
          new Promise((resolveFile: (...args: any[]) => any) => {
            fileEntry.file((file: File) => {
              let valid = true;
              if (onlyDcmFiles) {
                valid = minimatch(
                  getFileNameFromPartialPath(file.name.toLowerCase()),
                  DICOM_FILE_EXTENTIONS_GLOB
                );
              }
              const exists = fileEntries.find(
                (entry: FileState) =>
                  entry.name === file.name && entry.path === (file as any).webkitRelativePath
              );
              if (valid && !exists) {
                fileEntries.push({
                  icon: "pending",
                  name: file.name,
                  size: file.size,
                  lastModified: file.lastModified,
                  path: (file as any).webkitRelativePath
                });
              }
              resolveFile();
            });
          });
        }
      }
      resolveValidation(fileEntries);
    }).finally(() =>
      this.eventAggregator.emit(
        new UploadProgressEvent({ progress: null, status: "" }),
        "FileUploadService"
      )
    );
  };

  uploadFolderWithProgress = (
    files: File[],
    createUploadURL: string,
    queueUploadURLTemplate: (template: string) => string
  ) => {
    this.eventAggregator.emit(
      new UploadProgressEvent({ progress: 1, status: "Preparing..." }),
      "FileUploadService"
    );
    return this.createUpload(files, createUploadURL).pipe(
      switchMap((result: ICreateDicomImagesUploadResponse) => {
        return from([...this.sendUploads(files, result.filenames, result)]).pipe(
          mergeMap(calls => calls, MAX_CONCURRENT_REQUESTS),
          reduce(acc => acc, result)
        );
      }),
      concatMap((result: ICreateDicomImagesUploadResponse) =>
        this.queueUpload(queueUploadURLTemplate(result.uploadId))
      ),
      tap(result => {
        this.uploadCompleted = result;
      }),
      finalize(() => {
        if (this.uploadCompleted) {
          const dummyFile = { fieldName: "dummyFile" } as IFile;
          const dummyUpload = { file: dummyFile, metadata: null } as IFileUpload;
          this.eventAggregator.emit(new UploadCompletedEvent(dummyUpload), "FileUploadService");
        }
      })
    );
  };

  private createUpload = (files: File[], createURL: string) => {
    const dicomdirFile = files.find(
      (file: File) => getFileNameFromPartialPath(file.name) === DICOMDIR_NAME
    );
    if (dicomdirFile) {
      return of(dicomdirFile).pipe(
        switchMap((file: File) => {
          const formData = new FormData();
          formData.append("file", file, file.name);
          return this.httpClient.post<ICreateDicomImagesUploadResponse>(createURL, formData);
        })
      );
    } else {
      return this.httpClient.post<ICreateDicomImagesUploadResponse>(createURL, null);
    }
  };

  private sendUploads = (
    files: File[],
    globs: string[],
    uploadConfig: ICreateDicomImagesUploadResponse
  ) => {
    const totalProgress = files.length;
    return files.map((file: File, index: number) => {
      return of(file).pipe(
        switchMap((file: File) => {
          const progress = Math.round((100 * index) / totalProgress);
          const iFile = {
            fieldName: file.name,
            webkitRelativePath: (file as any).webkitRelativePath
          } as IFile;
          const iFileUpload = { file: iFile, metadata: null } as IFileUpload;
          const found = globs.some((glob: string) => {
            const globFileName = getFileNameFromPartialPath(glob);
            const fileName = getFileNameFromPartialPath(file.name);
            const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
            const dcmFileWithoutExtension =
              extension.toLowerCase() === "dcm" ? removeFileExtension(fileName) : fileName;
            return (
              minimatch(fileName, globFileName, {
                nocase: true
              }) ||
              minimatch(dcmFileWithoutExtension, globFileName, {
                nocase: true
              })
            );
          });
          if (!found) {
            this.eventAggregator.emit(new UploadFileFailEvent(iFileUpload), "FileUploadService");
            return EMPTY;
          }
          return this.blobStorage.uploadAmbraFile(uploadConfig, file).pipe(
            tap(() => {
              this.eventAggregator.emit(
                new UploadProgressEvent({ progress, status: `Uploading ${file.name}...` }),
                "FileUploadService"
              );
            }),
            catchError(() => {
              return of(false);
            }),
            finalize(() => {
              this.eventAggregator.emit(
                new UploadFileCompleteEvent(iFileUpload),
                "FileUploadService"
              );
            })
          );
        })
      );
    });
  };

  private queueUpload = (queueURL: string) => {
    return this.httpClient.post<any>(queueURL, null);
  };

  getAllFilesFromSystem(droppedFiles: NgxFileDropEntry[]): Promise<File[]> {
    const filesPromises = droppedFiles.map(async entry => {
      const file = await this.getFileFromSystem(entry);
      return file;
    });
    return Promise.all(filesPromises);
  }

  getFileFromSystem(droppedFile: NgxFileDropEntry): Promise<File> {
    // The `file` method is an async callback when using drag-n-drop DataTransfer API
    // Converting to Promise makes sure the file is retrieved in time to upload
    return new Promise<File>((resolve, reject) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          resolve(file);
        });
      }
      reject("Not a file");
    });
  }
}
