import { Injectable } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { patch, StateOperator } from "@ngxs/store/operators";

import {
  CaseType,
  defaultDisplayTags,
  Department,
  Group,
  IEvent,
  Organization,
  Role,
  User
} from "@vp/models";
import { deeperCopy } from "@vp/shared/utilities";

import * as ApplicationStateActions from "./application-state.actions";

export type Alert = {
  id: string;
  title: string;
  message: string;
  startDate: Date;
  duration: number;
  userTypeFriendlyId: string;
  roles: string[];
};

export type ApplicationStateModel = {
  caseTypes: CaseType[];
  groups: Group[] | null;
  user: User | null;
  userDepartments: Department[];
  userRoles: Role[];
  organization: Organization | null;
  displayTags: Record<string, string>;
  schedules: any;
  redirectRoute: string | undefined | null;
  event: IEvent | null;
  isBrowserOnline: boolean;
  alerts: Alert[];
};

@State<ApplicationStateModel>({
  name: "applicationState",
  defaults: {
    caseTypes: [],
    groups: [],
    user: null,
    userDepartments: [],
    userRoles: [],
    organization: null,
    displayTags: defaultDisplayTags,
    schedules: null,
    redirectRoute: null,
    event: null,
    isBrowserOnline: false,
    alerts: []
  }
})
@Injectable()
export class ApplicationState implements NgxsAfterBootstrap {
  @Selector([ApplicationState.getDisplayTags])
  public static displayTags(displayTags: Record<string, string>) {
    return displayTags;
  }

  @Selector()
  public static getDisplayTags(state: ApplicationStateModel) {
    return deeperCopy(state.displayTags);
  }

  @Selector()
  public static getAlerts(state: ApplicationStateModel) {
    return state.alerts;
  }

  @Selector([ApplicationState.getAlerts])
  public static alerts(alerts: Alert[]) {
    return alerts;
  }

  @Selector()
  public static getEvent(state: ApplicationStateModel) {
    return state.event;
  }

  @Selector([ApplicationState.getEvent])
  public static event(event: any[]) {
    return event;
  }

  @Selector()
  public static getisBrowserOnline(state: ApplicationStateModel): boolean {
    return state.isBrowserOnline;
  }

  @Selector([ApplicationState.getisBrowserOnline])
  public static isBrowserOnline(isBrowserOnline: boolean): boolean {
    return isBrowserOnline;
  }

  @Selector()
  public static getLoggedInUser(state: ApplicationStateModel) {
    return state.user;
  }

  @Selector([ApplicationState.getLoggedInUser])
  public static loggedInUser(user: User | null): User | null {
    return user ? deeperCopy(user) : null;
  }

  @Selector()
  public static redirectRoute(state: ApplicationStateModel) {
    return state.redirectRoute;
  }

  @Selector()
  public static userRoles(state: ApplicationStateModel) {
    return state.userRoles;
  }

  @Selector()
  public static selectedRole(state: ApplicationStateModel) {
    return state.userRoles.find(role => role.roleId == state.user?.selectedRoleId) ?? null;
  }

  @Action(ApplicationStateActions.AddAlert)
  addAlert(ctx: StateContext<ApplicationStateModel>, action: ApplicationStateActions.AddAlert) {
    const alerts = Array.isArray(action.alert) ? action.alert : [action.alert];
    ctx.patchState({ alerts: [...ctx.getState().alerts, ...alerts] });
  }

  @Action(ApplicationStateActions.EmitEvent)
  emitEvent(
    ctx: StateContext<ApplicationStateModel>,
    action: ApplicationStateActions.EmitEvent
  ): void {
    action.event.sender = action.sender;
    ctx.patchState({ event: action.event });
  }

  @Action(ApplicationStateActions.EventHandled)
  eventHandled(
    ctx: StateContext<ApplicationStateModel>,
    action: ApplicationStateActions.EventHandled
  ): void {
    const event = ctx.getState().event;

    if (event?.eventId === action.event.eventId) {
      const updatedEvent: IEvent = {
        ...event,
        handled: true
      };

      ctx.setState({
        ...ctx.getState(),
        event: updatedEvent
      });
    }
  }

  @Action(ApplicationStateActions.PatchState)
  patchState(ctx: StateContext<ApplicationStateModel>, action: ApplicationStateActions.PatchState) {
    ctx.setState(patch<ApplicationStateModel>({ ...action.partialState }));
  }

  @Action(ApplicationStateActions.AcknowledgeAlert)
  patchUserData(
    ctx: StateContext<ApplicationStateModel>,
    action: ApplicationStateActions.AcknowledgeAlert
  ) {
    const user = ctx.getState()?.user;
    if (user) {
      const acknowledgedAlerts = user.userData?.acknowledgedAlerts || [];
      const updatedAlerts = [...acknowledgedAlerts].concat(action.alertId);

      ctx.setState(
        patch({
          user: patch<User>({
            userData: patch({
              acknowledgedAlerts: updatedAlerts
            })
          }) as StateOperator<User | null>
        })
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngxsAfterBootstrap(_state: StateContext<ApplicationStateModel> | undefined): void {
    console.log("Application State Loaded!");
  }
}
