<div flexLayout="row wrap" flexLayoutAlign="start center">
  <div flex="20" mat-dialog-title>{{ data.title }}</div>
</div>
<mat-dialog-content>
  <ng-container
    [ngTemplateOutlet]="template"
    [ngTemplateOutletContext]="{
      selectedContent: (selectedContent$ | async) ?? null,
      contents: (contents$ | async) ?? []
    }"
  >
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions flexLayoutAlign="end center">
  <div>
    <button type="button" mat-flat-button color="primary" (click)="onCancel()">
      <span>Close</span>
    </button>
  </div>
</mat-dialog-actions>

<ng-template vpDynamicTemplate="loading">
  <div class="no-content" flexFill flexLayout="column" flexLayoutAlign="center center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <div>Loading...</div>
  </div>
</ng-template>

<ng-template
  vpDynamicTemplate="results"
  let-contents="contents"
  let-selectedContent="selectedContent"
  let-title="title"
>
  <div
    *ngIf="contents.length === 0"
    class="no-content"
    flexLayout="column"
    flexLayoutAlign="center center"
  >
    No contents available for this case.
  </div>

  <mat-drawer-container *ngIf="contents.length > 0" [class.no-drawer]="contents.length === 1">
    <mat-drawer mode="side" [opened]="contents.length > 1">
      <div flexLayout="column" flexLayoutAlign="start stretch">
        <div
          flexLayout="row"
          flexLayoutAlign="start center"
          *ngFor="let content of contents; trackBy: trackByContentId"
          class="menu-item"
          [class.active]="isContentSelected | memoize: selectedContent:content"
          (click)="onContentClicked(content)"
        >
          {{ content.displayName }}
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div [innerHTML]="selectedContent?.content" class="response-rendered extra-space"></div>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-template>
