import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from "@ngx-formly/core";
import { mergeDeep } from "@vp/shared/utilities";

@Component({
  selector: "lib-formly-object-type",
  templateUrl: "./formly-object-type.component.html",
  styleUrls: ["./formly-object-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyObjectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  flexProps: any = {};

  flexDefaults = {
    flex: "100",
    flexLayout: "column",
    flexLayoutAlign: "start stretch",
    flexLayoutGap: "16px grid"
  };

  ngOnInit(): void {
    this.flexProps = mergeDeep(this.flexDefaults, this.props, "replace");
  }

  trackById = (index: number, item: FormlyFieldConfig) => {
    return item.id ?? index;
  };
}
