import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { ApplicationState } from "@vp/data-access/application";
import { DisplayTag } from "@vp/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class UiDisplayTagService {
  @Select(ApplicationState.displayTags) displayTags$!: Observable<Record<string, string>>;

  public displayTags: Record<string, string> = {};
  constructor(public store: Store) {}

  overrideDefaultDisplayTags(organizationDisplayTags: DisplayTag[]) {
    const defaults = this.store.selectSnapshot(ApplicationState.displayTags);

    organizationDisplayTags.forEach(displayTag => {
      defaults[displayTag.tag] = displayTag.display;
    });
    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        displayTags: defaults
      })
    );
    this.displayTags = defaults;
  }
}
