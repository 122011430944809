import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MtxCalendarView,
  MtxDatetimepickerMode,
  MtxDatetimepickerType
} from "@ng-matero/extensions/datetimepicker";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "lib-time-picker-type",
  templateUrl: "./time-picker-type.component.html",
  styleUrls: ["./time-picker-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerTypeComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit, OnDestroy
{
  private readonly _destroyed$ = new Subject<void>();

  type: MtxDatetimepickerType = "datetime";
  mode: MtxDatetimepickerMode = "auto";
  startView: MtxCalendarView = "clock";
  multiYearSelector = false;
  touchUi = false;
  twelvehour = false;
  timeInterval = 1;
  timeInput = true;
  datetime = new UntypedFormControl();
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap((formValues: Record<string, unknown>) => {
          if (
            Object.prototype.hasOwnProperty.call(this.props, "defaultValueProperty") &&
            Object.prototype.hasOwnProperty.call(formValues, this.props["defaultValueProperty"])
          ) {
            const date = formValues[this.props["defaultValueProperty"]];
            this.formControl.setValue(date, { emitEvent: false });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  timeChangeHandler(time: Date) {
    this.formControl.setValue(time);
  }
}
