import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { TagsState } from "@vp/data-access/tags";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { Tag } from "@vp/models";
import { of } from "rxjs";

@Injectable()
export class CountryTagFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  // TODO: the logic in here is kinda weird. Consider refactoring.
  provide(state: Record<string, unknown>) {
    if (Object.keys(state).length === 0 || !state.clientTagTypeFriendlyId) return of({});

    const loggedInUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
    const tags = this.store.selectSnapshot(TagsState.tags);

    const clientTag = tags
      .filter(t => t.tagTypeFriendlyId === state.clientTagTypeFriendlyId)
      .find(c => loggedInUser?.assignedTags.includes(c.tagId));

    let countryTag: Tag | undefined;
    if (clientTag) {
      const countryTagId = clientTag.tagData?.serviceDelivered;
      countryTag = tags.find(t => t.tagId === countryTagId);
    }

    return of(countryTag ? { country: countryTag } : {});
  }
}
