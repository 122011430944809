import { Pipe, PipeTransform } from "@angular/core";
import { UserApiService } from "@vp/data-access/users";
import { User } from "@vp/models";
import { getValueForPath } from "@vp/shared/utilities";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Pipe({
  name: "userName"
})
export class UserNamePipe implements PipeTransform {
  constructor(private userApiService: UserApiService) {}

  transform(userId: string, paths?: string[] | undefined): Observable<string> {
    if (userId != null) {
      return this.userApiService.getUser(userId, true).pipe(
        map((user: User | null) => {
          if (user && !paths) {
            return defaultUserFormatting(user);
          } else if (paths && paths.length > 0) {
            return paths
              .map(path => getValueForPath(user, path) || `[value not found for path ${path}]`)
              .join(" ");
          }

          return `[user ${userId} not found]`;
        }),
        catchError(() => {
          return of(`[user ${userId} not found]`);
        })
      );
    }
    return of("[user not found]");
  }
}

const defaultUserFormatting = (user: User) => {
  if (!user.profile.firstName && !user.profile.lastName) {
    return user.email;
  }
  if (user.profile && user.profile.salutation) {
    user.profile.salutation = user.profile.salutation.trim();
  }
  if (user.profile && user.profile.suffix) {
    user.profile.suffix = user.profile.suffix.trim();
  }
  if (user.profile.salutation && user.profile.suffix) {
    return `${user.profile.salutation} ${user.profile.firstName} ${user.profile.lastName}, ${user.profile.suffix}`;
  } else if (user.profile.salutation) {
    return `${user.profile.salutation} ${user.profile.firstName} ${user.profile.lastName}`;
  } else if (user.profile.suffix) {
    return `${user.profile.firstName} ${user.profile.lastName}, ${user.profile.suffix}`;
  } else {
    return `${user.profile.firstName} ${user.profile.lastName}`;
  }
};
