import { Pipe, PipeTransform } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";

/**
 * This is an alternatives for *ngxPermissionsOnly, used in Single page view to solve the issue
 * with content jumping.
 * *ngxPermissionsOnly will get called everytime when the data context is updated, causing
 * unexpected content jumping behavior when the component gets destroyed and recreated.
 */
@Pipe({
  name: "hasPermission"
})
export class HasPermissionPipe implements PipeTransform {
  public constructor(private permissionService: NgxPermissionsService) {}

  transform(permissions: string | string[]) {
    return this.permissionService.hasPermission(permissions);
  }
}
