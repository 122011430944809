import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  TrackByFunction
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import * as UserStateActions from "@vp/data-access/users";
import { OrganizationFeatures, RealTimeNotification, Role } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { FeatureService } from "@vp/shared/features";
import { IS_IVY_API } from "@vp/shared/guards";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import {
  SignalRConnectionState,
  SignalREventService,
  SignalRState
} from "@vp/shared/signal-r-service";
import { AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { RouterUtilities } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";

@Component({
  selector: "vp-top-nav-user",
  templateUrl: "./top-nav-user.component.html",
  styleUrls: ["./top-nav-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavUserComponent implements OnDestroy {
  @Select(SignalRState.hubConnection) hubConnection$!: Observable<Partial<SignalRConnectionState>>;
  @Select(SignalRState.notifications) notifications$!: Observable<RealTimeNotification[]>;

  logoRedirectLink: string | undefined;

  signalREnabled$ = this.featureService.featureEnabled$(OrganizationFeatures.signalR);

  @Output() menuSetup: EventEmitter<any> = new EventEmitter();

  public OrganizationFeatures = OrganizationFeatures;

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    @Inject(IS_IVY_API) public readonly isIvyApi: boolean,
    public readonly authenticationService: AuthenticationService,
    public readonly appStoreService: AppStoreService,
    public readonly _PermissionsConstService: PermissionsConstService,
    public readonly permConst: PermissionsConstService,
    public readonly uiDisplayTagService: UiDisplayTagService,
    private readonly featureService: FeatureService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly routerUtilitiesService: RouterUtilities,
    private readonly store: Store,
    private readonly signalREventService: SignalREventService
  ) {}

  currentRoute$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => (<NavigationEnd>event).url)
  );

  featureEnabled$ = this.featureService.featureEnabled$(OrganizationFeatures.homePage);

  userDisplayName$ = this.appStoreService.userFullName$.pipe(
    startWith("Loading..."),
    filterNullMap(),
    takeUntil(this._destroyed$)
  );

  selectedRoleDisplayName$ = this.appStoreService.selectedRoleDisplayName$.pipe(
    startWith("Loading..."),
    filterNullMap(),
    takeUntil(this._destroyed$)
  );

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  onLogout = () => {
    /*
      authenticationService.logout() does a hard redirect and does not wait for any async operations to complete.
      This will leave signal-r subscriptions subcribed at the server. The stopListening method will clear out
      all signal-r notifications from the state, and wait for it emit an empty array before logging out.
    */
    this.signalREventService
      .stopListening()
      .pipe(
        take(1),
        switchMap(() => this.notifications$),
        filter(notifications => notifications.length === 0)
      )
      .subscribe(() => {
        this.authenticationService.logout();
      });
  };

  onLogin = () => {
    this._isLoading$.next(true);
    this.authenticationService.login();
  };

  switchUserRole(roleId: string) {
    this.appStoreService
      .updateUserRole(roleId)
      .pipe(
        switchMap(() => this.appStoreService.selectedRole),
        withLatestFrom(this.featureEnabled$),
        tap(([role, homeFeature]: [Role, boolean]) => {
          const { path, queryParams } = this.routerUtilitiesService.getRouteDefaultFromRole(role);
          if (homeFeature) {
            this.router.navigate(["/home"]).then(() => window.location.reload());
          } else {
            this.router.navigate([path], { queryParams }).then(() => window.location.reload());
          }
          this.store.dispatch(new UserStateActions.SetCurrentUserRole(role.roleId));
          this.menuSetup.emit();
        }),
        switchMap(() => this.signalREventService.clearSubscriptions()),
        take(1)
      )
      .subscribe({
        next: () => this.notificationService.successMessage("Switched role and app updated"),
        error: () => this.notificationService.errorMessage("Cannot switch role")
      });
  }

  copySignalrLogs = (): void => {
    this.hubConnection$.pipe(take(1)).subscribe(hub => {
      let logs =
        "SignalR Status: " +
        hub.state +
        "\nConnection Last Updated: " +
        hub.lastUpdated?.toLocaleString() +
        "\nConnectionId: " +
        hub.connectionId +
        "\n\nEvents:\n";

      if (hub.receivedEvents) {
        let events =
          hub.receivedEvents
            .map(e => `${e.eventTime.toLocaleString()}: ${e.method}\n${e.data}\n`)
            .join("\n") ?? [];

        if (events.length === 0) {
          events = "No events received";
        }

        logs = logs.concat(events);
      }

      navigator.clipboard.writeText(logs);
    });
  };

  // Satisfies template-use-track-by-function
  trackByFn: TrackByFunction<Role> = (_index: number, item: Role) => item.roleId;
}
