import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Message, MessageType } from "@vp/models";
import { EMPTY } from "rxjs";
import { tap } from "rxjs/operators";
import { MessageApiService } from "../api/message-api.service";
import * as MessageActions from "./messages.action";

export const defaultState = {
  pacMessages: [],
  tigerConnectMessages: []
};

export interface MessagesStateModel {
  pacMessages: Message[];
  tigerConnectMessages: Message[];
}

@State<MessagesStateModel>({
  name: "messages",
  defaults: defaultState
})
@Injectable()
export class MessagesState {
  constructor(private api: MessageApiService) {}

  @Selector()
  public static getPacMessages(state: MessagesStateModel) {
    return state.pacMessages;
  }

  @Selector([MessagesState.getPacMessages])
  public static pacMessages(pacMessages: Message[]) {
    return pacMessages;
  }

  @Selector([MessagesState.pacMessages])
  public static getDevicePacMessagesFn(pacMessages: Message[]) {
    return (deviceId: string) => pacMessages.filter(m => m.recipient === deviceId);
  }

  @Selector()
  public static getTigerConnectMessages(state: MessagesStateModel) {
    return state.tigerConnectMessages;
  }

  @Selector([MessagesState.getTigerConnectMessages])
  public static tigerConnectMessages(tigerConnectMessages: Message[]) {
    return tigerConnectMessages;
  }

  @Action(MessageActions.ResetState)
  resetState(ctx: StateContext<MessagesStateModel>) {
    ctx.setState(defaultState);
  }

  @Action(MessageActions.GetPacMessages)
  getPacMessages(
    ctx: StateContext<MessagesStateModel>,
    { deviceIds }: MessageActions.GetPacMessages
  ) {
    const otherPacMessages = ctx
      .getState()
      .pacMessages.filter(m => !deviceIds.includes(m.recipient));
    return this.api.getMessages({ messageType: MessageType.PAC, deviceIds }).pipe(
      tap(messages => {
        ctx.setState(
          patch<MessagesStateModel>({ pacMessages: [...otherPacMessages, ...messages] })
        );
      })
    );
  }

  @Action(MessageActions.SendMessages)
  sendMessages(
    ctx: StateContext<MessagesStateModel>,
    { messages, messageType, recipient, caseId }: MessageActions.SendMessages
  ) {
    return this.api.sendMessages(messages, messageType, recipient, caseId).pipe(
      tap(() => {
        if (messageType === "PAC") {
          ctx.dispatch(new MessageActions.GetPacMessages([recipient]));
        }
      })
    );
  }

  @Action(MessageActions.ClearPacMessages)
  clearPacMessages(
    ctx: StateContext<MessagesStateModel>,
    { deviceId, messageTarget }: MessageActions.ClearPacMessages
  ) {
    const messageIds = ctx
      .getState()
      .pacMessages.filter(
        m =>
          m.recipient === deviceId &&
          m.messageId &&
          m.additionalData?.messageTarget === messageTarget
      )
      .map(m => m.messageId);
    if (messageIds.length) {
      return this.api.deleteMessages(messageIds, MessageType.PAC, deviceId).pipe(
        tap(() => {
          ctx.dispatch(new MessageActions.GetPacMessages([deviceId]));
        })
      );
    }
    return EMPTY;
  }
}
