import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MemoizePipe } from "./memoize.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [MemoizePipe],
  exports: [MemoizePipe]
})
export class MemoizePipeModule {}
