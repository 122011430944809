import { Component, Input, OnDestroy } from "@angular/core";
import { filterNullMap } from "@vp/shared/operators";
import { interval, Observable, of, Subject } from "rxjs";
import { debounce, delay, filter, map, mergeMap, startWith, takeUntil } from "rxjs/operators";
import { HttpProgressState } from "..";
import { IHttpState } from "./IHttpState";
import { HttpStateService } from "./service/http-state.service";

@Component({
  selector: "vp-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent implements OnDestroy {
  @Input() public diameter = 70;
  @Input() public forApiUrl: string | null = null;
  @Input() public wait = 1000;
  @Input() overlay = true;

  constructor(private httpStateService: HttpStateService) {}

  private destroyed$ = new Subject<void>();

  public loading: Observable<boolean> = this.httpStateService.state.pipe(
    startWith(null),
    delay(0),
    filterNullMap(),
    filter((httpState: IHttpState) =>
      this.forApiUrl !== null ? httpState.url.indexOf(this.forApiUrl) > -1 : true
    ),
    map((progress: IHttpState) => (progress.state === HttpProgressState.start ? true : false)),
    mergeMap((loading: boolean) => of(loading).pipe(debounce(() => interval(this.wait)))),
    takeUntil(this.destroyed$)
  );

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
