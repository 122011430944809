import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe maps an array of objects to a array of strings containing the specificed member
 * this is useful for matHeaderRowDef directives when colums are an object and not an
 * array of strings.
 *
 * For Example:
 *    <mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></mat-header-row>
 */
@Pipe({
  name: "mapToField"
})
export class MapFieldPipe implements PipeTransform {
  transform(value: any[], member: string): string[] {
    return value.map(c => c[member]);
  }
}
