import { Injectable } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, updateItem } from "@ngxs/store/operators";
import { ContentData, ContentSearch, PageResult, PageState } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { deeperCopy } from "@vp/shared/utilities";
import { map, switchMap, take } from "rxjs/operators";
import { ContentApiService } from "../api/content-api-service";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";
import * as ContentFilterStateActions from "./content-filter-state.actions";

export type ContentFilterStateModel = {
  allContents: ContentData[];
  contentData: ContentData | null;
  workingCopy: ContentData | null;
  filter: Partial<ContentDataFilter>;
  pendingOperations: ContentOperations | null;
  pageState: Partial<PageState>;
  results: ContentSearch[];
  errors: string[];
};
const DEFAULT_PAGE_SIZE = 10;
export const defaultContentState = (): ContentFilterStateModel => {
  return {
    filter: {
      take: DEFAULT_PAGE_SIZE,
      skip: 0,
      contentTypeId: null,
      contentId: null
    },
    pageState: {
      totalRecords: 0,
      pageIndex: 0,
      pageCount: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      lastPage: 1
    },
    allContents: [],
    results: [],
    errors: [],
    workingCopy: null,
    contentData: null,
    pendingOperations: null
  };
};

@State<ContentFilterStateModel>({
  name: "contentFilter",
  defaults: defaultContentState()
})
@Injectable()
export class ContentFilterState implements NgxsAfterBootstrap {
  constructor(
    private contentApiService: ContentApiService,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  static getAllContents(state: ContentFilterStateModel): ContentData[] {
    return state.allContents;
  }

  @Selector([ContentFilterState.getAllContents])
  static allContents(allContents: ContentData[]): ContentData[] {
    return allContents;
  }

  @Selector()
  static getWorkingCopy(state: ContentFilterStateModel): ContentData | null {
    return state.workingCopy;
  }

  @Selector([ContentFilterState.getWorkingCopy])
  static workingCopy(workingCopy: ContentData | null): ContentData | null {
    return workingCopy;
  }

  @Selector()
  static getContentData(state: ContentFilterStateModel): ContentData | null {
    return state.contentData;
  }

  @Selector([ContentFilterState.getContentData])
  static contentData(contentData: ContentData | null): ContentData | null {
    return contentData;
  }

  @Selector()
  static getPendingOperations(state: ContentFilterStateModel): ContentOperations | null {
    return state.pendingOperations;
  }

  @Selector([ContentFilterState.getPendingOperations])
  static pendingOperations(pendingOperations: ContentOperations | null): ContentOperations | null {
    return pendingOperations;
  }

  @Selector()
  public static getResults(state: ContentFilterStateModel): ContentSearch[] {
    return state.results;
  }

  @Selector([ContentFilterState.getResults])
  public static results(results: ContentSearch[]): ContentSearch[] {
    return results;
  }

  @Selector()
  static getCurrentFilter(state: ContentFilterStateModel): Partial<ContentDataFilter> {
    return state.filter;
  }

  @Selector([ContentFilterState.getCurrentFilter])
  static currentFilter(contentDataFilter: Partial<ContentDataFilter>): Partial<ContentDataFilter> {
    return contentDataFilter;
  }

  @Selector()
  static getPageState(state: ContentFilterStateModel): Partial<PageState> {
    return state.pageState;
  }

  @Selector([ContentFilterState.getPageState])
  static pageState(pageState: Partial<PageState>): Partial<PageState> {
    return pageState;
  }

  ngxsAfterBootstrap(ctx: StateContext<ContentFilterStateModel>): void {
    this.authenticationService
      .isLoggedIn$()
      .pipe(take(1))
      .subscribe(authenticatedResult => {
        if (authenticatedResult.isAuthenticated) {
          ctx.dispatch(new ContentFilterStateActions.LoadContents());
        }
      });
  }

  @Action(ContentFilterStateActions.LoadContents)
  loadContents(ctx: StateContext<ContentFilterStateModel>) {
    this.contentApiService.getAllContents().subscribe((contents: ContentData[]) => {
      ctx.setState(
        patch<ContentFilterStateModel>({
          allContents: contents ?? []
        })
      );
    });
  }

  @Action(ContentFilterStateActions.SetFilter)
  setFilter(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.SetFilter
  ) {
    ctx.setState(
      patch<ContentFilterStateModel>({
        filter: action.filter
      })
    );
  }

  @Action(ContentFilterStateActions.LoadContentData)
  loadContent(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.LoadContentData
  ) {
    const contentData: ContentData = deeperCopy(action.contentData);
    ctx.setState(
      patch<ContentFilterStateModel>({
        workingCopy: contentData,
        contentData: contentData
      })
    );
  }

  @Action(ContentFilterStateActions.SetWorkingCopy)
  setWorkingCopy(
    ctx: StateContext<ContentFilterStateModel>,
    workingCopy: ContentFilterStateActions.SetWorkingCopy
  ) {
    ctx.patchState({
      workingCopy: workingCopy.contentData
    });
  }

  @Action(ContentFilterStateActions.SetContentData)
  setContentData(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.SetContentData
  ) {
    ctx.patchState({
      contentData: action.contentData
    });
  }

  @Action(ContentFilterStateActions.SetPendingOperations)
  setPendingOperations(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.SetPendingOperations
  ) {
    ctx.setState(
      patch<ContentFilterStateModel>({
        pendingOperations: action.contentOperations
      })
    );
  }

  @Action(ContentFilterStateActions.GetFiltered)
  getFiltered(ctx: StateContext<ContentFilterStateModel>) {
    const state: ContentFilterStateModel = ctx.getState();
    const pageSize = state.pageState.pageSize ?? DEFAULT_PAGE_SIZE;
    this.contentApiService
      .filteredContents(state.filter)
      .subscribe((pageResults: PageResult<ContentSearch>) => {
        ctx.setState(
          patch({
            results: pageResults.results ?? [],
            pageState: patch({
              pageIndex: state.pageState.pageIndex,
              totalRecords: pageResults.totalRecords,
              pageCount: pageSize > 0 ? (pageResults.totalRecords + pageSize - 1) / pageSize : 1,
              lastPage: pageSize > 0 ? pageResults.totalRecords / pageSize : 1
            })
          })
        );
      });
  }

  @Action(ContentFilterStateActions.AddContent)
  addContent(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.AddContent
  ) {
    this.contentApiService.createContent(action.content).subscribe(contentData => {
      ctx.setState(
        patch<ContentFilterStateModel>({
          allContents: append([contentData])
        })
      );
    });
  }

  @Action(ContentFilterStateActions.CommitOperations)
  commitOperations(ctx: StateContext<ContentFilterStateModel>) {
    const currentState = ctx.getState();
    const pendingOperations = currentState.pendingOperations;

    if (
      currentState?.contentData === null ||
      pendingOperations === null ||
      pendingOperations.operations.length === 0
    )
      return;

    this.contentApiService
      .patch(pendingOperations.contentId, pendingOperations.operations)
      .pipe(
        map(() => pendingOperations.contentId),
        switchMap(contentId => this.contentApiService.getContent(contentId))
      )
      .subscribe((contentData: ContentData) => {
        ctx.setState(
          patch<ContentFilterStateModel>({
            contentData: contentData,
            allContents: updateItem<ContentData>(
              c => c?.contentId === contentData.contentId,
              contentData
            )
          })
        );
      });
  }
}
