import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { PageResult, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { HttpResponseCache } from "@vp/shared/services/cache";
import { createUrlBuilder, mapToPageResult, withHeaderData } from "@vp/shared/utilities";
import { Operation } from "rfc6902";
import { Observable, forkJoin, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { UserFilter } from "../models/user-filter";

export const USER_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class UserApiService {
  constructor(
    @Inject(USER_API_BASE_URL) private _apiBaseUrl: string,
    private _http: HttpClient,
    private userResponseCache: HttpResponseCache<User>,
    private usersResponseCache: HttpResponseCache<User[]>
  ) {}

  public getUser = (
    userId: string,
    useCache: boolean = false,
    ageMs: number = 10000
  ): Observable<User | null> => {
    const apiURL = `${this._apiBaseUrl}/user/${userId}`;
    if (userId?.length === 0) {
      return throwError("User Id is required");
    }
    if (useCache) {
      return this.userResponseCache.getCachedData(userId, "user", ageMs, apiURL).pipe(
        map((httpResponse: HttpResponse<User>) => {
          if (httpResponse.status === 200) {
            return httpResponse.body;
          }
          return null;
        })
      );
    }
    return this._http.get<User>(apiURL);
  };

  public getUserLogin = (): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/user/get/login`;
    return this._http.get<User>(apiURL);
  };

  /**@Deprecated TODO: Create endpoint*/
  public getUsersList = (userIds: string[]): Observable<User[]> => {
    const uniqueIds = new Set();
    const result = userIds.filter(o => {
      if (uniqueIds.has(o)) return false;
      uniqueIds.add(o);
      return true;
    });

    if (result.length === 0) {
      return throwError("At least one User Id is required");
    }

    return forkJoin(result.map(userId => this.getUser(userId).pipe(filterNullMap())));
  };

  public getUsers = (
    filter: Readonly<Partial<UserFilter>>,
    useCache: boolean = false,
    ageMs: number = 10000
  ): Observable<User[]> => {
    const apiURL = `${this._apiBaseUrl}/user`;
    const urlBuilder = createUrlBuilder(apiURL, Object.keys(filter));
    const urlWithQueryString = urlBuilder.build({}, filter);

    if (useCache) {
      return this.usersResponseCache
        .getCachedData(urlWithQueryString, "users", ageMs, urlWithQueryString)
        .pipe(map((response: HttpResponse<User[]>) => response.body ?? []));
    }

    return this._http.get<User[]>(urlWithQueryString).pipe(map((users: User[]) => users ?? []));
  };

  public getUsersPageResult = (
    filter: Readonly<Partial<UserFilter>>,
    useCache: boolean = false,
    ageMs: number = 10000
  ): Observable<PageResult<User>> => {
    const apiURL = `${this._apiBaseUrl}/user`;
    const urlBuilder = createUrlBuilder(apiURL, Object.keys(filter));
    const urlWithQueryString = urlBuilder.build({}, filter);

    if (useCache) {
      return this.usersResponseCache
        .getCachedData(urlWithQueryString, "users", ageMs, urlWithQueryString)
        .pipe(withHeaderData(), mapToPageResult<User>());
    }
    return this._http
      .get<User[]>(urlWithQueryString, { observe: "response" })
      .pipe(withHeaderData(), mapToPageResult<User>());
  };

  public createUser = (user: User): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/user/`;
    return this._http.post<User>(apiURL, user);
  };

  public inviteUser = (user: User, resendInvite?: boolean): Observable<User> => {
    let apiURL = `${this._apiBaseUrl}/invite`;
    if (resendInvite) {
      apiURL += `?resendInvite=${resendInvite}`;
    }
    return this._http.post<User>(apiURL, user);
  };

  public updateUser = (user: User, resendVerificationCode?: boolean): Observable<User> => {
    let apiURL = `${this._apiBaseUrl}/user/${user.userId}`;
    if (resendVerificationCode) {
      apiURL += "?resend-code=true";
    }
    return this._http.put<User>(apiURL, user);
  };

  public updateDeviceUser = (device: User): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-user/${device.userId}`;
    return this._http.put<User>(apiURL, device);
  };

  public deleteDeviceUser = (deviceId: string): Observable<User> => {
    const apiURL = `${this._apiBaseUrl}/virtual-care/device-user/${deviceId}`;
    return this._http.delete<User>(apiURL);
  };

  public getAssignableUsers = (caseTypeId: string) => {
    const apiURL = `${this._apiBaseUrl}/users?caseTypeId=${caseTypeId}`;
    return this._http.get<User[]>(apiURL);
  };

  public getAssignableUsersForCase = (
    caseId: string,
    filter: Readonly<Partial<UserFilter>>
  ): Observable<User[]> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/assignableUsers`;
    const urlBuilder = createUrlBuilder(apiURL, Object.keys(filter));
    const urlWithQueryString = urlBuilder.build({}, filter);
    return this._http.get<User[]>(urlWithQueryString);
  };

  /**@Deprecated TODO: refactor to use user search endpoint */
  public getAssignableDevices = (
    caseId: string,
    filter: Readonly<Partial<UserFilter>>
  ): Observable<User[]> => {
    const apiURL = `${this._apiBaseUrl}/case/${caseId}/assignableDevices`;
    const urlBuilder = createUrlBuilder(apiURL, Object.keys(filter));
    const urlWithQueryString = urlBuilder.build({}, filter);
    return this._http.get<User[]>(urlWithQueryString);
  };

  public getGroupsAssignedToUsers = (groupIds: string[]): Observable<string[]> => {
    const apiURL = `${this._apiBaseUrl}/groups-assigned-to-users`;
    return this._http.post<string[]>(apiURL, groupIds);
  };

  public getTagsAssignedToUsers = (tagIds: string[]): Observable<string[]> => {
    const apiURL = `${this._apiBaseUrl}/tags-assigned-to-users`;
    return this._http.post<string[]>(apiURL, tagIds);
  };

  public patch = (userId: string, operations: Operation[], command: string | null = null) => {
    const apiURL = `${this._apiBaseUrl}/user/${userId}`;
    let params = new HttpParams();
    if (command) {
      params = params.append("command", String(command));
    }
    return this._http.patch(apiURL, operations, {
      params: params
    });
  };
}
