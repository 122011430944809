import { CommonModule } from "@angular/common";
import { InjectionToken, NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { GroupsState } from "./state+/groups-state";

export const GROUPS_API_BASE_URL = new InjectionToken<string>("GROUPS_API_BASE_URL");

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([GroupsState])]
})
export class GroupsDataAccessModule {}
