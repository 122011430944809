/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDatetimeAdapter } from "@ng-matero/extensions-moment-adapter";
import { DatetimeAdapter } from "@ng-matero/extensions/core";
import {
  MtxCalendarView,
  MtxDatetimepickerMode,
  MtxDatetimepickerType
} from "@ng-matero/extensions/datetimepicker";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import moment from "moment";
export enum DateDirection {
  Past = "past",
  Future = "future",
  Both = "both"
}

@Component({
  selector: "lib-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: DatetimeAdapter, useClass: MomentDatetimeAdapter }
  ]
})
export class DateTimePickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  maxDate!: Date | null;
  minDate!: Date | null;

  @ViewChild("picker") picker: any;

  type: MtxDatetimepickerType = "datetime";
  mode: MtxDatetimepickerMode = "auto";
  startView: MtxCalendarView = "month";
  multiYearSelector = false;
  touchUi = false;
  twelvehour = false;
  timeInterval = 1;
  timeInput = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.props.dateDirection === DateDirection.Past) {
      this.maxDate = new Date();
      this.minDate = null;
    } else if (this.props.dateDirection === DateDirection.Future) {
      this.maxDate = null;
      this.minDate = new Date();
    }
    this.formControl.valueChanges.subscribe(value => {
      if (typeof value === "object" && (value instanceof moment || value instanceof Date)) {
        const formattedDate = moment(value).format();
        this.formControl.setValue(formattedDate, { emitEvent: false });
      }
    });
  }
}
