import { EventBase } from "@vp/models";

export interface UnassignUserEventArgs {
  caseId: string;
  assignedUserId: string;
  message: string;
}

export class UnassignUserEvent extends EventBase<UnassignUserEventArgs> {
  constructor(eventArgs: UnassignUserEventArgs) {
    super();
    this.args = eventArgs;
  }
}
