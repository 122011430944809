import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthenticationService } from "@vp/shared/authentication";
import { AppNavService, NavigationItem } from "@vp/shared/top-nav/data-access/top-nav-state";
import { NgxPermissionsService } from "ngx-permissions";
import { NgxPermission } from "ngx-permissions/lib/model/permission.model";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Component({
  selector: "vp-top-nav-shell",
  templateUrl: "./top-nav-shell.component.html",
  styleUrls: ["./top-nav-shell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavShellComponent {
  menuOption: boolean | undefined;

  private _showExtendedMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showExtendedMenu: Observable<boolean> = this._showExtendedMenu$.asObservable();

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public appNavService: AppNavService,
    public readonly authenticationService: AuthenticationService,
    public ngxPermissionsService: NgxPermissionsService,
    public breakpointObserver: BreakpointObserver
  ) {}

  screenSize$ = this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ])
    .pipe(
      map((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XLarge] || state.breakpoints[Breakpoints.Large]) {
          return ScreenSize.Desktop;
        } else if (state.breakpoints[Breakpoints.Medium]) {
          return ScreenSize.MediumDevice;
        } else if (state.breakpoints[Breakpoints.Small]) {
          return ScreenSize.Tablet;
        } else if (state.breakpoints[Breakpoints.XSmall]) {
          return ScreenSize.Mobile;
        }
        return ScreenSize.Desktop;
      })
    );

  menuItems$ = combineLatest([
    this.appNavService.menuItems,
    this.ngxPermissionsService.permissions$.pipe(
      filter(permissions => Object.keys(permissions).length > 0),
      map(permissions =>
        Object.values(permissions).map((permission: NgxPermission) => permission.name)
      )
    ),
    this.screenSize$
  ]).pipe(
    map(([items, permissions, screenSize]) => {
      const itemsToDisplay = this.populateTopMenu(items, screenSize);
      this.extendedMenuVisibility(items, itemsToDisplay);
      this.applyVisibilityRules(items, itemsToDisplay, screenSize);
      return items.filter(item => permissions.filter(p => item.permissions.includes(p)).length > 0);
    })
  );

  get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  extendedMenuVisibility(menuItems: NavigationItem[], itemsToDisplay: number) {
    if (menuItems.length > itemsToDisplay) {
      this._showExtendedMenu$.next(true);
    } else {
      this._showExtendedMenu$.next(false);
      itemsToDisplay = menuItems.length;
    }
  }

  populateTopMenu(menuItems: NavigationItem[], screenSize?: string) {
    menuItems.sort((a, b) => a.order - b.order);
    let itemsToDisplay = 0;
    if (screenSize === ScreenSize.Desktop) {
      itemsToDisplay = 3;
    } else if (screenSize === ScreenSize.MediumDevice) {
      itemsToDisplay = 2;
    } else if (screenSize === ScreenSize.Tablet) {
      itemsToDisplay = 1;
    }
    return itemsToDisplay;
  }

  applyVisibilityRules(menuItems: NavigationItem[], itemsToDisplay: number, screenSize?: string) {
    switch (screenSize) {
      case ScreenSize.Desktop:
      case ScreenSize.MediumDevice:
        for (let i = 0; i < menuItems.length; i++) {
          if (i < itemsToDisplay) {
            menuItems[i].showOnDesktop = true;
          } else {
            menuItems[i].showOnDesktop = false;
          }
        }
        break;
      case ScreenSize.Tablet:
        for (let i = 0; i < menuItems.length; i++) {
          if (i < itemsToDisplay) {
            menuItems[i].showOnTablet = true;
          } else {
            menuItems[i].showOnTablet = false;
          }
        }
        break;
      case ScreenSize.Mobile:
        for (let i = 0; i < menuItems.length; i++) {
          menuItems[i].showOnMobile = false;
        }
        break;
    }
  }
}
enum ScreenSize {
  Desktop = "desktop",
  MediumDevice = "mediumDevice",
  Tablet = "tablet",
  Mobile = "mobile"
}
