import { Pipe, PipeTransform } from "@angular/core";
import { UiDisplayTagService } from "@vp/shared/store/ui";

@Pipe({
  name: "uiDisplayTags"
})
export class UiDisplayTagsPipe implements PipeTransform {
  constructor(private uiDisplayTagService: UiDisplayTagService) {}

  transform(tag: string): string {
    return this.uiDisplayTagService.displayTags[tag];
  }
}
