import { formatDate } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatFormFieldControl } from "@angular/material/form-field";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { BehaviorSubject } from "rxjs";

export enum DateDirection {
  Past = "past",
  Future = "future",
  Both = "both"
}

@Component({
  selector: "lib-formly-dob-picker",
  templateUrl: "./formly-dob-picker.component.html",
  styleUrls: ["./formly-dob-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: FormlyDobPickerComponent },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ]
})
export class FormlyDobPickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  maxDate!: Date | null;
  minDate!: Date | null;
  disabled$ = new BehaviorSubject<boolean>(false);

  @ViewChild("picker") picker: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.disabled$.next(this.formControl.disabled);

    if (this.props.dateDirection === DateDirection.Past) {
      this.maxDate = new Date();
      this.minDate = null;
    }

    this.formControl.valueChanges.subscribe(value => {
      this.disabled$.next(this.formControl.disabled);
      this.validateDate(value);
    });
  }

  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }

  validateDate(value: any) {
    if (
      value &&
      value._i?.length === value._f?.length &&
      this.maxDate &&
      new Date(value) > this.maxDate
    ) {
      this.formControl.setErrors({ maxDate: true });
    } else {
      const errors = this.formControl.errors;
      if (errors) {
        delete errors["maxDate"];
        if (Object.keys(errors).length === 0) {
          this.formControl.setErrors(null);
        } else {
          this.formControl.setErrors(errors);
        }
      }
    }
  }

  formatDate(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const d = new Date(event.value);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      const formattedDate = formatDate(d, "YYYY-MM-dd", "en-US");
      this.model[this.key as string] = formattedDate;
      this.formControl.setValue(formattedDate, { emitEvent: false });
      this.validateDate(formattedDate);
    }
  }
}
