import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IHttpState } from "../IHttpState";
import { HttpProgressState } from "../interceptor/HttpProgressState";

@Injectable({
  providedIn: "root"
})
export class HttpStateService {
  private _state = new BehaviorSubject<IHttpState>({} as IHttpState);
  state = this._state.asObservable();

  start(url: string) {
    this._state.next({
      state: HttpProgressState.start,
      url
    });
  }

  end(url: string) {
    this._state.next({
      state: HttpProgressState.end,
      url
    });
  }
}
