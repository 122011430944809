import { Inject, Injectable, InjectionToken } from "@angular/core";
import Ajv, { AnySchema } from "ajv";
import addFormats from "ajv-formats";

export const AJV_INSTANCE = new InjectionToken<Ajv>("The AJV Class Instance");
export const AJV_CLASS = new InjectionToken<Ajv>("The AJV Class Instance");
export const AJV_CONFIG = new InjectionToken<Ajv>("The AJV Class config");

/**
 * The response of a validation result
 */
export interface ValidateResult {
  /**
   * If the result is valid or not
   */
  isValid: boolean;

  /**
   * Error text from the validator
   */
  errorsText: string;
}

@Injectable({
  providedIn: "root"
})
export class JsonSchemaValidationService {
  constructor(@Inject(AJV_INSTANCE) private readonly ajv: Ajv) {
    addFormats(ajv);
  }
  // private readonly ajv = new Ajv({ allErrors: true });

  /**
   * Fetches the Schema and adds it to the validator schema set
   * @param schema The schema to add if not already present
   * @param name The name of the schema, this will be used as the key to store it
   */
  public loadSchema(schema: AnySchema, name: string): void {
    if (!this.ajv.getSchema(name)) {
      this.ajv.addSchema(schema, name);
    }
  }

  /**
   * Validate data against a schema
   * @param name The name of the schema to validate
   * @param data The data to validate
   */
  public validateData<T>(name: string, data: T): ValidateResult {
    const isValid = this.ajv.validate(name, data) as boolean;
    return { isValid, errorsText: this.ajv.errorsText() };
  }
}
