import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from "@angular/core";
import { defaultDisplayTags } from "@vp/models";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "vp-quick-search",
  templateUrl: "./quick-search.component.html",
  styleUrls: ["./quick-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickSearchComponent implements OnDestroy {
  @Input() showButtonText = true;
  @Input() inputPlaceholder = "Search";
  @Output() changedEvent = new EventEmitter<string>();

  quickSearchTag$: Observable<keyof typeof defaultDisplayTags> | undefined;
  inputOriginal = "";
  inputText = "";
  focused = false;
  @ViewChild("searchinput", { static: false }) searchinput!: ElementRef;

  private readonly destroyed$ = new Subject<void>();

  searchClicked() {
    this.changedEvent.emit(this.inputText);
  }

  enterPress() {
    this.changedEvent.emit(this.inputText);
  }

  checkIfEmpty(event: KeyboardEvent) {
    if (event.defaultPrevented) {
      return;
    }

    let handled = false;
    if (event.key !== undefined) {
      handled = true;
    } else if (event.keyCode !== undefined) {
      handled = true;
    }

    if (handled && this.inputText.length > 0 && event.code === "Delete") {
      this.clear();
    }

    if (handled) {
      event.preventDefault();
    }
  }

  changed() {
    this.changedEvent.emit(this.inputText);
  }

  clear() {
    this.inputText = "";
    this.changedEvent.emit(this.inputText);
    this.focused = true;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
