<div *ngIf="listOfCommands !== undefined; else spinner">
  <mat-card *ngIf="!dialogRef; else dialog">
    <mat-card-subtitle>Device Commands</mat-card-subtitle>
    <mat-card-content>
      <vp-device-command-list
        [commands]="listOfCommands"
        (sendCommand)="sendAdvancedCommand($event)"
      ></vp-device-command-list>
    </mat-card-content>
  </mat-card>

  <ng-template #dialog>
    <h1 mat-dialog-title>Device Commands</h1>
    <div mat-dialog-content>
      <vp-device-command-list
        [commands]="listOfCommands"
        (sendCommand)="sendAdvancedCommand($event)"
      ></vp-device-command-list>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </div>
  </ng-template>
</div>
<ng-template #spinner>
  <mat-spinner [diameter]="40"></mat-spinner>
</ng-template>
