import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Organization, Tag } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay, withLatestFrom } from "rxjs/operators";

@Pipe({
  name: "tagDisplay"
})
export class TagDisplayPipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  public transform(
    tagOrTags: string | string[],
    friendlyIdFilter: string | undefined = undefined,
    includeTitle: boolean | undefined = true,
    boldTitle: boolean | undefined = false
  ): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      withLatestFrom(
        this.tags$.pipe(
          shareReplay(),
          map(tags => {
            if (friendlyIdFilter) {
              return tags.filter(tag => tag.tagTypeFriendlyId === friendlyIdFilter);
            }
            return tags;
          })
        )
      ),
      map(([org, tags]: [Organization, Tag[]]) => {
        if (Array.isArray(tagOrTags)) {
          return getTagArrayDisplay(tagOrTags, tags, org, includeTitle, boldTitle);
        }
        const display = getTagDisplay(tagOrTags, tags, org, includeTitle, boldTitle);
        if (display) return display;
        return "";
      })
    );
  }
}

export const getTagArrayDisplay = (
  tagArray: string[],
  tags: Tag[],
  org: Organization,
  includeTitle: boolean,
  boldTitle: boolean
) => {
  return tagArray
    .map(tagId => {
      return getTagDisplay(tagId, tags, org, includeTitle, boldTitle);
    })
    .filter(t => t)
    .join(", ");
};

export const getTagDisplay = (
  tagId: string,
  tags: Tag[],
  org: Organization,
  includeTitle: boolean | undefined = true,
  boldTitle: boolean | undefined = false
) => {
  let tagDisplayName = "[not provided]";
  let tagTypeDisplayName = "[not provided]";
  const tag = tags.find(t => t.tagId === tagId);
  if (tag !== undefined) {
    tagDisplayName = tag.displayName;
    const tagType = org.tagTypes.find(r => r.tagTypeId === tag.tagTypeId);
    if (tagType !== undefined) {
      tagTypeDisplayName = tagType.displayName;
    }
    if (includeTitle) {
      return boldTitle
        ? `<b>${tagTypeDisplayName}</b>: ${tagDisplayName}`
        : `${tagTypeDisplayName}: ${tagDisplayName}`;
    }
    return tagDisplayName;
  }
  return null;
};
