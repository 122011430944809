import { EventBase } from "@vp/models";

export interface CallLightDeactivationNotificationEventArgs {
  deviceUserId: string;
  deactivatedBy: string;
  deactivationDateTime: string;
  patientConsoleDeviceData: any;
}

export class CallLightDeactivatedEvent extends EventBase<CallLightDeactivationNotificationEventArgs> {
  constructor(args: CallLightDeactivationNotificationEventArgs) {
    super();
    this.args = args;
  }
}
