export class ApplicationError extends Error {
  source!: string;

  constructor(message: string, source: string, originalError?: any) {
    super(message);

    // Set the prototype explicitly to maintain the instanceof behavior
    Object.setPrototypeOf(this, ApplicationError.prototype);

    // Set the name property to the custom error class name
    this.name = this.constructor.name;
    this.source = source;

    if (originalError.message) {
      this.message = originalError.message;
    }

    if (originalError) {
      Object.assign(this, originalError);
    }

    // Capture the current stack trace and store it in the error
    if (typeof (Error as any).captureStackTrace === "function") {
      (Error as any).captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(originalError.message).stack;
    }
  }
}
