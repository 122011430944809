import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FormlyModule } from "@ngx-formly/core";
import { PageMenuModule } from "@vp/shared/components/page-menu";
import { FlexModule } from "@vp/shared/directives/flex";
import { FormSanitizerModule } from "@vp/shared/directives/form-sanitizer";
import { MaterialModule } from "@vp/shared/material";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserBioDialogComponent } from "./user-bio-dialog/user-bio-dialog.component";
import { UserBioComponent } from "./user-bio.component";
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    FormlyModule.forChild({
      types: [{ name: "user-bio", component: UserBioComponent }]
    }),
    AngularEditorModule,
    MaterialModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    NgxPermissionsModule,
    FormsModule,
    MatFormFieldModule,
    PageMenuModule,
    MatProgressSpinnerModule,
    FormSanitizerModule,
    FlexModule
  ],
  declarations: [UserBioComponent, UserBioDialogComponent],
  exports: [UserBioComponent]
})
export class UserBioModule {}
