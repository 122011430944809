/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventBase } from "../common/event-base";

export interface SystemEventArgs {
  source: string;
  message: string;
  properties?: { [key: string]: any } | null | undefined;
}

export class SystemEvent extends EventBase<SystemEventArgs> {
  constructor(args: SystemEventArgs) {
    super();
    this.args = args;
  }
}
