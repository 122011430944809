import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { ApplicationState } from "@vp/data-access/application";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { CaseType, Organization, PageResult, PageState, Tag, User } from "@vp/models";
import { deeperCopy, mergeDeep } from "@vp/shared/utilities";
import { tap } from "rxjs/operators";
import { CaseTypesApiService } from "../api/case-types-api.service";
import { defaultCaseTypeFilter } from "../model/case-type-filter";
import * as CaseTypesActions from "./case-types-actions";

export interface CaseTypesStateModel {
  filter: Partial<CaseTypeFilter>;
  allCaseTypes: CaseType[];
  pagedCaseTypes: PageResult<CaseType> | null;
  currentCaseType: CaseType | null;
  caseTypeFee: number | null;
  pageState: Partial<PageState>;
}

export interface CaseTypeFilter {
  take?: number;
  skip?: number;
  search?: string | null | undefined;
  sort?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filters?: string[] | null | undefined;
}

@State<CaseTypesStateModel>({
  name: "caseTypes",
  defaults: {
    filter: defaultCaseTypeFilter(),
    allCaseTypes: [],
    pagedCaseTypes: null,
    currentCaseType: null,
    caseTypeFee: null,
    pageState: {
      totalRecords: 0,
      pageIndex: 0,
      pageCount: 0,
      pageSize: 25,
      lastPage: 1,
      partialResult: false
    }
  }
})
@Injectable()
export class CaseTypesState {
  constructor(private readonly _caseTypesApiService: CaseTypesApiService) {}

  @Selector()
  public static allCaseTypes(state: CaseTypesStateModel): CaseType[] {
    return deeperCopy(state.allCaseTypes);
  }

  @Selector([
    CaseTypesState.allCaseTypes,
    ApplicationState.loggedInUser,
    OrganizationState.organization,
    TagsState.tags
  ])
  public static allowableCaseTypes(
    allCaseTypes: CaseType[],
    loggedInUser: User,
    organization: Organization,
    allTags: Tag[]
  ): CaseType[] {
    const feature = organization?.features.find(
      feature => feature.friendlyId === "filterCaseTypeByTag" && feature.enabled
    );
    if (feature) {
      return filterCaseTypeByTag(
        feature.configurationLists?.filterRoleFriendlyIds ?? [],
        feature.configurationLists?.clientTagTypeFriendlyIds ?? [],
        allCaseTypes,
        loggedInUser,
        allTags
      );
    }

    return allCaseTypes;
  }

  @Selector()
  public static pagedCaseTypes(state: CaseTypesStateModel) {
    return state.pagedCaseTypes;
  }

  @Selector()
  public static getCurrentFilter(state: CaseTypesStateModel) {
    return state.filter;
  }

  @Selector([CaseTypesState.getCurrentFilter])
  public static currentFilter(filter: Partial<CaseTypeFilter>) {
    return filter;
  }

  @Selector()
  static getPageState(state: CaseTypesStateModel): Partial<PageState> {
    return state.pageState;
  }

  @Selector([CaseTypesState.getPageState])
  static pageState(pageState: PageState): Partial<PageState> {
    return pageState;
  }

  @Selector()
  public static currentCaseType(state: CaseTypesStateModel): CaseType | null {
    return deeperCopy(state.currentCaseType);
  }

  @Selector()
  public static caseTypeFee(state: CaseTypesStateModel) {
    return state.caseTypeFee;
  }

  // ngxsOnInit(ctx: StateContext<CaseTypesStateModel>) {
  //   ctx.dispatch(CaseTypesActions.LoadCaseTypes);
  // }

  @Action(CaseTypesActions.CreateCaseType)
  createCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseType }: CaseTypesActions.CreateCaseType
  ) {
    return this._caseTypesApiService
      .createCaseType(caseType)
      .pipe(
        tap((caseType: CaseType) =>
          ctx.patchState({ allCaseTypes: [...ctx.getState().allCaseTypes, caseType] })
        )
      );
  }

  @Action(CaseTypesActions.LoadCaseTypes)
  loadAll(ctx: StateContext<CaseTypesStateModel>) {
    return this._caseTypesApiService.getAllCaseTypes().pipe(
      tap((caseTypes: CaseType[]) => {
        ctx.setState(
          patch({
            allCaseTypes: caseTypes,
            pageState: patch({
              totalRecords: caseTypes.length
            })
          })
        );
      })
    );
  }

  @Action(CaseTypesActions.SetFilter)
  setFilter(ctx: StateContext<CaseTypesStateModel>, actions: CaseTypesActions.SetFilter) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        filter: mergeDeep(state.filter, actions.filter, actions.arrayAction)
      })
    );
  }

  @Action(CaseTypesActions.SetPageState)
  setPageState(ctx: StateContext<CaseTypesStateModel>, actions: CaseTypesStateModel) {
    const take = actions.pageState.pageSize ?? 25;
    const skip =
      actions.pageState.pageSize && actions.pageState.pageIndex
        ? actions.pageState.pageSize * actions.pageState.pageIndex
        : 0;
    const currentFilter = ctx.getState().filter;
    if (currentFilter) {
      const updatedFilter: CaseTypeFilter = {
        ...currentFilter,
        take: take,
        skip: skip
      };

      ctx.setState({
        ...ctx.getState(),
        filter: updatedFilter
      });
    }
  }

  @Action(CaseTypesActions.LoadPagedCaseTypes)
  loadPagedCaseTypes(
    ctx: StateContext<CaseTypesStateModel>,
    { filter }: CaseTypesActions.LoadPagedCaseTypes
  ) {
    const state = ctx.getState();
    return this._caseTypesApiService
      .getCaseTypesPaged(filter ?? state.filter)
      .subscribe(pageResult => {
        ctx.setState(
          patch({
            pagedCaseTypes: pageResult,
            pageState: patch({
              totalRecords: pageResult.totalRecords,
              partialResult: pageResult.partialResult,
              totalFilteredRecords: pageResult.pagingTotalRecordCount
            })
          })
        );
      });
  }

  @Action(CaseTypesActions.SetCurrentCaseType)
  setCurrentCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId }: CaseTypesActions.SetCurrentCaseType
  ) {
    return this._caseTypesApiService.getCaseTypeById(caseTypeId).pipe(
      tap(caseType => {
        ctx.patchState({ currentCaseType: caseType });
      })
    );
  }

  @Action(CaseTypesActions.UnsetCurrentCaseType)
  unsetCurrentCaseType(ctx: StateContext<CaseTypesStateModel>) {
    ctx.patchState({ currentCaseType: null, caseTypeFee: null });
  }

  @Action(CaseTypesActions.GetCaseTypeFee)
  getCaseTypeFee(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId }: CaseTypesActions.GetCaseTypeFee
  ) {
    return this._caseTypesApiService
      .getCaseTypeFee(caseTypeId)
      .pipe(tap((fee: number) => ctx.patchState({ caseTypeFee: fee })));
  }

  @Action(CaseTypesActions.UpdateCaseType)
  updateCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseType }: CaseTypesActions.UpdateCaseType
  ) {
    ctx.setState(
      patch({
        allCaseTypes: updateItem<CaseType>(
          ct => ct?.caseTypeId === caseType.caseTypeId,
          caseType => mergeDeep(caseType, caseType, "merge")
        )
      })
    );
  }

  @Action(CaseTypesActions.PatchCaseTypeWithOperations)
  patch(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId, operations }: CaseTypesActions.PatchCaseTypeWithOperations
  ) {
    return this._caseTypesApiService.patch(caseTypeId, operations).pipe(
      tap((caseType: CaseType) => {
        ctx.patchState({
          allCaseTypes: [
            ...ctx.getState().allCaseTypes.filter(g => g.caseTypeId !== caseTypeId),
            caseType
          ]
        });
      })
    );
  }

  @Action(CaseTypesActions.PatchCaseType)
  patchCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { original, changed }: CaseTypesActions.PatchCaseType
  ) {
    return this._caseTypesApiService.patchCaseType(original, changed).pipe(
      tap((caseType: CaseType) =>
        ctx.patchState({
          allCaseTypes: [
            ...ctx.getState().allCaseTypes.filter(g => g.caseTypeId !== original.caseTypeId),
            caseType
          ]
        })
      )
    );
  }

  @Action(CaseTypesActions.DeleteCaseType)
  deleteCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId }: CaseTypesActions.DeleteCaseType
  ) {
    return this._caseTypesApiService.deleteCaseType(caseTypeId).pipe(
      tap((result: CaseType) => {
        if (result) {
          ctx.patchState({
            allCaseTypes: [...ctx.getState().allCaseTypes.filter(g => g.caseTypeId !== caseTypeId)]
          });
        }
      })
    );
  }
}

export const filterCaseTypeByTag = (
  filterRoles: string[],
  clientTagTypes: string[],
  caseTypes: CaseType[],
  loggedInUser: User,
  allTags: Tag[]
) => {
  const currentRole = loggedInUser.roles.find(r => r.roleId === loggedInUser.selectedRoleId);
  if (currentRole && filterRoles.includes(currentRole?.friendlyId) && clientTagTypes.length) {
    const filterTags = allTags.filter(t => clientTagTypes.includes(t.tagTypeFriendlyId));
    const userClientTag = filterTags.filter(t => loggedInUser.assignedTags.includes(t.tagId));
    const allowedCaseTypeIds = userClientTag.reduce((acc: string[], tag: Tag) => {
      return acc.concat(
        tag.tagData?.allowedCaseTypes ? <string[]>tag.tagData?.allowedCaseTypes : []
      );
    }, []);
    if (allowedCaseTypeIds.length) {
      return caseTypes.filter(c => allowedCaseTypeIds.includes(c.caseTypeId));
    }
  }
  return caseTypes;
};
