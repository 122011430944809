import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FeatureEnabledDirective } from "./feature-enabled.directive";
import { FeatureFlagDirective } from "./feature-flag.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [FeatureFlagDirective, FeatureEnabledDirective],
  exports: [FeatureFlagDirective, FeatureEnabledDirective]
})
export class FeaturesModule {}
