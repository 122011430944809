import { Injectable, inject } from "@angular/core";

import { Store } from "@ngxs/store";
import { CaseType } from "@vp/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as CaseTypesActions from "../lib/state+/case-types-actions";
import { CaseTypesState } from "../lib/state+/case-types-state";
import { ResolveFn } from "@angular/router";

@Injectable()
export class CaseTypesDataResolverService {
  constructor(private store: Store) {}

  resolve(): CaseType[] | Observable<CaseType[]> | Promise<CaseType[]> {
    return this.store
      .dispatch(new CaseTypesActions.LoadCaseTypes())
      .pipe(map(() => this.store.selectSnapshot(CaseTypesState)));
  }
}

export const CaseTypesDataResolver: ResolveFn<CaseType[]> = ():
  | CaseType[]
  | Observable<CaseType[]>
  | Promise<CaseType[]> => {
  return inject(CaseTypesDataResolverService).resolve();
};
