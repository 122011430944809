<mat-form-field flex="100">
  <mat-label>{{ props.label }}</mat-label>

  <!-- Render input for text input -->
  @if (props.type === "text") {
    <input [formControl]="formControl" matInput [required]="props.required ? true : false" />
  }

  <!-- Render mat-select for dropdown selector -->
  @if (props.type === "select") {
    <mat-select
      [formControl]="formControl"
      [required]="props.required ? true : false"
      [multiple]="props.multiple"
      [errorStateMatcher]="this"
    >
      <mat-option *ngFor="let option of selOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  }

  <mat-hint>{{ props.description }}</mat-hint>
  <mat-error *ngIf="formControl.hasError('required')">This field is required</mat-error>
</mat-form-field>
