import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { Snippet } from "@vp/models";
import { SharedConfirmationService } from "@vp/shared/confirmation";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import {
  UserAdministrationService,
  UserAdministrationState
} from "@vp/user-administration/data-access/user-administration-state";
import { AddOrEditSnippetDialogComponent } from "@vp/user-snippets/ui/add-or-edit-snippet-dialog";
import { Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";
export const MAX_SNIPPETS = 30;

@Component({
  selector: "vp-user-snippets",
  templateUrl: "./user-snippets.component.html",
  styleUrls: ["./user-snippets.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class UserSnippetsComponent {
  expandedRow: Snippet | null = null;

  snippets$: Observable<Snippet[]> = this.userAdministrationService.workingCopy$.pipe(
    map(user => user?.userData?.snippets ?? [])
  );

  disableAddSnippet$ = this.snippets$.pipe(map(snippets => snippets.length >= MAX_SNIPPETS));

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly notificationService: NotificationService,
    private readonly confirmationDialog: SharedConfirmationService,
    private readonly userAdministrationService: UserAdministrationService
  ) {}

  addOrEditSnippet(action: "Add" | "Edit", snippet?: Snippet, index?: number) {
    const snippets =
      this.store.selectSnapshot(UserAdministrationState.getWorkingCopy)?.userData?.snippets ?? [];

    if (action === "Add" && snippets.length >= MAX_SNIPPETS) {
      this.notificationService.warningMessage(
        `Unable to add snippet, ${MAX_SNIPPETS} maximum snippets reached`
      );
      return;
    }

    this.dialog
      .open(AddOrEditSnippetDialogComponent, {
        width: "500px",
        data: { action: action, snippet: snippet },
        panelClass: "mat-mdc-dialog-w-text-editor"
      })
      .afterClosed()
      .pipe(
        take(1),
        filterNullMap(),
        tap((snippet: Snippet) => {
          this.userAdministrationService.addOrEditSnippet(action, snippet, index);
        })
      )
      .subscribe((snippet: Snippet) => {
        this.userAdministrationService.commit();
        this.notificationService.successMessage(
          snippet.title,
          `Snippet ${action === "Add" ? "Added" : "Updated"}`
        );
      });
  }

  deleteSnippet(index: number) {
    this.confirmationDialog
      .open("Delete this template?")
      .afterConfirmed()
      .subscribe({
        next: (confirmed: boolean) => {
          if (confirmed) {
            this.userAdministrationService.deleteSnippet(index);
          }
        },
        error: () => this.notificationService.warningMessage("Failed to delete")
      });
  }
}
