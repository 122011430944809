import { Pipe, PipeTransform } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { Organization } from "@vp/models";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
@Pipe({
  name: "role"
})
export class RolePipe implements PipeTransform {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  transform(roleId: string): Observable<string> {
    return this.organization$.pipe(
      shareReplay(),
      map(org => {
        const orgRole = org.roles.find(r => r.roleId === roleId);
        return `${orgRole?.displayName ?? "[role  not found]"}`;
      })
    );
  }
}
