import { EventBase } from "@vp/models";

export interface AssignUserEventArgs {
  caseId: string;
  assignedUserId: string;
  message: string;
}

export class AssignUserEvent extends EventBase<AssignUserEventArgs> {
  constructor(eventArgs: AssignUserEventArgs) {
    super();
    this.args = eventArgs;
  }
}
