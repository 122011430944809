import { Injectable, OnDestroy } from "@angular/core";
import { Select } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { CaseType } from "@vp/models";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, first, map, takeUntil } from "rxjs/operators";

@Injectable()
export class CaseTypeSelectorService implements OnDestroy {
  @Select(CaseTypesState.allowableCaseTypes) caseTypes!: Observable<CaseType[]>;

  private destroyed$ = new Subject<void>();

  private readonly _selectedCaseType$ = new BehaviorSubject<CaseType | null>(null);

  selectedCaseType$ = this._selectedCaseType$.asObservable();

  caseTypes$ = this.caseTypes.pipe(
    filter(caseTypes => caseTypes.length !== 0),
    map((caseTypes: CaseType[]) => {
      return [...caseTypes].sort((x, y) => {
        const a = x.displayName.toUpperCase();
        const b = y.displayName.toUpperCase();
        return a === b ? 0 : a > b ? 1 : -1;
      });
    }),
    takeUntil(this.destroyed$)
  );

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setSelected(caseTypeId: string | null) {
    this.caseTypes
      .pipe(
        map(caseTypes => caseTypes.find(ct => ct.caseTypeId === caseTypeId) ?? null),
        first()
      )
      .subscribe(caseType => {
        this._selectedCaseType$.next(caseType);
      });
  }
}
