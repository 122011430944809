import { EventBase } from "@vp/models";

export interface ChatMessageEventArgs {
  messageText: string;
  senderName: string | null;
  senderId: string | undefined;
  caseId: string | null;
}

export class CaseChatEvent extends EventBase<ChatMessageEventArgs> {
  constructor(result: ChatMessageEventArgs) {
    super();
    this.args = result;
  }
}
