import { Tag, TagType } from "@vp/models";
import { compare, getMissing } from "@vp/shared/utilities";
import { TagManagerStateModel } from "../models/tag-manager-state.model";

export const selectTagsAndSort = (state: Readonly<TagManagerStateModel>) => {
  const selectedTagTypeId = state.selectedTagType?.tagTypeId;
  const selectedTags = state.selectedTags.map(t => t.tagId);

  const result = [...state.tags]
    .filter(tag => applyFilter(selectedTagTypeId, tag, selectedTags, state))
    .sort((a, b) => compare(a.displayName, b.displayName, true));

  return result;
};

export interface RoleTagTypeRestriction {
  roleFriendlyId: string;
  tagTypeIds: string[];
}

export const selectTagTypesAndSort = (
  state: Readonly<TagManagerStateModel>,
  tagTypeSearch: string,
  allTagTypes: TagType[],
  userRoleFriendlyId: string,
  roleTagTypeRestrictions: RoleTagTypeRestriction[]
) => {
  /**
   * This calculates missing tags for corresponding selected tag types. If any tags
   * are missing we want to return nothing here so only selected tag types are shown
   * once a tag is selected for a selected tag type then we allow the filter to
   * continue as normal.
   */
  if (
    getMissing(
      state.selectedTagTypes.map(t => t.tagTypeId),
      state.selectedTags.map(t => t.tagTypeId)
    ).length > 0
  )
    return [];

  const selectedTagTypes = state.selectedTagTypes.map(tt => tt.friendlyId);
  return [...state.tagTypes]
    .filter((tagType: TagType) => {
      const roleTagTypeRestriction = roleTagTypeRestrictions.find(
        rtr => rtr.roleFriendlyId === userRoleFriendlyId
      );
      const hasAccess =
        roleTagTypeRestriction?.tagTypeIds.includes("*") ||
        roleTagTypeRestriction?.tagTypeIds.includes(tagType.friendlyId);

      if (hasAccess) return true;
      const childTagTypes = getChildTagTypes(tagType, allTagTypes);
      return childTagTypes.map(tt => tt.tagTypeId).includes(tagType.tagTypeId);
    })
    .filter(tagType => {
      /**
       * If we have do not selected any tag types yet, we show only tag types that have
       * no path, i.e. top level tag types.
       */
      if (selectedTagTypes.length === 0) {
        return tagType.tagTypeFriendlyPathId === null;
      }
      /* The selected tag types will inherently appear in order due to the way they are
       * hidden in the filter, so the selectedTagTypes should always be in the hierarchical
       * order here, so we join them together to match on the tag path to show only child
       * tag types for tag types that are already selected
       * */
      return tagType.tagTypeFriendlyPathId === selectedTagTypes.join(".");
    })
    .filter((tagType: TagType) => tagType.displayName?.search(new RegExp(tagTypeSearch, "i")) > -1)
    .sort((a, b) => compare(a.displayName, b.displayName, true));
};

export const getChildTagTypes = (tagType: TagType, tagTypes: TagType[]): TagType[] => {
  const childTagTypes = tagTypes.filter(tt => {
    const path = tt.tagTypeFriendlyPathId?.split(".").pop();
    return path === tagType.friendlyId;
  });
  if (childTagTypes.length > 0) {
    const result = childTagTypes.map(t => getChildTagTypes(t, tagTypes));
    const flatResult = result.reduce((acc, current) => acc.concat(current), []);
    return childTagTypes.concat(flatResult);
  }
  return childTagTypes;
};

export const applyFilter = (
  selectedTagTypeId: string | undefined,
  tag: Tag,
  selectedTags: string[],
  state: Readonly<TagManagerStateModel>
) => {
  const sameTagType = tag.tagTypeId === selectedTagTypeId;
  const checkPath = selectedTags.join(".").startsWith(tag.tagPath) || tag.tagPath === null;
  const tagIncluded = !selectedTags.includes(tag.tagId);
  const checkDisplayName = tag.displayName?.search(new RegExp(state.tagSearch, "i")) > -1;

  return sameTagType && checkPath && tagIncluded && checkDisplayName;
};
