import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ellipsis"
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, length: number): string {
    if (length === undefined) {
      return value;
    }

    if (value.length > length) {
      return value.substring(0, length) + "...";
    } else {
      return value;
    }
  }
}
