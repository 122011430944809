import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FieldType } from "@ngx-formly/core";
import { Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { OrganizationFeatures, User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { UserBioDialogComponent } from "./user-bio-dialog/user-bio-dialog.component";

@Component({
  selector: "vp-user-bio",
  templateUrl: "./user-bio.component.html",
  styleUrls: ["./user-bio.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBioComponent extends FieldType {
  @Select(UserAdministrationState.user) user$!: Observable<User | null>;

  showButton$ = combineLatest([
    this.ngxPermissionsService.hasPermission([this.permConst.Admin.User.Bio.Write]),
    this.featureService.featureFlagEnabled$(OrganizationFeatures.profile, "bioEnabled"),
    this.user$
  ]).pipe(
    map(([hasPermission, featureEnabled, user]: [boolean, boolean, User | null]) => {
      const loggedUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
      if (user && user.userId === loggedUser?.userId) {
        return true;
      }
      return hasPermission && featureEnabled;
    })
  );

  constructor(
    private dialog: MatDialog,
    public permConst: PermissionsConstService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly featureService: FeatureService,
    private readonly store: Store
  ) {
    super();
  }

  openBio() {
    this.dialog
      .open(UserBioDialogComponent, { panelClass: "mat-mdc-dialog-basic" })
      .afterClosed()
      .subscribe();
  }
}
