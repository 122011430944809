import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "daysBetweenTwoDates",
  pure: true
})
export class DaysBetweenTwoDatesPipe implements PipeTransform {
  transform(firstDate: Date, secondDate?: Date): string {
    return this.daysBetweenTwoDates(firstDate, secondDate);
  }
  daysBetweenTwoDates(firstDate: Date, secondDate?: Date): string {
    // Have to convert the variables back to Date or the date parts will fail
    const dateOne = new Date(firstDate);
    let dateTwo = new Date();
    if (secondDate) {
      dateTwo = new Date(secondDate);
    }
    const days = Math.floor(
      (Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate()) -
        Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    return isNaN(days) ? "unknown" : days.toFixed(0);
  }
}
