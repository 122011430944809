<mat-form-field flex>
  <mat-label>{{ props.label }}</mat-label>

  <!-- The timepicker input -->
  <mtx-datetimepicker-toggle
    [for]="datetimePicker"
    matSuffix
  ></mtx-datetimepicker-toggle>
  <mtx-datetimepicker
    #datetimePicker
    type="time"
  ></mtx-datetimepicker>
  <input
    [mtxDatetimepicker]="datetimePicker"
    [formControl]="formControl"
    autocomplete="false"
    matInput
    required
  />
</mat-form-field>
