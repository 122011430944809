import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, QueryParamsHandling, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { CaseData, CaseUser, RealTimeNotification, User, UserTypeFriendlyId } from "@vp/models";
import { EMPTY, Observable, Subject } from "rxjs";
import { exhaustMap, pairwise, takeUntil, withLatestFrom } from "rxjs/operators";
import { CaseDataFilter } from "../models/case-data-filter";
import * as CaseFilterStateActions from "../state+/case-filter-state.actions";

import { ApplicationState } from "@vp/data-access/application";
import { filterNullMap } from "@vp/shared/operators";
import * as SignalRStateActions from "@vp/shared/signal-r-service";
import { CaseFilterState } from "../state+/case-filter.state";

@Injectable()
export class CaseFilterStateNavigationService implements OnDestroy {
  @Select(CaseFilterState.results) filteredCaseData$!: Observable<CaseData[]>;
  @Select(CaseFilterState.currentFilter) currentFilter$!: Observable<CaseDataFilter>;
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;

  private _destroyed$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  listen() {
    this.activatedRoute.queryParamMap
      .pipe(
        takeUntil(this._destroyed$),
        exhaustMap((paramMap: ParamMap) => {
          const search: string | null = paramMap.get("search");
          const currentFilter = this.store.selectSnapshot(CaseFilterState.currentFilter);
          if (currentFilter.search !== search) {
            return this.store.dispatch(
              new CaseFilterStateActions.SetFilterState({
                ...currentFilter,
                search: search,
                status: []
              })
            );
          }
          return EMPTY;
        })
      )
      .subscribe();

    this.currentFilter$.pipe(takeUntil(this._destroyed$)).subscribe((filter: CaseDataFilter) => {
      this.navigate(filter);
    });

    this.filteredCaseData$
      .pipe(
        pairwise(),
        withLatestFrom(this.loggedInUser$.pipe(filterNullMap())),
        takeUntil(this._destroyed$)
      )
      .subscribe({
        next: ([[previous, current], user]: [[CaseData[], CaseData[]], User]) => {
          this.store.dispatch(
            new SignalRStateActions.ReconcileGroups(
              getNotifications(previous, user),
              getNotifications(current, user)
            )
          );
        }
      });
  }

  navigate(
    searchParams: Partial<CaseDataFilter>,
    queryParamsHandling: QueryParamsHandling | null = "",
    skipLocationChange = false,
    replaceUrl = true
  ): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: searchParams,
      queryParamsHandling,
      skipLocationChange,
      replaceUrl
    });
  }
}

const getNotifications = (caseData: CaseData[], user: User) => {
  return caseData.reduce((acc: RealTimeNotification[], caseData: CaseData) => {
    const deviceUser = caseData.users.find(
      (caseUser: CaseUser) => caseUser.userType === UserTypeFriendlyId.device
    );
    if (deviceUser) {
      acc.push({
        userId: user.userId,
        groupName: deviceUser.userId
      } as RealTimeNotification);
    }
    acc.push({
      userId: user.userId,
      groupName: caseData.caseId
    } as RealTimeNotification);
    return acc;
  }, []);
};
