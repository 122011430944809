import { Injectable, InjectionToken } from "@angular/core";
import { ArrayAction, deeperCopy, mergeDeep } from "@vp/shared/utilities";
import {
  UiSchema,
  UiSchemaCollection,
  UiSchemaDefinition,
  UiSchemaLayout
} from "./ui-schema-collection";

export const UI_SCHEMA_CONFIG = new InjectionToken<UiSchemaCollection>("UI_SCHEMA_CONFIG");

@Injectable({
  providedIn: "root"
})
export class UiSchemaConfigService {
  configs: UiSchemaCollection[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tabChanged: (index: number) => void | undefined = (_index: number) => {
    //
  };

  addConfig(config: UiSchemaCollection) {
    this.validateLayoutConfig(config);
    if (config.layouts?.length === 0 && config.definitions?.length === 0) {
      return;
    }
    if (config.onTabChanged) {
      this.tabChanged = config.onTabChanged;
    }

    this.configs.push(config);
  }

  addScopedConfig(
    config: Readonly<UiSchemaCollection>,
    scope: string | null = null,
    arrayAction: ArrayAction = "replace"
  ) {
    if (!config) {
      config = {
        scope: "default",
        layouts: []
      };
    }
    const clone = deeperCopy(config);
    if (scope) {
      Object.assign(clone, {
        scope: scope
      });
    } else if (!clone.scope) {
      throw Error("Scope is required.");
    }

    const existingIndex = this.configs.findIndex(c => c.scope === clone.scope);
    if (existingIndex >= 0) {
      this.configs[existingIndex] = mergeDeep(this.configs[existingIndex], clone, arrayAction);
    } else {
      this.addConfig(clone);
    }
  }

  private validateLayoutConfig(config: UiSchemaCollection) {
    if (!typeof Array.isArray(config.layouts) || !config.layouts) {
      throw Error("Layouts must be an array.");
    }
    if (!config.layouts.every(layout => layout.types.length > 0)) {
      throw Error("All layouts must have at least one type.");
    }
    if (config.definitions) {
      if (!config.definitions.every(def => def.types.length > 0)) {
        throw Error("All definitions must have at least one type.");
      }
    }
  }

  getLayoutElements(type: string, scope: string | null = null): UiSchema[] {
    let scopes: UiSchemaLayout[] = [];
    if (scope !== undefined && scope !== null && scope.length > 0) {
      scopes = this.configs
        .filter((config: UiSchemaCollection) => {
          return config?.scope === scope;
        })
        .reduce((accum: UiSchemaLayout[], config: UiSchemaCollection) => {
          return accum.concat(config.layouts || []);
        }, []);
    }

    // get the default configs
    const defaults = this.configs
      .filter(c => !c.scope)
      .reduce((accum: UiSchemaLayout[], config: UiSchemaCollection) => {
        return accum.concat(config.layouts || []);
      }, []);

    // apply default layouts first
    return defaults
      .concat(scopes)
      .filter((_scope: UiSchemaLayout) => {
        return _scope.types.includes(type) || _scope.types.includes("default");
      })
      .reduce((accum: UiSchema[], scope: UiSchemaLayout) => {
        return accum.concat(scope.elements);
      }, []);
  }

  getDefinitions(type: string): UiSchemaDefinition[] {
    let definitions: UiSchemaDefinition[] = [];
    this.configs.forEach((config: UiSchemaCollection) => {
      if (config.definitions) {
        definitions = definitions.concat(
          config.definitions
            .filter((def: UiSchemaDefinition) => def.types.includes(type))
            .reduce((acc: UiSchemaDefinition[], val: UiSchemaDefinition) => {
              return acc.concat(val);
            }, [])
        );
      }
    });
    return definitions;
  }
}
