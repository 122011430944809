import { Role, Tag, TagsArray, User, UserRole } from "@vp/models";
import { deepCopy, getValueAtPath } from "@vp/shared/utilities";

/**
 * Returns true if the userRole's default permissions include all of the
 * permissions in the permissionGroupTag. False otherwise.
 *
 * @param userRole - The Role to check.
 * @param permissionGroupTag - The Tag representing a permission group.
 * @param permissionTags - The list of all permission tags
 */
export const isPermissionGroupInRoleDefaults = (
  userRole: Role,
  permissionGroupTag: Tag,
  permissionTags: Tag[]
) => {
  const roleDefaultPermissions = userRole.permissions;
  const permissionTagIds: string[] = getPermissionsFromPermissionGroupTag(permissionGroupTag);
  if (permissionTagIds.length === 0) {
    return false;
  }

  const permissionGroups = permissionTags
    .filter(t => permissionTagIds.includes(t.tagId))
    .map(t => getValueAtPath(t.tagData, ["permissionsId"]))
    .filter(Boolean);

  if (permissionGroups.length === 0) {
    return false;
  }

  return permissionGroups.every(p => roleDefaultPermissions.includes(p));
};

/**
 * Returns the list of permission tag ids in the permissionGroupTag.
 *
 * @param permissionGroupTag - The Tag representing a permission group.
 */
export const getPermissionsFromPermissionGroupTag = (permissionGroupTag: Tag) => {
  return getValueAtPath(permissionGroupTag.tagData, ["permissions"]) ?? [];
};

/**
 * Updates the user's assigned tags by adding the tags in addTagList and removing
 * the tags in removeTagList.
 *
 * @param user - The User object to update.
 * @param addTagList - The list of tags to add to the user's assigned tags.
 * @param removeTagList - The list of tags to remove from the user's assigned tags.
 * @returns The updated list of assigned tags.
 */
export const updateUserAssignedTags = (
  user: User,
  addTagList: string[] = [],
  removeTagList: string[] = []
) => {
  let updatedAssignedTags = user.assignedTags.concat(addTagList);
  updatedAssignedTags = updatedAssignedTags.filter(t => !removeTagList.includes(t));
  return [...new Set(updatedAssignedTags)];
};

/**
 * Updates the user's roles with new access tags. If a role is excluded, it
 * will not be modified.
 *
 * @param user - The User object to update.
 * @param excludedRoles - An array of role friendlyIds to exclude from the
 * update.
 * @param addTagList - An array of tagIds to add to the user's roles accessTags.
 * @param removeTagList - An array of tagIds to remove from the user's roles accessTags.
 * @returns A new User object with the updated role access tags.
 */

export const updateUserRoleAccessTags = (
  user: User,
  excludedRoles: string[] = [],
  addTagList: string[] = [],
  removeTagList: string[] = []
) => {
  const userRoles = deepCopy(user.roles) as UserRole[];

  userRoles.forEach(role => {
    if (excludedRoles.includes(role.friendlyId)) {
      return;
    }
    let accessTags = role.accessTags || [];

    if (addTagList.length) {
      addTagList.forEach(tagId => {
        const exists = accessTags.some(accessTag => accessTag.tags.includes(tagId));
        if (!exists) {
          const newAccessTag = { tags: [tagId] } as TagsArray;
          accessTags = [...accessTags, newAccessTag];
        }
      });
    }

    if (removeTagList.length) {
      removeTagList.forEach(tagId => {
        accessTags = accessTags.filter(accessTag => !accessTag.tags.includes(tagId));
      });
    }

    role.accessTags = accessTags;
  });

  return userRoles;
};

/**
 * Reconciles the access tags of a user's roles with their assigned tags. This
 * function ensures that all assigned tags are present in the access tags of
 * each role, unless the role is excluded. If an assigned tag is not present in
 * the role's access tags, it is added.
 *
 * @param user - The User object to reconcile.
 * @param excludedRoles - An array of role friendlyIds to exclude from the
 * reconciliation.
 * @returns A new User object with the reconciled role access tags.
 */
export const reconcileUserRoleAccessTags = (user: User, excludedRoles: string[] = []) => {
  const copy = deepCopy(user) as User;
  const assignedTags = copy.assignedTags;
  copy.roles.forEach(role => {
    if (excludedRoles.includes(role.friendlyId)) {
      return;
    }
    const accessTags = role.accessTags || [];
    for (const tag of assignedTags) {
      const exists = accessTags.some(a => a.tags.includes(tag));
      if (!exists) {
        // add missing access tag
        const newAccessTag = { tags: [tag] } as TagsArray;
        accessTags.push(newAccessTag);
      }
    }
    role.accessTags = accessTags;
  });
  return copy;
};

/**
 * Returns an array of tagIds that are present in the user's assigned tags and
 * also present in the access tags of each of the user's roles, excluding
 * roles that are present in the excludedRoles array.
 *
 * @param user - The User object to check.
 * @param tags - The list of tags to check.
 * @param excludedRoles - An array of role friendlyIds to exclude from the check.
 * @returns An array of tagIds that are common to the user's assigned tags and
 * all of their roles, excluding those that are excluded.
 */
export const getCommonTags = (user: User, tags: Tag[], excludedRoles: string[] = []) => {
  const tagIds = tags.filter(t => user.assignedTags.includes(t.tagId)).map(x => x.tagId);
  const rolesToCheck = user.roles.filter(r => !excludedRoles.includes(r.friendlyId));

  // each role should include an access tag thats exists in the assigned tag to be a common tag
  const common = tagIds.filter(tag =>
    rolesToCheck.every(r => r.accessTags?.some(a => a.tags.includes(tag)))
  );
  return common;
};
