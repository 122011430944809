import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldArrayType, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-array-type",
  templateUrl: "./formly-array-type.component.html",
  styleUrls: ["./formly-array-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyArrayTypeComponent extends FieldArrayType {
  constructor() {
    super();
  }

  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
