import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, updateItem } from "@ngxs/store/operators";
import { CommunicationData, CommunicationSummary, CommunicationSummaryParams } from "@vp/models";
import { deeperCopy } from "@vp/shared/utilities";
import { createPatch } from "rfc6902";
import { EMPTY } from "rxjs";
import { CommunicationsApiService } from "../api/communications-api.service";
import * as CommunicationActions from "./communication.actions";

export type CommunicationStateModel = {
  communicationSummary: CommunicationSummary;
  communications: CommunicationData[];
  original: CommunicationData | null;
  workingCopy: CommunicationData | null;
  errors: [];
};

@State<CommunicationStateModel>({
  name: "communication",
  defaults: {
    communicationSummary: {
      totalMessages: 0,
      type: 0,
      unreadMessageCount: 0,
      unResolvedMessageCount: 0,
      followUpCount: 0
    },
    communications: [],
    original: null,
    workingCopy: null,
    errors: []
  }
})
@Injectable()
export class CommunicationState {
  constructor(private communicationsApiService: CommunicationsApiService) {}

  @Selector()
  private static getCommunicationsSummaries(state: CommunicationStateModel): CommunicationSummary {
    return state.communicationSummary;
  }

  @Selector([CommunicationState.getCommunicationsSummaries])
  public static communicationsSummaries(
    communicationSummary: CommunicationSummary
  ): CommunicationSummary {
    return communicationSummary;
  }

  @Selector()
  private static getCommunications(state: CommunicationStateModel): CommunicationData[] {
    return state.communications;
  }

  @Selector([CommunicationState.getCommunications])
  public static communications(communications: CommunicationData[]): CommunicationData[] {
    return communications;
  }

  @Selector()
  private static getWorkingCopy(state: CommunicationStateModel): CommunicationData | null {
    return state.workingCopy;
  }

  @Selector([CommunicationState.getWorkingCopy])
  public static workingCopy(workingCopy: CommunicationData | null): CommunicationData | null {
    return workingCopy;
  }

  ////////////////////////  Actions

  @Action(CommunicationActions.SetCommunicationSummaries)
  setCommunicationSummaries(
    ctx: StateContext<CommunicationStateModel>,
    queryParams: CommunicationSummaryParams
  ) {
    return this.communicationsApiService
      .getCommunicationSummary(queryParams)
      .subscribe((commSummary: CommunicationSummary) => {
        ctx.setState(
          patch<CommunicationStateModel>({
            communicationSummary: commSummary
          })
        );
      });
  }

  @Action(CommunicationActions.SetCommunications)
  getCommunications(
    ctx: StateContext<CommunicationStateModel>,
    actions: CommunicationActions.SetCommunications
  ) {
    const state = ctx.getState();
    const communications = state.communications.filter(x => !actions.caseIds.includes(x.caseId));
    ctx.setState(
      patch<CommunicationStateModel>({
        communications: Array.from(new Set([...communications, ...actions.comms]))
      })
    );
  }

  @Action(CommunicationActions.SetCurrentCommunication)
  setCurrentCommunication(
    ctx: StateContext<CommunicationStateModel>,
    actions: CommunicationActions.SetCurrentCommunication
  ) {
    const state = ctx.getState();
    const communication = state.communications.find(
      x => x.communicationId === actions.communicationId
    );
    ctx.setState(
      patch<CommunicationStateModel>({
        original: communication,
        workingCopy: communication
      })
    );
  }

  @Action(CommunicationActions.CreateNewCommunication)
  createNewCommunication(
    ctx: StateContext<CommunicationStateModel>,
    actions: CommunicationActions.CreateNewCommunication
  ) {
    if (!actions.communication) {
      return;
    }

    const communication: CommunicationData = deeperCopy(actions.communication);
    this.communicationsApiService
      .createCommunication(actions.caseId, communication)
      .subscribe((comm: CommunicationData) => {
        ctx.setState(
          patch<CommunicationStateModel>({
            communications: append([comm])
          })
        );
      });
  }

  @Action(CommunicationActions.ResolveCommunication)
  resolveCommunication({
    communicationId,
    isResolved,
    caseId
  }: CommunicationActions.ResolveCommunication) {
    if (!communicationId) {
      return EMPTY;
    }
    return this.communicationsApiService.markResolved(caseId, communicationId, isResolved);
  }

  @Action(CommunicationActions.Patch)
  patch(ctx: StateContext<CommunicationStateModel>, action: CommunicationActions.Patch) {
    const state = ctx.getState();
    const original = state.original;
    const workingCopy = state.workingCopy;
    const operations = createPatch(original, workingCopy);

    if (operations.length > 0 && workingCopy) {
      this.communicationsApiService
        .patch(action.caseId, action.communicationId, operations, "communicationData_saveMessage")

        .subscribe(() => {
          ctx.setState(
            patch<CommunicationStateModel>({
              communications: updateItem<CommunicationData>(
                t => t?.communicationId === action.communicationId,
                patch<CommunicationData>(workingCopy)
              )
            })
          );
        });
    }
  }

  @Action(CommunicationActions.UpdateState)
  updateState(
    ctx: StateContext<CommunicationStateModel>,
    { communicationData }: CommunicationActions.UpdateState
  ) {
    ctx.setState(
      patch<CommunicationStateModel>({
        communications: updateItem<CommunicationData>(
          t => t?.communicationId === communicationData.communicationId,
          patch<CommunicationData>(communicationData)
        )
      })
    );
  }

  @Action(CommunicationActions.UpdateWorkingCopy)
  updateWorkingCopy(
    ctx: StateContext<CommunicationStateModel>,
    action: CommunicationActions.UpdateWorkingCopy
  ) {
    const state = ctx.getState();
    if (state.workingCopy) {
      const communicationData: CommunicationData = {
        ...state.workingCopy,
        ...action.communicationData
      };
      if (communicationData) {
        ctx.setState(
          patch({
            workingCopy: communicationData
          })
        );
      }
    }
  }
}
