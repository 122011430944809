import { EventBase, Message } from "@vp/models";

export interface MessagesAddedNotification {
  messages: Message[];
  messageTarget: string | null;
  deviceId: string | null;
}

export interface MessagesRemovedNotification {
  messages: string[];
  deviceId: string | null;
}

export class PacMessagesAdded extends EventBase<MessagesAddedNotification> {
  constructor(messagesAddedNotification: MessagesAddedNotification) {
    super();
    this.args = messagesAddedNotification;
  }
}

export class PacMessagesRemoved extends EventBase<MessagesRemovedNotification> {
  constructor(messagesRemovedNotification: MessagesRemovedNotification) {
    super();
    this.args = messagesRemovedNotification;
  }
}
