import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import {
  CaseData,
  CaseType,
  CaseUser,
  Organization,
  OrganizationFeatures,
  User,
  UserRole
} from "@vp/models";
import { FeatureService } from "@vp/shared/features";

import { LoggerService } from "@vp/shared/logger-service";
import { AppStoreService } from "@vp/shared/store/app";
import { hasOwnProperty } from "@vp/shared/utilities";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, ReplaySubject, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AccessControlService {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;
  @Select(CaseState.current) caseData$!: Observable<CaseData>;

  rolePermission$ = new ReplaySubject<UserRole>(1);

  constructor(
    private readonly featureService: FeatureService,
    private readonly appStoreService: AppStoreService,
    private readonly logger: LoggerService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly router: Router
  ) {}

  initNgxPermissions() {
    combineLatest([
      this.appStoreService.loggedInUser$,
      this.caseData$,
      this.caseTypes$,
      this.featureService.featureEnabled$(OrganizationFeatures.userPermissions)
    ])
      .pipe(
        tap(([user, caseData, caseTypes, userPermissionsEnabled]) => {
          this.ngxPermissionsService.flushPermissions();
          let currentPermissions = [...(user?.userData?.permissions ?? [])];
          if (user) {
            if (caseData) {
              const found = caseTypes.find(
                caseType => caseType.caseTypeId === caseData.caseType.caseTypeId
              );
              if (found) {
                this.getCaseTypePermissions(found, user, caseData, currentPermissions);
              }
            }
            if (userPermissionsEnabled) {
              const currentNavigation = this.router.getCurrentNavigation();
              const theRoute = currentNavigation?.finalUrl?.toString();
              const selectedRole = user?.roles.find(x => x.roleId === user.selectedRoleId);
              const applicablePerm = user?.userData?.userPermissions
                ?.filter(x => x.roleId === selectedRole?.friendlyId)
                .map(x => x.permissionSets.filter(perm => perm.includes("global")))
                .flat();
              if (applicablePerm) {
                currentPermissions = Array.from(
                  new Set([...currentPermissions, ...applicablePerm])
                );
                this.ngxPermissionsService.loadPermissions(currentPermissions);
              }
              const userPermissions = user?.userData?.userPermissions?.find(
                x => theRoute?.includes(x.moduleId) && x.roleId === selectedRole?.friendlyId
              );
              if (userPermissions) {
                currentPermissions = Array.from(
                  new Set([...currentPermissions, ...userPermissions.permissionSets])
                );
              }
            }
          }
          // Load permissions into NgxPermissionsService (duplicates removed)
          this.ngxPermissionsService.loadPermissions(currentPermissions);
        })
      )
      .subscribe({
        error: (error: any) => {
          this.logger.errorEvent(error, `${this.constructor.name}.${this.initNgxPermissions.name}`);
        }
      });
  }

  /**
   * Get user's non-case type permissions
   * @param caseType The current case type object
   * @param user The current user object
   * @param currentPermissions Updated permissions by ref
   */
  private getCaseTypePermissions(
    caseType: CaseType,
    user: User,
    caseData: CaseData,
    currentPermissions: string[]
  ) {
    // From User Object get the selectedRoleFriendlyId and the UserId for the current loggued user
    const loggedInUserCurrentRole = user.roles.find(
      u => u.roleId === user.selectedRoleId
    )?.friendlyId;
    const userId = user.userId;
    // From Case Check if currentUser is added on CaseUsers
    const currentUserPermisionsOnCase = caseData.users.filter(
      x => x.userId == userId && x.roleId == user.selectedRoleId
    );

    // Get the roleResponsibilities assigned to the current user in the current case

    let roleResponsibilities: (string | undefined)[] = [];

    roleResponsibilities =
      currentUserPermisionsOnCase.length === 0
        ? [loggedInUserCurrentRole]
        : (roleResponsibilities = currentUserPermisionsOnCase.reduce(
            (roleResponsibilities: (string | undefined)[], caseUser: CaseUser) => {
              if (caseUser.requireAcceptance === true && caseUser.acceptanceStatus !== "accepted") {
                roleResponsibilities.push(`${loggedInUserCurrentRole}.pending-acceptance`);
              } else {
                if (
                  caseUser.responsibilityFriendlyId === null ||
                  caseUser.responsibilityFriendlyId === undefined
                ) {
                  roleResponsibilities.push(loggedInUserCurrentRole);
                } else {
                  roleResponsibilities.push(
                    `${loggedInUserCurrentRole}.${caseUser.responsibilityFriendlyId}`
                  );
                }
              }
              // doing a distinct before returning it.
              return [...new Set(roleResponsibilities.map(item => item))];
            },
            []
          ));

    // from CaseType let's evaluate if any roleResponsibility matches with any of the current user role.responsability
    caseType.rolePermissions.forEach(rolePermissionGroup => {
      const hasRoleResponsibility = rolePermissionGroup.roles.find(role =>
        roleResponsibilities.find(x => x === role.friendlyId)
      );
      if (hasRoleResponsibility) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentPermissions.push(...new Set(rolePermissionGroup.permissions.map(item => item!)));
      }
    });
  }

  userHasRoles = (roles: string[]): Observable<(string | undefined)[]> =>
    this.appStoreService.user$.pipe(
      map((user: User) => {
        if (user.roles && user.roles.length > 0) {
          return user.roles
            .filter(role => (role.friendlyId ? roles.includes(role.friendlyId) : false))
            .map(r => r.friendlyId);
        }
        return [];
      })
    );

  userSelectedRoleIncludes = (roles: string[]): Observable<boolean> =>
    this.appStoreService.user$.pipe(
      map(user => {
        if (user.roles && user.roles.length > 0) {
          const selectedRole = user.roles.find(role => role.roleId === user.selectedRoleId);
          if (selectedRole && selectedRole.friendlyId) {
            return roles.includes(selectedRole.friendlyId);
          }
          return false;
        }
        return false;
      })
    );

  hasPermission(
    caseType: CaseType,
    caseData: CaseData,
    user: User,
    requestedPermission: string
  ): boolean {
    // eslint-disable-next-line prefer-const
    let currentPermissions: string[] = [];
    currentPermissions = [...(user?.userData?.permissions ?? [])];

    // From User Object get the selectedRoleFriendlyId and the UserId for the current loggued user
    const loggedInUserCurrentRole = this.getloggedInUserCurrentRole(user);
    const userId = user.userId;

    // From Case Check if currentUser is added on CaseUsers
    const currentUserPermisionsOnCase = caseData.users.filter(
      x => x.userId == userId && x.roleId == user.selectedRoleId
    );

    // Get the roleResponsibilities assigned to the current user in the current case
    const roleResponsibilities = this.getRoleResponsabilities(
      currentUserPermisionsOnCase,
      loggedInUserCurrentRole
    );

    return this.evaluatePermissions(
      currentPermissions,
      caseType,
      roleResponsibilities,
      requestedPermission
    );
  }

  private getloggedInUserCurrentRole(user: User): string | undefined {
    // From User Object get the selectedRoleFriendlyId and the UserId for the current loggued user
    return user.roles.find(u => u.roleId === user.selectedRoleId)?.friendlyId;
  }

  private getRoleResponsabilities(
    currentUserPermisionsOnCase: CaseUser[],
    loggedInUserCurrentRole: string | undefined
  ): (string | undefined)[] {
    return currentUserPermisionsOnCase.length === 0
      ? [loggedInUserCurrentRole]
      : currentUserPermisionsOnCase.reduce(
          (roleResponsibilities: (string | undefined)[], caseUser: CaseUser) => {
            if (
              caseUser.responsibilityFriendlyId === null ||
              caseUser.responsibilityFriendlyId === undefined
            ) {
              roleResponsibilities.push(loggedInUserCurrentRole);
            } else {
              roleResponsibilities.push(
                `${loggedInUserCurrentRole}.${caseUser.responsibilityFriendlyId}`
              );
            }
            // doing a distinct before returning it.
            return [...new Set(roleResponsibilities.map(item => item))];
          },
          []
        );
  }

  private evaluatePermissions(
    currentPermissions: string[],
    caseType: CaseType,
    roleResponsibilities: (string | undefined)[],
    requestedPermission: string
  ): boolean {
    // from CaseType let's evaluate if any roleResponsibility matches with any of the current user role.responsability
    caseType.rolePermissions.forEach(rolePermissionGroup => {
      const hasRoleResponsibility = rolePermissionGroup.roles.find(role =>
        roleResponsibilities.includes(role.friendlyId)
      );
      if (hasRoleResponsibility) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentPermissions.push(...new Set(rolePermissionGroup.permissions.map(item => item!)));
      }
    });
    return currentPermissions.includes(requestedPermission);
  }

  /**
   * Defines a strategy for the first route the user currenly has permissions
   * to view and navigates to it. It will always navigate the router.
   *
   * @param defaultRoutesStrategy object with 'path' and 'only'
   */
  public defaultRoutesStrategy = (defaultRoutesStrategy: unknown[]) =>
    this.ngxPermissionsService.permissions$.pipe(
      filter(permissions => Object.keys(permissions).length !== 0),
      distinctUntilChanged((x, y) => Object.keys(x).length === Object.keys(y).length),
      tap(permissions => {
        const permissionValues = Object.keys(permissions);
        let defaultRoute = ["/"];
        defaultRoutesStrategy.some(route => {
          if (route && typeof route === "object") {
            if (hasOwnProperty(route, "path") && typeof route.path === "string") {
              if (hasOwnProperty(route, "only") && Array.isArray(route.only)) {
                if (route.only.some((x: string) => permissionValues.find(y => x === y))) {
                  defaultRoute = [route.path];
                  return true;
                }
              } else {
                defaultRoute = [route.path];
                return true;
              }
            }
          }
          return false;
        });
        this.router.navigate(defaultRoute);
      })
    );
}
