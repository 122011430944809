import { DeviceSpeaker, EventBase } from "@vp/models";

export interface DeviceSpeakersNotification {
  deviceUserId: string;
  deviceSpeakers: DeviceSpeaker[];
}

export class DeviceSpeakersUpdatedEvent extends EventBase<DeviceSpeakersNotification> {
  constructor(notification: DeviceSpeakersNotification) {
    super();
    this.args = notification;
  }
}
