/* eslint-disable @typescript-eslint/no-explicit-any */
import { guid } from "guid-factory";
import * as moment from "moment";
import { NIL as EMPTY_GUID, v4 as uuidCreate, validate as uuidValidate } from "uuid";
import { IPredicate } from "./utilities";
/**
 * Scoped property retrieval on an object. Gets the property/object at the location of props specificed by
 * an array of properties representing the "path."
 *
 * For Example:
 *  ["parent","child", "grandchild", "greatgrandchild"] = parent.child.grandchild.greatgrandchild
 *
 * @param obj Object - The target object to insert said properties into. By reference.
 * @param props Array - Array of properties representing the path at which to insert. See above for example.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueForPath = (obj: any, path: string): any => {
  const props = path.split(".");
  return getValueAtPath(obj, props);
};

/**
 * Scoped property retrieval on an object. Gets the property/object at the location of props specificed by
 * an array of properties representing the "path."
 *
 * For Example:
 *  ["parent","child", "grandchild", "greatgrandchild"] = parent.child.grandchild.greatgrandchild
 *
 * @param obj Object - The target object to insert said properties into. By reference.
 * @param props Array - Array of properties representing the path at which to insert. See above for example.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueAtPath = (obj: any, props: string[]): any => {
  const _props = props.slice();
  if (_props.length === 1 && _props[0] === "#") {
    return obj;
  } else if (_props.length > 1 && _props[0] === "#") {
    _props.shift();
  }
  const reduced = _props.reduce((result, prop) => {
    if (prop === "*") {
      return result;
    }

    if (Array.isArray(result)) {
      // array path with index e.g. "[0]"
      const regex = /\[(?<index>\d*)\]/;
      const match = prop.match(regex);
      if (match) {
        const index = match.groups?.index;
        return index ? result[+index] : undefined;
      }
    }

    return result ? result[prop] : undefined;
  }, obj);
  return obj && reduced;
};

/**
 * Scoped property creation on target object. Inserts the objToInsert object at the location of props specificed by
 * an array of properties representing the "path" of the object to insert:
 *
 * For Example, if the following array of properties is passed:
 *  ["parent","child", "grandchild", "greatgrandchild"]
 *
 * Then objToInsert is created at parent.child.grandchild.greatgrandchild in targetObject. This also creates any
 * missing nested objects along the way. So if grandchild is missing in the above example, then it is created,
 * and the greatgrandchild is then created using the objToInsert object.
 *
 * This will deep merge (includes full typed objects) nodes that exist. Applying the objToInsert over the top of
 * existing properties and values.
 *
 * Function takes single parameter containing the below properties to ensure the entire object is passed by reference.
 *
 * @param params Object - A single parameter containing the following parameters.
 *
 *  targetObject Object - The target object to insert said properties into. By reference.
 *  props Array - Array of properties representing the path at which to insert. See above for example.
 *  objecToInsert Object - the object to be inserted at the above path as the last property in the props array
 */
export const insert = (
  params: { targetObject: any; props: string[]; objToInsert: any },
  arrayAction: ArrayAction = "concat"
): void => {
  const _props = params.props.slice();
  // if the path is beyond root (#), then remove the root prop
  if (_props.length > 1 && _props[0] === "#") {
    _props.shift();
  }
  // Otherwise if we only have one prop and that prop is root then just insert the object and return
  else if (_props.length === 1 && _props[0] === "#") {
    params.targetObject = mergeDeep(params.targetObject, params.objToInsert, arrayAction);
    return;
  }
  // otherwise continue processing
  _props.reduce((result, prop, index, array) => {
    if (!result[prop]) {
      if (Array.isArray(result)) {
        // array path with index e.g. "[0]"
        const regex = /\[(?<index>\d*)\]/;
        const match = prop.match(regex);
        if (match) {
          const arrayIndex = match.groups?.index;
          if (arrayIndex) {
            const element = result[+arrayIndex];
            if (index === array.length - 1) {
              result[+arrayIndex] = mergeDeep(element, params.objToInsert, arrayAction);
              return result[+arrayIndex];
            }
            return element;
          }
        }
      }
      result[prop] = {};
    }
    if (index === array.length - 1) {
      result[prop] = mergeDeep(result[prop], params.objToInsert, arrayAction);
    }
    return result[prop];
  }, params.targetObject);
};

export const objectKeyValueFilter = (
  obj: any,
  keyFilter: string,
  valueFilters: string[],
  results: IKeyValueFilterResult[] = [],
  path = ""
) => {
  const accumulator = results;
  const addDelimiter = (a: string, b: string) => (a ? `${a}.${b}` : b);

  if (obj) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const fullPath = addDelimiter(path, key);
      if (key === keyFilter && typeof value !== "object" && valueFilters.includes(value)) {
        const parts = fullPath.split(".");
        parts.pop();
        accumulator.push({
          field: parts[parts.length - 1],
          path: parts.join("."),
          value: value
        } as IKeyValueFilterResult);
      } else if (typeof value === "object") {
        objectKeyValueFilter(value, keyFilter, valueFilters, accumulator, fullPath);
      }
    });
  }
  return accumulator;
};

export interface IKeyValueFilterResult {
  field: string;
  path: string;
  value: string;
}

export const deepCopy = (objectToCopy: any): any => {
  return JSON.parse(JSON.stringify(objectToCopy));
};

export const deeperCopy = (objectToCopy: any): any => {
  let copy;
  if (null === objectToCopy || "object" !== typeof objectToCopy) return objectToCopy;
  if (objectToCopy instanceof moment) {
    return moment(objectToCopy);
  }
  if (objectToCopy instanceof Date) {
    copy = new Date();
    copy.setTime(objectToCopy.getTime());
    return copy;
  }

  if (objectToCopy instanceof Array) {
    copy = [];
    for (let i = 0, len = objectToCopy.length; i < len; i++) {
      copy[i] = deeperCopy(objectToCopy[i]);
    }
    return copy;
  }
  if (objectToCopy instanceof Object) {
    const object: { [key: string]: any } = {};
    for (const attr in objectToCopy) {
      if (Object.prototype.hasOwnProperty.call(objectToCopy, attr)) {
        object[attr] = deeperCopy(objectToCopy[attr]);
      }
    }
    return object;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const getRecord = <T>(recordData: Record<string, unknown>, property: string) => {
  if (Object.prototype.hasOwnProperty.call(recordData, property)) {
    return recordData[property] as T;
  }
  return;
};

export type ArrayAction = "merge" | "concat" | "replace";

export const mergeDeep = (
  target: any,
  source: any,
  arrayAction: ArrayAction = "concat",
  ignoreMissing = true
) => {
  target = (obj => {
    let cloneObj;
    try {
      cloneObj = JSON.parse(JSON.stringify(obj));
    } catch (err) {
      // If the stringify fails due to circular reference, the merge defaults
      // to a less-safe assignment that may still mutate elements in the target.
      // You can change this part to throw an error for a truly safe deep merge.
      cloneObj = Object.assign({}, obj);
    }
    return cloneObj;
  })(target);

  const isObject = (obj: any) => obj && typeof obj === "object";

  if (!isObject(target) || !isObject(source)) return source;

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue))
      if (arrayAction === "merge") {
        target[key] = targetValue.map((x, i) =>
          sourceValue.length <= i ? x : mergeDeep(x, sourceValue[i], arrayAction, ignoreMissing)
        );
        if (sourceValue.length > targetValue.length)
          target[key] = target[key].concat(sourceValue.slice(targetValue.length));
      } else if (arrayAction === "concat") {
        target[key] = targetValue.concat(sourceValue);
      } else {
        target[key] = sourceValue;
      }
    else if (isObject(targetValue) && isObject(sourceValue))
      target[key] = mergeDeep(
        Object.assign({}, targetValue),
        sourceValue,
        arrayAction,
        ignoreMissing
      );
    else target[key] = sourceValue;
  });

  return target;
};

export const compare = (
  a: number | string | Date | undefined,
  b: number | string | Date | undefined,
  isAsc: boolean
) => {
  if (a && b) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } else {
    return 0;
  }
};

/**
 * Creates an object map with the key/values reversed for easy reverse lookup
 */
export class TwoWayMap {
  private map: Record<string, string>;
  private reverseMap: Record<string, string>;
  constructor(map: Record<string, string>) {
    this.map = map;
    this.reverseMap = {};
    for (const key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get = (key: string) => this.map[key];
  getKey = (key: string) => this.reverseMap[key];
}

/**
 * Check unknown types for a property to used in type guards
 */

export const hasOwnProperty = <X extends Record<never, unknown>, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const omitProperty = <T>(obj: T, key: keyof T): Partial<T> => {
  const { [key]: _, ...rest } = obj;
  return rest as Partial<T>;
};

export const isNullOrUndefined = <T>(obj: T | null | undefined): obj is null | undefined => {
  return typeof obj === "undefined" || obj === null;
};

export const isEmptyObject = (obj: any) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const uniqueArray = (a: any) =>
  [...new Set(a.map((o: any) => JSON.stringify(o)))].map((s: any) => JSON.parse(s));

export const getUtcNow = () => {
  const now = new Date();
  return now.toISOString();
};

export function intersectOn<T>(a: T[], b: T[], predicate: IPredicate): T[] {
  return a.reduce((items: T[], available: T) => {
    const found = b.find((item: T) => predicate(item, available));
    if (found) {
      items.push(found);
    }
    return items;
  }, []);
}

//https://stackoverflow.com/questions/16227197/compute-intersection-of-two-arrays-in-javascript/16227294#16227294
export const intersection = <T>(a: T[], b: T[]): T[] => {
  let t;
  if (b.length > a.length) (t = b), (b = a), (a = t); // indexOf to loop over shorter
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
};

/**
 * Recursively checks that object has at least one value that is not
 * null, empty, or undefined.
 * @param obj {any}
 * @returns {boolean}
 */
export const isNullish = (obj: any): boolean => {
  const nullish = Object.values(obj).every(value => {
    if (value === null || value === "" || value === undefined) {
      return true;
    } else if (typeof value === "object") {
      return isNullish(value);
    }
    return false;
  });
  return nullish;
};

export const getMissing = (fromValues: string[], inValues: string[]) =>
  fromValues.filter(
    item =>
      Array.from(new Set(inValues))
        .filter(id => fromValues.includes(id))
        .indexOf(item) < 0
  );

export function nameof<TObject>(obj: TObject, key: keyof TObject): string;
export function nameof<TObject>(key: keyof TObject): string;
export function nameof(key1: any, key2?: any): any {
  return key2 ?? key1;
}

export type DeepPartial<T> = Partial<{ [P in keyof T]: DeepPartial<T[P]> }>;

export function partialEqual(obj1: any, partial: any): boolean {
  if (obj1 == null || typeof obj1 !== "object" || partial == null || typeof partial !== "object") {
    return obj1 === partial;
  }
  for (const key of Object.keys(partial)) {
    if (!partialEqual(obj1[key], partial[key])) {
      return false;
    }
  }
  return true;
}

export function objectsEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }
  if (obj1 == null || typeof obj1 !== "object" || obj2 == null || typeof obj2 !== "object") {
    return false;
  }
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  for (const key of obj1Keys) {
    if (!objectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}

/**
 * Checks if an object contains a property, if the object is an array it checks that
 * every object in the array has the property.
 * @param obj
 * @param key
 * @returns {boolean}
 */
export function objectHasProperty(obj: any, key: any): boolean {
  if (typeof key === "string") {
    return Object.prototype.hasOwnProperty.call(obj, key);
  } else if (typeof key === "number") {
    return Object.prototype.hasOwnProperty.call(obj, key.toString());
  } else if (Array.isArray(key)) {
    return key.every(k => objectHasProperty(obj, k));
  }
  return false;
}

/**
 * Delete a property on an object given a path "/" notated path. eg /a/b/c or #/a/b/c (from a ui-schema)
 * @param obj {Record<string, unknown>} object reference
 * @param path Path to object
 * @returns {void}
 */
export function deleteProperty(obj: Record<string, unknown>, path: string): void {
  const pathElements = path.replace("#/", "/").split("/").filter(Boolean);
  if (pathElements.length === 0) {
    return;
  }
  const [current, ...remaining] = pathElements;
  if (!Object.prototype.hasOwnProperty.call(obj, current)) {
    return;
  }
  if (remaining.length === 0) {
    delete (obj as Record<string, unknown>)[current];
  } else {
    deleteProperty(obj[current] as Record<string, unknown>, remaining.join("/"));
  }
}

// https://stackoverflow.com/questions/26501688/a-typescript-guid-class

// export function newGuid(): string {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, character => {
//     // eslint-disable-next-line no-bitwise
//     const randomNumber = (Math.random() * 16) | 0;
//     // eslint-disable-next-line no-bitwise
//     const randomValue = character === "x" ? randomNumber : (randomNumber & 0x3) | 0x8;
//     return randomValue.toString(16);
//   });
// }

export function newGuid(): guid {
  return uuidCreate() as guid;
}

export function isValidGuid(guid: guid | string): boolean {
  const guidAsString = guid as string;
  return uuidValidate(guidAsString);
}

export function emptyGuid(): guid {
  return EMPTY_GUID;
}

const isPowerOfTwo = (x: number): boolean => {
  return x != 0 && (x & (x - 1)) == 0;
};

export function getEnumFlags<
  // eslint-disable-next-line @typescript-eslint/ban-types
  O extends object,
  K extends O[keyof O] = O[keyof O]
>(obj: O): K[] {
  const isFlag = (arg: string | number | K): arg is K => {
    const nArg = Number(arg);
    const isNumber = !Number.isNaN(nArg);
    return isNumber && isPowerOfTwo(nArg);
  };

  const enumFlags: K[] = [];

  Object.keys(obj).forEach(key => {
    const nKey = Number(key);
    if (isFlag(nKey)) {
      enumFlags.push(nKey);
    }
  });

  return enumFlags;
}

export function ExtendsClass<T>(
  instance: any,
  baseClass: new (...args: any[]) => T
): instance is T {
  let currentPrototype = Object.getPrototypeOf(instance);
  while (currentPrototype) {
    if (currentPrototype === baseClass.prototype) {
      return true;
    }
    currentPrototype = Object.getPrototypeOf(currentPrototype);
  }
  return false;
}

export function cleanUnused(obj: any): any {
  const clone = deeperCopy(obj);
  Object.keys(clone).forEach(key => {
    if (clone[key] === null || clone[key] === undefined) {
      delete clone[key];
    }
  });
  return clone;
}

export function checkRequired<T extends Record<string, unknown>>(
  object: T,
  requiredProperties: { [K in keyof T]-?: boolean }
): boolean {
  const missingProperties = Object.keys(requiredProperties).filter(
    property => requiredProperties[property] && !(property in object)
  );

  if (missingProperties.length > 0) {
    console.log(`Missing required properties: ${missingProperties.join(", ")}`);
    return false;
  }

  return true;
}

export function convertTo<T>(data: Record<string, unknown>): T {
  const newData = {} as T;
  for (const key in data) {
    if (!Object.prototype.hasOwnProperty.call(newData, key)) {
      (newData as any)[key] = data[key];
    }
  }
  return newData;
}

/**
 *
 * @param items T[] - array of items to sort
 * @param sort string - dot notated property path to sort by e.g. "parent.child.grandchild"
 * @param sortDirection string - asc or desc
 * @returns T[]
 */
export function sortItems<T>(items: T[], sort: string[], sortDirection: string): T[] {
  const sortedItems = [...items];
  sortedItems.sort((a, b) => {
    let comparison = 0;
    for (let i = 0; i < sort.length; i++) {
      const valueA = getValueForPath(a, sort[i]);
      const valueB = getValueForPath(b, sort[i]);

      if (typeof valueA === "string" && typeof valueB === "string") {
        comparison = valueA.localeCompare(valueB);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        comparison = valueA - valueB;
      }

      if (comparison !== 0) {
        // If the values are not equal, break the loop and return the comparison result
        break;
      }
    }

    // Apply sort direction
    if (sortDirection === "desc") {
      comparison = -comparison;
    }

    return comparison;
  });
  return sortedItems;
}

export function stringArraysEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export function swapArrayElement<T>(arr: T[], sourceIndex: number, targetIndex: number) {
  [arr[sourceIndex], arr[targetIndex]] = [arr[targetIndex], arr[sourceIndex]];
  return arr;
}

type BoolOrString = boolean | string;

export function equals(a: BoolOrString, b: BoolOrString): boolean {
  if (typeof a === "boolean" && typeof b === "string") {
    return (a ? "true" : "false") === b;
  } else if (typeof a === "string" && typeof b === "boolean") {
    return a === (b ? "true" : "false");
  } else {
    return a === b;
  }
}

export function isNullOrWhiteSpace(value: string | null | undefined): boolean {
  return value === null || value === undefined || value.trim() === "";
}

export interface Differences {
  added: string[];
  removed: string[];
}

export const getStringArrayDifferences = (previous: string[], current: string[]): Differences => {
  const previousSet = new Set(previous);
  const currentSet = new Set(current);
  const added = [...currentSet].filter(item => !previousSet.has(item));
  const removed = [...previousSet].filter(item => !currentSet.has(item));
  return {
    added,
    removed
  };
};
