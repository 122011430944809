import { EventBase } from "@vp/models";

export interface CallLightActivationNotificationEventArgs {
  caseId: string | undefined;
  caseDisplayName: string | undefined;
  deviceName: string;
  activatedBy: string;
  activationDateTime: string;
  deviceUserId: string;
  patientConsoleDeviceData: any;
}

export class CallLightActivatedEvent extends EventBase<CallLightActivationNotificationEventArgs> {
  constructor(args: CallLightActivationNotificationEventArgs) {
    super();
    this.args = args;
  }
}
