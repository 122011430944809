import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { CaseData } from "@vp/models";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "vp-security-popup",
  templateUrl: "./security-popup.component.html",
  styleUrls: ["./security-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityPopupComponent implements OnInit {
  permissionsArray$: Observable<string[]> | undefined = undefined;

  @Select(CaseState.current) caseData$!: Observable<CaseData>;

  constructor(public ngxPermissionsService: NgxPermissionsService) {}

  ngOnInit(): void {
    // Map permissions object to an interable array for display
    this.permissionsArray$ = this.ngxPermissionsService.permissions$.pipe(
      map(permissionsObject =>
        Object.keys(permissionsObject).map(perm => permissionsObject[perm].name)
      )
    );
  }

  opened = () => {
    console.log("Security Panel Opened");
  };
}
