import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { RouterModule } from "@angular/router";
import { FlexModule } from "@vp/shared/directives/flex";
import { NgxPermissionsModule } from "ngx-permissions";
import { TopNavListComponent } from "./top-nav-list.component";

@NgModule({
  imports: [CommonModule, MatDividerModule, RouterModule, NgxPermissionsModule, FlexModule],
  declarations: [TopNavListComponent],
  exports: [TopNavListComponent]
})
export class TopNavListModule {}
