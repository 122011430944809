import { Directive, HostBinding, Input } from "@angular/core";

@Directive({
  selector: "[flexLayoutGap]"
})
export class FlexLayoutGapDirective {
  private _gap!: string;
  private _display!: string;

  @Input() set flexLayoutGap(value: string) {
    const [gap, display] = value.split(" ");
    this._gap = gap;
    this._display = display || "flex";
  }

  @HostBinding("style.gap") get gap(): string {
    return this._gap;
  }

  @HostBinding("style.display") get display(): string {
    return this._display;
  }

  constructor() {}
}
